import { z } from "zod"
import { ZReactionCount } from "/js/models/Chat"
import { del, getZod, patchZod, postZod } from "/js/composables/useAxios"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { useServiceHelpers } from "/js/services/useServiceHelpers"
import type { Identifiable } from "/js/models/Identifiable"
import { ZIdentifiable } from "/js/models/Identifiable"

export const ZObjectiveVisibilityPrivateGoal = z.literal("private_goal")
export const ZObjectiveVisibilityPublicGoal = z.literal("public_goal")

export const ZGoalTaskStatusPending = z.literal("pending")
export const ZGoalTaskStatusCompleted = z.literal("completed")
export const ZGoalTaskStatusMissed = z.literal("missed")

export const ZGoalTaskEventComplete = z.literal("complete")
export const ZGoalTaskEventUncomplete = z.literal("uncomplete")

export const ZGoalTaskEvent = z.enum([
  ZGoalTaskEventComplete.value,
  ZGoalTaskEventUncomplete.value,
])

export const ZObjectiveVisibility = z.enum([
  ZObjectiveVisibilityPrivateGoal.value,
  ZObjectiveVisibilityPublicGoal.value,
])

export const ZGoalTaskStatus = z.enum([
  ZGoalTaskStatusPending.value,
  ZGoalTaskStatusCompleted.value,
  ZGoalTaskStatusMissed.value,
])

export const ZGoalObjective = z.object({
  id: z.string(),
  visibility: ZObjectiveVisibility,
  description: z.string(),
  achievability_description: z.string(),
  measurability_description: z.string(),
  relevance_description: z.string(),
  due_date: z.date().nullable(),
  reactions: ZReactionCount.array().nullable(),
  comments_count: z.number().nullable(),
  reactions_count: z.number().nullable(),
  tasks_count: z.number().nullable(),
  completed_at: z.date().nullable(),
})

export const ZGoalTask = z.object({
  id: z.string(),
  description: z.string(),
  due_date: z.date().nullable(),
  recurring_days: z.number().nullable(),
  status: ZGoalTaskStatus,
  completable_start_at: z.date().nullable(),
  completable_end_at: z.date().nullable(),
  completable: z.boolean(),
  parent_task_id: z.string().nullable(),
  updated_at: z.date(),
})

export type GoalObjective = z.infer<typeof ZGoalObjective>
export type GoalTask = z.infer<typeof ZGoalTask>

export const ZGoalObjectiveParams = z.object({
  visibility: ZObjectiveVisibility.optional(),
  description: z.string().optional(),
  due_date: z.date().nullable().optional(),
  completed: z.boolean().nullable().optional(),
  achievability_description: z.string().optional(),
  measurability_description: z.string().optional(),
  relevance_description: z.string().optional(),
})

export const ZGoalTaskParams = z.object({
  description: z.string().optional(),
  due_date: z.date().nullable().optional(),
  recurring_days: z.number().nullable().optional(),
})

export type GoalObjectiveParams = z.infer<typeof ZGoalObjectiveParams>
export type GoalTaskParams = z.infer<typeof ZGoalTaskParams>
export type ObjectiveVisibility = z.infer<typeof ZObjectiveVisibility>
export type GoalTaskEvent = z.infer<typeof ZGoalTaskEvent>

export const GoalsApi = {
  getUserGoals: async (userId: string): Promise<GoalObjective[]> =>
    await getZod(`/api/users/${userId}/goal_objectives`, ZGoalObjective.array()),

  getMyGoals: async (): Promise<GoalObjective[]> =>
    await getZod(`/api/goal_objectives`, ZGoalObjective.array()),

  getGoalObjective: async (goalObjectiveId: string): Promise<GoalObjective> =>
    await getZod(`/api/goal_objectives/${goalObjectiveId}`, ZGoalObjective),

  createGoalObjective: async (goal_objective: GoalObjectiveParams): Promise<GoalObjective> =>
    await postZod(`/api/goal_objectives`, { goal_objective }, ZGoalObjective),

  updateGoalObjective: async (goal_objective_id: string, goal_objective: GoalObjectiveParams): Promise<GoalObjective> =>
    await patchZod(`/api/goal_objectives/${goal_objective_id}`, { goal_objective }, ZGoalObjective),

  deleteGoalObjective: async (goal_objective_id: string): Promise<void> =>
    await del(`/api/goal_objectives/${goal_objective_id}`),

  getGoalTasks: async (goalObjectiveId: string): Promise<GoalTask[]> =>
    await getZod(`/api/goal_objectives/${goalObjectiveId}/goal_tasks`, ZGoalTask.array()),

  createGoalTask: async (goalObjectiveId: string, goal_task: GoalTaskParams): Promise<GoalTask> =>
    await postZod(`/api/goal_objectives/${goalObjectiveId}/goal_tasks`, { goal_task }, ZGoalTask),

  updateGoalTask: async (goalTaskId: string, goal_task: GoalTaskParams): Promise<GoalTask> =>
    await patchZod(`/api/goal_tasks/${goalTaskId}`, { goal_task }, ZGoalTask),

  updateGoalTaskEvent: async (goalTaskId: string, event: GoalTaskEvent): Promise<GoalTask> =>
    await patchZod(`/api/goal_tasks/${goalTaskId}/${event}`, {}, ZGoalTask),

  deleteGoalTask: async (goalTaskId: string): Promise<void> =>
    await del(`/api/goal_tasks/${goalTaskId}`),

  toggleGoalObjectiveReaction: async (goalObjectiveId: string, reaction: string, native: string): Promise<GoalObjective> =>
    await postZod(`/api/goal_objectives/${goalObjectiveId}/user_reactions`, {
      user_reaction: {
        reaction,
        native,
      },
    }, ZGoalObjective),

  generateGoalObjectiveFields: async (description: string): Promise<Identifiable> =>
    await postZod(`/api/goal_objectives/generate`, { description }, ZIdentifiable),

  generateGoalObjectiveTasks: async (goalId: string): Promise<Identifiable> =>
    await postZod(`/api/goal_objectives/${goalId}/generate_tasks`, {}, ZIdentifiable),
}

export const useTaskService = () => {
  const queryClient = useQueryClient()
  const { optimisticDelete, modifyItem } = useServiceHelpers(queryClient)

  const goalTaskListQueryKey = (goalObjectiveId: string) => ["goalTasks", goalObjectiveId]

  const getGoalTasksQuery = (goalObjectiveId: string) => {
    return useQuery({
      queryKey: goalTaskListQueryKey(goalObjectiveId),
      queryFn: async () => await GoalsApi.getGoalTasks(goalObjectiveId),
    })
  }

  const updateGoalTaskEvent = useMutation({
    mutationFn: async (params: { goalId: string, goalTaskId: string; event: GoalTaskEvent }) => {
      return await GoalsApi.updateGoalTaskEvent(params.goalTaskId, params.event)
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: goalTaskListQueryKey(params.goalId) })
      // await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(params.goalId) }) // FIX THIS. find out what's not working below
      // const previousItems = queryClient.getQueryData<GoalTask[]>(goalTaskListQueryKey(params.goalId))
      // modifyItem<GoalTask>(goalTaskListQueryKey(params.goalId), params.goalId, (item) => {
      //   return { ...item, status: params.event === "complete" ? "completed" : "pending" }
      // })
      // return { previousItems }
    },
    onError: async (err, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
      // if (context?.previousItems) {
      //   console.log("replacing with previous items", context.previousItems)
      //   queryClient.setQueryData<GoalTask[]>(goalTaskListQueryKey(variables.goalId), context.previousItems)
      // }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
    },
  })

  const deleteGoalTask = useMutation({
    mutationFn: async (params: { goalId: string, goalTaskId: string }) => {
      return await GoalsApi.deleteGoalTask(params.goalTaskId)
    },
    onMutate: async (params) => {
      const queryKey = goalTaskListQueryKey(params.goalId)
      await queryClient.cancelQueries({ queryKey })
      return optimisticDelete<GoalTask>(queryKey, "id", params.goalId)
    },
    onError: (err, variables, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData<GoalTask[]>(goalTaskListQueryKey(variables.goalId), context.previousItems)
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
    },
  })

  const updateGoalTask = useMutation({
    // TODO: look at updateGoalTaskEvent and once that is working, refactor this to use the same pattern
    mutationFn: async (params: { goalId: string, goalTaskId: string, goalTask: GoalTaskParams }) => {
      return await GoalsApi.updateGoalTask(params.goalTaskId, params.goalTask)
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: goalTaskListQueryKey(params.goalId) })
      // await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(params.goalTaskId) })
    },
    onError: async (err, variables, context) => {
      // await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalTaskId) })
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
    },
  })

  const createGoalTask = useMutation({
    mutationFn: async (params: { goalId: string, goalTask: GoalTaskParams }) => {
      return await GoalsApi.createGoalTask(params.goalId, params.goalTask)
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: goalTaskListQueryKey(params.goalId) })
    },
    onError: async (err, variables, context) => {
      // await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: goalTaskListQueryKey(variables.goalId) })
    },
  })

  return {
    updateGoalTaskEvent,
    getGoalTasksQuery,
    goalTaskListQueryKey,
    deleteGoalTask,
    updateGoalTask,
    createGoalTask,
  }
}
