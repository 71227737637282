<template>
  <div class="flex items-center gap-4">
    <DashboardIcon class="h-9 w-9" />
    <div class="flex flex-col gap-0.5">
      <div class="text-sm">{{ option.title }}</div>
      <div class="text-secondary text-xs">
        Feed •
        <option-box-product-primary-link
          @clickProduct="emit('clickProduct')"
          :disableInternalLinks
          :productId="option.product_id"
          :productTitle="option.product_title" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DashboardIcon from "/js/components/icons/DashboardIcon.vue"
import type { NavigationResourcePostFeed } from "/js/components/NavigationResources/NavigationResourcesTypes"
import OptionBoxProductPrimaryLink from "/js/components/GlobalSearch/OptionBoxProductPrimaryLink.vue"

defineProps<{
  option: NavigationResourcePostFeed
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()
</script>

<script lang="ts">
export default {
  name: "PostFeedOptionBox",
}
</script>
