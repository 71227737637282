<template>
  <FormKit
    type="form"
    form-class="flex flex-col gap-5"
    :actions="false"
    #default="{ disabled }"
    @submit="handleSubmit">
    <div class="flex items-center gap-2" v-if="user && feed && !post">
      <user-avatar-view :user />

      <div class="flex flex-col items-start gap-1">
        <div class="text-sm">{{ fullName(user) }}</div>
        <div class="text-secondary text-xs">
          Posting in
          <span class="rounded bg-emerald-200 px-2 py-px text-emerald-800">{{ feed.title }}</span>
        </div>
      </div>
    </div>

    <div class="flex items-center gap-8">
      <button
        type="button"
        @click="defaultShowCoverImage = true"
        :disabled="showCoverImage"
        class="text-secondary hover:text-primary disabled:hover:text-secondary flex items-center gap-2 text-sm disabled:opacity-50">
        <PhotoIcon class="h-6 w-6" />
        Add Cover Image
      </button>

      <button
        type="button"
        @click="defaultShowAttachments = true"
        :disabled="showAttachments"
        class="text-secondary hover:text-primary disabled:hover:text-secondary flex items-center gap-2 text-sm disabled:opacity-50">
        <LinkIcon class="h-6 w-6" />
        Add Attachment
      </button>

      <button
        type="button"
        v-if="canManagePoll"
        @click="defaultShowPoll = true"
        :disabled="showPoll"
        class="text-secondary hover:text-primary disabled:hover:text-secondary flex items-center gap-2 text-sm disabled:opacity-50">
        <chart-bar-icon class="h-5 w-5" />
        Add Poll
      </button>
    </div>

    <FormKit
      type="text"
      v-model="title"
      name="title"
      :label="titleLabel"
      :placeholder="titlePlaceHolder"
      validation="required"
      :disabled="showPoll && !isPollEditable" />

    <FormKit v-model="body" name="body" label="Body" :disabled="showPoll && !isPollEditable">
      <template #inner>
        <formkit-input-wrapper>
          <div class="editor-minimal editor-medium px-4">
            <editor-wrapper
              placeholder="Share something great"
              :initial-data="post?.body"
              @editor-data="body = $event" />
          </div>
        </formkit-input-wrapper>
      </template>
    </FormKit>

    <FormKit
      help="Add a cover image"
      name="cover_image_id"
      label="Cover Image"
      v-if="showCoverImage">
      <template #inner>
        <media-gallery-image-input
          :scope="coverMediaGalleryScope"
          assistant
          v-model="coverImage"
          :disabled="!!disabled" />
      </template>
    </FormKit>

    <FormKit type="text" label="Attachments" v-if="showAttachments">
      <template #inner>
        <media-gallery-attachments-input
          :scope="attachmentsMediaGalleryScope"
          v-model="attachments" />
      </template>
    </FormKit>
    <FormKit
      v-model="poll"
      name="poll"
      label="Poll"
      v-if="showPoll && canManagePoll"
      :actions="false">
      <template #inner>
        <div v-if="showPoll" class="card border border-gray-200 p-4">
          <poll-form-fields v-model="pollParams" :disabled="!isPollEditable" />
        </div>
      </template>
    </FormKit>

    <div v-if="false" class="flex flex-col gap-4" v-auto-animate>
      <switch-button v-model="isScheduled"> Set a schedule for this post</switch-button>

      <div class="grid gap-4 xl:grid-cols-2" v-if="isScheduled">
        <date-time-component-field
          v-model="startDate"
          label="Schedule for date"
          name="schedule_at" />

        <FormKit
          v-model="startTime"
          name="start_time"
          type="time"
          validation="required"
          label="Time" />
      </div>
    </div>
    <div class="flex justify-end">
      <button type="submit" class="btn btn-primary self-start" :disabled="!!disabled">
        {{ props.post ? "Update" : "Create" }} Post
      </button>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import { computed, reactive, ref, watch } from "vue"
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"
import EditorWrapper from "/js/components/utilities/Editor/EditorWrapper.vue"
import type { ProductAttachment } from "/js/models/Product"
import DateTimeComponentField from "/js/components/utilities/FormFields/DateTimeComponentField.vue"
import SwitchButton from "/js/components/utilities/FormFields/SwitchButton.vue"
import { ChartBarIcon } from "@heroicons/vue/24/outline"
import type { Post, PostParams } from "/js/models/Post"
import { PostsApi } from "/js/services/PostsApi"
import MediaGalleryImageInput from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGalleryImageInput.vue"
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import MediaGalleryAttachmentsInput from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGalleryAttachmentsInput.vue"
import { get24HourTime } from "/js/composables/get24HourTime"
import { useGate } from "/js/composables/useGate"
import { fullName } from "/js/models/User"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import UserAvatarView from "/js/components/UserAvatarView.vue"
import { useQuery } from "@tanstack/vue-query"
import PhotoIcon from "/js/components/icons/PhotoIcon.vue"
import LinkIcon from "/js/components/icons/LinkIcon.vue"
import { useDraggingDataSource } from "/js/composables/useDraggingDataSource"
import { toastError } from "/js/composables/toast"
import { type PollOptionParams, type PollParams } from "/js/models/Poll"
import { pollQueryKey, PollsApi } from "/js/services/PollsApi"
import PollFormFields from "/js/components/Polls/PollFormFields.vue"

const props = defineProps<{
  productId: string
  postFeedId: string
  post?: Post
}>()

const title = ref<string>("")
const body = ref<any | undefined>(undefined)
const coverImage = ref<ProductAttachment | undefined>()

const { productGateStatus } = useGate()

const { data: user } = useCurrentUserService().load()

const { data: feed } = useQuery({
  queryKey: computed(() => ["postFeed", props.postFeedId]),
  queryFn: async () => await PostsApi.getPostFeed(props.postFeedId),
  enabled: computed(() => !props.post),
})

const coverMediaGalleryScope = computed((): MediaGalleryScope => {
  if (productGateStatus(props.productId, "content_manage") === "authorized") {
    return { type: "product", id: props.productId, accept: "image" }
  } else {
    return { type: "user", id: props.productId, accept: "image" }
  }
})

const attachmentsMediaGalleryScope = computed((): MediaGalleryScope => {
  if (productGateStatus(props.productId, "content_manage") === "authorized") {
    return { type: "product", id: props.productId }
  } else {
    return { type: "user", id: props.productId }
  }
})

const titlePlaceHolder = computed(() => {
  if (showPoll.value) {
    return "Ask a question"
  } else {
    return "Enter a title (required)"
  }
})

const titleLabel = computed(() => {
  if (showPoll.value) {
    return "Question"
  } else {
    return "Title"
  }
})

const canManagePoll = computed(
  () => productGateStatus(props.productId, "content_manage") === "authorized"
)

const attachments = ref<ProductAttachment[]>([])
const poll = ref<PollParams | undefined>()
const pollParams = reactive<PollParams>({
  question: "",
  poll_options_attributes: [
    {
      text: "",
    },
    {
      text: "",
    },
  ],
})

/** Poll is editable only if there are no responses yet */
const isPollEditable = computed(
  () =>
    !props.post?.poll_id ||
    (attachedPoll.value && canManagePoll.value && attachedPoll.value.total_votes === 0)
)

const startDate = ref<Date>(new Date(Date.now() + 10 * 60 * 1000))
const startTime = ref<string>(get24HourTime(startDate.value))
const isScheduled = ref<boolean>(false)
const defaultShowCoverImage = ref(!!props.post?.cover_image || !!coverImage.value)
const defaultShowAttachments = ref(!!props.post?.attachments?.length || !!attachments.value.length)
const defaultShowPoll = ref(!!props.post?.poll_id)

const showCoverImage = computed(() => defaultShowCoverImage.value || !!coverImage.value)
const showAttachments = computed(() => defaultShowAttachments.value || !!attachments.value.length)
const showPoll = computed(() => defaultShowPoll.value || !!poll.value)

const params = computed(
  (): PostParams => ({
    title: title.value,
    body: body.value || {},
    cover_image_id: coverImage.value?.id || null,
    attachments_ids: attachments.value.map((a) => a.id),
  })
)

const emit = defineEmits<{
  saved: [post: Post]
}>()

const handleSubmit = async (formData: any, node: FormKitNode) => {
  node?.clearErrors()
  try {
    let post: Post
    if (props.post) {
      post = await PostsApi.updatePost(props.post.id, params.value)
    } else {
      post = await PostsApi.createPost(props.postFeedId, params.value)
    }
    if (isPollEditable.value && defaultShowPoll.value && post) {
      if (!pollParams) {
        toastError("Poll is required")
        return
      }
      if (pollParams.poll_options_attributes.length < 2) {
        toastError("Minimum 2 Poll options are required")
        return
      }
      // Poll question is always the post title
      pollParams.question = params.value.title

      if (props.post?.poll_id) {
        await PollsApi.updatePoll(props.post?.poll_id, pollParams)
      } else {
        await PollsApi.createPoll({
          poll_params: pollParams,
          pollable_type: "Post",
          pollable_id: post.id,
        })
      }
    }
    emit("saved", post)
  } catch (e) {
    node?.setErrors(...getFormKitErrorMessage(e, formData))
  }
}

const { data: attachedPoll } = useQuery({
  queryKey: computed(() => (props.post?.poll_id ? pollQueryKey(props.post?.poll_id) : ["poll"])),
  queryFn: async () => {
    if (props.post?.poll_id) {
      return await PollsApi.getPoll(props.post?.poll_id)
    }
    return null
  },
  enabled: computed(() => !!props.post?.poll_id),
})

watch(
  () => props.post,
  (post) => {
    if (post) {
      title.value = post.title
      body.value = post.body
      coverImage.value = post.cover_image || undefined
      attachments.value = post.attachments || []
    }
  },
  { immediate: true }
)

watch(
  () => attachedPoll.value,
  (_poll) => {
    if (_poll) {
      pollParams.question = _poll.question
      pollParams.end_time = _poll.end_time
      pollParams.poll_options_attributes = _poll.poll_options.map((option) => {
        return {
          id: option.id,
          text: option.text,
        }
      })
    }
  },
  { immediate: true }
)

</script>

<script lang="ts">
export default {
  name: "PostEditForm",
}
</script>
