<template>
  <modal v-model="showModal" :title="title">
    <div class="gap-padding flex flex-col">
      <template v-if="modelType === 'community'">
        <create-channel-form
          :model-type="{ type: 'community' }"
          @save="handleCreateRoom"
          @cancel="modelType = undefined" />
      </template>

      <template v-else-if="modelType === 'product'">
        <FormKit type="select" multiple label="Select the Learning Hub to add the channel to">
          <template #inner>
            <multi-product-select v-model="productId" />
          </template>
        </FormKit>

        <template v-if="productId">
          <create-channel-form
            :model-type="{ type: 'product', productId: productId }"
            @save="handleCreateRoom" />
        </template>
      </template>
    </div>
  </modal>
</template>

<script setup lang="ts">
import Modal from "/js/components/Modals/Modal.vue"
import { computed, nextTick, ref } from "vue"
import type { ChatRoomListItem } from "/js/models/Chat"
import CreateChannelForm from "/js/components/Chat/CreateChannelForm.vue"
import MultiProductSelect from "/js/components/utilities/FormFields/MultiProductSelect.vue"
import { generateChatRoute } from "/js/router/ChatRoutes"
import { useChatStoreV2 } from "/js/stores/ChatStoreV2"
import { useRouter } from "vue-router"

export type ChannelType = "community" | "product"

const props = defineProps<{
  channelId?: string | undefined
}>()

const { insertChatRoom } = useChatStoreV2()
const router = useRouter()

const productId = ref<string | undefined>()

const modelType = defineModel<"community" | "product" | undefined>()

const emit = defineEmits<{
  save: [room: ChatRoomListItem]
}>()

const showModal = computed({
  get() {
    return modelType.value !== undefined
  },
  set(value) {
    if (!value) {
      modelType.value = undefined
    }
  },
})

const title = computed(() => {
  if (modelType.value === "community") {
    return "Create Community Channel"
  } else if (modelType.value === "product") {
    return "Create Learning Hub Channel"
  }
  return ""
})

const handleCreateRoom = (room: ChatRoomListItem) => {
  insertChatRoom(room)
  nextTick(() => {
    // TODO: a context should be pushed to the props that says if we should push to the channels section or the product/community section
    if (room.product_id) {
      router.push(generateChatRoute("product", "show", { room_id: room.id, product_id: room.product_id }))
    } else {
      router.push(generateChatRoute("community", "show", { room_id: room.id }))
    }
  })
  showModal.value = false
  emit("save", room)
}
</script>

<script lang="ts">
export default {
  name: "CreateChannelModal",
}
</script>
