import { computed } from 'vue'
import { nanoid } from 'nanoid'

export const sourceTypes = [
  'vimeoEmbed',
  'youtubeEmbed',
  'googleSheetsEmbed',
  'googleDocsEmbed',
  'typeformEmbed',
] as const

export type EmbedSourceType = (typeof sourceTypes)[number]

export type EmbedConfig = {
  name: string
  type: EmbedSourceType
  regex: RegExp
  embedUrl: string
  placeholderUrl: string
  matchesGroup: Record<string, number>
}

const sources: Record<EmbedSourceType, EmbedConfig> = {
  vimeoEmbed: {
    type: 'vimeoEmbed',
    name: 'Vimeo',
    regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
    embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
    placeholderUrl: 'https://vimeo.com/XXXXXXX',
    matchesGroup: { remote_id: 1 },
  },
  youtubeEmbed: {
    type: 'youtubeEmbed',
    name: 'YouTube',
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
    placeholderUrl: 'https://www.youtube.com/watch?v=XXXXXXX',
    embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
    matchesGroup: { remote_id: 1 },
  },
  googleSheetsEmbed: {
    type: 'googleSheetsEmbed',
    name: 'Google Sheets',
    regex: /(?:https?:\/\/)?(?:www\.)?(?:docs\.google\.com\/spreadsheets\/d\/)([^\/\?\&\=]+)/,
    placeholderUrl:
      'https://docs.google.com/spreadsheets/d/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    embedUrl:
      'https://docs.google.com/spreadsheets/d/<%= remote_id %>/pubhtml?widget=true&amp;headers=false',
    matchesGroup: { remote_id: 1 },
  },
  googleDocsEmbed: {
    type: 'googleDocsEmbed',
    name: 'Google Docs',
    regex: /(?:https?:\/\/)?(?:www\.)?(?:docs\.google\.com\/document\/d\/)([^\/\?\&\=]+)/,
    placeholderUrl: 'https://docs.google.com/document/d/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    embedUrl: 'https://docs.google.com/document/d/<%= remote_id %>/pub?embedded=true',
    matchesGroup: { remote_id: 1 },
  },
  typeformEmbed: {
    type: 'typeformEmbed',
    name: 'TypeForm',
    regex: /(?:https?:\/\/)?((?:[\w\d-]+))\.(?:typeform\.com\/to\/)([^\/\?\&\=]+)/,
    placeholderUrl: 'https://abcd1234.typeform.com/to/XXXXXXXX',
    embedUrl: 'https://<%= subdomain %>.typeform.com/to/<%= remote_id %>',
    matchesGroup: { subdomain: 1, remote_id: 2 },
  },
}

export default sources

export const getMatchObject = (url: string, source: EmbedConfig) => {
  const matches = url.match(source.regex)
  if (!matches) return undefined

  return Object.keys(source.matchesGroup).map((key) => {
    return {
      key,
      value: matches[source.matchesGroup[key] as number],
    }
  })
}

export const getEmbedUrl = (sourceUrl: string, source: EmbedConfig) => {
  const matchValues = getMatchObject(sourceUrl, source)
  if (!matchValues) return undefined

  let url = source.embedUrl

  if (source.type === "googleDocsEmbed" || source.type === "googleSheetsEmbed") {
    // if url ends with pub
    if (sourceUrl.endsWith("/pub")) {
      return sourceUrl
    }
  }

  matchValues.forEach((match) => {
    url = url.replace(`<%= ${match.key} %>`, match.value as string)
  })
  return url
}

export const isValidResourceUrl = (url: string | null | undefined, source: EmbedConfig) => {
  return url && source && source.regex.test(url)
}

export const buildContent = (blocks: any[]) => {
  return {
    blocks,
    time: Date.now(),
  }
}

export const buildEmbedBlock = (url: string | undefined, sourceType: EmbedSourceType) => {
  return {
    data: {
      url,
    },
    type: sourceType,
  }
}

export const buildEmptyParagraphBlock = () => {
  return { type: 'paragraph', data: { text: '' } }
}