import { type RouteRecordRaw } from "vue-router"
import { communityEditMeta, communityReadMeta } from "/js/router/meta"

export const eventDateRouteFormatted = (date: Date): string => {
  return date.toISOString().split("T")[0]!
}

export const namedRoutes = {
  events_index: "community_events.index",
  events_date_index: "community_events.date_index",
  events_edit: "community_events.edit",
} as const

export const communityEventsRoutes: RouteRecordRaw[] = [
  {
    name: namedRoutes.events_index,
    path: "",
    redirect: {
      name: namedRoutes.events_date_index,
      params: { date: eventDateRouteFormatted(new Date()) },
    },
    meta: communityReadMeta
  },
  {
    name: namedRoutes.events_date_index,
    path: "date/:date",
    component: () => import("/js/components/Events/CommunityEvents.vue"),
    props: (route) => {
      if (route.params.date && typeof route.params.date === "string") {
        const date = new Date(route.params.date)
        if (!isNaN(date.getTime())) {
          return { date }
        }
      }
      return { date: undefined }
    },
    meta: communityReadMeta,
    children: [
      {
        name: namedRoutes.events_edit,
        path: ":event_id/edit",
        component: () => import("/js/components/Events/CommunityEvents.vue"),
        meta: communityEditMeta
      }
    ]
  },
]
