<template>
  <div v-if="data?.text">
    {{ data.text }}
  </div>
</template>

<script setup lang="ts">
import type { PartialGptMessageTextGenerate } from "/js/components/GptChat/TextGpt/TextGptModels"

const props = defineProps<{
  data?: PartialGptMessageTextGenerate
}>()
</script>

<script lang="ts">
export default {
  name: "TextGptAssistantMessage",
}
</script>
