<template>
  <mounted-teleport to="#sidebar-section-layout-top-right">
    <slot/>
  </mounted-teleport>
</template>

<script setup lang="ts">
import MountedTeleport from "/js/components/MountedTeleport.vue"
</script>

<script lang="ts">
export default {
  name: "SidebarSectionTitle",
}
</script>
