import type { BlockCardProps, BlockWidget } from "/js/models/Widget"

export const CommunityBlockCard: BlockCardProps = {
  title: "Community Heading",
  description: "Highlight your community with a custom heading",
  bg: "bg-blue-400",
  blockType: "HeaderWidget",
}

export const MembersBlockCard: BlockCardProps = {
  title: "Members",
  description: "Prompt social connections within your community",
  bg: "bg-yellow-400",
  blockType: "MembersWidget",
}

export const FeaturedBlockCard: BlockCardProps = {
  title: "Featured Block",
  description: "Spotlight Learning Hubs from your community",
  bg: "bg-orange-400",
  blockType: "FeaturedProductsWidget",
}

export const RichTextBlockCard: BlockCardProps = {
  title: "Rich Text",
  description: "Customize your content with our rich text editor",
  bg: "bg-red-400",
  blockType: "RichTextWidget",
}

export const ContentBlockCard: BlockCardProps = {
  title: "Content",
  description: "Embed existing content from your community",
  bg: "bg-sky-300",
  blockType: "ContentWidget",
}

export const EventsBlockCard: BlockCardProps = {
  title: "Events",
  description: "Highlight the most recent and next upcoming events",
  bg: "bg-purple-600",
  blockType: "EventWidget",
}

export const FeedBlockCard: BlockCardProps = {
  title: "Feed",
  description: "Highlight the latest posts from across your community",
  bg: "bg-green-500",
  blockType: "FeedWidget",
}

export const AccordionBlockCard: BlockCardProps = {
  title: "Accordion",
  description: "Create collapsible sections of content",
  bg: "bg-pink-500",
  blockType: "AccordionWidget",
}

export const InstructorsBlockCard: BlockCardProps = {
  title: "Instructors",
  description: "Showcase your instructors",
  bg: "bg-blue-400",
  blockType: "InstructorsWidget",
}

export const CurriculumBlockCard: BlockCardProps = {
  title: "Curriculum",
  description: "Highlight the curriculum for your Learning Hub",
  bg: "bg-yellow-400",
  blockType: "CurriculumWidget",
}

export const ChatRoomBlockCard: BlockCardProps = {
  title: "Channels",
  description: "Connect with your community in real-time",
  bg: "bg-orange-400",
  blockType: "ChatRoomWidget",
}

export const homeBlockCards: BlockCardProps[] = [
  CommunityBlockCard,
  MembersBlockCard,
  FeaturedBlockCard,
  RichTextBlockCard,
  ContentBlockCard,
  EventsBlockCard,
  FeedBlockCard,
  AccordionBlockCard,
  ChatRoomBlockCard
]

export const hubOverviewBlockCards: BlockCardProps[] = [
  RichTextBlockCard,
  InstructorsBlockCard,
  AccordionBlockCard,
  CurriculumBlockCard,
  ChatRoomBlockCard
]

export const communityRegistrationBlockCards: BlockCardProps[] = [
  CommunityBlockCard,
  RichTextBlockCard,
  AccordionBlockCard
]

export const makeBlockWidgetCards = (blockCards: BlockCardProps[]): BlockWidget[] => {
  return blockCards.map((c): BlockWidget => ({
    id: `block_${c.blockType}`,
    type: "block",
    data: c,
  }))
}


