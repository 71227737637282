<template>
  <div class="flex gap-2">
    <button :disabled="disabled" type="button" class="btn btn-danger" @click="emit('remove')">
      Remove
    </button>
    <button :disabled="disabled" type="button" class="btn btn-primary" @click="emit('change')">
      Change Image
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  disabled: boolean
}>()

const emit = defineEmits<{
  change: []
  remove: []
}>()
</script>

<script lang="ts">
export default {
  name: "ExistingImageActions",
}
</script>
