<template>
  <input
    :accept="inputAccept"
    type="file"
    class="hidden"
    ref="fileInput"
    :multiple="multiple"
    @change="onFileChange" />
  <slot v-bind="{ selectFile }"></slot>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { mediaSupportedMimeTypes } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"

const props = defineProps<{
  accept?: SupportedMedia | SupportedMedia[]
  multiple?: boolean
}>()

const inputAccept = computed(() => mediaSupportedMimeTypes(props.accept)?.join(","))

const fileInput = ref<HTMLInputElement | null>(null)

const selectFile = () => {
  fileInput.value?.click()
}

defineExpose({
  selectFile,
})

const emit = defineEmits<{
  files: [value: File[]]
}>()

const onFileChange = (e: Event) => {
  const fileList = (e.target as HTMLInputElement).files
  if (!fileList) return
  emit("files", Array.from(fileList))
}

</script>

<script lang="ts">
export default {
  name: "FileInput",
}
</script>
