<template>
  <modal
    v-model="showModal"
    :title
    :hideTopBar
    :noBackdropClose
    :modalType
    :bg
    :sizeType
    :rightModalWidthClass
    :noPadding>
    <template #default="{ close }">
      <slot v-if="cachedItem" v-bind="{ data: cachedItem, close }" />
    </template>
  </modal>
</template>

<script setup lang="ts" generic="T">
import { computed, ref, watch } from "vue"
import type { Ref } from "vue"
import Modal from "/js/components/Modals/Modal.vue"
import type { ItemModalProps } from "/js/components/Modals/ModalTypes"

const props = defineProps<ItemModalProps<T>>()

const cachedItem = ref(props.modelValue) as Ref<T | undefined>

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      cachedItem.value = value
    } else {
      setTimeout(() => {
        cachedItem.value = undefined
      }, 300) // 200 is the duration of the modal transition
    }
  }
)

const item = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const emit = defineEmits<{
  "update:modelValue": [value: T | undefined]
}>()

const showModal = computed({
  get: () => !!item.value,
  set: (value) => {
    if (!value) {
      if (props.confirmModalClose) {
        if (confirm("You have unsaved changes. Are you sure you want to leave?")) {
          item.value = undefined
        }
      } else {
        item.value = undefined
      }
    }
  },
})
</script>

<script lang="ts">
export default {
  name: "ItemModal",
}
</script>
