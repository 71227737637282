<template>
  <content-view :isError :isEmpty="isSuccess && data?.length === 0" :isLoading>
    <template #empty>
      <empty-view title="No events found" subtitle="">
        <events-empty-icon />
      </empty-view>
    </template>
    <template #error>
      <error-view title="Something went wrong" subtitle="Try refreshing the page.">
        <member-empty-icon />
      </error-view>
    </template>

    <template #default>
      <responsive-table class="text-sm" :class="{ 'opacity-60': isLoading }">
        <thead class="bg-primary text-secondary text-left">
          <tr>
            <th class="p-3">Name</th>
            <th class="p-3">Confirmed</th>
            <th class="p-3">Attended</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in data" :key="event.id" class="text-primary group hover:bg-gray-50">
            <td>
              <router-link
                class="flex items-center gap-2 p-3"
                :to="{
                  name: namedRoutes.events_edit,
                  params: { event_id: event.id, date: eventDateRouteFormatted(event.start_date) },
                }">
                <calendar-day-box size="sm" :date="event.start_date" />
                {{ event.name }}
              </router-link>
            </td>
            <td class="p-3">
              {{ event.attendance ? "Yes" : "No" }}
            </td>
            <td class="p-3">
              {{ event.attendance?.attended_at ? "Yes" : "-" }}
            </td>
          </tr>
        </tbody>
      </responsive-table>
    </template>
  </content-view>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import { computed } from "vue"
import { UsersApi } from "/js/services/UsersApi"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import MemberEmptyIcon from "/js/components/icons/MemberEmptyIcon.vue"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import ResponsiveTable from "/js/components/Admin/ResponsiveTable.vue"
import CalendarDayBox from "/js/components/utilities/UI/CalendarDayBox.vue"
import { eventDateRouteFormatted, namedRoutes } from "/js/router/EventsRoutes"
import EventsEmptyIcon from "/js/components/icons/EventsEmptyIcon.vue"

const props = defineProps<{
  userId: string
}>()

const { data, isError, isSuccess, isLoading } = useQuery({
  queryKey: computed(() => ["userEvents", props.userId]),
  queryFn: async () => await UsersApi.getUserEvents(props.userId),
})
</script>

<script lang="ts">
export default {
  name: "UserEventAttendanceList",
}
</script>
