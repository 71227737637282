export type BtnVariant = 'default' | 'danger'

export const radixBtnClass = (variant: BtnVariant) => {
  const standardButtonClass = 'w-full text-left px-4 py-2 text-sm select-none outline-none';

  switch (variant) {
    case 'default':
      return `${standardButtonClass} data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900 text-gray-700 data-[disabled]:opacity-50 data-[disabled]:text-gray-500`;
    case 'danger':
      return `${standardButtonClass} data-[highlighted]:bg-gray-100 data-[highlighted]:text-red-700 text-red-600 data-[disabled]:opacity-50 data-[disabled]:text-gray-500`;
  }
}