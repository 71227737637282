<template>
  <gpt-chat-process-view-modal
    v-model="showModal"
    :modalType
    title="Generate Image"
    placeholder="Describe how your image should look like"
    @generated="handleGenerate"
    :params="{
      process_type: 'generate_image',
    }">
    <template #default="{ openGpt }">
      <button :disabled="generating" type="button" class="btn btn-primary btn-sm" @click.prevent.stop="openGpt">
        {{ generating ? "Please Wait..." : "Generate Image" }}
      </button>
    </template>
  </gpt-chat-process-view-modal>
</template>

<script setup lang="ts">
import GptChatProcessViewModal from "/js/components/GptChat/GptChatProcessViewModal.vue"
import type { GptMessage } from "/js/services/GptApi"
import { ref } from "vue"
import { toastError } from "/js/composables/toast"
import type { ModalProps } from "/js/components/Modals/ModalTypes"

const props = defineProps<{
  modalType?: ModalProps["modalType"]
}>()

const emit = defineEmits<{
  image: [image: File]
}>()

const showModal = ref(false)
const generating = ref(false)

const handleGenerate = async (message: GptMessage) => {
  const remoteUrl = message.gpt_image?.data_url
  if (!remoteUrl) return

  generating.value = true
  showModal.value = false

  try {
    const data = await fetch(remoteUrl, { cache: 'no-cache' })
    const blob = await data.blob()
    const file = new File([blob], "downloaded-image.jpg", { type: blob.type })
    emit("image", file)
  } catch (e) {
    toastError("Sorry, there was an error while working with this image.")
    console.error(e)
  } finally {
    generating.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: "ImageGenerator",
}
</script>
