<template>
  <div
    class="flex shrink-0 items-center justify-center bg-slate-200 relative"
    :class="[sizes[size], roundedClass]">
    <gradient-image
      :id
      class="h-full w-full"
      :class="roundedClass"
      :url="avatarUrl"
      v-if="!showText"/>

    <span class="text-primary" :class="textSizes[size]" v-else-if="text">
      {{ text }}
    </span>

    <div class="absolute bottom-0 right-0 p-px bg-white rounded-full" v-if="verified" v-tooltip="'Verified'">
      <VerifiedIcon class="text-accent" :class="badgeSizes[size]" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AvatarSize } from "/js/components/RoundAvatarTypes"
import GradientImage from "/js/components/GradientImage.vue"
import { computed } from "vue"
import VerifiedIcon from "/js/components/icons/VerifiedIcon.vue"

type Props = {
  id?: string
  avatarUrl?: string | null | undefined
  size?: AvatarSize
  text?: string
  rounded?: boolean | string
  noText?: boolean
  verified?: boolean | null
}

const sizes: Record<AvatarSize, string> = {
  xs: "h-6 w-6",
  sm: "h-9 w-9",
  sm2: "h-11 w-11",
  md: "h-20 w-20",
  lg: "h-32 w-32",
  xl: "h-64 w-64",
} as const

const textSizes: Record<AvatarSize, string> = {
  xs: "text-xxs",
  sm: "text-sm",
  sm2: "text-base",
  md: "text-base",
  lg: "text-4xl",
  xl: "text-7xl",
} as const

const badgeSizes: Record<AvatarSize, string> = {
  xs:  "h-4 w-4",
  sm:  "h-4 w-4",
  sm2: "h-4 w-4",
  md:  "h-6 w-6",
  lg:  "h-8 w-8",
  xl:  "h-8 w-8",
} as const

const props = withDefaults(defineProps<Props>(), {
  size: "sm",
  rounded: true,
})

const roundedClass = computed(() => {
  if (props.rounded === true) {
    return "rounded-full"
  } else if (props.rounded === false) {
    return ""
  } else if (typeof props.rounded === "string") {
    return `${props.rounded}`
  }

  return ""
})

const showText = computed(() => {
  return !props.noText && !props.avatarUrl
})
</script>

<script lang="ts">
export default {
  name: "RoundAvatar",
}
</script>
