<template>
  <dropdown-menu menu-items-wrapper-class="max-w-[150px]">
    <template #button>
      <slot name="button">
        <PlusSquareIcon class="btn-zoom h-6 w-6" />
      </slot>
    </template>

    <dropdown-menu-item @click="showCreateChannel = 'community'">
      <div class="flex flex-col gap-2">
        <div class="font-medium">Community Discussion</div>
        <div class="text-secondary text-xs">
          A discussion channel that is not tied to any Learning Hub.
        </div>
      </div>
    </dropdown-menu-item>

    <dropdown-menu-item @click="showCreateChannel = 'product'">
      <div class="flex flex-col gap-2">
        <div class="font-medium">Learning Hub Discussion</div>
        <div class="text-secondary text-xs">
          A discussion channel that is only available to members from a specific Learning Hub.
        </div>
      </div>
    </dropdown-menu-item>
  </dropdown-menu>
</template>

<script setup lang="ts">

import DropdownMenu from "/js/components/DropdownMenu.vue";
import PlusSquareIcon from "/js/components/icons/PlusSquareIcon.vue";
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue";
import type {ChannelType} from "/js/components/Chat/CreateChannelModal.vue";

const showCreateChannel = defineModel<ChannelType>()

</script>

<script lang="ts">
export default {
  name: "CreateChannelDropdown"
}
</script>