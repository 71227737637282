import { z } from "zod"
import { ZCourseOutlineQuizQuestion } from "/js/components/GptChat/CourseOutline/CourseOutlineModels"
import type { DeepPartialNullable } from "/js/types/PartialTypes"

export const ZGenerateQuizQuestions = z.object({
  questions: ZCourseOutlineQuizQuestion.array(),
})

export type GenerateQuizQuestions = z.infer<typeof ZGenerateQuizQuestions>

export type PartialGenerateQuizQuestions = DeepPartialNullable<GenerateQuizQuestions>