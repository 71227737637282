<template>
  <div v-if="data && data.message_type === 'generate_image'">
    <img class="max-w-full" v-if="data.gpt_image" :src="data.gpt_image.data_url || undefined" alt="Generated image" />
    <div v-else-if="data.status === 'pending'" class="flex gap-2 items-center">
      <span>Analyzing...</span>
      <spinner class="h-4 w-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GptMessage } from "/js/services/GptApi"
import Spinner from "/js/components/icons/Spinner.vue"

const props = defineProps<{
  data?: GptMessage
}>()
</script>

<script lang="ts">
export default {
  name: "GenerateImageAssistantMessage",
}
</script>