<template>
  <item-modal
    v-model="item"
    #default="{ data }"
    :title
    :noBackdropClose="loading"
    :hideTopBar
    :modalType
    :sizeType
    :noPadding>
    <div class="flex flex-col gap-14">
      <slot :data="data"></slot>

      <div class="flex justify-end gap-4">
        <button
          :disabled="loading"
          class="btn btn-danger relative"
          @click="item && emit('confirm', item)">
          <span v-if="loading" class="absolute inset-0 flex items-center justify-center">
            <spinner class="h-4 w-4 text-white" />
          </span>
          <span :class="{ invisible: loading }">
            <slot name="confirm" :data="data">Delete</slot>
          </span>
        </button>
        <button class="btn btn-secondary" @click="item = undefined">Cancel</button>
      </div>
    </div>
  </item-modal>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue"
import ItemModal from "/js/components/Modals/ItemModal.vue"
import type { ConfirmationModalProps } from "/js/components/Modals/ModalTypes"
import Spinner from "/js/components/icons/Spinner.vue"

const props = defineProps<ConfirmationModalProps<T>>()

const item = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const emit = defineEmits<{
  "update:modelValue": [value: T | undefined]
  cancel: []
  confirm: [value: T]
}>()
</script>

<script lang="ts">
export default {
  name: "ConfirmationItemModal",
}
</script>
