<template>
  <post-option-box
    v-if="option.type === 'Post'"
    :option="option.object"
    :disableInternalLinks
    @clickProduct="emit('clickProduct')" />

  <product-option-box
    v-else-if="option.type === 'Product'"
    :option="option.object" />

  <user-option-box v-else-if="option.type === 'User'" :option="option.object" />

  <event-option-box
    v-else-if="option.type === 'Event'"
    :option="option.object"
    :disableInternalLinks
    @clickProduct="emit('clickProduct')" />

  <post-feed-option-box
    v-else-if="option.type === 'PostFeed'"
    :option="option.object"
    :disableInternalLinks
    @clickProduct="emit('clickProduct')" />

  <chat-room-option-box
    v-else-if="option.type === 'ChatRoom'"
    :option="option.object"
    :disableInternalLinks
    @clickProduct="emit('clickProduct')" />
</template>

<script setup lang="ts">

import ProductOptionBox from "/js/components/GlobalSearch/ProductOptionBox.vue"
import UserOptionBox from "/js/components/GlobalSearch/UserOptionBox.vue"
import PostFeedOptionBox from "/js/components/GlobalSearch/PostFeedOptionBox.vue"
import ChatRoomOptionBox from "/js/components/GlobalSearch/ChatRoomOptionBox.vue"
import PostOptionBox from "/js/components/GlobalSearch/PostOptionBox.vue"
import EventOptionBox from "/js/components/GlobalSearch/EventOptionBox.vue"
import type { NavigationResource } from "/js/components/NavigationResources/NavigationResourcesTypes"

defineProps<{
  option: NavigationResource
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()

</script>

<script lang="ts">
export default {
  name: "NavigationOptionBox",
}
</script>