<template>
  <slot v-bind="{ openGpt }" v-if="!skipButton">
    <button @click="openGpt">Chat with AI</button>
  </slot>
  <modal v-model="showGpt" :initialFocus="promptRef" :title noBackdropClose :modalType>
    <delay-view next-tick>
      <gpt-chat-process-view-inner
        :params
        :defaultPrompt
        :placeholder
        @generated="emit('generated', $event)" />
    </delay-view>
  </modal>
</template>

<script setup lang="ts">
import Modal from "/js/components/Modals/Modal.vue"
import { ref } from "vue"
import { type CreateChatParams, type GptMessage } from "/js/services/GptApi"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import GptChatProcessViewInner from "/js/components/GptChat/GptChatProcessViewInner.vue"
import DelayView from "/js/components/Chat/DelayView.vue"

const props = withDefaults(
  defineProps<{
    title?: string
    params?: CreateChatParams
    modalType?: ModalProps["modalType"]
    skipButton?: boolean
    defaultPrompt?: string
    placeholder?: string
  }>(),
  {
    title: "Chat with AI",
    modalType: "headless",
  }
)

const emit = defineEmits<{
  generated: [message: GptMessage]
}>()

const showGpt = defineModel<boolean>({ default: false })
const promptRef = ref<HTMLElement>() // used to set initial focus / pass to modal

const openGpt = () => (showGpt.value = true)
</script>

<script lang="ts">
export default {
  name: "GptChatProcessViewModal",
}
</script>
