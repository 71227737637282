<template>
  <div>
    <button class="btn btn-primary btn-sm" @click="goalId = undefined">View All Goals</button>
    <content-view :isError :isEmpty="isSuccess && !goal" :isLoading>
      <template #error>
        <error-view title="Error loading goal" :error action="Try Again" @on-action="refetch">
          <events-empty-icon />
        </error-view>
      </template>
      <template #default>
        <div class="flex flex-col gap-4 py-8" v-if="goal">
          <goal-details-items :goal class="flex flex-col gap-4" />
          <public-user-goal-tasks :goalId="goal.id" />
          <goal-objective-user-reactions :goal />
          <goal-objective-comments :goal />
        </div>
      </template>
    </content-view>
  </div>
</template>

<script setup lang="ts">
import GoalObjectiveUserReactions from "/js/components/Goals/GoalObjectiveUserReactions.vue"
import GoalTasks from "/js/components/Profile/GoalTasks.vue"
import GoalObjectiveComments from "/js/components/Goals/GoalObjectiveComments.vue"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import EventsEmptyIcon from "/js/components/icons/EventsEmptyIcon.vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import GoalDetailsCard from "/js/components/Goals/GoalDetailsCard.vue"
import { useQuery } from "@tanstack/vue-query"
import { computed } from "vue"
import { GoalsApi } from "/js/services/GoalsApi"
import GoalDetailsItems from "/js/components/Goals/GoalDetailsItems.vue"
import PublicUserGoalTasks from "/js/components/Goals/PublicUserGoals/PublicUserGoalTasks.vue"

const props = defineProps<{
  userId: string
}>()

const goalId = defineModel<string>()

const {
  data: goal,
  isLoading,
  isError,
  isSuccess,
  refetch,
  error,
} = useQuery({
  queryKey: computed(() => ["goalObjective", goalId.value]),
  queryFn: async () => await GoalsApi.getGoalObjective(goalId.value ?? ""),
  enabled: computed(() => !!goalId.value),
})

</script>

<script lang="ts">
export default {
  name: "PublicUserGoalDetails",
}
</script>