<template>
  <SwitchGroup as="div" class="flex items-center gap-2">
    <Switch
      :disabled="disabled"
      v-model="enabled"
      :class="[
        enabled ? 'bg-teal-600' : 'bg-gray-200',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]">

      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]">
        <span
          v-if="hasIconOff"
          :class="[
            enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center text-gray-400 transition-opacity',
          ]"
          aria-hidden="true">
          <slot name="iconOff"></slot>
        </span>

        <span
          v-if="hasIconOn"
          :class="[
            enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center text-teal-600 transition-opacity',
          ]"
          aria-hidden="true">
          <slot name="iconOn"></slot>
        </span>
      </span>
    </Switch>
    <SwitchLabel as="span" class="text-secondary cursor-pointer text-sm" v-if="hasLabel">
      <slot></slot>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup lang="ts">
import { Switch, SwitchLabel, SwitchGroup } from "@headlessui/vue"
import { computed, useSlots, watch } from "vue"

const slots = useSlots()

const hasIconOn = slots.iconOn !== undefined
const hasIconOff = slots.iconOff !== undefined
const hasLabel = slots.default !== undefined

type Props = {
  modelValue: boolean
  icon?: boolean
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  icon: false,
})

const enabled = defineModel<boolean>()

const emit = defineEmits<{
  change: [value: boolean]
}>()

watch(enabled, (value) => {
  if (value !== undefined) {
    emit("change", value)
  }
})

</script>

<script lang="ts">
export default {
  name: "SwitchButton",
}
</script>
