<template>
  <div class="flex w-full flex-1 flex-col gap-12">
    <flash :type="flash.type" :message="flash.message" v-if="flash" />
    <h3 class="text-primary text-center font-medium">Sign Up</h3>

    <FormKit type="form" :actions="false" #default="{ disabled }" @submit="handleSubmit">
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-4">
          <FormKit
            type="text"
            v-model="firstName"
            name="first_name"
            validation-label="First Name"
            placeholder="First Name" />
          
          <FormKit
            type="text"
            v-model="lastName"
            name="last_name"
            validation-label="Last Name"
            placeholder="Last Name" />

          <FormKit
            type="text"
            v-model="email"
            autocomplete="off"
            name="email"
            placeholder="Email address"
            validation-visibility="dirty"
            validation="required" />

          <FormKit
            type="password"
            v-model="password"
            autocomplete="off"
            name="password"
            placeholder="Password"
            validation-visibility="dirty"
            validation="required" />
        </div>

        <div class="flex flex-col gap-4">
          <button type="submit" class="btn btn-primary px-10 py-3" :disabled="!!disabled">
            Sign Up for Free
          </button>

          <div class="text-primary text-center">
            By signing up for Zixat, you agree to the
            <router-link to="/terms" class="primary-link">Terms of Service</router-link>
            and the
            <router-link to="/privacy" class="primary-link">Privacy Policy</router-link>
            .
          </div>

          <form-kit-messages />
        </div>
      </div>
    </FormKit>

    <template v-if="mode === 'signUp'">
      <div class="text-primary text-center">
        Have an account?
        <button type="button" class="primary-link" @click="emit('switchRegistration', 'signIn')">
          Sign In
        </button>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import type { RegistrationType } from "/js/components/Registration/RegistrationTypes"
import type { FormKitNode } from "@formkit/core"
import { FormKitMessages } from "@formkit/vue"
import CheckBoxInner from "/js/components/utilities/FormFields/CheckBoxInner.vue"
import { ProfileApi } from "/js/services/ProfileApi"
import type { UserParams } from "/js/services/ProfileApi"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import Flash from "/js/components/Registration/Flash.vue"
import { useFlash } from "/js/composables/useFlash"

const props = defineProps<{
  invitationEmail?: string | null
  invitationToken?: string | null
}>()

type Mode = "signUp" | "invitation"

const mode = computed((): Mode => {
  if (props.invitationEmail) {
    return "invitation"
  } else {
    return "signUp"
  }
})

const email = ref(props.invitationEmail || "")
const firstName = ref("")
const lastName = ref("")
const password = ref("")

const flash = useFlash()

const emit = defineEmits<{
  switchRegistration: [value: RegistrationType]
}>()

const handleSubmit = async (formData: Record<string, unknown>, node: FormKitNode) => {
  node.clearErrors()
  try {
    let params: UserParams = {
      email: email.value,
      password: password.value,
      first_name: firstName.value,
      last_name: lastName.value,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    if (props.invitationToken) {
      params = {
        ...params,
        token: props.invitationToken,
      }
    }

    await ProfileApi.create(params)
    window.location.reload()
  } catch (e) {
    console.log(e)
    node.setErrors(...getFormKitErrorMessage(e, formData))
  }
}
</script>

<script lang="ts">
export default {
  name: "SignUpForm",
}
</script>
