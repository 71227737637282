<template>
  <slot name="error" v-if="isError" />

  <slot name="empty" v-else-if="isEmpty" />

  <slot name="loading" v-else-if="isLoading">
    <div class="flex h-full w-full items-center justify-center py-24">
      <spinner class="h-24 w-24 text-teal-500"></spinner>
    </div>
  </slot>
  <slot name="default" v-else></slot>
</template>

<script setup lang="ts">
import Spinner from "/js/components/icons/Spinner.vue"

type Props = {
  isError: boolean
  isLoading: boolean
  isEmpty: boolean
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: "ContentView",
}
</script>
