<template>
  <div>
    <main-sidebar-button :route="{ name: adminRouteNames.dashboard }">
      <template #link="{ isActive }">
        <PentagonFillIcon class="h-6 w-6" v-if="isActive" />
        <PentagonIcon class="h-6 w-6" v-else />
        Dashboard
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: settingsRouteNames.general }">
      <template #link="{ isActive }">
        <CogIconFill class="h-6 w-6" v-if="isActive" />
        <CogIcon class="h-6 w-6" v-else />
        Settings
      </template>
      <template #subnav>
        <main-sidebar-subnav
          :route="{ name: settingsRouteNames.registrationEdit }"
          title="Registration Page" />
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: membersRouteNames.members }">
      <template #link="{ isActive }">
        <MultiUserFillIconSm class="h-6 w-6" v-if="isActive" />
        <MultiUserIconSm class="h-6 w-6" v-else />
        Members
      </template>
      <template #subnav>
        <main-sidebar-subnav :route="{ name: membersRouteNames.invites }" title="Invites" />
        <main-sidebar-subnav :route="{ name: membersRouteNames.groups }" title="Groups" />
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: adminRouteNames.events }">
      <template #link="{ isActive }">
        <CalendarFillIcon class="h-6 w-6" v-if="isActive" />
        <CalendarIcon class="h-6 w-6" v-else />
        Events
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: adminRouteNames.learning_hubs }">
      <template #link="{ isActive }">
        <LearningHubsFillIcon class="h-6 w-6" v-if="isActive" />
        <LearningHubsIcon class="h-6 w-6" v-else />
        Learning Hubs
      </template>
      <template #subnav>
        <main-sidebar-subnav :route="{ name: learningHubsRouteNames.plans }" title="Plans" />
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: contentRouteNames.content }">
      <template #link="{ isActive }">
        <BookFillIcon class="h-6 w-6" v-if="isActive" />
        <BookIcon class="h-6 w-6" v-else />
        Content
      </template>
      <template #subnav>
        <main-sidebar-subnav
          :route="{ name: contentRouteNames.mediaAssets }"
          title="Media Assets" />
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: surveysRouteNames.surveys }">
      <template #link="{ isActive }">
        <ClipboardFillIcon class="h-6 w-6" v-if="isActive" />
        <ClipboardIcon class="h-6 w-6" v-else />
        Surveys
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: pollsRouteNames.polls }">
      <template #link="{ isActive }">
        <ChartBarIconFill class="h-6 w-6" v-if="isActive" />
        <ChartBarIcon class="h-6 w-6" v-else />
        Polls
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: billingRouteNames.billing }">
      <template #link="{ isActive }">
        <BanknotesIconFill class="h-6 w-6" v-if="isActive" />
        <BanknotesIcon class="h-6 w-6" v-else />
        Billing
      </template>
      <template #subnav>
        <button
          class="text-primary flex items-center gap-2 px-6 py-1 text-left text-sm"
          @click="goToBilling">
          Manage
          <spinner class="h-4 w-4" v-if="billingLoading" />
        </button>
      </template>
    </main-sidebar-button>
  </div>
</template>

<script setup lang="ts">
import MainSidebarButton from "/js/components/Layout/Sidebar/MainSidebarButton.vue"
import MainSidebarSubnav from "/js/components/Layout/Sidebar/MainSidebarSubnav.vue"
import {
  adminRouteNames,
  billingRouteNames,
  contentRouteNames,
  learningHubsRouteNames,
  membersRouteNames,
  pollsRouteNames,
  settingsRouteNames,
  surveysRouteNames,
} from "/js/router/AdminRoutes"
import MultiUserFillIconSm from "/js/components/icons/MultiUserFillIconSm.vue"
import MultiUserIconSm from "/js/components/icons/MultiUserIconSm.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import LearningHubsIcon from "/js/components/icons/LearningHubsIcon.vue"
import LearningHubsFillIcon from "/js/components/icons/LearningHubsFillIcon.vue"
import BookIcon from "/js/components/icons/BookIcon.vue"
import BookFillIcon from "/js/components/icons/BookFillIcon.vue"
import CogIcon from "/js/components/icons/CogIcon.vue"
import CogIconFill from "/js/components/icons/CogIconFill.vue"
import { BanknotesIcon, ChartBarIcon } from "@heroicons/vue/24/outline"
import { BanknotesIcon as BanknotesIconFill } from "@heroicons/vue/24/solid"
import { ChartBarIcon as ChartBarIconFill } from "@heroicons/vue/24/solid"
import { ref } from "vue"
import Spinner from "/js/components/icons/Spinner.vue"
import { PlansApi } from "/js/services/PlansApi"
import { toastError } from "/js/composables/toast"
import { PlatformPlansApi } from "/js/services/PlatformPlansApi"
import PentagonFillIcon from "/js/components/icons/PentagonFillIcon.vue"
import PentagonIcon from "/js/components/icons/PentagonIcon.vue"
import ClipboardIcon from "/js/components/icons/ClipboardIcon.vue"
import ClipboardFillIcon from "/js/components/icons/ClipboardFillIcon.vue"

const billingLoading = ref(false)
const goToBilling = async () => {
  if (billingLoading.value) {
    return
  }
  try {
    billingLoading.value = true
    const billingLink = await PlatformPlansApi.getBillingLink()
    window.location.href = billingLink.url
  } catch (e) {
    toastError(e)
  } finally {
    billingLoading.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: "AdminSidebar",
}
</script>
