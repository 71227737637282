<template>
  <button
    :disabled="disabled"
    type="button"
    @click="emit('select')"
    :class="{
      'border-teal-600 text-teal-600': selected,
      'border-gray-200 hover:border-gray-300 text-gray-500': !selected,
    }"
    class="p-4 border-2 rounded-lg flex flex-col gap-3">
    <slot name="header"></slot>
    <span class="flex gap-2">
      <component :is="icon" v-if="icon" class="w-6 h-6" />
      <span class="font-medium text-black text-base">{{ text }}</span>
    </span>
    <span v-if="description" class="text-left text-sm text-gray-500" v-html="description"></span>
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  selected: boolean
  disabled?: boolean
  icon?: any
  text: string
  description?: string | null
}>()

const emit = defineEmits<{
  select: []
}>()
</script>

<script lang="ts">
export default {
  name: 'SelectableButton',
}
</script>
