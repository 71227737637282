<template>
  <sign-up-form
    v-if="registrationType === 'signUp'"
    @switchRegistration="registrationType = $event" />

  <sign-in-form
    v-else-if="registrationType === 'signIn'"
    @switchRegistration="registrationType = $event" />

  <confirmation-new
    type="resendConfirmation"
    v-else-if="registrationType === 'confirmationNew'"
    @switchRegistration="registrationType = $event" />

  <confirmation-new type="forgotPassword"
    v-else-if="registrationType === 'forgotPassword'"
    @switchRegistration="registrationType = $event" />

</template>

<script setup lang="ts">
import type { RegistrationType } from "/js/components/Registration/RegistrationTypes"
import SignUpForm from "/js/components/Registration/SignUpForm.vue"
import SignInForm from "/js/components/Registration/SignInForm.vue"
import { computed } from "vue"
import ConfirmationNew from "/js/components/Registration/ConfirmationNew.vue"

const props = defineProps<{
  modelValue: RegistrationType
}>()

const emit = defineEmits<{
  "update:modelValue": [value: RegistrationType]
}>()

const registrationType = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})
</script>

<script lang="ts">
export default {
  name: "RegistrationContainer",
}
</script>