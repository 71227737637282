<template>
  <div class="card flex flex-col gap-4 p-6">
    <post-card-header :post @update="postToEdit = $event" @delete="postToDelete = $event" />

    <component :is="truncate ? TruncateView : 'div'" class="flex flex-col gap-4 pt-4 z-0">
      <button @click="openAttachment = post.cover_image" class="w-full" v-if="post.cover_image">
        <img
          :src="attachmentImageUrl(post.cover_image)"
          alt="post image"
          class="max-h-64 w-full rounded-lg bg-gray-50 object-cover" />
      </button>

      <div>
        <router-link
          :to="{
            name: learningHubRoutes.learning_hubs_posts_show,
            params: {
              post_id: post.id,
              product_id: post.product_id,
              post_feed_id: post.post_feed_id,
            },
          }">
          <h5>{{ post.title }}</h5>
        </router-link>

        <div class="editor-minimal readonly text-secondary text-sm">
          <editor-wrapper :initial-data="post.body" readonly />
        </div>

        <div v-if="post.poll_id">
          <poll-card-loader :poll-id="post.poll_id" />
        </div>

      </div>

      <div class="flex flex-wrap items-center gap-4" v-if="post.attachments">
        <asset-card v-for="asset in attachmentAssets" :asset :key="asset.id" />
      </div>
    </component>

    <post-likes
      :likes-count="post.post_likes_count"
      :comments-count="post.comments_count"
      :liked="post.followed_by_current_user"
      @comment="handleCommentClick(post)"
      @follow="emit('follow', post)" />

    <slot></slot>
  </div>

  <modal-product-attachment v-model="openAttachment" />

  <confirmation-item-modal
    v-model="postToDelete"
    :loading="removingPost"
    title="Delete Post?"
    @confirm="handleDeletePost">
    Are you sure you want to delete this post?
  </confirmation-item-modal>

  <item-modal v-model="postToEdit" #default="{ data }" title="Edit Post">
    <post-edit-form
      :post="data"
      :product-id="post.product_id"
      :post-feed-id="post.post_feed_id"
      @saved="handleUpdatePost" />
  </item-modal>
</template>

<script setup lang="ts">
import EditorWrapper from "/js/components/utilities/Editor/EditorWrapper.vue"
import PostLikes from "/js/components/Posts/PostLikes.vue"
import ModalProductAttachment from "/js/components/Modals/ModalProductAttachment.vue"
import { computed, ref } from "vue"
import { attachmentImageUrl, type ProductAttachment } from "/js/models/Product"
import ConfirmationItemModal from "/js/components/Modals/ConfirmationItemModal.vue"
import { PostsApi } from "/js/services/PostsApi"
import { toastError, toastSuccess } from "/js/composables/toast"
import ItemModal from "/js/components/Modals/ItemModal.vue"
import PostEditForm from "/js/components/Posts/PostEditForm.vue"
import TruncateView from "/js/components/utilities/TruncateView.vue"
import {
  namedRoutes as learningHubsNamedRoutes,
  namedRoutes as learningHubRoutes,
} from "/js/router/LearningHubRoutes"
import PostCardHeader from "/js/components/Posts/PostCardHeader.vue"
import AssetCard from "/js/components/utilities/FormFields/FileUpload/UI/AssetCard.vue"
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { useRouter } from "vue-router"
import PollCardLoader from "/js/components/Polls/PollCardLoader.vue";
import type {Post} from "/js/models/Post";

const props = defineProps<{
  post: Post
  truncate?: boolean
}>()

const router = useRouter()

const emit = defineEmits<{
  comment: [value: Post]
  follow: [value: Post]
  delete: [value: Post]
  update: [value: Post]
}>()

const openAttachment = ref<ProductAttachment | undefined>()
const postToDelete = ref<Post | undefined>()
const removingPost = ref(false)
const postToEdit = ref<Post | undefined>()

const attachmentAssets = computed((): Asset[] => {
  return (
    props.post.attachments?.map(
      (attachment) =>
        ({
          id: attachment.id,
          type: "productAttachment",
          data: attachment,
          date: attachment.created_at,
        }) as Asset
    ) || []
  )
})

const handleDeletePost = async (post: Post) => {
  try {
    removingPost.value = true
    await PostsApi.deletePost(post.id)
    emit("delete", post)
    toastSuccess("Post deleted")
  } catch (error) {
    toastError(error)
  } finally {
    removingPost.value = false
    postToDelete.value = undefined
  }
}

const handleUpdatePost = (post: Post) => {
  emit("update", post)
  postToEdit.value = undefined
}

const handleCommentClick = (post: Post) => {
  router.push({
    name: learningHubsNamedRoutes.learning_hubs_posts_show,
    params: {
      product_id: post.product_id,
      post_feed_id: post.post_feed_id,
      post_id: post.id,
    },
  })
}

</script>

<script lang="ts">
export default {
  name: "PostCard",
}
</script>
