//@ts-ignore
import Header from "@editorjs/header"
//@ts-ignore
import List from "@editorjs/list"
//@ts-ignore
import CodeTool from "@editorjs/code"
//@ts-ignore
import Paragraph from "@editorjs/paragraph"
//@ts-ignore
import Table from "@editorjs/table"
//@ts-ignore
import Checklist from "@editorjs/checklist"
//@ts-ignore
import Marker from "@editorjs/marker"
//@ts-ignore
import Warning from "@editorjs/warning"
//@ts-ignore
import RawTool from "@editorjs/raw"
//@ts-ignore
import Quote from "@editorjs/quote"
//@ts-ignore
import InlineCode from "@editorjs/inline-code"
//@ts-ignore
import Delimiter from "/js/overrides/EditorJsDelimiter/delimiter"
//@ts-ignore
import NestedList from "@editorjs/nested-list"
// @ts-ignore
import Link from "/js/overrides/EditorJsLink/link"
//@ts-ignore
// import Link from "@editorjs/link"

//@ts-ignore
import AlignmentTuneTool from "editorjs-text-alignment-blocktune"
import type { EditorConfig } from "@editorjs/editorjs"
import {
  createVueComponentPlugin,
  type IframeEmbedPluginConfig,
} from "/js/components/utilities/Editor/createVueComponentPlugin"
import ImagePluginVue from "/js/components/utilities/Editor/ImagePlugin.vue"
import ImagePlaceholderIcon from "/js/components/icons/ImagePlaceholderIcon.vue"
import YoutubeIcon from "/js/components/icons/YoutubeIcon.vue"
import VimeoIcon from "/js/components/icons/VimeoIcon.vue"
import GoogleSheetsIcon from "/js/components/icons/GoogleSheetsIcon.vue"
import GoogleDocsIcon from "/js/components/icons/GoogleDocsIcon.vue"
import IframeEmbedPlugin from "/js/components/utilities/Editor/IframeEmbedPlugin.vue"
import hasProperty from "/js/composables/hasProperty"
import TypeFormIcon from "/js/components/icons/TypeFormIcon.vue"
import AttachmentsPluginVue from "/js/components/utilities/Editor/AttachmentsPlugin.vue"
import {
  type SocketMessage,
  subscribeToNewMessage,
  unsubscribeFromNewMessage,
} from "/js/composables/useUserChannel"
import { type LinkPreview, LinkPreviewsApi } from "/js/services/LinkPreviewsApi"
import ContentGeneratorPlugin from "/js/components/utilities/Editor/ContentGeneratorPlugin.vue"
import { PencilSquareIcon, QuestionMarkCircleIcon, QueueListIcon } from "@heroicons/vue/24/outline"
import QuestionPluginVue from "/js/components/utilities/Editor/QuestionPlugin.vue"
import AccordionPluginVue from "/js/components/utilities/Editor/AccordionPlugin.vue"

const ImagePlugin = createVueComponentPlugin(ImagePluginVue, "Image", ImagePlaceholderIcon)
const AttachmentsPlugin = createVueComponentPlugin(
  AttachmentsPluginVue,
  "Attachments",
  ImagePlaceholderIcon
)
const YoutubeEmbedPlugin = createVueComponentPlugin(
  IframeEmbedPlugin,
  "YouTube Embed",
  YoutubeIcon,
  null,
  { configKey: "youtubeEmbed" }
)
const VimeoEmbedPlugin = createVueComponentPlugin(
  IframeEmbedPlugin,
  "Vimeo Embed",
  VimeoIcon,
  null,
  { configKey: "vimeoEmbed" }
)
const GoogleSheetsPlugin = createVueComponentPlugin(
  IframeEmbedPlugin,
  "Google Sheets",
  GoogleSheetsIcon,
  null,
  { configKey: "googleSheetsEmbed" }
)
const GoogleDocsPlugin = createVueComponentPlugin(
  IframeEmbedPlugin,
  "Google Docs",
  GoogleDocsIcon,
  null,
  {
    configKey: "googleDocsEmbed",
    note: "The Document needs to be published first (In Google Docs go to File > Share > Publish to Web. Then use the normal share link)",
  } as IframeEmbedPluginConfig
)

const TypeformPlugin = createVueComponentPlugin(IframeEmbedPlugin, "TypeForm", TypeFormIcon, null, {
  configKey: "typeformEmbed",
})

const EditorContentGeneratorPlugin = createVueComponentPlugin(
  ContentGeneratorPlugin,
  "Write with AI",
  PencilSquareIcon
)

const QuestionPlugin = createVueComponentPlugin(
  QuestionPluginVue,
  "Question",
  QuestionMarkCircleIcon
)

const AccordionPlugin = createVueComponentPlugin(
  AccordionPluginVue,
  "Accordion",
  QueueListIcon
)

const customTools = [
  "imageUploader",
  "youtubeEmbed",
  "vimeoEmbed",
  "googleSheetsEmbed",
  "googleDocsEmbed",
  "typeformEmbed",
]

type Tool = EditorConfig["tools"]

const headerTool: Tool = {
  header: {
    //@ts-ignore
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Enter a header",
      levels: [2, 3, 4, 5],
      defaultLevel: 3,
    },
    tunes: ["alignmentTune"],
  },
}

const listTool: Tool = {
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
}

const waitForSocketMessage = (): Promise<LinkPreview> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      reject(new Error("Timeout reached while waiting for socket message"))
    }, 5000) // 5 seconds timeout

    const subscribeToNewMessageCallback = (socketMessage: SocketMessage) => {
      if (socketMessage.type === "link_preview") {
        unsubscribeFromNewMessage(subscribeToNewMessageCallback)
        clearTimeout(timeout)
        resolve(socketMessage.object)
      }
    }

    subscribeToNewMessage(subscribeToNewMessageCallback)
  })
}

const linkTool: Tool = {
  linkTool: {
    // @ts-ignore
    class: Link,
    config: {
      createOnPaste: true,
      key: "linkTool",
      doFetch: async (options: { url: string }) => {
        return new Promise(async (resolve, reject) => {
          try {
            const preview = await LinkPreviewsApi.createPreview(options.url)
            if (preview.success === 1) {
              resolve(preview)
              return
            }

            const result = await waitForSocketMessage()
            resolve(result)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
      },
    },
  },
}

const codeTool: Tool = {
  code: {
    class: CodeTool,
  },
}

const paragraphTool: Tool = {
  paragraph: {
    class: Paragraph,
    tunes: ["alignmentTune"],
  },
}

const tableTool: Tool = {
  table: {
    class: Table,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },
}

const checklistTool: Tool = {
  checklist: {
    class: Checklist,
  },
}

const markerTool: Tool = {
  Marker: {
    class: Marker,
    shortcut: "CMD+SHIFT+M",
  },
}

const warningTool: Tool = {
  warning: {
    class: Warning,
    inlineToolbar: true,
    shortcut: "CMD+SHIFT+W",
    config: {
      titlePlaceholder: "Title",
      messagePlaceholder: "Message",
    },
  },
}

const rawTool: Tool = {
  raw: RawTool,
}

const quoteTool: Tool = {
  quote: {
    class: Quote,
    inlineToolbar: true,
    shortcut: "CMD+SHIFT+O",
    config: {
      quotePlaceholder: "Enter a quote",
      captionPlaceholder: "Quote's author",
    },
  },
}

const inlineCodeTool: Tool = {
  inlineCode: {
    class: InlineCode,
    shortcut: "CMD+SHIFT+M",
  },
}

const delimiterTool: Tool = {
  delimiter: Delimiter,
}

const alignmentTuneTool: Tool = {
  alignmentTune: {
    class: AlignmentTuneTool,
    config: {
      default: "left",
    },
  },
}

const imageUploaderTool: Tool = {
  imageUploader: {
    //@ts-ignore
    class: ImagePlugin,
    tunes: ["alignmentTune"],
  },
}

const attachmentsTool: Tool = {
  attachments: {
    //@ts-ignore
    class: AttachmentsPlugin,
  },
}

const questionTool: Tool = {
  question: {
    //@ts-ignore
    class: QuestionPlugin
  },
}

const accordionTool: Tool = {
  accordion: {
    //@ts-ignore
    class: AccordionPlugin
  },
}

const generatorTool: Tool = {
  generator: {
    //@ts-ignore
    class: EditorContentGeneratorPlugin,
  },
}

const youtubeEmbedTool: Tool = {
  youtubeEmbed: {
    //@ts-ignore
    class: YoutubeEmbedPlugin,
  },
}

const vimeoEmbedTool: Tool = {
  vimeoEmbed: {
    //@ts-ignore
    class: VimeoEmbedPlugin,
  },
}

const googleSheetsEmbedTool: Tool = {
  googleSheetsEmbed: {
    //@ts-ignore
    class: GoogleSheetsPlugin,
  },
}

const googleDocsEmbedTool: Tool = {
  googleDocsEmbed: {
    //@ts-ignore
    class: GoogleDocsPlugin,
  },
}

const typeformEmbedTool: Tool = {
  typeformEmbed: {
    //@ts-ignore
    class: TypeformPlugin,
  },
}

export const editorConfig: EditorConfig = {
  placeholder: "Click here and start typing...",
  tools: {
    ...headerTool,
    ...generatorTool,
    ...listTool,
    ...codeTool,
    ...paragraphTool,
    ...tableTool,
    ...checklistTool,
    ...markerTool,
    ...warningTool,
    ...rawTool,
    ...quoteTool,
    ...inlineCodeTool,
    ...linkTool,
    ...delimiterTool,
    ...alignmentTuneTool,
    ...questionTool,
    ...accordionTool,
    ...imageUploaderTool,
    ...attachmentsTool,
    ...youtubeEmbedTool,
    ...vimeoEmbedTool,
    ...googleSheetsEmbedTool,
    ...googleDocsEmbedTool,
    ...typeformEmbedTool,
  },
}

export const userEditorConfig: EditorConfig = {
  placeholder: "Click here and start typing...",
  tools: {
    ...listTool,
    ...paragraphTool,
    ...alignmentTuneTool,
  },
}

export const surveyEditorConfig: EditorConfig = {
  placeholder: "Click here and start typing...",
  tools: {
    ...paragraphTool,
    ...generatorTool,
    ...imageUploaderTool,
    ...linkTool,
    ...delimiterTool,
    ...alignmentTuneTool,
  },
}

export const makeEditorConfig = (
  config: EditorConfig,
  productId: string | null | undefined
): EditorConfig => {
  const newConfig = { ...config }

  if (!productId) return newConfig

  const tools = newConfig.tools
  if (!tools) return newConfig

  customTools.forEach((tool) => {
    const toolConfig = tools[tool] as any
    if (toolConfig) {
      toolConfig.config = {
        ...toolConfig.config,
        productId,
      }
    }
    tools[tool] = toolConfig
  })

  return newConfig
}

export const editorBodyHasContent = (body: Record<string, any>) => {
  if (!body) return false

  const keys = Object.keys(body)
  if (keys.length === 0) return false

  if (!hasProperty(body, "blocks")) return false

  const blocks = body.blocks

  if (!Array.isArray(blocks)) return false

  if (blocks.length === 0) return false

  // find the first block that has a data.text property that's a non empty string
  const firstBlockWithText = blocks.find((block) => {
    if (!hasProperty(block, "data")) return false
    if (!hasProperty(block.data, "text")) return false
    if (typeof block.data.text !== "string") return false
    if (block.data.text.trim().length === 0) return false
    return true
  })

  if (!firstBlockWithText) return false

  return true
}
