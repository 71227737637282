<template>
  <loading-view :loading>
    <template v-if="readOnly">
      <div class="flex w-full flex-col gap-4">
        <h5>{{ editorData?.question }}</h5>

        <div class="flex gap-2" v-if="answer">
          <h6 class="text-secondary">Your answer:</h6>
          <p class="whitespace-pre-line">
            {{ answerLabel }}
          </p>
        </div>
        <FormKit
          v-else-if="editorData"
          type="form"
          form-class="flex flex-col gap-4"
          :actions="false"
          #default="{ disabled }"
          @submit="handleSubmitAnswer">
          <template v-if="editorData.questionType === 'text'">
            <FormKit
              type="textarea"
              v-model="userAnswerData"
              validation-label="Answer"
              label="Write your answer"
              validation="required" />
          </template>

          <template v-else-if="editorData.questionType === 'radio'">
            <FormKit
              decorator-icon="circle"
              type="radio"
              :options="radioOptions"
              v-model="userAnswerData" />
          </template>

          <button type="submit" :disabled="!!disabled" class="btn btn-primary self-start">
            Submit
          </button>
        </FormKit>
      </div>
    </template>
    <template v-else>
      <div class="flex w-full flex-col gap-4">
        <FormKit
          type="text"
          v-model="data.question"
          validation-label="Question"
          label="Write a question"
          validation="required" />

        <FormKit
          type="select"
          v-model="data.questionType"
          label="Question Type"
          select-icon="down"
          :options="questionTypes" />

        <div class="card padding flex flex-col gap-4 border" v-if="data.questionType === 'radio'">
          <FormKit
            v-for="option in data.options"
            type="text"
            v-model="option.label"
            placeholder="Add a response option"
            validation-label="Option"
            validation="required" />

          <button
            type="button"
            class="btn btn-primary btn-sm self-start"
            @click="addResponseOption">
            Add Response Option
          </button>
        </div>
      </div>
    </template>
  </loading-view>
</template>

<script setup lang="ts">
import type {
  QuestionPluginProps,
  QuestionPluginQuestionType,
} from "/js/components/utilities/Editor/createVueComponentPlugin"
import { computed, onMounted, ref } from "vue"
import { EditorContentApi, type EjsPluginQuestionAnswer } from "/js/services/EditorContentApi"
import { toastError } from "/js/composables/toast"
import LoadingView from "/js/components/LoadingView.vue"
import type { FormKitNode } from "@formkit/core"
import { nanoid } from "nanoid"

const props = defineProps<QuestionPluginProps>()

const data = computed({
  get: () =>
    props.editorData || {
      identifier: "",
      questionType: "text",
      options: [],
    },
  set: (value) => {
    if (value) {
      props.onUpdate(value)
    }
  },
})

const questionTypes: Record<QuestionPluginQuestionType, string> = {
  text: "Text",
  radio: "Choice",
}

const answer = ref<EjsPluginQuestionAnswer | undefined>()

const answerLabel = computed(() => {
  if (!answer.value) return ""

  if (data.value.questionType === "text") {
    return answer.value.answer
  } else if (data.value.questionType === "radio") {
    return data.value.options?.find((option) => option.identifier === answer.value?.answer)?.label
  }

  return ""
})

const userAnswerData = ref("")

const loading = ref(false)

const addResponseOption = () => {
  const options = data.value.options || []
  options.push({
    label: "",
    identifier: nanoid(20),
  })
  data.value.options = options
}

const radioOptions = computed(() => {
  return data.value.options?.map((option) => ({
    label: option.label,
    value: option.identifier,
  }))
})

const handleSubmitAnswer = async (formData: Record<string, any>, node: FormKitNode) => {
  if (!props.editorData?.identifier) return

  try {
    loading.value = true
    answer.value = await EditorContentApi.createQuestionAnswer({
      answer: userAnswerData.value,
      ejs_plugin_question_id: props.editorData.identifier,
    })
  } catch (error) {
    toastError("Failed to submit answer")
    console.log(error)
  } finally {
    loading.value = false
  }
}

const getQuestion = async () => {
  if (props.readOnly) return
  if (props.editorData?.identifier) return

  try {
    loading.value = true
    const questionContainer = await EditorContentApi.createQuestionContainer(
      props.editorData?.productId
    )
    data.value = {
      questionType: "text",
      productId: props.editorData?.productId,
      identifier: questionContainer.id,
    }
  } catch (error) {
    toastError("Failed to add question")
    props.replaceContent(null)
  } finally {
    loading.value = false
  }
}

const getAnswer = async () => {
  if (!props.readOnly) return
  if (!props.editorData?.identifier) return

  try {
    loading.value = true
    answer.value = await EditorContentApi.getQuestionAnswer(props.editorData.identifier)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  getQuestion()
  getAnswer()
})
</script>

<script lang="ts">
export default {
  name: "QuestionPlugin",
}
</script>
