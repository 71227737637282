<template>
  <paged-cards>
    <template #title>
      <h4 class="font-medium">{{ title }}</h4>
    </template>
    <div class="card border border-gray-200" v-for="room in rooms">
      <router-link
        class="flex flex-col"
        :to="generateChatRoute('channel', 'show', { room_id: room.id })">
        <div class="padding flex flex-col gap-4">
          <h5 class="text-primary text-lg font-medium">#{{ room.name }}</h5>
          <div class="text-secondary line-clamp-2 whitespace-pre-line" v-if="room.description">
            {{ room.description }}
          </div>
        </div>
      </router-link>
    </div>
  </paged-cards>
</template>

<script setup lang="ts">
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import type { ChatRoomListItem } from "/js/models/Chat"
import { generateChatRoute } from "/js/router/ChatRoutes"

const props = withDefaults(
  defineProps<{
    rooms: ChatRoomListItem[]
    title?: string
  }>(),
  {
    title: "Chat Rooms",
  }
)

</script>

<script lang="ts">
export default {
  name: "ChatRoomCard",
}
</script>
