import { useQuery, useQueryClient } from "@tanstack/vue-query"
import { UsersApi } from "/js/services/UsersApi"
import { useServiceHelpers } from "/js/services/useServiceHelpers"
import type { User, UserProfile } from "/js/models/User"
import type { UserGroup } from "/js/services/permissionPolicyService"

export const currentUserQueryKey = ["currentUser"]
export const userGroupsQueryKey = ["userGroups"]

export const useCurrentUserService = () => {
  const queryClient = useQueryClient()
  const { syncItem } = useServiceHelpers(queryClient)

  const loadGroups = (enabled = true) =>
    useQuery({
      queryKey: userGroupsQueryKey,
      queryFn: UsersApi.getUserGroups,
      staleTime: Infinity,
      enabled,
    })

  const load = (enabled = true) =>
    useQuery({
      queryKey: currentUserQueryKey,
      queryFn: UsersApi.getCurrentUser,
      staleTime: Infinity,
      enabled,
    })

  const syncCurrentUser = (user: UserProfile) => {
    syncItem(
      [
        {
          key: currentUserQueryKey,
          type: "single",
        },
      ],
      user
    )
  }

  const hasManagingRole = (productId: string) => {
    const groups = queryClient.getQueryData<UserGroup[]>(userGroupsQueryKey)
    if (!groups) return false

    // has community_admin, community_owner role for current community
    // has product_manager, product_instructor role for current product
    return (
      groups.some((group) => {
        if (["community_admins", "community_owners"].includes(group.group_type)) {
          return true
        }

        if (
          ["product_managers", "product_instructors"].includes(group.group_type) &&
          group.product_id === productId
        ) {
          return true
        }

        return false
      }) || false
    )
  }

  return {
    load,
    loadGroups,
    syncCurrentUser,
    hasManagingRole,
  }
}
