<template>
  <app-modal
    v-if="modalType === 'app'"
    v-model="open"
    :hideTopBar
    :noBackdropClose
    :sizeType
    :confirmModalClose
    :noPadding
    :title>
    <template #default="{ close }">
      <slot v-bind="{ close }" />
    </template>
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
  </app-modal>

  <headless-modal
    v-else-if="modalType === 'headless'"
    v-model="open"
    :hideTopBar
    :noBackdropClose
    :sizeType
    :confirmModalClose
    :noPadding
    :initialFocus
    :title>
    <template #default="{ close }">
      <slot v-bind="{ close }" />
    </template>
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
  </headless-modal>

  <right-modal
    v-else-if="modalType === 'right'"
    v-model="open"
    :fullSpace
    :title
    :hideTopBar
    :noPadding
    :bg
    :rightModalWidthClass
  >
    <template #default="{ close }">
      <slot v-bind="{ close }" />
    </template>
    <template #title v-if="!title">
      <slot name="title" />
    </template>
  </right-modal>
</template>

<script setup lang="ts">
import AppModal from "/js/components/Modals/AppModal.vue"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import HeadlessModal from "/js/components/Modals/HeadlessModal.vue"
import RightModal from "/js/components/Modals/RightModal.vue"

const open = defineModel<boolean>()

withDefaults(defineProps<ModalProps>(), {
  modalType: "headless",
  noPadding: false,
})
</script>

<script lang="ts">
export default {
  name: "Modal",
}
</script>
