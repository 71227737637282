import { postZod } from "/js/composables/useAxios"
import {
  type NavigationResource,
  type NavigationResourceType,
  ZNavigationResource,
} from "/js/components/NavigationResources/NavigationResourcesTypes"

export const SearchApi = {
  async search(
    query: string,
    filter_models?: NavigationResourceType[]
  ): Promise<NavigationResource[]> {
    return postZod("/api/search", { query, filter_models }, ZNavigationResource.array())
  },
}
