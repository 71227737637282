<template>
  <svg width="200" height="171" viewBox="0 0 200 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M103.487 148.716C144.472 148.716 177.699 115.489 177.699 74.3582C177.699 33.227 144.325 0 103.487 0C62.5019 0 29.2749 33.227 29.2749 74.3582C29.2749 115.489 62.5019 148.716 103.487 148.716Z" fill="#EAEEF9"/>
    <path d="M187.12 50.7986C190.434 50.7986 193.121 48.1117 193.121 44.7972C193.121 41.4828 190.434 38.7959 187.12 38.7959C183.805 38.7959 181.118 41.4828 181.118 44.7972C181.118 48.1117 183.805 50.7986 187.12 50.7986Z" fill="#EAEEF9"/>
    <path d="M195.902 27.3791C198.165 27.3791 200 25.5441 200 23.2806C200 21.0171 198.165 19.1821 195.902 19.1821C193.638 19.1821 191.803 21.0171 191.803 23.2806C191.803 25.5441 193.638 27.3791 195.902 27.3791Z" fill="#EAEEF9"/>
    <path d="M32.6414 25.6154C34.905 25.6154 36.7399 23.7805 36.7399 21.5169C36.7399 19.2534 34.905 17.4185 32.6414 17.4185C30.3779 17.4185 28.543 19.2534 28.543 21.5169C28.543 23.7805 30.3779 25.6154 32.6414 25.6154Z" fill="#EAEEF9"/>
    <path d="M7.61147 104.658C11.8152 104.658 15.2229 101.25 15.2229 97.0463C15.2229 92.8426 11.8152 89.4348 7.61147 89.4348C3.40777 89.4348 0 92.8426 0 97.0463C0 101.25 3.40777 104.658 7.61147 104.658Z" fill="#EAEEF9"/>
    <g filter="url(#filter0_d_775_40331)">
      <path d="M146.879 129.602H168.13C170.799 129.602 173.087 127.315 173.087 124.647V25.5584C173.087 22.8907 170.799 20.604 168.13 20.604H147.539" fill="url(#paint0_linear_775_40331)"/>
    </g>
    <g filter="url(#filter1_d_775_40331)">
      <path d="M62.5075 129.602H41.917C39.2478 129.602 36.96 127.315 36.96 124.647V25.5584C36.96 22.8907 39.2478 20.604 41.917 20.604H62.0834" fill="url(#paint1_linear_775_40331)"/>
    </g>
    <path d="M51.2847 40.4219H61.58" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M51.2847 53.7607H61.58" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M51.2847 67.481H61.58" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M51.2847 81.2012H61.58" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M51.2847 107.878H61.58" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M157.453 40.4219H147.539" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M157.453 53.7607H147.539" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M157.453 67.481H147.539" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M157.453 81.2012H147.539" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M157.453 107.878H147.539" stroke="#D6DCE8" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <g filter="url(#filter2_d_775_40331)">
      <path d="M142.582 137.986H67.0835C64.4143 137.986 62.1265 135.7 62.1265 133.032V17.9364C62.1265 15.2686 64.4143 12.9819 67.0835 12.9819H142.582C145.251 12.9819 147.539 15.2686 147.539 17.9364V133.032C147.539 135.7 145.251 137.986 142.582 137.986Z" fill="url(#paint2_linear_775_40331)"/>
    </g>
    <path d="M133.431 50.2703H80.8103C79.6664 50.2703 78.9038 49.5796 78.9038 48.5436V44.0541C78.9038 43.0181 79.6664 42.3274 80.8103 42.3274H133.431C134.575 42.3274 135.337 43.0181 135.337 44.0541V48.5436C135.337 49.2343 134.575 50.2703 133.431 50.2703Z" fill="#D5DDEA"/>
    <path d="M133.431 70.8504H80.8103C79.6664 70.8504 78.9038 70.1597 78.9038 69.1237V64.6342C78.9038 63.5982 79.6664 62.9075 80.8103 62.9075H133.431C134.575 62.9075 135.337 63.5982 135.337 64.6342V69.1237C135.337 70.1597 134.575 70.8504 133.431 70.8504Z" fill="#D5DDEA"/>
    <path d="M133.431 91.8111H80.8103C79.6664 91.8111 78.9038 91.1204 78.9038 90.0844V85.5949C78.9038 84.5589 79.6664 83.8682 80.8103 83.8682H133.431C134.575 83.8682 135.337 84.5589 135.337 85.5949V90.0844C135.337 91.1204 134.575 91.8111 133.431 91.8111Z" fill="#D5DDEA"/>
    <path d="M133.431 113.153H112.459C111.315 113.153 110.553 112.463 110.553 111.427V106.937C110.553 105.901 111.315 105.21 112.459 105.21H133.431C134.575 105.21 135.338 105.901 135.338 106.937V111.427C135.338 112.463 134.575 113.153 133.431 113.153Z" fill="#D5DDEA"/>
    <defs>
      <filter id="filter0_d_775_40331" x="124.879" y="9.604" width="70.2075" height="152.998" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_40331"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_40331" result="shape"/>
      </filter>
      <filter id="filter1_d_775_40331" x="14.96" y="9.604" width="69.5474" height="152.998" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_40331"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_40331" result="shape"/>
      </filter>
      <filter id="filter2_d_775_40331" x="40.1265" y="1.98193" width="129.413" height="169.004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_40331"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_40331" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_775_40331" x1="159.975" y1="18.0828" x2="159.975" y2="130.777" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_775_40331" x1="49.7254" y1="18.0828" x2="49.7254" y2="130.777" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_775_40331" x1="104.805" y1="10.0905" x2="104.805" y2="139.334" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
    </defs>
  </svg>
</template>