<template>
  <div class="grid grid-cols-4 gap-4">
    <button
      type="button"
      v-for="asset in assets"
      :key="asset.id"
      :disabled="asset.type === 'fileUploader'"
      @click="emit('select', asset)"
      class="relative aspect-video rounded-lg bg-gray-200 hover:bg-gray-300">
      <template v-if="asset.type === 'productAttachment'">
        <media-gallery-grid-thumb :asset />
      </template>

      <span
        v-else-if="asset.type === 'fileUploader'"
        class="absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-gray-500 bg-opacity-40 text-white">

        <media-gallery-grid-thumb :asset class="absolute"/>

        <template v-if="failed(asset)">
          <div
            class="absolute inset-0 flex flex-col items-center justify-center gap-2 rounded-lg bg-black/80 text-red-500">
            <div>Failed</div>
            <button type="button" v-tooltip="`Retry`" @click="emit('retry', asset)">
              <ArrowPathIcon class="btn-zoom h-6 w-6" />
            </button>
          </div>
        </template>
        <template v-else>
          <spinner class="h-8 w-8" />
          <span class="relative">{{ asset.data.percentUploaded }}%</span>
        </template>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import Spinner from "/js/components/icons/Spinner.vue"
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { ArrowPathIcon } from "@heroicons/vue/24/outline"
import MediaGalleryGridThumb from "/js/components/utilities/FormFields/FileUpload/UI/MediaGalleryGridThumb.vue"

defineProps<{
  assets: Asset[]
}>()

const emit = defineEmits<{
  select: [asset: Asset]
  retry: [asset: Asset]
}>()

const failed = (asset: Asset) => {
  return asset.type === "fileUploader" && asset.data.status === "failed"
}
</script>

<script lang="ts">
export default {
  name: "MediaGalleryGrid",
}
</script>
