<template>
  <div class="bg-cover bg-center" :class="bgClass(url, id)" :style="bgStyle(url)">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { bgClass, bgStyle } from '/js/composables/useBgStyle'

const props = defineProps<{
  url?: string | null
  id?: string | null
}>()
</script>

<script lang="ts">
export default {
  name: 'GradientImage',
}
</script>
