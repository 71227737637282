import { toast } from "vue-sonner"
import getErrorMessage from "/js/composables/getErrorMessage"

const lastToastAt = new Date()

const toastThrottle = (fn: () => void) => {
  const now = new Date()
  if (now.getTime() - lastToastAt.getTime() < 500) {
    return
  }
  fn()
  lastToastAt.setTime(now.getTime())
}

export const toastError = (data: unknown) => {
  toastThrottle(() => {
    toast.error(getErrorMessage(data))
  })
}

export const toastSuccess = (message: string) => {
  toastThrottle(() => {
    toast.success(message)
  })
}

export const toastInfo = (message: string) => {
  toastThrottle(() => {
    toast.info(message)
  })
}
