import { type RouteRecordRaw, RouterView } from "vue-router"
import { productChannelRoutes } from "/js/router/ChatRoutes"
import { productPermission } from "/js/router/meta"
import { eventDateRouteFormatted } from "/js/router/EventsRoutes"

export const namedRoutes = {
  learning_hubs: "learning_hubs",
  learning_hubs_index: "learning_hubs.index",
  learning_hubs_show: "learning_hubs.show",
  learning_hubs_overview: "learning_hubs.overview",
  learning_hubs_overview_edit: "learning_hubs.overview.edit",
  learning_hubs_members: "learning_hubs.members",
  learning_hubs_course: "learning_hubs.course",
  learning_hubs_course_object: "learning_hubs.course.object",
  learning_hubs_events_index: "learning_hubs.events",
  learning_hubs_events_date_index: "learning_hubs.events.date_index",
  learning_hubs_events_edit: "learning_hubs.events.edit",
  learning_hubs_faq: "learning_hubs.faq",
  learning_hubs_faq_edit: "learning_hubs.faq.edit",
  learning_hubs_posts_index: "learning_hubs.posts.index",
  learning_hubs_posts_new: "learning_hubs.posts.new",
  learning_hubs_posts_show: "learning_hubs.posts.show",
  learning_hubs_posts_edit: "learning_hubs.posts.edit",
  learning_hubs_collections: "learning_hubs.collections",
  learning_hubs_collections_index: "learning_hubs.collections.index",
  learning_hubs_collections_new: "learning_hubs.collections.new",
  learning_hubs_collections_show: "learning_hubs.collections.show",
  learning_hubs_collection_folders_edit: "learning_hubs.collection_folders.edit",
  learning_hubs_collection_items_show: "learning_hubs.collection_items.show",
  learning_hubs_collection_items_edit: "learning_hubs.collection_items.edit",
  learning_hubs_product_contents_show: "learning_hubs.product_contents.show",
  learning_hubs_product_contents_edit: "learning_hubs.product_contents.edit",
  learning_hubs_product_reviews: "learning_hubs.product_reviews",
} as const

export const learningHubsRoutes: RouteRecordRaw[] = [
  {
    name: namedRoutes.learning_hubs_index,
    path: "",
    component: () => import("/js/components/LearningHubs/LearningHubList.vue"),
    meta: {
      authorize: {
        classType: "Community",
        authorizeFor: "community_read",
        requiresPlatformPlan: true,
      },
    },
  },
  {
    name: namedRoutes.learning_hubs_show,
    path: ":product_id",
    component: () => import("/js/components/LearningHubs/LearningHubDetails.vue"),
    props: (route) => ({ productId: route.params.product_id }),
    redirect: { name: namedRoutes.learning_hubs_overview },
    meta: {
      ...productPermission("product_read"),
    },
    children: [
      ...productChannelRoutes,
      {
        name: namedRoutes.learning_hubs_overview,
        path: "overview",
        component: () => import("/js/components/LearningHubs/Overview/LearningHubOverview.vue"),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_overview_edit,
        path: "overview/edit",
        component: () => import("/js/components/LearningHubs/Overview/LearningHubOverviewEdit.vue"),
        props: (route) => ({ productId: route.params.product_id }),
        meta: productPermission("product_edit"),
      },
      {
        name: namedRoutes.learning_hubs_members,
        path: "members",
        component: () => import("/js/components/Members/ProductMemberList.vue"),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_course,
        path: "course",
        component: () => import("/js/components/LearningHubs/LearningHubCourseViewWrapper.vue"),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_course_object,
        path: "course/:learning_object_id",
        component: () => import("/js/components/LearningHubs/LearningHubCourseViewWrapper.vue"),
        props: (route) => ({ learningObjectId: route.params.learning_object_id }),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_events_index,
        path: "events",
        redirect: {
          name: namedRoutes.learning_hubs_events_date_index,
          params: { date: eventDateRouteFormatted(new Date()) },
        },
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_events_date_index,
        path: "events/date/:date",
        component: () => import("/js/components/Events/ProductEvents.vue"),
        meta: productPermission("product_read"),
        props: (route) => {
          if (route.params.date && typeof route.params.date === "string") {
            const date = new Date(route.params.date)
            if (!isNaN(date.getTime())) {
              return { date }
            }
          }
          return { date: undefined }
        },
        children: [
          {
            name: namedRoutes.learning_hubs_events_edit,
            path: ":event_id/edit",
            component: () => import("/js/components/Events/ProductEvents.vue"),
            meta: productPermission("product_read"),
          }
        ]
      },
      {
        name: namedRoutes.learning_hubs_faq,
        path: "faq",
        component: () => import("/js/components/LearningHubs/Faq/Faq.vue"),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_faq_edit,
        path: "faq/edit",
        component: () => import("/js/components/LearningHubs/Faq/FaqEdit.vue"),
        meta: {
          ...productPermission("content_manage"),
        },
      },
      {
        name: namedRoutes.learning_hubs_product_contents_show,
        path: "content/:product_content_id",
        props: (route) => ({ productContentId: route.params.product_content_id }),
        component: () => import("/js/components/LearningHubs/ProductContentShow.vue"),
        meta: productPermission("product_read"),
      },
      {
        name: namedRoutes.learning_hubs_product_contents_edit,
        path: "content/:product_content_id/edit",
        props: (route) => ({ productContentId: route.params.product_content_id }),
        component: () => import("/js/components/LearningHubs/ProductContentShow.vue"),
        meta: {
          ...productPermission("content_manage"),
        },
      },
      {
        path: "feed/:post_feed_id",
        props: (route) => ({ postFeedId: route.params.post_feed_id }),
        component: RouterView,
        redirect: { name: namedRoutes.learning_hubs_posts_index },
        meta: productPermission("product_read"),
        children: [
          {
            name: namedRoutes.learning_hubs_posts_index,
            path: "",
            props: (route) => ({
              postFeedId: route.params.post_feed_id,
            }),
            meta: {
              ...productPermission("product_read"),
            },
            component: () => import("/js/components/Posts/PostList.vue"),
          },
          {
            name: namedRoutes.learning_hubs_posts_new,
            path: "posts/new",
            props: (route) => ({
              postFeedId: route.params.post_feed_id,
            }),
            meta: {
              ...productPermission("content_manage"),
            },
            component: () => import("/js/components/Posts/PostList.vue"),
          },
          {
            name: namedRoutes.learning_hubs_posts_show,
            path: "posts/:post_id",
            props: (route) => ({
              postFeedId: route.params.post_feed_id,
              postId: route.params.post_id,
            }),
            meta: {
              ...productPermission("product_read"),
            },
            component: () => import("/js/components/Posts/PostShow.vue"),
          },
          {
            name: namedRoutes.learning_hubs_posts_edit,
            path: "posts/:post_id/edit",
            props: (route) => ({ postId: route.params.post_id }),
            meta: {
              ...productPermission("content_manage"),
            },
            component: () => import("/js/components/Posts/PostList.vue"),
          },
        ],
      },
      {
        name: namedRoutes.learning_hubs_collections,
        path: "collections/:collection_id",
        props: (route) => ({ collectionId: route.params.collection_id }),
        component: RouterView,
        meta: productPermission("product_read"),
        redirect: { name: namedRoutes.learning_hubs_collections_index },
        children: [
          {
            name: namedRoutes.learning_hubs_collections_index,
            path: "",
            meta: {
              ...productPermission("product_read"),
            },
            component: () => import("/js/components/Collections/CollectionList.vue"),
          },
          {
            name: namedRoutes.learning_hubs_collections_new,
            path: "new",
            meta: {
              ...productPermission("content_manage"),
            },
            component: () => import("/js/components/Collections/CollectionList.vue"),
          },
          {
            name: namedRoutes.learning_hubs_collection_folders_edit,
            path: "folders/:collection_folder_id/edit",
            props: (route) => ({
              editCollectionFolderId: route.params.collection_folder_id,
            }),
            meta: {
              ...productPermission("content_manage"),
            },
            component: () => import("/js/components/Collections/CollectionList.vue"),
          },
          {
            name: namedRoutes.learning_hubs_collection_items_show,
            path: "items/:collection_item_id",
            props: (route) => ({ collectionItemId: route.params.collection_item_id }),
            meta: {
              ...productPermission("product_read"),
            },
            component: () => import("/js/components/Collections/CollectionItemShow.vue"),
            children: [
              {
                name: namedRoutes.learning_hubs_collection_items_edit,
                path: "edit",
                component: () => import("/js/components/Collections/CollectionItemShow.vue"),
                meta: {
                  ...productPermission("content_manage"),
                },
              },
            ],
          },
        ],
      },
      {
        name: namedRoutes.learning_hubs_product_reviews,
        path: "reviews",
        component: () => import("/js/components/Reviews/ReviewList.vue"),
        props: (route) => ({ productId: route.params.product_id }),
        meta: productPermission("product_read"),
      },
    ],
  },
]
