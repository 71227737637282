// gets the domain name from the url. only works with no suffix domains (domain.com, not domain.co.uk)
export function getMainDomain() {
  let hostname = window.location.hostname
  let parts = hostname.split(".")

  // Take the last two parts to get the main domain and its top-level domain
  if (parts.length > 1) {
    return parts.slice(-2).join(".")
  }

  return hostname // fallback for edge cases
}