<template>
  <paged-cards>
    <template #title>
      <h4 class="font-medium">{{ title }}</h4>
    </template>
    <div class="card border border-gray-200" v-for="event in events">
      <gradient-image
        class="aspect-[21/9] w-full rounded-t-lg"
        :id="event.id"
        :url="attachmentImageUrl(event.cover_image)" />

      <div class="flex items-start gap-4 p-4">
        <calendar-day-box :date="event.start_date" />
        <event-title-date
          :title="event.name"
          :start-date="event.start_date"
          :end-date="event.end_date"
          :recurring="event.recurring" />
      </div>
    </div>
  </paged-cards>
</template>

<script setup lang="ts">
import type { EventItem } from "/js/models/EventModels"
import EventTitleDate from "/js/components/utilities/UI/EventTitleDate.vue"
import CalendarDayBox from "/js/components/utilities/UI/CalendarDayBox.vue"
import GradientImage from "/js/components/GradientImage.vue"
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import { attachmentImageUrl } from "/js/models/Product"

withDefaults(
  defineProps<{
    events: EventItem[]
    title?: string
  }>(),
  {
    title: "Events",
  }
)
</script>

<script lang="ts">
export default {
  name: "EventsCarouselCard",
}
</script>
