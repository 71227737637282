<template>
  <div
    :class="
      clsx(
        {
          'border-x-8 border-b-0 border-t-8 border-solid border-x-transparent':
            props.direction === 'up',
          'border-y-8 border-l-0 border-r-8 border-solid border-y-transparent':
            props.direction === 'left',
          'border-y-8 border-l-8 border-r-0 border-solid border-y-transparent':
            props.direction === 'right',
          'border-x-8 border-b-8 border-t-0 border-solid border-x-transparent':
            props.direction === 'down',
        },
        props.color
      )
    "></div>
</template>

<script setup lang="ts">
import clsx from "clsx"

export type Direction = "up" | "down" | "left" | "right"

const props = defineProps<{
  direction: Direction
  color?: string
}>()
</script>

<script lang="ts">
export default {
  name: "ArrowTriangle",
}
</script>
