<template>
  <modal v-model="showGallery" title="Media Gallery" no-backdrop-close>
    <media-gallery
      :scope="context.scope"
      :auto-submit="context.autoSubmit"
      :multiple="context.multiple"
      :public-blob="context.publicBlob"
      :accept="context.accept"
      :assistant
      @save="handleSave"
      @close="showGallery = false" />
  </modal>
  <div class="flex flex-col gap-4">
    <dropzone-image-preview v-if="productAttachment" :url="attachmentImageUrl(productAttachment, { preferThumb: true })" />
    <gallery-select-media v-else @show-gallery="showGallery = true" />
    <existing-image-actions
      :disabled="!!context.disabled"
      v-if="productAttachment"
      @remove="productAttachment = undefined"
      @change="showGallery = true" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import DropzoneImagePreview from "/js/components/utilities/FormFields/FileUpload/UI/DropzoneImagePreview.vue"
import Modal from "/js/components/Modals/Modal.vue"
import MediaGallery from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGallery.vue"
import { attachmentImageUrl, type ProductAttachment } from "/js/models/Product"
import GallerySelectMedia from "/js/components/utilities/FormFields/FileUpload/UI/GallerySelectMedia.vue"
import ExistingImageActions from "/js/components/utilities/FormFields/FileUpload/UI/ExistingImageActions.vue"

const props = defineProps<{
  context: any
}>()

const showGallery = ref(false)

const assistant = computed(() => !!props.context.assistant)

const productAttachment = ref<ProductAttachment | undefined>(props.context.attachment)

const handleSave = (value: ProductAttachment) => {
  showGallery.value = false
  productAttachment.value = value
}

watch(
  productAttachment,
  (value) => {
    props.context.node.input(value?.id)
  },
  { immediate: true }
)
</script>

<script lang="ts">
export default {
  name: "FormKitGalleryAttachmentInput",
}
</script>