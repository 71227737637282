<template>
  <div class="flex max-w-full items-center gap-2">
    <div v-if="users.length >= 2" class="-ml-2 flex items-center justify-center -space-x-4">
      <round-avatar
        class="relative -translate-y-1 rounded-full border border-white object-cover"
        :avatar-url="users[0]?.avatar_url"
        :id="users[0]?.id"
        :text="initials(users[0])"
        size="xs"
        rounded="rounded-full" />

      <round-avatar
        class="relative translate-y-1 rounded-full border border-white object-cover"
        :avatar-url="users[1]?.avatar_url"
        :id="users[1]?.id"
        :text="initials(users[1])"
        size="xs"
        rounded="rounded-full" />
    </div>

    <round-avatar
      v-else
      :avatar-url="users[0]?.avatar_url"
      :id="users[0]?.id"
      :text="initials(users[0])"
      size="xs"
      rounded="rounded-full" />

    <div class="flex min-w-0 flex-1 flex-col leading-snug">
      <div class="truncate" :class="{ 'font-bold': !isRead }">
        {{ roomName }}
      </div>
      <div v-if="otherUsersCount" class="text-xxs text-gray-500">
        and {{ otherUsersCount }} other{{ otherUsersCount > 1 ? "s" : "" }}
      </div>
    </div>
    <unread-dot v-if="!isRead" class="h-2 w-2" />
  </div>
</template>

<script setup lang="ts">
import type { ChatRoomListItem } from "/js/models/Chat"
import { computed } from "vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import { initials } from "/js/models/User"
import UnreadDot from "/js/components/icons/UnreadDot.vue"

const props = defineProps<{
  room: ChatRoomListItem
  isRead: boolean
}>()

const users = computed(() => {
  return props.room.latest_users ?? []
})

const roomName = computed(() => {
  const user = props.room?.latest_users?.[0]
  if (!user) return "Untitled"
  return user.first_name || user.last_name || "Untitled"
})

const otherUsersCount = computed(() => {
  if (!props.room.direct_users_count) return undefined
  if (props.room.direct_users_count <= 2) return undefined
  return props.room.direct_users_count - 2
})
</script>

<script lang="ts">
export default {
  name: "DirectMessageRoomListItem",
}
</script>
