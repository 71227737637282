import {ref} from "vue";
import type {User} from "/js/models/User";

export const modalOpen = ref(false)
export const modalUser = ref<User | null>(null)

export const openUser = (user: User) => {
  modalUser.value = user
  modalOpen.value = true
}

export const closeUser = () => {
  modalOpen.value = false
  modalUser.value = null
}