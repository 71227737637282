import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import { computed, type ComputedRef, onMounted, onUnmounted, type Ref, ref, watch } from "vue"
import { type CreateChatParams, GptApi, type GptChat, type GptMessage } from "/js/services/GptApi"
import { useQuery } from "@tanstack/vue-query"

export const useGptChat = (chatId?: ComputedRef<string | undefined>) => {
  const userChannel = useUserChannel()

  const gptChat = ref<GptChat | undefined>()
  const messages = ref<GptMessage[]>([])

  const lastAssistantMessage = computed((): GptMessage | undefined => {
    return messages.value.filter((m) => m.role === "assistant").slice(-1)[0]
  })

  const generating = computed(() => {
    return lastAssistantMessage.value && lastAssistantMessage.value?.status === "pending"
  })

  const subscribeToNewMessageCallback = (socketMessage: SocketMessage) => {

    if (socketMessage.type === "gpt_chat") {
      if (socketMessage.object.id === gptChat.value?.id) {
        gptChat.value = socketMessage.object
      }
      return
    }

    const fromThisChat =
      socketMessage.type === "gpt_chat_message" &&
      socketMessage.object.gpt_chat_id === gptChat.value?.id

    if (fromThisChat) {
      updateOrCreateMessage(socketMessage.object)
    }
  }

  const updateOrCreateMessage = (message: GptMessage) => {
    const index = messages.value.findIndex((m) => m.id === message.id)
    if (index === -1) {
      messages.value = [...messages.value, message]
    } else {
      messages.value = messages.value.map((m) => (m.id === message.id ? message : m))
    }
  }

  const sendPrompt = async (prompt: string, params?: CreateChatParams) => {
    console.log("sending prompt")
    if (generating.value) {
      console.log("Already generating, skipping")
      return
    }

    if (!gptChat.value) {
      gptChat.value = await GptApi.createChat(params)
    }

    const response = await GptApi.sendMessage(gptChat.value.id, prompt)

    updateOrCreateMessage(response)
  }

  const loadGptChat = () => {
    return useQuery({
      queryKey: computed(() => ["gpt_chat", chatId?.value ?? 'new']),
      queryFn: async () => {
        if (!chatId?.value) {
          gptChat.value = undefined
          return null
        }
        const apiChat = await GptApi.getChat(chatId.value)
        gptChat.value = apiChat
        return apiChat
      },
    })
  }

  const loadGptChatMessages = () => {
    return useQuery({
      queryKey: computed(() => ["gpt_chat_messages", gptChat.value?.id]),
      enabled: computed(() => !!gptChat.value),
      queryFn: async () => {
        if (!gptChat.value) return []
        const apiMessages = await GptApi.getMessages(gptChat.value.id)
        messages.value = apiMessages
        return apiMessages
      },
    })
  }

  onMounted(() => {
    userChannel.subscribeToNewMessage(subscribeToNewMessageCallback)
  })

  onUnmounted(() => {
    userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback)
  })

  watch(gptChat, (value) => {
    if (!value) {
      messages.value = []
    }
  })

  return {
    gptChat,
    messages,
    generating,
    lastAssistantMessage,
    sendPrompt,
    loadGptChatMessages,
    loadGptChat
  }
}
