<template>
  <div class="text-secondary flex max-w-64 flex-col justify-between rounded-lg bg-gray-50">
    <div class="truncate rounded-t-lg bg-gray-100 p-2 text-xs">{{ assetFileName(asset) }}</div>

    <a :href="assetDataUrl(asset)" v-if="assetDataUrl(asset)" target="_blank">
      <img
        :src="assetThumbUrl(asset, true)"
        alt="preview image"
        v-if="assetImageRepresentable(asset)"
        class="h-28 w-full rounded-b-lg object-contain" />
      <div class="flex h-28 items-center justify-center" v-else>
        <PaperClipIcon class="h-10 w-10" />
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import { PaperClipIcon } from "@heroicons/vue/24/outline"
import {
  type Asset,
  assetDataUrl,
  assetFileName,
  assetImageRepresentable,
  assetThumbUrl,
} from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"

defineProps<{
  asset: Asset
}>()

</script>

<script lang="ts">
export default {
  name: "AssetCard",
}
</script>
