<template>
  <paged-cards>
    <template #title>
      <h4 class="font-medium">{{ title }}</h4>
    </template>
    <content-view :is-error="false" :is-empty="!courseUnits.length" :isLoading="false">
      <template #empty>
        <empty-view
          class="w-full !pb-2"
          title="No Curriculum Found"
          subtitle="Check back later for updates.">
          <CurriculumEmptyIcon />
        </empty-view>
      </template>
    </content-view>
    <div class="card border border-gray-200" v-for="courseUnit in courseUnits">
      <router-link
        class="flex h-full flex-col"
        :to="{ name: learningHubRoutes.learning_hubs_course, params: { product_id: productId } }">
        <div class="padding flex h-full flex-col gap-2">
          <div class="text-primary line-clamp-1 text-lg font-medium">{{ courseUnit.title }}</div>
          <div
            class="text-secondary line-clamp-2 flex-1 whitespace-pre-line text-sm"
            v-if="courseUnit.description">
            {{ courseUnit.description }}
          </div>

          <div class="flex gap-4">
            <div
              class="flex items-center gap-2"
              v-if="courseUnit.unit_lessons_count"
              v-tooltip="
                `${courseUnit.unit_lessons_count} Lesson${courseUnit.unit_lessons_count > 1 ? 's' : ''}`
              ">
              <span>{{ courseUnit.unit_lessons_count }}</span>
              <ProjectsIcon class="h-5 w-5" />
            </div>
            <div
              class="flex items-center gap-2"
              v-if="courseUnit.quizzes_count"
              v-tooltip="
                `${courseUnit.quizzes_count} Quiz${courseUnit.quizzes_count > 1 ? 'zes' : ''}`
              ">
              <span>{{ courseUnit.quizzes_count }}</span>
              <QuizIcon class="h-5 w-5" />
            </div>
            <div
              class="flex items-center gap-2"
              v-if="courseUnit.unit_assignments_count"
              v-tooltip="
                `${courseUnit.unit_assignments_count} Assignment${courseUnit.unit_assignments_count > 1 ? 's' : ''}`
              ">
              <span>{{ courseUnit.unit_assignments_count }}</span>
              <PencilIcon class="h-5 w-5" />
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </paged-cards>
</template>

<script setup lang="ts">
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import { namedRoutes as learningHubRoutes } from "/js/router/LearningHubRoutes"
import type { CourseUnitWidget } from "/js/models/Course"
import ProjectsIcon from "/js/components/icons/ProjectsIcon.vue"
import QuizIcon from "/js/components/icons/QuizIcon.vue"
import PencilIcon from "/js/components/icons/PencilIcon.vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import CurriculumEmptyIcon from "/js/components/icons/CurriculumEmptyIcon.vue"

withDefaults(
  defineProps<{
    courseUnits: CourseUnitWidget[]
    productId: string
    title?: string
  }>(),
  {
    title: "Curriculum",
  }
)
</script>

<script lang="ts">
export default {
  name: "CurriculumCard",
}
</script>
