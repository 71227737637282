import {z} from "zod";

export const ZEventAttendance = z.object({
  id: z.string(),
  event_id: z.string(),
  user_id: z.string(),
  attended_at: z.date().nullable(),
  zoom_minutes_attended: z.number().nullable(),
})

export type EventAttendance = z.infer<typeof ZEventAttendance>