import "/js/extensions"
import {createApp} from 'vue'
import router from "/js/router/routes";
import {VueQueryPlugin, type VueQueryPluginOptions} from "@tanstack/vue-query";
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import {createPinia} from "pinia";
import {plugin, defaultConfig } from "@formkit/vue";
import { generateClasses } from "@formkit/themes";
import { applicationIcons } from "@formkit/icons";
import formKitTheme from "/js/tailwind-theme"
import FloatingVue from 'floating-vue'
import HoneybadgerVue from '@honeybadger-io/vue'
import MainApp from "/js/components/MainApp.vue";
import { FormKitTagInputSelectType } from "/js/components/utilities/FormFields/FormKitImageUploadInputObjects"

const config = {
  apiKey: 'hbp_NTkVdQqrLvllvP457UfF44ze47Tl5E2o4W9g',
  environment: 'production',
}

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  },
};

const apps = [
  {
    id: "app",
    component: MainApp
  }
]

apps.forEach(data => {
  const element = document.getElementById(data.id)
  if (element) {
    const app = createApp(data.component)
    if (window.APP_CONFIG.HONEYBADGER) {
      app.use(HoneybadgerVue, config)
    }
    const pinia = createPinia()

    app.use(pinia)
    app.use(FloatingVue)
    app.use(VueQueryPlugin, vueQueryPluginOptions)
    app.use(autoAnimatePlugin)
    app.use(router)
    app.use(plugin, defaultConfig({
      icons: applicationIcons,
      config: {
        classes: generateClasses(formKitTheme),
      },
      inputs: {
        FormKitTagInputSelectType: FormKitTagInputSelectType
      }
    }))
    app.mount(element)
  }
})
