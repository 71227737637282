<template>
  <div class="flex flex-col gap-4">
    <div class="card padding flex flex-col gap-4">
      <h5>Events</h5>
      <user-event-attendance-list :userId />
    </div>

    <div class="card padding flex flex-col gap-4">
      <h5>Learning Hubs</h5>
      <user-product-list :userId />
    </div>
  </div>
</template>

<script setup lang="ts">

import UserEventAttendanceList from "/js/components/PublicUserProfile/UserEventAttendanceList.vue"
import UserProductList from "/js/components/PublicUserProfile/UserProductList.vue"

const props = defineProps<{
  userId: string
}>()

</script>

<script lang="ts">
export default {
  name: "PublicUserRegistrations",
}
</script>
