<template>
  <input :id="id"
         type="radio"
         @input="handleInput"
         class="hidden"
         :checked="isChecked"
         :value="value"
         :disabled="disabled"
  />
  <slot :selected="isChecked" :select="select"></slot>
</template>

<script setup lang="ts" generic="Option">
import {nanoid} from "nanoid";
import {computed} from "vue";

type Props = {
  modelValue?: Option
  value: Option
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const emit = defineEmits<{
  "update:modelValue": [value: Option | undefined]
}>()

const selection = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const isChecked = computed(() => selection.value === props.value)

const id = nanoid()

function handleInput(e: any) {
  selection.value = e.target.value
}

const select = () => {
  selection.value = props.value
}

</script>

<script lang="ts">
export default {
  name: "RadioLargeSelect"
}
</script>