<template>
  <div class="text-xxs flex gap-1">
    <popover-menu :portal button-class="primary-link" v-model="mdHelpOpen">
      <template #button>Markdown</template>
      <PopoverContent>
        <div class="padding card flex flex-col gap-4 shadow-lg relative">
          <h4 class="font-semibold">Markdown Help</h4>
          <ul class="list-inside list-disc">
            <li class="flex justify-between gap-12"><span>Bold</span><span>**Bold**</span></li>
            <li class="flex justify-between gap-12"><span>Italic</span><span>*Italic*</span></li>
            <li class="flex justify-between gap-12">
              <span>Strikethrough</span><span>~~Strikethrough~~</span>
            </li>
            <li class="flex justify-between gap-12"><span>Heading 1</span><span># Space</span></li>
            <li class="flex justify-between gap-12"><span>Heading 2</span><span>## Space</span></li>
            <li class="flex justify-between gap-12"><span>Heading 3</span><span>### Space</span></li>
            <li class="flex justify-between gap-12"><span>Heading 4</span><span>#### Space</span></li>
            <li class="flex justify-between gap-12">
              <span>Heading 5</span><span>##### Space</span>
            </li>
            <li class="flex justify-between gap-12">
              <span>Heading 6</span><span>###### Space</span>
            </li>
            <li class="flex justify-between gap-12">
              <span>Numbered list</span><span>1. Space</span>
            </li>
            <li class="flex justify-between gap-12"><span>Bullet list</span><span>* Space</span></li>
            <li class="flex justify-between gap-12"><span>Quote</span><span>> Space</span></li>
            <li class="flex justify-between gap-12"><span>Code snippet</span><span>```</span></li>
            <li class="flex justify-between gap-12"><span>Divider</span><span>---</span></li>
            <li class="flex justify-between gap-12">
              <span>Link</span><span>[Link](https://a.com)</span>
            </li>
            <li class="flex justify-between gap-12"><span>Code</span><span>`Code`</span></li>
          </ul>
        </div>
      </PopoverContent>
    </popover-menu>
    <template v-if="showEnterCmd">
      <span class="text-gray-500/50">|</span>
      <span class="text-gray-500/50">Enter to send</span>
      <span class="text-gray-500/50">|</span>
      <span class="text-gray-500/50">
      {{ isOSX ? "⌘ Cmd" : "^ Ctrl"}}+Enter for newline</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import PopoverMenu from "/js/components/PopoverMenu.vue"
import { PopoverContent } from "radix-vue"
import { isOSX } from "/js/composables/useDetectOS"

withDefaults(
  defineProps<{
    showEnterCmd?: boolean
    portal?: boolean
  }>(),
  {
    showEnterCmd: false,
    portal: true,
  }
)

const mdHelpOpen = ref(false)
</script>

<script lang="ts">
export default {
  name: "MarkdownHelp",
}
</script>
