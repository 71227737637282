<template>
  <template v-if="noTransition">
    <div
      class="fixed inset-0 z-10 bg-gray-900/50"
      v-if="sidebarOpen"
      @click.prevent.stop="sidebarOpen = false"></div>
    <div class="fixed bottom-0 left-0 top-0 z-20" :class="{ hidden: !sidebarOpen }">
      <slot></slot>
    </div>
  </template>
  <TransitionRoot v-else as="template" :show="sidebarOpen">
    <Dialog as="div" class="fixed inset-0 z-10 flex lg:hidden" @close="sidebarOpen = false">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full">
        <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="absolute right-0 top-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="sidebarOpen = false">
                <span class="sr-only">Close sidebar</span>
                <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>

          <div class="flex h-0 flex-1 flex-col overflow-y-auto">
            <slot></slot>
          </div>
        </div>
      </TransitionChild>

      <div class="w-14 flex-shrink-0" aria-hidden="true">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue"
import { XMarkIcon } from "@heroicons/vue/20/solid"
import Logo from "/js/components/icons/Logo.vue"
import { computed } from "vue"
import { boolean } from "zod"

const props = defineProps<{
  noTransition?: boolean
}>()

const sidebarOpen = defineModel<boolean>()
</script>

<script lang="ts">
export default {
  name: "MobileNavbarTransition",
}
</script>
