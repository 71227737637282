import { z } from "zod"
import type { DeepPartialNullable } from "/js/types/PartialTypes"
import {
  type LearnableType,
  learnableQuizType,
  learnableUnitAssignmentType,
  learnableUnitLessonType,
} from "/js/models/Course"

// import { makeSchemaDeepPartialAndNullable } from "/js/types/PartialTypes"

export const ZCourseOutlineUnitLesson = z.object({
  title: z.string(),
  description: z.string(),
})

export const ZCourseOutlineUnitAssignment = z.object({
  title: z.string(),
  description: z.string(),
})

export const ZCourseOutlineQuizAnswer = z.object({
  title: z.string(),
  correct: z.boolean(),
  description_hint: z.string().nullable().optional(),
})

export const ZCourseOutlineQuizQuestion = z.object({
  title: z.string(),
  answers: ZCourseOutlineQuizAnswer.array(),
})

export const ZCourseOutlineQuiz = z.object({
  title: z.string(),
  description: z.string(),
  questions: ZCourseOutlineQuizQuestion.array(),
})

export const ZCourseOutlineUnitItem = z.union([
  z.object({
    type: learnableUnitLessonType,
    ...ZCourseOutlineUnitLesson.shape,
  }),
  z.object({
    type: learnableUnitAssignmentType,
    ...ZCourseOutlineUnitAssignment.shape,
  }),
  z.object({
    type: learnableQuizType,
    ...ZCourseOutlineQuiz.shape,
  }),
])

export const ZCourseOutlineUnit = z.object({
  title: z.string(),
  description: z.string(),
  items: ZCourseOutlineUnitItem.array(),
})

export const ZCourseOutline = z.object({
  course_units: ZCourseOutlineUnit.array(),
})

export type CourseOutlineUnit = z.infer<typeof ZCourseOutlineUnit>
export type CourseOutline = z.infer<typeof ZCourseOutline>


// export type PartialCourseOutlineUnit = DeepPartialNullable<CourseOutlineUnit>
// export type PartialCourseOutline = DeepPartialNullable<CourseOutline>
export type PartialCourseOutline = DeepPartialNullable<CourseOutline>

export const learnableTypeLabel = (type: LearnableType | string) => {
  switch (type) {
    case learnableUnitLessonType.value:
      return "Lesson"
    case learnableQuizType.value:
      return "Quiz"
    case learnableUnitAssignmentType.value:
      return "Assignment"
  }

  return ""
}