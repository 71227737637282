import type { RouteMeta } from "vue-router"
import type { ProductAbility } from "/js/composables/useGate"

export const communityReadMeta: RouteMeta = {
  authorize: {
    classType: "Community",
    authorizeFor: "community_read",
    requiresPlatformPlan: true,
  },
}

export const communityEditMeta: RouteMeta = {
  authorize: {
    classType: "Community",
    authorizeFor: "community_edit",
    requiresPlatformPlan: true,
  },
}

export const productPermission = (authorizeFor: ProductAbility): RouteMeta => ({
  authorize: {
    classType: "Product",
    productIdKey: "product_id",
    authorizeFor,
    requiresPlatformPlan: true,
  },
})