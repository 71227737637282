<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="currentColor"/>
    <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="currentColor"/>
    <path d="M6.375 10.875C6.2775 10.875 6.18 10.8525 6.09 10.815C5.9925 10.7775 5.9175 10.725 5.8425 10.6575C5.7075 10.515 5.625 10.3275 5.625 10.125C5.625 10.0275 5.6475 9.92999 5.685 9.83999C5.7225 9.74999 5.775 9.6675 5.8425 9.5925C5.9175 9.525 5.9925 9.47248 6.09 9.43498C6.36 9.32248 6.6975 9.3825 6.9075 9.5925C7.0425 9.735 7.125 9.92999 7.125 10.125C7.125 10.17 7.1175 10.2225 7.11 10.275C7.1025 10.32 7.0875 10.365 7.065 10.41C7.05 10.455 7.0275 10.5 6.9975 10.545C6.975 10.5825 6.9375 10.62 6.9075 10.6575C6.765 10.7925 6.57 10.875 6.375 10.875Z" fill="currentColor"/>
    <path d="M9 10.875C8.9025 10.875 8.805 10.8525 8.715 10.815C8.6175 10.7775 8.5425 10.725 8.4675 10.6575C8.3325 10.515 8.25 10.3275 8.25 10.125C8.25 10.0275 8.2725 9.93002 8.31 9.84002C8.3475 9.75002 8.4 9.66753 8.4675 9.59253C8.5425 9.52503 8.6175 9.47252 8.715 9.43502C8.985 9.31502 9.3225 9.38253 9.5325 9.59253C9.6675 9.73503 9.75 9.93003 9.75 10.125C9.75 10.17 9.7425 10.2225 9.735 10.275C9.7275 10.32 9.7125 10.365 9.69 10.41C9.675 10.455 9.6525 10.5 9.6225 10.545C9.6 10.5825 9.5625 10.62 9.5325 10.6575C9.39 10.7925 9.195 10.875 9 10.875Z" fill="currentColor"/>
    <path d="M11.625 10.875C11.5275 10.875 11.43 10.8525 11.34 10.815C11.2425 10.7775 11.1675 10.725 11.0925 10.6575C11.0625 10.62 11.0325 10.5825 11.0025 10.545C10.9725 10.5 10.95 10.455 10.935 10.41C10.9125 10.365 10.8975 10.32 10.89 10.275C10.8825 10.2225 10.875 10.17 10.875 10.125C10.875 9.93003 10.9575 9.73503 11.0925 9.59253C11.1675 9.52503 11.2425 9.47252 11.34 9.43502C11.6175 9.31502 11.9475 9.38253 12.1575 9.59253C12.2925 9.73503 12.375 9.93003 12.375 10.125C12.375 10.17 12.3675 10.2225 12.36 10.275C12.3525 10.32 12.3375 10.365 12.315 10.41C12.3 10.455 12.2775 10.5 12.2475 10.545C12.225 10.5825 12.1875 10.62 12.1575 10.6575C12.015 10.7925 11.82 10.875 11.625 10.875Z" fill="currentColor"/>
    <path d="M6.375 13.5C6.2775 13.5 6.18 13.4775 6.09 13.44C6 13.4025 5.9175 13.35 5.8425 13.2825C5.7075 13.14 5.625 12.945 5.625 12.75C5.625 12.6525 5.6475 12.555 5.685 12.465C5.7225 12.3675 5.775 12.285 5.8425 12.2175C6.12 11.94 6.63 11.94 6.9075 12.2175C7.0425 12.36 7.125 12.555 7.125 12.75C7.125 12.945 7.0425 13.14 6.9075 13.2825C6.765 13.4175 6.57 13.5 6.375 13.5Z" fill="currentColor"/>
    <path d="M9 13.5C8.805 13.5 8.61 13.4175 8.4675 13.2825C8.3325 13.14 8.25 12.945 8.25 12.75C8.25 12.6525 8.2725 12.555 8.31 12.465C8.3475 12.3675 8.4 12.285 8.4675 12.2175C8.745 11.94 9.255 11.94 9.5325 12.2175C9.6 12.285 9.6525 12.3675 9.69 12.465C9.7275 12.555 9.75 12.6525 9.75 12.75C9.75 12.945 9.6675 13.14 9.5325 13.2825C9.39 13.4175 9.195 13.5 9 13.5Z" fill="currentColor"/>
    <path d="M11.625 13.5001C11.43 13.5001 11.235 13.4176 11.0925 13.2826C11.025 13.2151 10.9725 13.1326 10.935 13.0351C10.8975 12.9451 10.875 12.8476 10.875 12.7501C10.875 12.6526 10.8975 12.5551 10.935 12.4651C10.9725 12.3676 11.025 12.2851 11.0925 12.2176C11.265 12.0451 11.5275 11.9625 11.7675 12.015C11.82 12.0225 11.865 12.0375 11.91 12.06C11.955 12.075 12 12.0976 12.045 12.1276C12.0825 12.1501 12.12 12.1876 12.1575 12.2176C12.2925 12.3601 12.375 12.5551 12.375 12.7501C12.375 12.9451 12.2925 13.1401 12.1575 13.2826C12.015 13.4176 11.82 13.5001 11.625 13.5001Z" fill="currentColor"/>
    <path d="M15.375 7.38H2.625C2.3175 7.38 2.0625 7.125 2.0625 6.8175C2.0625 6.51 2.3175 6.255 2.625 6.255H15.375C15.6825 6.255 15.9375 6.51 15.9375 6.8175C15.9375 7.125 15.6825 7.38 15.375 7.38Z" fill="currentColor"/>
    <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="currentColor"/>
  </svg>
</template>
