<template>
  <user-modal :user-id="comment.user?.id" #default="{ showModal }">
    <button @click="showModal" class="w-full">
      <div class="flex items-center gap-2">
        <round-avatar
            :id="comment.user?.id"
            :avatar-url="comment.user?.avatar_url"
            :verified="comment.user?.verified"
            :text="initials(comment.user)" />

        <div class="flex flex-col leading-tight items-start">
          <div class="text-sm">{{ fullName(comment.user) }}</div>
          <div class="text-secondary text-xs">
            {{ customTimeAgo(comment.created_at) }}
          </div>
        </div>
      </div>
    </button>
  </user-modal>

  <div
    class="relative ml-4 pl-2 before:absolute before:inset-0 before:mt-1 before:block before:w-px before:border-l-2 before:border-l-gray-100">
    <div class="pl-5">
      <div class="text-sm sm:max-w-3/4">
        {{ comment.body }}
      </div>

      <button
        class="primary-link flex items-center gap-2 text-sm"
        @click="showReplies = true"
        v-if="shouldShowLoadRepliesButton">
        Load Replies
        <spinner v-if="isLoading" class="h-4 w-4"></spinner>
      </button>

      <button
        class="primary-link self-start text-sm disabled:text-gray-400"
        :disabled="shouldDisableReplyButton"
        @click="beginReplyToComment"
        v-if="shouldShowReplyButton">
        Reply
      </button>
    </div>

    <!--  replies  -->
    <template v-if="showReplies && comments">
      <div class="mt-4 flex flex-col gap-4">
        <div v-for="c in comments" :key="c.id">
          <comment-view
            :config="config"
            :level="level + 1"
            v-model:reply-to-comment="replyToCommentModel"
            :comment="c" />
        </div>
      </div>
    </template>

    <!--  reply to comment controls  -->
    <div
      :id="`reply-to-comment-${comment.id}`"
      v-if="replyToComment && replyToComment.id === comment.id"
      class="mt-4 flex flex-col gap-2 pl-4">
      <div class="editor-minimal bg-gray-50 p-3">
        <FormKit
          type="textarea"
          v-model="replyBody"
          name="message"
          label=""
          validation="required" />
      </div>

      <div v-if="sendError" class="text-danger text-xs">
        {{ sendError }}
      </div>

      <div class="flex w-full items-center gap-4 self-end">
        <div class="text-secondary self-start text-sm">
          Reply to
          <span class="text-accent">{{ fullName(comment.user) }}</span>
        </div>

        <div class="grow"></div>

        <button @click="addComment" class="btn btn-primary btn-sm" :disabled="!replyBodyHasContent">
          Add Reply
        </button>

        <button @click="replyToCommentModel = undefined" class="primary-link text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials } from "/js/models/User"
import { customTimeAgo } from "/js/composables/customTimeAgo"
import { computed, nextTick, ref } from "vue"
import type { Comment } from "/js/models/Comment"
import { CommentsApi } from "/js/services/CommentsApi"
import { useQuery } from "@tanstack/vue-query"
import getErrorMessage from "/js/composables/getErrorMessage"
import Spinner from "/js/components/icons/Spinner.vue"
import { useCommentsService, replyCommentListQueryKey } from "/js/services/useCommentsService"
import type { CommentableConfig } from "/js/services/useCommentsService"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import UserModal from "/js/components/Modals/UserModal.vue";

const props = defineProps<{
  comment: Comment
  config: CommentableConfig
  replyToComment: Comment | undefined
  level: number
}>()

const replyToCommentModel = computed({
  get: () => props.replyToComment,
  set: (value) => emit("update:replyToComment", value),
})

const emit = defineEmits<{
  "update:replyToComment": [value: Comment | undefined]
}>()

const { addReplyToComment } = useCommentsService(props.config)

const addedReply = ref(false)

const sendError = ref<string | undefined>()

const hasReplies = computed(() => {
  return props.comment.children_count > 0
})

const showReplies = ref(!hasReplies.value)

const replyBody = ref<string | undefined>()

const replyBodyHasContent = computed(() => {
  return replyBody.value && replyBody.value.length > 0
})

const allowFurtherNesting = computed(() => {
  return props.level < 2
})

const beginReplyToComment = async () => {
  replyBody.value = undefined
  replyToCommentModel.value = props.comment

  nextTick(() => {
    const el = document.getElementById(`reply-to-comment-${props.comment.id}`)
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  })
}

const addComment = async () => {
  if (!replyBody.value) return

  try {
    sendError.value = undefined

    await addReplyToComment.mutateAsync({
      commentId: props.comment.id,
      body: replyBody.value,
      config: props.config,
    })

    replyToCommentModel.value = undefined
    addedReply.value = true
  } catch (e) {
    sendError.value = getErrorMessage(e)
  }
}

const shouldShowLoadRepliesButton = computed(() => {
  return (
    allowFurtherNesting.value &&
    hasReplies.value &&
    !addedReply.value &&
    (!showReplies.value || (showReplies.value && isLoading.value))
  )
})

const shouldShowReplyButton = computed(() => {
  return allowFurtherNesting.value && showReplies.value && !isLoading.value
})

const shouldDisableReplyButton = computed(() => {
  return replyToCommentModel.value && replyToCommentModel.value.id === props.comment.id
})

const { data: comments, isInitialLoading: isLoading } = useQuery({
  queryKey: computed(() => replyCommentListQueryKey(props.comment.id)),
  queryFn: async () => await CommentsApi.getChildComments(props.comment.id),
  enabled: computed(() => showReplies.value && hasReplies.value),
})
</script>

<script lang="ts">
export default {
  name: "CommentView",
}
</script>
