<template>
  <div class="w-screen h-screen flex items-center justify-center" v-if="isLoading">
    <spinner class="h-24 w-24 text-teal-500"></spinner>
  </div>
  <app v-else-if="loaded"></app>
  <div class="w-screen h-screen flex flex-col gap-5 items-center justify-center" v-else-if="error">
    <div class="text-red-500">There was an error while loading the data</div>
    <button class="btn-primary" @click="reloadData">Reload</button>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import Spinner from '/js/components/icons/Spinner.vue'
import App from '/js/App.vue'
import { useCurrentUserService } from '/js/services/useCurrentUserService'
import { useCommunityService } from '/js/services/useCommunityService'
import getErrorMessage from '/js/composables/getErrorMessage'
import { useFlash } from '/js/composables/useFlash'
import { toastError, toastSuccess } from "/js/composables/toast"
import { useQuery } from "@tanstack/vue-query"
import { UsersApi } from "/js/services/UsersApi"
import { usePageViewStore } from "/js/stores/PageViewStore"

const flash = useFlash()


onMounted(() => {
  if (!flash) return

  if (flash.type === 'error') {
    toastError(flash.message)
  } else if (flash.type === 'notice') {
    toastSuccess(flash.message)
  }
})

const currentUserService = useCurrentUserService()
const communityService = useCommunityService()

const { startPeriodicSync } = usePageViewStore()

const {
  data: user,
  isLoading: userLoading,
  error: userError,
  refetch: refetchUser,
} = currentUserService.load()

const {
  data: currentCommunity,
  isLoading: currentCommunityLoading,
  error: communityError,
  refetch: refetchCommunity,
} = communityService.loadCurrentCommunity()

const {
  data: userGroups,
  isLoading: userGroupsLoading,
  error: userGroupsError,
  refetch: refetchUserGroups,
} = currentUserService.loadGroups()

onMounted(() => {
  UsersApi.logActivity()
  startPeriodicSync()
})

const isLoading = computed(
  () => userLoading.value || currentCommunityLoading.value || userGroupsLoading.value
)

const error = computed(() => {
  if (!userError.value && !communityError.value && !userGroupsError.value) return null
  return [userError.value, communityError.value, userGroupsError.value]
    .map(getErrorMessage)
    .filter(Boolean)
    .join('\n')
})

const loaded = computed(() => !isLoading.value && !error.value)

const reloadData = () => {
  refetchUser()
  refetchCommunity()
  refetchUserGroups()
}
</script>

<script lang="ts">
export default {
  name: 'ContainerApp',
}
</script>
