<template>
  <div
    ref="contentContainer"
    :class="{ 'overflow-hidden': truncate && truncatable }"
    :style="{ maxHeight: truncate && truncatable ? `${maxHeight}px` : 'none' }"
    v-auto-animate>
    <slot />

    <button
      v-if="truncatable"
      class="group absolute bottom-0 left-1/2 -translate-x-1/2 transform px-8 pb-4 pt-8 z-10"
      @click.prevent.stop="toggleRevealAction">
      <ChevronDownIcon
        :class="{ 'rotate-180 opacity-20 group-hover:opacity-100': !truncate }"
        class="h-5 w-5 rounded bg-white p-1 shadow transition-all group-hover:h-6 group-hover:w-6 group-hover:shadow-md" />
    </button>

    <modal v-model="showModal">
      <slot />
    </modal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useElementSize } from "@vueuse/core"
import Modal from "/js/components/Modals/Modal.vue"
import ChevronDownIcon from "/js/components/icons/ChevronDownIcon.vue"

// Define the common height value
const maxHeight = 192 // 48 * 4, assuming 1rem = 16px

const props = withDefaults(
  defineProps<{
    modal?: boolean
  }>(),
  {
    modal: false,
  }
)

const truncate = ref(false)
const truncatable = ref(false)
const showModal = ref(false)
const contentContainer = ref<HTMLElement | null>(null)

const { height: contentHeight } = useElementSize(contentContainer)

const toggleRevealAction = () => {
  if (truncate.value) {
    handleRevealAction()
  } else {
    handleHideAction()
  }
}

const handleRevealAction = () => {
  if (props.modal) {
    showModal.value = !showModal.value
  } else {
    truncate.value = !truncate.value
  }
}

const handleHideAction = () => {
  truncate.value = true
  showModal.value = false
}

const didSetElementSizeTruncate = ref(false)

// Watch content height and disable truncating if the height is too small
watch(
  contentHeight,
  (newHeight) => {
    if (!didSetElementSizeTruncate.value && newHeight > 0 && newHeight >= maxHeight) {
      didSetElementSizeTruncate.value = true
      truncate.value = true
      truncatable.value = true
    }
  },
  { immediate: true }
)
</script>

<script lang="ts">
export default {
  name: "TruncateView",
}
</script>