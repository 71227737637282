import { ref } from "vue"

const sidebarSectionSideActive = ref(false)
const sidebarOpen = ref(false)

export const useSidebarLayout = () => {
  return {
    sidebarSectionSideActive,
    sidebarOpen
  }
}