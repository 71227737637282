<template>
  <card-loader :loading="isLoading">
    <featured-products-card
      title="Featured Learning Hubs"
      :products
      v-if="products && products.length > 0" />
    <empty-view title="No Learning Hubs found" v-else>
      <learning-hubs-empty-icon class="h-40 w-40" />
    </empty-view>
    <error-message :error v-if="error" />
  </card-loader>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import FeaturedProductsCard from "/js/components/BlockBuilder/Cards/FeaturedProductsCard.vue"
import type { FeaturedProductsWidget } from "/js/models/Widget"
import { computed } from "vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import LearningHubsEmptyIcon from "/js/components/icons/LearningHubsEmptyIcon.vue"

const props = defineProps<{
  widgetId: string
  widget: FeaturedProductsWidget
}>()

const {
  data: products,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["widget_product_list", props.widgetId]),
  queryFn: async () => await WidgetsApi.getProducts(props.widgetId),
})
</script>

<script lang="ts">
export default {
  name: "CommunityFeaturedProductsLoader",
}
</script>
