import { get, post } from "/js/composables/useAxios"
import type { AdminInvitation, Invitation } from "/js/services/ProfileApi"
import type { CommunityRole, ProductRole } from "/js/services/permissionPolicyService"

export type BulkInvitationParams = {
  community_user_group_type: CommunityRole
  product_ids: string[]
  emails: string[]
}

export type InvitableType = "Community" | "Product"

export const InvitationsApi = {
  inviteCommunityMember: async (
    communityId: string,
    email: string,
    role: CommunityRole
  ): Promise<void> => {
    return await post(`/api/invitations`, {
      invitable_type: "Community",
      invitable_id: communityId,
      invitee_email: email,
      invitee_role: role,
    })
  },

  inviteProductMember: async (
    productId: string,
    email: string,
    role: ProductRole
  ): Promise<void> => {
    return await post(`/api/invitations`, {
      invitation: {
        invitable_type: "Product",
        invitable_id: productId,
        invitee_email: email,
        invitee_role: role,
      },
    })
  },

  bulkInvite: async (params: BulkInvitationParams): Promise<void> => {
    return await post(`/api/invitations/bulk_create`, { bulk_invite: params })
  },

  getInvitationByInvitable: async (invitableType: InvitableType, invitableId: string): Promise<Invitation> => {
    return await post(`/api/invitations/invitable`, {
      invitable_type: invitableType,
      invitable_id: invitableId,
    })
  },

  getInvitation: async (token: string): Promise<Invitation> => {
    return await get(`/api/invitations/${token}`)
  },
}
