<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-30 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in duration-200"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-300 bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300 sm:duration-300"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-300"
            leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div :class="rightModalWidthClass">
              <div class="flex h-full flex-col overflow-y-auto overflow-x-hidden shadow-xl" :class="bg ? bg : 'bg-white'">
                <div class="relative h-full">
                  <div
                    class="absolute left-6 right-6 top-6 flex items-center justify-between"
                    v-if="!hideTopBar">
                    <div>
                      <h4 class="font-medium" v-if="title">{{ title }}</h4>
                      <slot name="title" v-else></slot>
                    </div>
                    <icon-button @click="handleClose">
                      <x-mark-icon class="h-6 w-6" />
                    </icon-button>
                  </div>

                  <div class="h-full" :class="{ 'pt-16': !fullSpace && !hideTopBar }">
                    <slot v-bind="{ close: handleClose }" />
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue"
import { XMarkIcon } from "@heroicons/vue/20/solid"
import type { RightModalProps } from "/js/components/Modals/ModalTypes"
import IconButton from "/js/components/IconButton.vue"

withDefaults(defineProps<RightModalProps>(), {
  fullSpace: false,
  hideTopBar: false,
  rightModalWidthClass: "w-screen max-w-sm"
})

const open = defineModel<boolean>()

const handleClose = () => {
  open.value = false
}
</script>

<script lang="ts">
export default {
  name: "RightModal",
}
</script>
