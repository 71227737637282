<template>
  <div class="flex w-full max-w-2xl flex-col items-center gap-4 text-center">
    <img-homepage-empty class="h-40 w-40 lg:h-64 lg:w-64" />
    <h4 class="text-primary font-semibold">Overview Page is Empty</h4>
    <div class="text-secondary text-sm lg:text-base">
      Start building your learning hub’s overview page by using our interactive tools. Drag and drop
      your desired content from the left side and edit its content to your learning hub’s needs.
    </div>
  </div>
</template>

<script setup lang="ts">
import ImgHomepageEmpty from "/js/components/icons/Images/ImgHomepageEmpty.vue";
</script>

<script lang="ts">
export default {
  name: "LearningHubEditOverviewEmptyCard"
}
</script>