<template>
  <PopoverRoot v-model:open="toggleState" :modal>
    <PopoverTrigger :class="buttonClass" @click.prevent.stop>
      <slot name="button">
        <ellipsis-icon
          class="h-4 w-4 text-gray-600 hover:text-gray-500"
          aria-hidden="true"
          :class="iconColor" />
      </slot>
    </PopoverTrigger>

    <PopoverPortal v-if="portal">
      <slot />
    </PopoverPortal>

    <slot v-else />
  </PopoverRoot>
</template>

<script setup lang="ts">
import { PopoverPortal, PopoverRoot, PopoverTrigger } from "radix-vue"
import EllipsisIcon from "/js/components/icons/EllipsisIcon.vue"

type Props = {
  iconColor?: string
  buttonClass?: string
  menuItemsWrapperClass?: string
  align?: "start" | "center" | "end"
  modal?: boolean
  portal?: boolean
}

withDefaults(defineProps<Props>(), {
  placement: "bottom-end",
  align: "end",
  modal: false,
  portal: true,
})

const toggleState = defineModel({ default: false })
</script>

<script lang="ts">
export default {
  name: "DropdownMenu",
}
</script>
