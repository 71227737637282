import { defineStore } from "pinia"
import { computed, nextTick, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useTourService } from "/js/services/useTourService"

export type TourStepItem = {
  id: string
  title: string
}

export const useTourStore = (steps: TourStepItem[]) => {
  const started = ref(false)
  const tourService = useTourService()
  const route = useRoute()
  const pageIds = ref<string[]>([])
  const activeStepId = ref<string | null>(null)

  const { data: completedStepIds } = tourService.loadTourStepIds()

  const stepIds = computed(() => {
    return steps.map((step) => step.id)
  })

  const notCompletedSteps = computed(() => {
    if (completedStepIds.value === undefined) return []
    return steps.filter((step) => !completedStepIds.value.includes(step.id))
  })

  const notCompletedStepIds = computed(() => {
    return notCompletedSteps.value.map((step) => step.id)
  })

  const notCompletedPageIds = computed(() => {
    return pageIds.value.filter((id) => notCompletedStepIds.value.includes(id))
  })

  const hasCompletedAllSteps = computed(() => {
    return notCompletedSteps.value.length === 0
  })

  const getDocumentStepIds = (stepIds: string[]) =>
    stepIds.filter((id) => document.getElementById(id))

  const activeStep = computed(() => {
    if (!activeStepId.value) return null
    return steps.find((step) => step.id === activeStepId.value) || null
  })

  const nextStepId = computed(() => {
    if (!activeStepId.value) return null
    const stepsAfterActive = stepIds.value.slice(stepIds.value.indexOf(activeStepId.value) + 1)
    return stepsAfterActive.find((id) => pageIds.value.includes(id)) || null
  })

  const nextStep = computed(() => {
    if (!nextStepId.value) return null
    return steps.find((step) => step.id === nextStepId.value) || null
  })

  const previousStepId = computed(() => {
    if (!activeStepId.value) return null
    const stepsBeforeActive = stepIds.value.slice(0, stepIds.value.indexOf(activeStepId.value))
    return stepsBeforeActive.reverse().find((id) => pageIds.value.includes(id)) || null
  })

  const previousStep = computed(() => {
    if (!previousStepId.value) return null
    return steps.find((step) => step.id === previousStepId.value) || null
  })

  const firstStepId = computed(() => {
    return stepIds.value.find((id) => pageIds.value.includes(id)) || null
  })

  const firstNotCompletedStepId = computed(() => {
    return notCompletedStepIds.value.find((id) => pageIds.value.includes(id)) || null
  })

  const firstNotCompletedStep = computed(() => {
    if (!firstNotCompletedStepId.value) return null
    return steps.find((step) => step.id === firstNotCompletedStepId.value) || null
  })

  const firstStep = computed(() => {
    if (!firstStepId.value) return null
    return steps.find((step) => step.id === firstStepId.value) || null
  })

  const start = async () => {
    started.value = true
    activeStepId.value = firstNotCompletedStepId.value
  }

  watch(completedStepIds, () => {
    if (completedStepIds.value && started.value) {
      if (!activeStepId.value) {
        activeStepId.value = firstNotCompletedStepId.value
      }
    }
  })

  const completeStep = (id: string | string[] | null) => {
    if (!id) return
    const ids = Array.isArray(id) ? id : [id]
    const notCompletedIds = ids.filter((id) => !completedStepIds.value?.includes(id))
    if (notCompletedIds.length === 0) return
    tourService.completeStep.mutateAsync(notCompletedIds)
  }

  const goToNextStep = () => {
    completeStep(activeStepId.value)
    if (!nextStepId.value) return
    activeStepId.value = nextStepId.value
  }

  const goToPreviousStep = () => {
    if (!previousStepId.value) return
    activeStepId.value = previousStepId.value
  }

  const doneTour = () => {
    completeStep(activeStepId.value)

    activeStepId.value = null
  }

  const skipTour = () => {
    tourService.completeStep.mutateAsync(stepIds.value) // complete all steps
    started.value = false
    activeStepId.value = null
  }

  const resetTour = () => {
    tourService.resetTour.mutateAsync()
  }

  watch(
    route,
    () => {
      nextTick(() => {
        pageIds.value = getDocumentStepIds(stepIds.value)
        if (started.value && !activeStepId.value) {
          nextTick(() => {
            activeStepId.value = firstNotCompletedStepId.value
          })
        }
      })
    },
    { immediate: true }
  )

  return {
    start,
    started,
    activeStep,
    nextStep,
    previousStep,
    goToNextStep,
    goToPreviousStep,
    skipTour,
    doneTour,
    completedStepIds,
    notCompletedSteps,
    hasCompletedAllSteps,
    firstStep,
    firstNotCompletedStep,
    pageIds,
    resetTour,
  }
}

export const mainTourStore = defineStore("mainTourStore", () => {
  const steps: TourStepItem[] = [
    {
      id: "v-step-admin-sidebar",
      title: "Admin Sidebar",
    },
    {
      id: "v-step-admin-switcher",
      title: "Admin Switcher",
    },
    {
      id: "v-step-admin-dashboard-cta-cards",
      title: "Admin Dashboard Cards",
    },
  ]

  return useTourStore(steps)
})
