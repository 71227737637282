<template>
  <div v-if="data?.content" class="flex flex-col gap-4">
    <template v-for="item in data.content.filter(notNullish)">
      <p v-if="item.type === 'paragraph' && item.text">
        {{ item.text }}
      </p>

      <ul v-if="item.type === 'list' && item.items">
        <li v-for="listItem in item.items.filter(notNullish)" class="list-disc ml-4">
          {{ listItem }}
        </li>
      </ul>

      <pre v-if="item.type === 'code' && item.code" class="rounded-lg bg-gray-200 p-2">
        <code>
          {{ item.code }}
        </code>
      </pre>

      <div v-if="item.type === 'quote'" class="flex flex-col gap-2 border-l-4 border-accent p-2">
        <p v-if="item.text">{{ item.text }}</p>
        <p v-if="item.caption" class="text-xs text-accent">- {{ item.caption }}</p>
      </div>

      <h2 v-if="item.type === 'header2' && item.text" class="font-medium">{{ item.text }}</h2>
      <h3 v-if="item.type === 'header3' && item.text" class="font-medium">{{ item.text }}</h3>
      <h4 v-if="item.type === 'header4' && item.text" class="font-medium">{{ item.text }}</h4>
      <h5 v-if="item.type === 'header5' && item.text" class="font-medium">{{ item.text }}</h5>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PartialEditorJsSchema } from "/js/components/GptChat/EditorJs/EditorJsModels"
import { notNullish } from "@vueuse/core"

const props = defineProps<{
  data?: PartialEditorJsSchema
}>()
</script>

<script lang="ts">
export default {
  name: "EditorJsAssistantMessage",
}
</script>
