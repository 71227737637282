<template>
  <div class="padding-x flex flex-col gap-4">
    <gradient-image
      class="aspect-[21/7] max-h-64 w-full rounded-lg"
      :id="user.id"
      :url="user.cover_url" />

    <div class="-mt-32 px-8 pb-4 pt-8">
      <round-avatar
        class="border-2 border-white shadow"
        :id="user.id"
        :avatar-url="user.avatar_url"
        :verified="user.verified"
        :text="initials(user)"
        size="lg" />
    </div>

    <p class="flex items-center gap-4 text-lg font-medium text-gray-900">
      {{ fullName(user) }}

      <span
        v-if="communityRole"
        class="rounded-lg px-2 py-1 text-sm"
        :class="tagClass(communityRole.community_role)">
                        {{ communityRoleLabel }}
                      </span>

      <span class="flex-1"></span>

      <MessageUserAction
        v-if="canMessage"
        :userId="user.id"
        button-class="btn btn-primary btn-sm" />
    </p>

    <p class="text-secondary flex items-center gap-1 text-sm" v-if="timeZoneString">
      <ClockIcon class="h-4 w-4" />
      {{ timeZoneString }} local time
    </p>

    <p class="whitespace-pre-line text-xs text-gray-500">
      {{ user.description }}
    </p>
  </div>
</template>

<script setup lang="ts">

import { fullName, initials, type User } from "/js/models/User"
import { CommunityOptionLabels, tagClass } from "/js/services/permissionPolicyService"
import ClockIcon from "/js/components/icons/ClockIcon.vue"
import MessageUserAction from "/js/components/Chat/MessageUserAction.vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import GradientImage from "/js/components/GradientImage.vue"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { computed } from "vue"
import { useQuery } from "@tanstack/vue-query"
import { UsersApi } from "/js/services/UsersApi"

const props = defineProps<{
  user: User
}>()

const { data: currentUser } = useCurrentUserService().load()

const canMessage = computed(() => {
  if (!currentUser.value) return false
  return currentUser.value.id !== props.user.id
})

const { data: communityRole } = useQuery({
  queryKey: computed(() => ["communityRole", props.user.id]),
  queryFn: async () => await UsersApi.getCommunityRole(props.user.id),
  staleTime: Infinity,
})

const communityRoleLabel = computed(() => {
  if (communityRole.value) {
    return CommunityOptionLabels[communityRole.value.community_role]
  }
  return undefined
})

const getTimeInUserTimezone = (timeZone: string) => {
  const date = new Date()

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
    timeZone,
  }

  const formatter = new Intl.DateTimeFormat("en-US", options)
  return formatter.format(date)
}

const timeZoneString = computed(() => {
  if (props.user.time_zone) {
    return getTimeInUserTimezone(props.user.time_zone)
  }
  return undefined
})

</script>

<script lang="ts">
export default {
  name: "PublicUserProfileTab",
}
</script>