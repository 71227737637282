import type {ProductAccessType, ProductStatus} from "/js/models/Product"
import {labelClass} from "/js/composables/gradientId";

export const productStatusTagClass = (status: ProductStatus): string => {
  switch (status) {
    case "draft":
      return "bg-yellow-100 text-yellow-500"
    case "published":
      return "bg-green-100 text-green-500"
    case "archived":
      return "bg-gray-100 text-gray-500"
  }
}

export const productStatusTagLabel = (status: ProductStatus): string => {
  switch (status) {
    case "draft":
      return "Draft"
    case "published":
      return "Published"
    case "archived":
      return "Archived"
  }
}

export const productStatusTooltipLabel = (status: ProductStatus): string => {
  switch (status) {
    case "draft":
      return "This Learning Hub is not visible to members."
    case "published":
      return "This Learning Hub is visible to members."
    case "archived":
      return "This Learning Hub is archived and not visible to new members. It is still visible to members with an existing subscription."
  }
}

export const productAccessTagLabel = (access: ProductAccessType): string => {
  switch (access) {
    case "public_product":
      return "Public"
    case "members":
      return "Members only"
    case "invite":
      return "Invite only"
  }
}

export const productAccessTagClass = (access: ProductAccessType): string => {
  switch (access) {
    case "public_product":
      return "bg-indigo-100 text-indigo-500"
    case "members":
      return "bg-green-100 text-green-500"
    case "invite":
      return "bg-red-100 text-red-500"
  }
}

export const productAccessTooltipLabel = (access: ProductAccessType): string => {
  switch (access) {
    case "public_product":
      return "Anyone can join this Learning Hub"
    case "members":
      return "Only community members can join this Learning Hub."
    case "invite":
      return "Only members invited by the Learning Hub owner can join this Learning Hub."
  }
}

export const productLabelColors = [
  "bg-blue-100 text-blue-500",
  "bg-yellow-100 text-yellow-500",
  "bg-purple-100 text-purple-500",
  "bg-red-100 text-red-500",
  "bg-green-100 text-green-500",
]

export const productLabelTagClass = (label: string): string => {
  const color = labelClass(label, productLabelColors)
  return color || "bg-gray-100 text-gray-500"
}


