<template>
  <router-link custom :to="to" v-slot="{ href, navigate }">
    <DropdownMenuItem as="a" :href @click="navigate" :class="radixBtnClass(variant)">
      <slot />
    </DropdownMenuItem>
  </router-link>
</template>

<script setup lang="ts">
import type { RouteLocationNamedRaw } from "vue-router"
import { radixBtnClass } from "/js/components/utilities/DropdownMenuBtnClass"
import type { BtnVariant } from "/js/components/utilities/DropdownMenuBtnClass"
import { DropdownMenuItem } from "radix-vue"

type Props = {
  variant?: BtnVariant
  to: RouteLocationNamedRaw
}

withDefaults(defineProps<Props>(), {
  variant: "default",
})
</script>

<script lang="ts">
export default {
  name: "DropdownRouterMenuItem",
}
</script>
