<template>
  <DropdownMenuItem :disabled :class="radixBtnClass(variant)">
    <slot></slot>
  </DropdownMenuItem>
</template>

<script setup lang="ts">
import { DropdownMenuItem } from "radix-vue"
import { type BtnVariant, radixBtnClass } from "/js/components/utilities/DropdownMenuBtnClass"

type Props = {
  variant?: BtnVariant
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  variant: "default",
})
</script>

<script lang="ts">
export default {
  name: "DropdownMenuItem",
}
</script>
