<template>
  <div :id="holder"></div>
</template>

<script setup lang="ts">
import { editorConfig, makeEditorConfig } from "/js/components/utilities/Editor/editorConfig"
import { useEditorBuilder } from "/js/components/utilities/Editor/useEditorBuilder"
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import type { EditorConfig } from "@editorjs/editorjs"
import {
  buildContent,
  buildEmptyParagraphBlock,
} from "/js/components/utilities/Editor/embedPluginSources"

const { holder, editor, initEditor, destroyEditor } = useEditorBuilder()

const props = defineProps<{
  initialData?: any
  autofocus?: boolean
  readonly?: boolean
  customConfig?: EditorConfig
  productId?: string | null
}>()

const emit = defineEmits<{
  editorData: [value: any]
}>()

const editorData = ref(props.initialData)

const currentConfig = computed(() => {
  if (props.customConfig) {
    return props.customConfig
  } else {
    return editorConfig
  }
})

const buildEditorIfNeeded = () => {
  if (editor.value) {
    return
  }

  let config = {
    ...makeEditorConfig(currentConfig.value, props.productId),
    autofocus: props.autofocus,
    onChange: editorChangeHandler,
    data: editorData.value,
  }

  if (props.readonly) {
    // remove placeholder key from editorConfig
    delete config.placeholder
    config = {
      ...config,
      readOnly: true,
    }
  }

  initEditor(config)
}

const editorChangeHandler = async (api: any, event: any) => {
  if (!editor.value) return
  if (props.readonly) return
  editorData.value = await editor.value.save()
}

watch(
  editorData,
  (value) => {
    emit("editorData", editorData.value)
  },
  { immediate: true }
)

watch(
  () => props.initialData,
  async (value) => {
    if (!editor.value) {
      return
    }

    await editor.value.isReady

    if (!value && !!editorData.value) {
      editor.value.render(buildContent([buildEmptyParagraphBlock()]))
    } else if (!!value) {
      // if props initial changes, that means we have updated editor data. this watcher is not immediate so it only reacts to future changes
      editorData.value = value
      editor.value.render(value)
    }
  }
)

const resetEditor = async () => {
  editorData.value = null
  if (!editor.value) return
  await editor.value.isReady
  editor.value.render(buildContent([buildEmptyParagraphBlock()]))
}

defineExpose({
  resetEditor,
})

onMounted(buildEditorIfNeeded)

onBeforeUnmount(destroyEditor)
</script>

<script lang="ts">
export default {
  name: "EditorWrapper",
}
</script>
