<template>
  <textarea
    rows="1"
    v-model="prompt"
    ref="inputRef"
    @keydown="handleKeydown"
    autofocus
    class="max-h-52 resize-none"
    placeholder="Write your message..." />
</template>

<script setup lang="ts">

import { nextTick, onMounted, ref, watch } from "vue"

const prompt = defineModel<string>({ required: false })

const inputRef = ref<HTMLTextAreaElement | undefined>()

const emit = defineEmits<{
  submit: [value: string]
}>()

function handleKeydown(event: KeyboardEvent) {
  if (event.key === "Enter") {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault()
      prompt.value += "\n"
    } else {
      event.preventDefault()
      if (prompt.value && prompt.value.trim().length > 0) {
        emit("submit", prompt.value)
      }
    }
  }
}

const resizeTextarea = () => {
  if (inputRef.value) {
    inputRef.value.style.height = "auto" // Reset the height
    inputRef.value.style.height = `${inputRef.value.scrollHeight}px` // Set it to the scroll height
  }
}

onMounted(() => {
  resizeTextarea()
})

watch(prompt, () => {
  nextTick(() => {
    resizeTextarea()
  })
})

</script>

<script lang="ts">
export default {
  name: "AutogrowTextarea",
}
</script>
