<template>
  <hover-card @open="onHoverCardOpen" :open-delay="300">
    <template #trigger>
      <slot></slot>
    </template>
    <content-view
      :is-error="isError"
      :is-empty="isSuccess && products?.length === 0"
      :isLoading="isLoading">
      <template #empty>
        <div class="flex h-20 items-center justify-center">
          <span class="text-gray-500">No Learning Hubs found</span>
        </div>
      </template>
      <template #error>
        <div class="flex h-20 items-center justify-center">
          <span class="text-center text-red-500">
            There was an error while loading the Learning Hubs
          </span>
        </div>
      </template>
      <template #loading>
        <div class="flex flex-col gap-2">
          <skeleton class="h-4 w-64"></skeleton>
          <skeleton class="h-4 w-64"></skeleton>
          <skeleton class="h-4 w-64"></skeleton>
        </div>
      </template>

      <div class="flex flex-col gap-2">
        <router-link
          v-for="product in currentProducts"
          :key="product.id"
          class="flex gap-2 rounded-xl px-2 py-2 hover:bg-gray-50"
          :to="{ name: namedRoutes.learning_hubs_show, params: { product_id: product.id } }">
          <span class="flex flex-1 items-center gap-4">
            <round-avatar
              :avatar-url="attachmentImageUrl(product.cover_attachment, { preferThumb: true })"
              :id="product.id"
              size="xs"
              no-text
              rounded="rounded-lg" />
            <span>{{ product.name }}</span>
          </span>
        </router-link>
      </div>
    </content-view>
  </hover-card>
</template>

<script setup lang="ts">
import HoverCard from "/js/components/HoverCard.vue"
import { useQuery } from "@tanstack/vue-query"
import { computed, ref } from "vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import Skeleton from "/js/components/Skeleton.vue"
import { PlansApi } from "/js/services/PlansApi"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import { namedRoutes } from "/js/router/LearningHubRoutes"
import { attachmentImageUrl } from "/js/models/Product"

const props = defineProps<{
  planId: string
  excludeIds?: string[] | undefined
}>()

const hoverCardOpen = ref(false)

const {
  data: products,
  isLoading,
  isError,
  isSuccess,
} = useQuery({
  queryKey: computed(() => ["plan_products", props.planId]),
  queryFn: async () => PlansApi.getProducts(props.planId),
  staleTime: Infinity,
  cacheTime: Infinity,
  enabled: computed(() => !!hoverCardOpen.value),
})

const currentProducts = computed(() => {
  if (!products.value) return []
  return products.value.filter((product) => {
    if (!props.excludeIds) return true
    return !props.excludeIds.includes(product.id)
  })
})

const onHoverCardOpen = (open: boolean) => {
  hoverCardOpen.value = open
}
</script>

<script lang="ts">
export default {
  name: "PlanProductsHoverCard",
}
</script>
