import { z } from "zod"
import type { DeepPartialNullable } from "/js/types/PartialTypes"
import { nanoid } from "nanoid"

// Define literals for type fields
const ZParagraphType = z.literal("paragraph")
const ZListType = z.literal("list")
const ZCodeType = z.literal("code")
const ZQuoteType = z.literal("quote")
const ZHeader2Type = z.literal("header2")
const ZHeader3Type = z.literal("header3")
const ZHeader4Type = z.literal("header4")
const ZHeader5Type = z.literal("header5")

// Define literals for list styles
const ZUnorderedListStyle = z.literal("unordered")
const ZOrderedListStyle = z.literal("ordered")

// Define literals for alignments
const ZLeftAlignment = z.literal("left")
const ZCenterAlignment = z.literal("center")
const ZRightAlignment = z.literal("right")

// Define enums using the literals
const ZListStyles = z.enum([ZUnorderedListStyle.value, ZOrderedListStyle.value])
const ZAlignments = z.enum([ZLeftAlignment.value, ZCenterAlignment.value, ZRightAlignment.value])

// Define the schema for each type of content
const ZParagraphSchema = z.object({
  type: ZParagraphType,
  text: z.string(),
  alignment: ZAlignments.optional(),
})

const ZListSchema = z.object({
  type: ZListType,
  items: z.array(z.string()),
  style: ZListStyles.optional(),
})

const ZCodeSchema = z.object({
  type: ZCodeType,
  code: z.string(),
})

const ZQuoteSchema = z.object({
  type: ZQuoteType,
  text: z.string(),
  caption: z.string(),
  alignment: ZAlignments.optional(),
})

const ZHeaderSchema = z.object({
  type: z.enum([ZHeader2Type.value, ZHeader3Type.value, ZHeader4Type.value, ZHeader5Type.value]),
  text: z.string(),
})

export const ZEditorJsSchema = z.object({
  content: z.array(
    z.union([ZParagraphSchema, ZListSchema, ZCodeSchema, ZQuoteSchema, ZHeaderSchema])
  ),
})

export type EditorJsParagraph = z.infer<typeof ZParagraphSchema>
export type EditorJsList = z.infer<typeof ZListSchema>
export type EditorJsCode = z.infer<typeof ZCodeSchema>
export type EditorJsQuote = z.infer<typeof ZQuoteSchema>
export type EditorJsHeader = z.infer<typeof ZHeaderSchema>

export type EditorJsSchema = z.infer<typeof ZEditorJsSchema>
export type PartialEditorJsSchema = DeepPartialNullable<EditorJsSchema>

class EJSBuilder {
  private obj: any

  constructor() {
    this.obj = {}
  }

  type(type: string): this {
    this.obj.type = type
    return this
  }

  data(object: object): this {
    this.obj.data = object
    return this
  }

  alignment(alignment: string | undefined): this {
    if (!alignment) return this

    if (!this.obj.tunes) {
      this.obj.tunes = {}
    }

    if (!this.obj.tunes.alignmentTune) {
      this.obj.tunes.alignmentTune = {}
    }

    this.obj.tunes.alignmentTune.alignment = alignment
    return this
  }

  build(): object {
    return this.obj
  }
}

export const convertEJSParagraph = (paragraph: EditorJsParagraph): object => {
  return new EJSBuilder()
    .type("paragraph")
    .data({ text: paragraph.text })
    .alignment(paragraph.alignment)
    .build()
}

export const convertEJSList = (list: EditorJsList): object => {
  return new EJSBuilder()
    .type("list")
    .data({
      items: list.items.map((item) => ({ content: item, items: [] })),
      style: list.style,
    })
    .build()
}

export const convertEJSCode = (code: EditorJsCode): object => {
  return new EJSBuilder().type("code").data({ code: code.code }).build()
}

export const convertEJSQuote = (quote: EditorJsQuote): object => {
  return new EJSBuilder()
    .type("quote")
    .data({ text: quote.text, caption: quote.caption })
    .alignment(quote.alignment)
    .build()
}

export const convertEJSHeader = (header: EditorJsHeader): object => {
  const level: number = parseInt(header.type.replace("header", ""), 10)
  return new EJSBuilder().type("header").data({ text: header.text, level }).build()
}

export const convertEJSContent = (content: EditorJsSchema): object[] => {
  return content.content.map((block) => {
    switch (block.type) {
      case ZParagraphType.value:
        return convertEJSParagraph(block)
      case ZListType.value:
        return convertEJSList(block)
      case ZCodeType.value:
        return convertEJSCode(block)
      case ZQuoteType.value:
        return convertEJSQuote(block)
      case ZHeader2Type.value:
      case ZHeader3Type.value:
      case ZHeader4Type.value:
      case ZHeader5Type.value:
        return convertEJSHeader(block)
    }
  })
}
