<template>
  <slot :community="community" v-if="community"/>
</template>

<script setup lang="ts">
import { useCommunityService } from "/js/services/useCommunityService"

const { loadCurrentCommunity } = useCommunityService()

const { data: community } = loadCurrentCommunity()
</script>

<script lang="ts">
export default {
  name: "CurrentCommunity",
}
</script>
