<template>
  <asset-card :asset />
</template>

<script setup lang="ts">
import type { ProductAttachment } from "/js/models/Product"
import { computed } from "vue"
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import AssetCard from "/js/components/utilities/FormFields/FileUpload/UI/AssetCard.vue"

const props = defineProps<{
  attachment: ProductAttachment
}>()

const asset = computed((): Asset => {
  return {
    id: props.attachment.id,
    type: "productAttachment",
    data: props.attachment,
    date: props.attachment.created_at,
  }
})
</script>

<script lang="ts">
export default {
  name: "AttachmentCard",
}
</script>
