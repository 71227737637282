<template>
  <div v-if="loading" class="w-full h-full flex items-center justify-center">
    <spinner class="h-24 w-24 text-teal-500"></spinner>
  </div>
  <slot v-else/>
</template>

<script setup lang="ts">
import Spinner from "/js/components/icons/Spinner.vue";

defineProps<{
  loading: boolean
}>()
</script>

<script lang="ts">
export default {
  name: "LoadingView"
}
</script>