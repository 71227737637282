<template>
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M200 100C200 124.447 191.287 146.684 176.723 164.109C158.388 186.086 130.819 200 99.87 200C70.2211 200 43.6931 187.126 25.3576 166.71C9.62289 149.025 0 125.618 0 100C0 44.7334 44.7334 0 100 0C155.267 0 200 44.7334 200 100Z" fill="#EAEEF9"/>
    <path d="M176.853 164.109C158.518 186.086 130.949 200 100 200C70.3513 200 43.8233 187.126 25.4878 166.71C28.6087 164.239 32.5099 162.029 37.3213 160.078L40.9624 158.648C47.8545 155.917 56.8272 152.536 68.9208 147.464C71.6516 146.294 73.8623 144.213 75.0326 141.483C75.6828 140.052 76.073 138.362 76.073 136.671V114.174C75.6828 113.654 75.2927 113.264 75.0326 112.744C73.0821 109.883 71.7817 106.502 71.3916 102.861L69.441 101.951C63.5892 103.381 63.9793 97.1392 62.5489 85.1757C61.8987 80.2342 62.6789 79.1939 64.8896 78.4136L66.7102 75.9429C55.5268 50.5853 61.1185 34.7205 72.1718 29.1288C68.5307 21.0664 68.2706 18.2056 68.2706 18.2056C68.2706 18.2056 91.8077 22.1067 100 20.5463C110.273 18.4656 126.138 20.9364 131.99 34.5905C141.743 38.4917 145.644 44.6035 146.424 51.3655C147.854 62.0287 141.743 73.7322 140.572 78.2836C140.702 78.5437 140.832 78.6737 140.832 78.8037C142.393 79.584 142.913 80.8844 142.393 85.1757C140.962 96.489 141.353 103.511 135.501 101.951L130.299 111.053C130.039 112.094 130.039 112.484 129.909 113.264C129.649 114.955 129.779 118.336 129.779 136.931C129.779 139.142 130.429 141.222 131.6 143.173C132.9 145.124 134.721 146.814 137.061 147.724C137.061 147.724 137.191 147.724 137.321 147.854C150.715 153.446 160.338 157.087 167.36 159.818C171.001 160.988 174.122 162.549 176.853 164.109Z" fill="white"/>
    <path d="M140.832 78.1535C141.353 75.2927 140.052 71.7816 139.012 69.3109C138.882 68.9208 138.622 68.5307 138.492 68.0105C133.94 58.9078 123.927 55.2667 114.305 54.7465C89.7272 53.4461 87.5165 58.1275 79.8442 51.1054C82.1849 54.7465 82.445 60.3382 78.8039 67.3603C76.2031 72.3018 71.2616 74.5125 66.9703 75.9429C55.787 50.5853 61.3787 34.7205 72.432 29.1288C68.7909 21.0664 68.5308 18.2056 68.5308 18.2056C68.5308 18.2056 92.0679 22.1067 100.13 20.5463C110.403 18.4656 126.268 20.9364 132.12 34.5905C141.873 38.4917 145.644 44.6035 146.554 51.3655C147.985 61.8987 142.003 73.4721 140.832 78.1535Z" fill="url(#paint0_linear_640_34048)"/>
    <path d="M131.86 142.913V147.334H75.0327V141.613C75.6829 140.182 76.073 138.492 76.073 136.801V114.174C75.6829 113.654 75.2928 113.264 75.0327 112.744V111.443C76.2031 113.524 77.7635 115.475 79.7141 117.035L97.5295 129.389C101.691 132.9 107.673 133.03 111.964 129.649L128.609 114.694C129.129 114.174 129.779 113.654 130.299 113.134C130.039 114.825 130.169 118.206 130.169 136.801C130.039 139.012 130.689 141.222 131.86 142.913Z" fill="url(#paint1_linear_640_34048)"/>
    <defs>
      <linearGradient id="paint0_linear_640_34048" x1="60.8278" y1="48.1861" x2="146.789" y2="48.1861" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0BACC"/>
        <stop offset="1" stop-color="#969EAE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_640_34048" x1="103.463" y1="147.553" x2="103.463" y2="127.781" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="#E2E5EC"/>
      </linearGradient>
    </defs>
  </svg>
</template>