<template>
  <div class="flex items-center gap-4">
    <DashboardIcon class="h-9 w-9" />
    <div class="flex flex-col gap-0.5">
      <div class="text-sm">{{ option.title }}</div>
      <div class="text-secondary text-xs">
        Post • {{ fullName(option.user) }}
        <template v-if="option.product_id && option.product_title">
          •
          <option-box-product-primary-link
            @clickProduct="emit('clickProduct')"
            :disableInternalLinks
            :productId="option.product_id"
            :productTitle="option.product_title" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fullName } from "/js/models/User"
import DashboardIcon from "/js/components/icons/DashboardIcon.vue"
import { type NavigationResourcePost } from "/js/components/NavigationResources/NavigationResourcesTypes"
import OptionBoxProductPrimaryLink from "/js/components/GlobalSearch/OptionBoxProductPrimaryLink.vue"

defineProps<{
  option: NavigationResourcePost
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()
</script>

<script lang="ts">
export default {
  name: "PostOptionBox",
}
</script>
