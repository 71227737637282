import { ref } from "vue";
type Flash = {
  message: string
  type: "error" | "notice"
}

const flash = ref<Flash|null>(null)
if (window.flash?.error) {
  flash.value = {
    message: window.flash.error,
    type: "error"
  }
  delete window.flash.error
}

if (window.flash?.notice) {
  flash.value = {
    message: window.flash.notice,
    type: "notice"
  }
  delete window.flash.notice
}

export const useFlash = () => {
  const consumedFlash = flash.value;
  flash.value = null;
  return consumedFlash;
}