<template>
  <div class="flex items-center gap-4">
    <calendar-day-box size="sm" :date="option.start_date" />
    <div class="flex flex-col gap-0.5">
      <div class="text-sm">{{ option.name }}</div>
      <div class="text-secondary text-xs">
        Event
        <template v-if="option.product_id && option.product_title">
          •
          <option-box-product-primary-link
            @clickProduct="emit('clickProduct')"
            :disableInternalLinks
            :productId="option.product_id"
            :productTitle="option.product_title" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CalendarDayBox from "/js/components/utilities/UI/CalendarDayBox.vue"
import type { NavigationResourceEvent } from "/js/components/NavigationResources/NavigationResourcesTypes"
import OptionBoxProductPrimaryLink from "/js/components/GlobalSearch/OptionBoxProductPrimaryLink.vue"

defineProps<{
  option: NavigationResourceEvent
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()
</script>

<script lang="ts">
export default {
  name: "EventOptionBox",
}
</script>
