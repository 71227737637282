<template>
  <card-loader :loading>
    <template v-if="canDisplay">
      <chat-room-card title="Channels" :rooms v-if="rooms.length" />
      <empty-view
          class="!pb-0 !pt-12"
          title="No Channels Found"
          subtitle="Check back later for updates."
          v-else>
        <img-discussions-empty class="h-40 w-40" />
      </empty-view>
      <error-message :error v-if="error" />
    </template>
    <template v-else>
      <empty-view title="Community access is required to view this content" />
    </template>
  </card-loader>
</template>

<script setup lang="ts">
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import LearningHubsEmptyIcon from "/js/components/icons/LearningHubsEmptyIcon.vue"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import type { ChatRoomWidget } from "/js/models/Widget"
import { storeToRefs } from "pinia"
import { useChatStoreV2 } from "/js/stores/ChatStoreV2"
import ChatRoomCard from "/js/components/BlockBuilder/Cards/ChatRoomCard.vue"
import { computed } from "vue"
import { useGate } from "/js/composables/useGate"
import ImgDiscussionsEmpty from "/js/components/icons/Images/ImgDiscussionsEmpty.vue";

const props = defineProps<{
  widget: ChatRoomWidget
}>()

const { hasCommunityAbility } = useGate()

const {
  channelRooms,
  roomsIsInitialLoading: loading,
  roomsError: error,
} = storeToRefs(useChatStoreV2())

const canDisplay = computed(() => hasCommunityAbility("community_read"))

const rooms = computed(() => {
  return channelRooms.value.filter((room) => props.widget.chat_room_ids.includes(room.id)) ?? []
})
</script>

<script lang="ts">
export default {
  name: "CommunityChatRoomCardLoader",
}
</script>
