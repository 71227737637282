import {useQuery} from "@tanstack/vue-query"
import {
  PriceTypes,
  RecurringIntervals,
  SubscriptionStatuses, ZBillingLink,
  ZStripePlan,
  ZStripePrice,
  ZStripeSessionCheckout,
  ZStripeSubscription,
} from "/js/services/PlansApi"
import {del, getZod, patch, postZod} from "/js/composables/useAxios"
import {z} from "zod"

export const PlatformPlanStatuses = ["draft", "published", "disabled"] as const

export const ZPlatformPrice = z.object({
  id: z.string(),
  name: z.string(),
  price_type: z.enum(PriceTypes),
  recurring_interval: z.enum(RecurringIntervals).nullable(),
  unit_amount: z.number(),
  currency: z.string(),
  stripe_price_id: z.string().nullable(),
  stripe_plan_id: z.string(),
})

export const ZPlatformPlan = z.object({
  id: z.string(),
  name: z.string(),
  subtitle: z.string().nullable(),
  description: z.string().nullable(),
  status: z.enum(PlatformPlanStatuses),
  prices: ZPlatformPrice.array(),
  max_users: z.number(),
  max_products: z.number(),
  max_admins: z.number(),
})

export const ZPlatformSubscription = z.object({
  id: z.string(),
  status: z.enum(SubscriptionStatuses),
  current_period_end: z.date(),
  current_period_start: z.date(),
  cancel_at_period_end: z.boolean().nullable(),
  prices: ZPlatformPrice.array(),
  stripe_plans: ZPlatformPlan.omit({prices: true, cover_image: true}).array(),
})

export const ZPlatformSubscriptionResponseTrial = z.object({
  mode: z.literal("trial"),
  trial: z.number()
})

export const ZPlatformSubscriptionResponseSubscription = z.object({
  mode: z.literal("subscription"),
  subscription: ZPlatformSubscription
})

export const ZPlatformSubscriptionResponse = z.union([ZPlatformSubscriptionResponseTrial, ZPlatformSubscriptionResponseSubscription])

export const ZPlatformSessionSubscription = z.object({
  type: z.literal("subscription"),
  object: ZPlatformSubscription,
})

export const ZPlatformSession = z.union([ZStripeSessionCheckout, ZPlatformSessionSubscription])

export type PlatformSubscription = z.infer<typeof ZPlatformSubscription>
export type PlatformPrice = z.infer<typeof ZPlatformPrice>
export type PlatformPlan = z.infer<typeof ZPlatformPlan>
export type PlatformSubscriptionResponse = z.infer<typeof ZPlatformSubscriptionResponse>

export const PlatformPlansApi = {
  getPlans: async () => {
    return getZod("/api/admin/platform_plans", ZPlatformPlan.array())
  },

  changeSubscription: async (price_id: string, redirect: string) => {
    return postZod(`/api/admin/subscription`, {price_id, redirect}, ZPlatformSession)
  },

  cancelSubscription: async (): Promise<void> => {
    return await del("/api/admin/subscription")
  },

  reactivateSubscription: async (): Promise<void> => {
    return await patch("/api/admin/subscription/reactivate", {})
  },

  getSubscription: async (): Promise<PlatformSubscriptionResponse | null> => {
    try {
      return await getZod("/api/platform_subscription", ZPlatformSubscriptionResponse)
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 404) {
        return null
      }
      throw e
    }
  },

  getBillingLink: async () => {
    return await getZod("/api/admin/billing", ZBillingLink)
  }
}

export const usePlatformPlansQuery = () => {
  return useQuery({
    queryKey: ["platform_plans"],
    queryFn: async () => await PlatformPlansApi.getPlans(),
  })
}
