<template>
  <FormKit
    type="form"
    form-class="flex flex-col gap-5 flex-1"
    :actions="false"
    #default="{ disabled }"
    @submit="handleSubmit">
    <FormKit
      type="select"
      multiple
      label="Select Users"
      validation="required"
      validation-label="Select at least one user"
      v-model="userIds">
      <template #inner>
        <multi-user-select
          v-model="users"
          :group-query="groupsQueryLoader"
          v-model:search-term="query" />
      </template>
    </FormKit>

    <div class="flex justify-between gap-2">
      <button type="submit" class="btn btn-primary self-start" :disabled="!!disabled">
        {{ channelId ? "Update" : "Create" }} Discussion
      </button>

      <button type="button" class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import type { ChatMessageUser, ChatRoomListItem } from "/js/models/Chat"
import type { FormKitNode } from "@formkit/core"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import { ChatApi } from "/js/services/ChatApi"
import { useQuery } from "@tanstack/vue-query"
import MultiUserSelect from "/js/components/utilities/FormFields/MultiUserSelect.vue"
import { refDebounced } from "@vueuse/core"

const props = defineProps<{
  channelId?: string | undefined
}>()

const query = ref("")
const debouncedQuery = refDebounced(query, 300)

const groupsQueryLoader = () => {
  return useQuery({
    queryKey: computed(() => ["autocomplete_chat_members", debouncedQuery.value]),
    queryFn: async () => await ChatApi.autocompleteMembers(debouncedQuery.value),
    placeholderData: (d) => d
  })
}

const emit = defineEmits<{
  save: [room: ChatRoomListItem]
  cancel: []
}>()

const params = computed(() => {
  return { user_ids: users.value.map((user) => user.id) }
})

const users = ref<ChatMessageUser[]>([])

// fake model for formkit validation
const userIds = computed({
  get() {
    return users.value.map((user) => user.id)
  },
  set(value) {},
})

const handleSubmit = async (formData: any, node?: FormKitNode) => {
  try {
    node?.clearErrors()
    const room = await ChatApi.createDirectMessageRoom(params.value)
    emit("save", room)
  } catch (e) {
    console.log(e)
    node?.setErrors(...getFormKitErrorMessage(e, formData))
  }
}
</script>

<script lang="ts">
export default {
  name: "CreateDirectMessageForm",
}
</script>
