<template>
  <div class="flex flex-col gap-4">
    <markdown-text
      v-if="message.message_type === 'markdown'"
      :text="message.content"
      class="prose prose-sm prose-neutral inline-flex max-w-none flex-col p-2 prose-headings:font-medium prose-h5:text-sm prose-p:my-0.5" />

    <course-outline-assistant-message
      v-else-if="
        message.message_type === 'course_outline' || message.message_type === 'add_course_units'
      "
      :data="dataStringToPartialObject(message.content, message.message_type)" />

    <text-gpt-assistant-message
      v-else-if="message.message_type === 'generate_text'"
      :data="dataStringToPartialObject(message.content, message.message_type)" />

    <editor-js-assistant-message
      v-else-if="message.message_type === 'generate_editor_content'"
      :data="dataStringToPartialObject(message.content, message.message_type)" />

    <quiz-question-assistant-message
      v-else-if="message.message_type === 'generate_quiz_questions'"
      :data="dataStringToPartialObject(message.content, message.message_type)" />

    <generate-image-assistant-message
      v-else-if="message.message_type === 'generate_image'"
      :data="message" />

    <button
      :disabled
      type="button"
      class="btn btn-primary self-start"
      v-if="isActionable"
      @click="handleGenerate">
      {{ generateButtonLabel }}
    </button>

    <div v-if="message.status === 'failed'" class="text-xs text-red-500">
      Failed to generate response
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  dataStringToPartialObject,
  type GptMessage,
  isGptMessageActionableType,
} from "/js/services/GptApi"
import MarkdownText from "/js/components/utilities/MarkdownText.vue"
import CourseOutlineAssistantMessage from "/js/components/GptChat/CourseOutline/CourseOutlineAssistantMessage.vue"
import { computed } from "vue"
import TextGptAssistantMessage from "/js/components/GptChat/TextGpt/TextGptAssistantMessage.vue"
import EditorJsAssistantMessage from "/js/components/GptChat/EditorJs/EditorJsAssistantMessage.vue"
import QuizQuestionAssistantMessage from "/js/components/GptChat/GenerateQuizQuestions/QuizQuestionAssistantMessage.vue"
import GenerateImageAssistantMessage from "/js/components/GptChat/GenerateImage/GenerateImageAssistantMessage.vue"

const props = defineProps<{
  message: GptMessage
  disabled?: boolean
}>()

const emit = defineEmits<{
  generate: [message: GptMessage]
}>()

const isActionable = computed(() => {
  return isGptMessageActionableType(props.message)
})

const generateButtonLabel = computed(() => {
  switch (props.message.message_type) {
    case "course_outline":
      return "Create Course Outline"
    case "add_course_units":
      return "Add Course Units"
    case "generate_text":
      return "Use this response"
    case "generate_editor_content":
      return "Use this content"
    case "generate_quiz_questions":
      return "Use these questions"
    case "generate_image":
      return "Use this image"
    case "markdown":
      return undefined
  }
})

const handleGenerate = async () => {
  emit("generate", props.message)
}
</script>

<script lang="ts">
export default {
  name: "AssistantMessage",
}
</script>
