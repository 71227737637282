declare global {
  interface Array<T> {
    uniq<K extends keyof T>(key: K): T[];
    uniqBy<U>(callback: (item: T) => U): T[];
  }
}

if (!Array.prototype.uniq) {
  Array.prototype.uniq = function <T, K extends keyof T>(this: T[], key: K): T[] {
    return this.filter((item, index, self) => {
      return index === self.findIndex(t => t[key] === item[key]);
    });
  };
}

if (!Array.prototype.uniqBy) {
  Array.prototype.uniqBy = function <T, U>(this: T[], callback: (item: T) => U): T[] {
    return this.filter((item, index, self) => {
      return index === self.findIndex(t => callback(t) === callback(item));
    });
  };
}

export {}