<template>
  <div v-for="event in events" class="flex items-start gap-4">
    <calendar-day-box :date="event.start_date" />

    <event-title-date
      :title="event.name"
      :start-date="event.start_date"
      :end-date="event.end_date"
      :recurring="event.recurring" />
  </div>
</template>

<script setup lang="ts">
import type { EventItem } from "/js/models/EventModels"
import EventTitleDate from "/js/components/utilities/UI/EventTitleDate.vue"
import CalendarDayBox from "/js/components/utilities/UI/CalendarDayBox.vue"

defineProps<{
  events: EventItem[]
}>()
</script>

<script lang="ts">
export default {
  name: "EventsListCard",
}
</script>
