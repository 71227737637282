<template>
  <div
    class="flex flex-col gap-4 rounded-lg border border-gray-200 p-6 shadow-sm"
    :class="{ 'opacity-50': actionable === 'disabled' }">
    <h2 class="text-center text-lg font-medium leading-6 text-gray-900">{{ plan.name }}</h2>

    <div class="flex h-20 flex-col gap-2">
      <template v-if="plan.plan_type === 'free'">
        <div class="text-center">
          <span class="text-4xl font-extrabold text-gray-900">Free</span>
        </div>
      </template>

      <template v-else>
        <div class="text-center">
          <span class="text-4xl font-extrabold text-gray-900">${{ monthlyPrice?.toFixed(2) }}</span>
          <span class="font-medium">/mo</span>
        </div>

        <div
          class="text-secondary text-center text-sm"
          v-if="monthlyPrice && price?.recurring_interval === 'year'">
          ${{ (monthlyPrice * 12).toFixed(2) }}/yr, billed annually
        </div>
      </template>
    </div>

    <hr />

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between opacity-50" v-if="includeFreeSection && freePlan">
        <h3 class="text-xs font-medium uppercase tracking-wide text-gray-900">
          Everything in Free Plan
        </h3>

        <plan-products-hover-card :plan-id="freePlan.id">
          <button
            type="button"
            class="btn btn-secondary btn-xs self-center rounded-full px-4">
            {{ freePlan.product_ids.length }} Learning Hubs
          </button>
        </plan-products-hover-card>
      </div>

      <div class="flex items-center justify-between">
        <h3 class="text-xs font-medium uppercase tracking-wide text-gray-900">
          {{ plan.name }} includes:
        </h3>

        <plan-products-hover-card :plan-id="plan.id" :exclude-ids="excludeIds">
          <button
            :disabled="actionable === 'disabled'"
            type="button"
            v-if="plan.product_ids.length > 0"
            class="btn btn-secondary btn-xs self-center rounded-full px-4">
            {{ productsCount }} Learning Hubs
          </button>
        </plan-products-hover-card>
      </div>

      <p class="mt-4 text-sm text-gray-500 whitespace-pre-line">{{ plan.description }}</p>
    </div>

    <div class="flex-1"></div>

    <div v-if="confirmPlan" class="flex w-full gap-4 bg-amber-50">
      <button @click="emit('cancel')" type="button" class="btn btn-secondary flex-1">Cancel</button>
      <button @click="emit('confirmPrice', price)" type="button" class="btn btn-primary flex-1">
        Confirm
      </button>
    </div>
    <button
      v-else
      @click="emit('price', price)"
      type="button"
      class="btn btn-primary flex items-center gap-4"
      :disabled="actionable !== 'selectable' || !!loadingStatus">
      <template v-if="loadingStatus === 'loading'">
        Please Wait
        <spinner class="h-4 w-4 text-white" />
      </template>
      <template v-else>
        {{ buttonTitle }}
      </template>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { StripePlan, StripePrice } from "/js/services/PlansApi"
import { computed } from "vue"
import PlanProductsHoverCard from "/js/components/Subscriptions/PlanProductsHoverCard.vue"
import Spinner from "/js/components/icons/Spinner.vue"
import type { PlanSelectorActionable } from "/js/components/Subscriptions/PlanSelectorTypes"

const props = defineProps<{
  plan: StripePlan
  price: StripePrice
  freePlan: StripePlan | undefined
  freePrice: StripePrice | undefined
  actionable: PlanSelectorActionable
  confirmPlan: boolean
  loadingStatus: "loading" | "disabled" | undefined
}>()

const emit = defineEmits<{
  price: [value: StripePrice]
  confirmPrice: [value: StripePrice]
  cancel: []
}>()

const includeFreeSection = computed(() => {
  return props.freePlan && props.plan.plan_type !== "free" && props.plan.product_ids.length > 0
})

const productsCount = computed(() => {
  if (props.plan.id === props.freePlan?.id) return props.freePlan.product_ids.length
  const planIds = props.plan.product_ids
  const freePlanIds = props.freePlan?.product_ids ?? []
  return planIds.filter((id) => !freePlanIds.includes(id)).length
})

const excludeIds = computed(() => {
  if (props.plan.id === props.freePlan?.id) return []
  return props.freePlan?.product_ids ?? []
})

const buttonTitle = computed(() => {
  if (props.actionable === "current") return "Current Plan"
  if (props.actionable === "disabled") return "Unavailable"
  if (props.actionable === "canceled") return "Canceled"
  return "Select Plan"
})

const monthlyPrice = computed(() => {
  const price = props.price
  if (!price) return undefined

  let amount = (price?.unit_amount ?? 0) / 100

  if (price.recurring_interval === "year") {
    amount = amount / 12
  }

  return amount
})
</script>

<script lang="ts">
export default {
  name: "PlanSelectorCard",
}
</script>
