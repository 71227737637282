import { ref } from "vue"
import { UsersApi } from "/js/services/UsersApi"
import { nanoid } from "nanoid"

type PageView = {
  id: string
  path: string
  date: Date
}

const pendingPageViews = ref<PageView[]>([])
const intervalId = ref<number | null>(null)

// this is used to communicate props to various components created by the EditorJS Vue component.
export const usePageViewStore =  () => {

  const addPageView = (path: string) => {
    const date = new Date()

    // find a page view with the same path added in the last second
    const lastPageView = pendingPageViews.value.find(
      (pv) => pv.path === path && date.getTime() - pv.date.getTime() < 1000
    )

    if (lastPageView) {
      return
    }

    // console.log("Actually adding page view", path)
    pendingPageViews.value.push({ id: nanoid(), path, date: new Date() })
  }

  const syncPageViews = async () => {
    // console.log("Syncing page views", pendingPageViews.value.length)
    if (pendingPageViews.value.length) {
      const pageViewsBackup = [...pendingPageViews.value]
      try {
        pendingPageViews.value = []
        await UsersApi.logPageView(pageViewsBackup.map((pv) => pv.path))
      } catch (e) {
        // distinct by id
        pendingPageViews.value = [
          ...new Map(pageViewsBackup.map((item) => [item.id, item])).values(),
        ]
        console.error(e)
      }
    }
  }

  const stopPeriodicSync = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  const startPeriodicSync = (timeout?: number) => {
    stopPeriodicSync()
    console.log("Starting periodic sync")

    intervalId.value = setInterval(async () => {
      await syncPageViews()
    }, timeout || 10000)
  }

  return {
    addPageView,
    startPeriodicSync,
    stopPeriodicSync,
  }
}
