import { get, patch, post } from "/js/composables/useAxios"
import type { User } from "/js/models/User"
import type { CommunityRole, ProductRole } from "/js/services/permissionPolicyService"

export type UserParams = {
  email: string
  password: string
  first_name?: string
  last_name?: string
  time_zone?: string
  remember_me?: boolean
  token?: string | null
}

export type DeviseMessage = {
  message: string
}

export type ForgotPasswordMessage = {
  signed_in: boolean
} & DeviseMessage

export type ForgotPasswordParams = {
  password: string
  reset_password_token: string
}

const InvitationActionTypes = [
  "redirect_to_invitable",
  "redirect_to_invitable_registration",
  "sign_in",
  "sign_up",
] as const

export type Invitation = {
  action: (typeof InvitationActionTypes)[number]
  invitee_email: string
}

export const InvitationStatuses = ["pending", "accepted", "rejected"] as const
export type InvitationStatus = (typeof InvitationStatuses)[number]

export type AdminInvitation = {
  id: string
  invitee_email: string
  invitee: User | null
  invitable_name: string | null
  status: InvitationStatus
  invitee_role: CommunityRole | ProductRole
  expiration_date: Date
  created_at: Date
}

export const ProfileApi = {
  create: async (user: UserParams): Promise<void> => {
    return await post(`/users`, { user })
  },

  signIn: async (user: UserParams): Promise<void> => {
    return await post(`/users/sign_in`, { user })
  },

  confirmEmail: async (token: string): Promise<void> => {
    return await post(`/users/confirmation`, { confirmation_token: token })
  },

  forgotPassword: async (email: string): Promise<DeviseMessage> => {
    return await post(`/users/password`, { user: { email } })
  },

  editForgotPassword: async (params: ForgotPasswordParams): Promise<ForgotPasswordMessage> => {
    return await patch(`/users/password`, { user: params })
  },

  resendConfirmation: async (email: string): Promise<DeviseMessage> => {
    return await post(`/users/confirmation`, { user: { email } })
  },

}
