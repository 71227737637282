<template>
  <div class="w-full overflow-x-auto overflow-y-visible scrollbar:h-0 scrollbar:w-0" ref="scroller">
    <table class="custom-responsive w-full" :class="{ scrolled: !arrivedState.left }">
      <slot></slot>
    </table>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useScroll } from "@vueuse/core"

const scroller = ref<HTMLElement | null>(null)

const { arrivedState } = useScroll(scroller)

</script>

<script lang="ts">
export default {
  name: "ResponsiveTable",
}
</script>
