<template>
  <div class="relative h-full w-full">
    <div v-if="isImage" class="absolute inset-0 opacity-50 blur-sm bg-no-repeat bg-cover" :style="backgroundImageStyle"></div>
    <img
      v-if="isImage"
      :src="assetThumbUrl(asset, true)"
      class="relative h-full w-full rounded-lg object-contain"
      alt="image" />

    <div
      v-else
      class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-gray-50 p-4">
      <PaperClipIcon class="h-8 w-8 text-gray-400" />
      <div class="text-secondary line-clamp-1 text-sm">{{ name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { assetThumbUrl } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { PaperClipIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"

const props = defineProps<{
  asset: Asset
}>()

const isImage = computed(() => {
  if (props.asset.type === "productAttachment") {
    return props.asset.data.attachment_type === "image"
  } else if (props.asset.type === "fileUploader") {
    return !!props.asset.data.thumbUrl
  }

  return false
})

const backgroundImageStyle = computed(() => {
  if (!isImage.value) return {}
  return {
    backgroundImage: `url(${assetThumbUrl(props.asset)})`
  }
})

const name = computed(() => {
  if (props.asset.type === "productAttachment") {
    return props.asset.data.file_name
  } else if (props.asset.type === "fileUploader") {
    return props.asset.data.file?.name
  }

  return undefined
})
</script>

<script lang="ts">
export default {
  name: "MediaGalleryGridThumb",
}
</script>
