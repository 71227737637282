<template>
  <svg width="226" height="221" viewBox="0 0 226 221" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110.987 214.239C160.98 214.239 201.51 173.709 201.51 123.538C201.51 73.3659 160.98 32.8357 110.987 32.8357C60.9938 32.8357 20.4637 73.3659 20.4637 123.538C20.4637 173.709 60.9938 214.239 110.987 214.239Z" fill="#EAEEF9"/>
    <path d="M44.3889 64.7955H173.121C178.299 64.7955 182.227 68.7236 182.227 73.9014V155.497C182.227 160.675 178.299 164.603 173.121 164.603H44.3889C39.2111 164.603 35.2831 160.675 35.2831 155.497V73.9014C35.2831 68.7236 39.5682 64.7955 44.3889 64.7955Z" fill="url(#paint0_linear_640_34613)"/>
    <path d="M184.727 117.11V157.283C184.727 162.461 180.62 166.746 175.264 166.746H42.9606C37.7827 166.746 33.4976 162.461 33.4976 157.283V113.717" stroke="#1676EE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M173.478 94.6129H131.698C127.592 94.6129 123.664 96.0413 120.629 98.541L109.737 107.468C106.702 109.968 102.774 111.396 98.6673 111.396H51.5309C46.7101 111.396 42.7821 115.324 42.7821 120.145C42.7821 120.502 42.7821 121.038 42.9606 121.395L51.7094 167.46C52.4236 171.745 55.9945 174.959 60.4582 174.959H161.337C165.622 174.959 169.372 171.924 170.086 167.639L182.227 104.612C183.12 99.7908 179.906 95.5057 175.085 94.6129C174.371 94.6129 173.836 94.6129 173.478 94.6129Z" fill="white"/>
    <g filter="url(#filter0_d_640_34613)">
      <path d="M192.226 82.2932H137.948C132.591 82.2932 127.592 84.0786 123.485 87.471L109.202 99.0766C105.274 102.29 100.096 104.254 94.7393 104.254H33.3191C27.07 104.254 22.0706 109.432 22.0706 115.503C22.0706 116.039 22.0706 116.574 22.2492 117.11L33.4976 177.28C34.3904 182.994 39.2112 187.1 44.7461 187.1H176.157C181.87 187.1 186.512 183.172 187.405 177.459L203.296 95.1486C204.367 88.8994 200.261 83.3645 194.011 82.1146C193.476 82.2932 192.762 82.2932 192.226 82.2932Z" fill="url(#paint1_linear_640_34613)"/>
    </g>
    <path d="M87.2402 144.606C90.4541 144.606 93.1323 141.928 93.1323 138.714C93.1323 135.5 90.4541 132.822 87.2402 132.822C84.0264 132.822 81.3482 135.5 81.3482 138.714C81.3482 141.928 84.0264 144.606 87.2402 144.606Z" fill="#989FB0"/>
    <path d="M133.127 144.428C136.341 144.428 139.019 141.749 139.019 138.535C139.019 135.322 136.341 132.643 133.127 132.643C129.913 132.643 127.235 135.322 127.235 138.535C127.235 141.928 129.913 144.428 133.127 144.428Z" fill="#989FB0"/>
    <path d="M200.975 182.994C200.261 186.029 199.189 189.243 197.939 191.921C194.547 198.527 189.191 203.705 182.584 207.098C175.8 210.49 167.765 211.918 159.73 210.133C140.804 206.205 128.663 187.636 132.591 168.71C136.519 149.784 154.91 137.464 173.836 141.571C180.62 142.999 186.512 146.392 191.512 151.034C199.903 159.425 203.474 171.567 200.975 182.994Z" fill="url(#paint2_linear_640_34613)"/>
    <path d="M177.942 172.816H169.907V164.782C169.907 163.175 168.658 161.747 166.872 161.747C165.265 161.747 163.837 162.996 163.837 164.782V172.816H155.802C154.195 172.816 152.767 174.066 152.767 175.852C152.767 177.637 154.017 178.887 155.802 178.887H163.837V186.922C163.837 188.529 165.087 189.957 166.872 189.957C168.479 189.957 169.907 188.707 169.907 186.922V178.887H177.942C179.549 178.887 180.977 177.637 180.977 175.852C180.977 174.066 179.549 172.816 177.942 172.816Z" fill="white"/>
    <path d="M102.952 102.29C96.5247 89.7922 95.0963 74.7942 99.3814 61.2247C103.488 47.6551 113.308 36.0495 125.449 29.4433C129.199 27.4793 133.484 25.8724 137.769 25.6938C142.054 25.5153 146.696 26.9436 149.553 30.5146C152.41 33.7284 152.946 39.0848 150.624 42.6558C148.125 46.0482 143.125 47.4765 139.019 46.4053C132.413 45.1554 127.056 39.9776 125.449 33.7284C123.842 27.4793 125.985 20.1589 130.984 16.0523C134.198 13.1955 138.662 11.5886 142.768 10.3388C162.766 4.44672 184.548 3.73253 204.903 8.19621" stroke="#C9D4E2" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="4 4"/>
    <path d="M115.808 150.855H104.738V153.533H115.808V150.855Z" fill="#989FB0"/>
    <path d="M215.615 3.5539C215.437 6.23211 215.258 8.73176 213.294 9.26741C211.33 9.80305 210.438 8.01758 209.188 5.51792C207.938 3.19681 208.652 0.69715 210.795 0.161509C212.759 -0.374132 215.973 0.340056 215.615 3.5539Z" fill="#DAE2EB"/>
    <path d="M213.473 16.4093C214.009 13.1955 214.544 11.41 212.759 10.5173C210.795 9.62453 209.545 11.2315 207.402 13.374C205.617 15.338 206.688 18.1948 208.474 19.0875C210.616 20.1588 212.937 19.0875 213.473 16.4093Z" fill="#DAE2EB"/>
    <path d="M215.973 10.5173C215.794 11.7671 214.901 12.6599 213.652 12.8384C213.116 12.8384 212.58 12.8384 211.866 12.8384C209.366 12.4813 207.402 10.8744 207.581 9.26746C207.759 7.66054 210.081 6.7678 212.937 7.1249C213.473 7.1249 214.009 7.30344 214.366 7.48199C215.437 7.83908 216.151 9.08891 215.973 10.5173C215.973 10.5173 215.973 10.3387 215.973 10.5173Z" fill="#989FB0"/>
    <path d="M16.7142 60.6889C16.7142 57.6536 16.7142 54.6183 18.8568 53.7256C21.1779 52.8329 22.4277 54.9754 24.2132 58.0107C25.8201 60.6889 25.1059 63.5457 22.7848 64.4384C20.8208 65.3312 16.7142 64.7955 16.7142 60.6889Z" fill="#DAE2EB"/>
    <path d="M17.964 45.691C17.6069 49.4405 17.0713 51.583 19.3924 52.4758C21.7135 53.3685 22.9633 51.4045 25.2844 48.5478C27.0699 46.0481 25.8201 42.8342 23.499 41.9415C21.1779 41.0488 18.3211 42.6557 17.964 45.691Z" fill="#DAE2EB"/>
    <path d="M16 52.833C16 51.4046 17.0713 50.3333 18.3211 50.1548C18.8568 49.9762 19.5709 49.9762 20.2851 50.1548C23.1419 50.3333 25.6415 51.9402 25.463 53.7257C25.2844 55.5112 22.9633 56.761 19.928 56.4039C19.3924 56.4039 18.8568 56.2254 18.3211 56.0468C16.8927 55.8683 16 54.4399 16 52.833Z" fill="#989FB0"/>
    <path d="M28.1412 53.3686C46.8886 53.3686 81.5267 64.26 82.0624 104.254" stroke="#C9D4E2" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="4 4"/>
    <defs>
      <filter id="filter0_d_640_34613" x="0.0706406" y="71.1146" width="225.395" height="148.986" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_640_34613"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_640_34613" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_640_34613" x1="108.767" y1="68.2627" x2="108.767" y2="124.284" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0BACC"/>
        <stop offset="1" stop-color="#969EAE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_640_34613" x1="112.709" y1="79.6862" x2="112.709" y2="188.232" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_640_34613" x1="131.821" y1="175.851" x2="201.771" y2="175.851" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0BACC"/>
        <stop offset="1" stop-color="#969EAE"/>
      </linearGradient>
    </defs>
  </svg>
</template>