import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { TourApi } from "/js/services/TourApi"

export const tourKey = ["tour"]

export const useTourService = () => {
  const queryClient = useQueryClient()

  const loadTourStepIds = () =>
    useQuery({
      queryKey: tourKey,
      queryFn: async () => await TourApi.show(),
    })

  const completeStep = useMutation({
    mutationFn: async (tourStepId: string | string[]) => {
      return await TourApi.update(tourStepId)
    },
    onSuccess: (data, variables) => {
      if (data) {
        queryClient.setQueryData(tourKey, data)
      }
    },
  })

  const resetTour = useMutation({
    mutationFn: async () => {
      return await TourApi.reset()
    },
    onSuccess: (data, variables) => {
      if (data) {
        queryClient.setQueryData(tourKey, data)
      }
    },
  })

  return {
    loadTourStepIds,
    completeStep,
    resetTour,
  }
}
