import { computed, ref } from "vue"

export const useSimpleQuery = <T>(query: () => Promise<T>) => {
  const isLoading = ref(false)
  const error = ref()
  const data = ref<T>()
  const loadedOnce = ref(false)
  let promise: Promise<T> | undefined = undefined

  const isSuccess = computed(() => loadedOnce.value && !error.value)

  const fetchData = async () => {
    if (isLoading.value) {
      // If already loading, wait for the current promise to resolve/reject
      await promise
      return data.value  // Return the data after waiting for the current loading process
    }

    isLoading.value = true
    error.value = undefined

    promise = query()

    try {
      data.value = await promise
      error.value = undefined
      loadedOnce.value = true
    } catch (e) {
      console.log("error promise", e, promise)
      error.value = e
    } finally {
      isLoading.value = false
      promise = undefined
    }

    return data.value
  }

  return { isLoading, error, data, fetchData, isSuccess }
}