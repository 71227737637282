<template>
  <div v-html="markedText"></div>
</template>

<script setup lang="ts">
import { marked } from "marked"
import { computed } from "vue"

const props = withDefaults(defineProps<{
  text: string
  breaks?: boolean
}>(), {
  breaks: false,
})

const markedText = computed(() => {
  return marked.parse(props.text, { breaks: props.breaks })
})

</script>

<script lang="ts">
export default {
  name: "MarkdownText",
}
</script>