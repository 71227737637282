<template>
  <div class="aspect-square rounded-lg bg-gray-800 flex w-full shrink-0">
    <img
      :src="community.logo_thumb_url || community.logo_url"
      v-if='community.logo_thumb_url || community.logo_url'
      class="w-full h-full rounded-lg object-cover"
      alt="community logo" />
    <div class="text-2xl font-medium text-gray-200 flex w-full h-full items-center justify-center" v-else>
      {{ communityInitial }}
    </div>
  </div>
</template>

<script setup lang="ts">

import type { Community } from "/js/models/Community"
import { computed } from 'vue'

const props = defineProps<{
  community: Community
}>()

const communityInitial = computed(() => {
  return props.community.name[0]?.toUpperCase()
})

</script>

<script lang="ts">
export default {
  name: "CommunityLogo",
}
</script>
