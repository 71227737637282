<template>
  <div
    class="drop-zone flex flex-col items-center justify-center gap-3"
    :class="{ 'border-red-500': errorMessage }">
    <arrow-down-tray-icon class="h-8 w-8" v-if="over" />
    <photo-icon class="h-8 w-8" v-else />

    <div v-if="over">Drop files here</div>
    <div v-else-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>Drag files here or click to upload</div>

    <div v-if="extensions && !errorMessage">
      Supported file types: <span class="font-medium">{{ extensions }}</span>
    </div>

    <slot/>
  </div>
</template>

<script setup lang="ts">
import { PhotoIcon } from "@heroicons/vue/20/solid"
import { ArrowDownTrayIcon } from "@heroicons/vue/24/solid"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { mediaSupportedExtensions } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { computed } from "vue"

const props = defineProps<{
  over: boolean
  errorMessage?: string
  accept?: SupportedMedia | SupportedMedia[]
}>()

const extensions = computed(() => {
  if (!props.accept) return undefined
  return mediaSupportedExtensions(props.accept).join(", ")
})
</script>

<script lang="ts">
export default {
  name: "DropzoneView",
}
</script>
