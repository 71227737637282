<template>
  <user-reactions-list v-if="goal.visibility === 'public_goal'"
                       :reactions="goal.reactions || undefined"
                       reactionableType="GoalObjective"
                       :reactionableId="goal.id"
                       @toggleReaction="handleReaction" />
</template>

<script setup lang="ts">

import UserReactionsList from "/js/components/UserReactions/UserReactionsList.vue"
import type { EmojiMartData } from "/js/models/Chat"
import { type GoalObjective, GoalsApi } from "/js/services/GoalsApi"
import { toastError } from "/js/composables/toast"
import { useQueryClient } from "@tanstack/vue-query"

const queryClient = useQueryClient()

const props = defineProps<{
  goal: GoalObjective
}>()

const handleReaction = async (reaction: EmojiMartData) => {
  try {
    await GoalsApi.toggleGoalObjectiveReaction(props.goal.id, reaction.colons, reaction.native)
    await queryClient.invalidateQueries({ queryKey: ["goalObjective", props.goal.id] })
  } catch (error) {
    toastError(error)
  }
}
</script>

<script lang="ts">
export default {
  name: "GoalObjectiveUserReactions",
}
</script>