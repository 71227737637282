<template>
  <div class="text-secondary text-sm">
    Create a dedicated conversation channel for members to engage and discuss topics
  </div>

  <FormKit
    type="form"
    form-class="flex flex-col gap-5 flex-1"
    :actions="false"
    #default="{ disabled }"
    @submit="handleSubmit">
    <FormKit
      type="text"
      v-model="params.name"
      label="Channel Name"
      placeholder="Write a channel name"
      validation-visibility="dirty"
      validation="required"
      help="Eg. A community channel name could be: community-general, all-introduction, etc.">
      <template #prefixIcon>
        <div class="text-secondary pl-4">#</div>
      </template>
    </FormKit>

    <FormKit type="textarea" v-model="params.description" label="Description" />

    <FormKit name="access" v-model="params.room_access" validation="required" label="Access">
      <template #inner>
        <div class="grid md:grid-cols-2 gap-4">
          <radio-custom-select
            v-model="params.room_access"
            :value="option.value"
            #default="{ selected, select }"
            v-for="option in accessOptions">
            <selectable-button
              :icon="option.icon"
              :disabled="!!disabled"
              @select="select"
              :description="option.description"
              :text="option.text"
              :selected="selected" />
          </radio-custom-select>
        </div>
      </template>
    </FormKit>

    <FormKit
      v-if="params.room_access === 'private_access'"
      type="select"
      multiple
      label="Select Groups to add to this discussion"
      name="user_group_ids"
      validation="required"
      validation-label="Selecting at least one group"
      v-model="params.user_group_ids">
      <template #inner>
        <multi-user-group-select :group-query="groupsQueryLoader" v-model="params.user_group_ids" />
      </template>
    </FormKit>

    <div class="flex justify-between gap-2">
      <button type="submit" class="btn btn-primary self-start" :disabled="!!disabled">
        {{ channelId ? "Update" : "Create" }} Discussion
      </button>

      <button type="button" class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue"
import type { ChatRoomAccessType, ChatRoomListItem } from "/js/models/Chat"
import type { FormKitNode } from "@formkit/core"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import { ChatApi } from "/js/services/ChatApi"
import RadioCustomSelect from "/js/components/utilities/FormFields/RadioCustomSelect.vue"
import SelectableButton from "/js/components/utilities/FormFields/SelectableButton.vue"
import { LockClosedIcon } from "@heroicons/vue/24/outline"
import GlobalIcon from "/js/components/icons/GlobalIcon.vue"
import { useQuery } from "@tanstack/vue-query"
import MultiUserGroupSelect from "/js/components/utilities/FormFields/MultiUserGroupSelect.vue"
import type { EventType } from "/js/services/useEventService"

type AccessOption = {
  text: string
  value: ChatRoomAccessType
  description: string
  icon: any
}

const props = defineProps<{
  channelId?: string | undefined
  modelType: EventType
}>()

const groupsQueryLoader = () => {
  return useQuery({
    queryKey: computed(() => {
      if (props.modelType.type === "community") {
        return ["channel_user_groups"]
      } else {
        return ["product_channel_user_groups", props.modelType.productId]
      }
    }),
    queryFn: () => {
      if (props.modelType.type === "community") {
        return ChatApi.getChannelAvailableUserGroups()
      } else {
        return ChatApi.getProductChannelAvailableUserGroups(props.modelType.productId)
      }
    },
  })
}

const { data: room } = useQuery({
  queryKey: computed(() => ["chat_room", props.channelId ?? ""]),
  queryFn: () => ChatApi.getChatRoom(props.channelId ?? ""),
  enabled: !!props.channelId,
})
//TODO: when room loads populate params

type Params = {
  name: string
  description: string
  room_access: ChatRoomAccessType
  user_group_ids: string[]
}

const emit = defineEmits<{
  save: [room: ChatRoomListItem]
  cancel: []
}>()

const params = reactive<Params>({
  name: "",
  description: "",
  room_access: "members_access",
  user_group_ids: [],
})

const handleSubmit = async (formData: any, node?: FormKitNode) => {
  try {
    node?.clearErrors()
    if (props.modelType.type === "community") {
      const room = await ChatApi.createChannel(params)
      emit("save", room)
    } else if (props.modelType.type === "product") {
      const room = await ChatApi.createProductChannel(props.modelType.productId, params)
      emit("save", room)
    }
  } catch (e) {
    console.log(e)
    node?.setErrors(...getFormKitErrorMessage(e, formData))
  }
}

const accessOptions = computed((): AccessOption[] => {
  return [
    {
      text: "All Members",
      value: "members_access",
      description: `All members in the ${
        props.modelType.type === "product" ? "Learning Hub" : "Community"
      } can access this channel`,
      icon: GlobalIcon,
    },
    {
      text: "Private",
      value: "private_access",
      description: "Only members of a specific groups/subgroups can see and attend this event",
      icon: LockClosedIcon,
    },
  ]
})

watch(
  () => props.modelType,
  (value) => {
    if (!value) {
      // reset form
      params.name = ""
      params.room_access = "members_access"
      params.user_group_ids = []
    }
  }
)
</script>

<script lang="ts">
export default {
  name: "CreateChannelForm",
}
</script>
