<template>
  <div>
    <div class="flex flex-col gap-2">
      <div class="font-medium">Goal Description</div>
      <div class="text-secondary max-w-2xl text-sm">
        {{ goal.description }}
      </div>
    </div>

    <div class="flex flex-col gap-2" v-if="goal.due_date">
      <div class="font-medium">Target Completion Date</div>
      <div class="text-secondary text-sm">
        {{ formatDate(goal.due_date, "MMMM DD, YYYY") }}
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium">Why This Goal is Achievable</div>
      <div class="text-secondary max-w-2xl text-sm">
        {{ goal.achievability_description }}
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium">How Success Will Be Measured</div>
      <div class="text-secondary max-w-2xl text-sm">
        {{ goal.measurability_description }}
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium">Why This Goal Matters</div>
      <div class="text-secondary max-w-2xl text-sm">
        {{ goal.relevance_description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { formatDate } from "@vueuse/core"
import type { GoalObjective } from "/js/services/GoalsApi"

const props = defineProps<{
  goal: GoalObjective
}>()

</script>

<script lang="ts">
export default {
  name: "GoalDetailsItems",
}
</script>