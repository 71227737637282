<template>
  <div class="padding flex h-screen w-screen flex-col items-center justify-center">
    <template v-if="status === 'loading'">
      <spinner class="text-accent h-24 w-24" />
      <div>Please wait, we're confirming your payment...</div>
    </template>
    <template v-else-if="status === 'success'">Redirecting...</template>
    <template v-else-if="status === 'error'">
      <div class="text-red-600">
        There was an error processing your payment. Please contact support.
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import Spinner from "/js/components/icons/Spinner.vue"
import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useQuery } from "@tanstack/vue-query"
import { PlansApi } from "/js/services/PlansApi"

const userChannel = useUserChannel()

type Status = "loading" | "success" | "error"

const status = ref<Status>("loading")

const route = useRoute()
const router = useRouter()

const queryCheckoutSessionId = computed(() => {
  return route.query.checkout_session_id
})

const queryCheckoutRedirect = computed(() => {
  return route.query.redirect
})

// pull the session status once
const { data: checkoutSessionStatus } = useQuery({
  queryKey: computed(() => ["checkoutSession", queryCheckoutSessionId.value]),
  queryFn: async () => {
    if (!queryCheckoutSessionId.value) return null
    return await PlansApi.getCheckoutSessionStatus(queryCheckoutSessionId.value as string)
  },
  enabled: computed(() => !!queryCheckoutSessionId.value),
})

// wait for socket message
const subscribeToNewMessageCallback = (socketMessage: SocketMessage) => {
  if (socketMessage.type === "checkout_session_status") {
    const { status: socketStatus, id } = socketMessage.object
    if (socketStatus === "paid" && id === queryCheckoutSessionId.value) {
      status.value = "success"
    } else {
      status.value = "error"
    }
  }
}

watch(status, (value) => {
  if (value === "success") {
    if (queryCheckoutRedirect.value && typeof queryCheckoutRedirect.value === "string") {
      router.replace(queryCheckoutRedirect.value)
    } else {
      router.replace("/")
    }
  }
})

watch(checkoutSessionStatus, (value) => {
  if (!value) return
  if (value.status === "paid") {
    status.value = "success"
  }
  // not handling error / nil status here
})

onMounted(async () => {
  userChannel.subscribeToNewMessage(subscribeToNewMessageCallback)
})

onUnmounted(() => {
  userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback)
})
</script>

<script lang="ts">
export default {
  name: "StripeCheckoutsWaitApp",
}
</script>
