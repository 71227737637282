<template>
  <div class="group flex cursor-pointer items-start gap-2" @click.prevent.stop="checked = !checked">
    <input type="checkbox" class="peer hidden" v-model="checked" />

    <checked-icon
      class="hidden h-5 w-7 group-hover:scale-105 group-focus:transition group-active:scale-100 peer-checked:flex" />
    <unchecked-icon
      class="flex h-5 w-7 group-hover:scale-105 group-focus:transition group-active:scale-100 peer-checked:hidden" />

    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import UncheckedIcon from "/js/components/icons/UncheckedIcon.vue"
import CheckedIcon from "/js/components/icons/CheckedIcon.vue"

const checked = defineModel<boolean>()
</script>

<script lang="ts">
export default {
  name: "CheckBoxInner",
}
</script>
