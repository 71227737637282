import { UploadsApi } from "/js/components/utilities/FormFields/FileUpload/UploadsApi"
import {
  attachmentImageUrl, isImageRepresentable,
  type ProductAttachment,
  type ProductAttachmentParams,
} from "/js/models/Product"
import { computed, reactive } from "vue"
import {
  type FileUploader,
  makeFileUploader,
  useFileUploader,
} from "/js/components/utilities/FormFields/FileUpload/fileUploader"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { supportedMediaForFile } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"

export type EntityScope =
  | {
      type: "community"
    }
  | {
      type: "product"
      id: string
    }

export type MediaGalleryScope = (
  | EntityScope
  | {
      type: "user"
      id: string // this is not the user id, it's a unique identifier for the user scope
    }
) & {
  accept?: SupportedMedia | SupportedMedia[]
}

const acceptKey = (accept: SupportedMedia | SupportedMedia[] | undefined) => {
  if (!accept) return undefined
  if (Array.isArray(accept)) {
    return accept.join("_")
  } else {
    return accept
  }
}

export const galleryScopeKey = (scope: MediaGalleryScope) => {
  if (scope.type === "community") {
    return ["community", acceptKey(scope.accept)].filter((k) => k).join("_")
  } else if (scope.type === "product") {
    return ["product", scope.id, acceptKey(scope.accept)].filter((k) => k).join("_")
  } else if (scope.type === "user") {
    return `user_${scope.id}`
  } else {
    throw new Error("[Internal Error] invalid scope")
  }
}

type BaseAsset = {
  id: string
  date: Date
}

export type Asset =
  | (BaseAsset & {
      type: "productAttachment"
      data: ProductAttachment
    })
  | (BaseAsset & {
      type: "fileUploader"
      data: FileUploader
    })

type KeyedArray<T> = {
  [key: string]: T[]
}

const useKeyedScopedArray = <T>(key: string, reactiveObj: KeyedArray<T>) => {
  const scopedArray = computed({
    get: (): T[] => {
      return reactiveObj[key] || []
    },
    set: (value) => {
      reactiveObj[key] = value
    },
  })

  return {
    scopedArray,
  }
}

const groupedUploaders = reactive<KeyedArray<FileUploader>>({})
const groupedProductAttachments = reactive<KeyedArray<ProductAttachment>>({})
const tempThumbUrls = reactive<{
  [key: string]: string
}>({})

const useScopedUploaders = (scope: MediaGalleryScope) =>
  useKeyedScopedArray<FileUploader>(galleryScopeKey(scope), groupedUploaders)

const useScopedProductAttachments = (scope: MediaGalleryScope) =>
  useKeyedScopedArray<ProductAttachment>(galleryScopeKey(scope), groupedProductAttachments)

export const assetThumbUrl = (asset: Asset, preferThumb?: boolean): string | undefined => {
  if (asset.type === "productAttachment") {
    if (tempThumbUrls[asset.id]) {
      return tempThumbUrls[asset.id]
    } else {
      return attachmentImageUrl(asset.data, { preferThumb })
    }
  } else if (asset.type === "fileUploader") {
    return asset.data.thumbUrl
  }

  return undefined
}

export const assetDataUrl = (asset: Asset): string | undefined => {
  if (asset.type === "productAttachment") {
    return asset.data.data_url || undefined
  } else if (asset.type === "fileUploader") {
    return asset.data.thumbUrl
  }
  return undefined
}

export const assetImageRepresentable = (asset: Asset): boolean => {
  if (asset.type === "productAttachment") {
    return isImageRepresentable(asset.data)
  } else if (asset.type === "fileUploader") {
    return !!asset.data.thumbUrl
  }
  return false
}

export const isAssetUploading = (asset: Asset): boolean => {
  return asset.type === "fileUploader" && asset.data.status === "uploading"
}

export const assetPercentUploaded = (asset: Asset): number | undefined => {
  return asset.type === "fileUploader" ? asset.data.percentUploaded : undefined
}

export const assetError = (asset: Asset) => {
  return asset.type === "fileUploader" && asset.data.status === "failed"
}

export const assetSupportedMedia = (asset: Asset): SupportedMedia => {
  if (asset.type === "productAttachment") {
    return asset.data.attachment_type
  } else if (asset.type === "fileUploader") {
    if (asset.data.file) {
      return supportedMediaForFile(asset.data.file)
    }
  }
  return "other"
}



export const assetFileName = (asset: Asset): string | undefined => {
  if (asset.type === "productAttachment") {
    return asset.data.file_name || undefined
  } else if (asset.type === "fileUploader") {
    return asset.data.file?.name
  }
  return undefined
}

export const makeAssets = (scope: MediaGalleryScope) => {
  const productAttachments = groupedProductAttachments[galleryScopeKey(scope)] || []
  const uploaders = groupedUploaders[galleryScopeKey(scope)] || []
  const assets: Asset[] = []

  for (const productAttachment of productAttachments) {
    assets.push({
      id: productAttachment.id,
      date: new Date(productAttachment.created_at),
      type: "productAttachment",
      data: productAttachment,
    })
  }

  for (const uploader of uploaders) {
    assets.push({
      id: uploader.id,
      date: uploader.createdAt,
      type: "fileUploader",
      data: uploader,
    })
  }

  return assets.sort((a, b) => b.date.getTime() - a.date.getTime())
}

export const useMediaGallery = (scope: MediaGalleryScope) => {
  const { scopedArray: uploaders } = useScopedUploaders(scope)
  const { scopedArray: productAttachments } = useScopedProductAttachments(scope)

  const uploadPromise = async (blobId: string) => {
    const params: ProductAttachmentParams = {
      data: blobId,
    }

    if (scope.type === "community") {
      return await UploadsApi.createCommunityAttachment(params)
    } else if (scope.type === "product") {
      return await UploadsApi.createProductAttachment(scope.id, params)
    } else if (scope.type === "user") {
      return await UploadsApi.createUserAttachment(params)
    } else {
      throw new Error("[Internal Error] invalid scope")
    }
  }

  const isUploading = computed(() => {
    return uploaders.value.length > 0
  })

  const makePostUpload = async (blobId: string, fileUploader: FileUploader) => {
    let productAttachment = await uploadPromise(blobId)

    // keep the same created at so it doesn't jump around
    productAttachment.created_at = fileUploader.createdAt

    // store the local thumb as a placeholder for the current session of this component.
    // changing URLs will cause the image to flicker
    if (fileUploader.thumbUrl) {
      tempThumbUrls[productAttachment.id] = fileUploader.thumbUrl
    }

    productAttachments.value = [productAttachment, ...productAttachments.value]
    return productAttachment
  }

  const uploadFiles = async (files: File[], publicBlob: boolean) => {
    return await Promise.allSettled(
      files.filter((f) => f).map((f) => runUpload(makeFileUploader(), f, publicBlob))
    )
  }

  const runUpload = async (fileUploader: FileUploader, file: File, publicBlob: boolean) => {
    const { uploadFile } = useFileUploader(fileUploader)

    const existingIndex = uploaders.value.findIndex((u) => u.id === fileUploader.id)

    if (existingIndex > -1) {
      let uploadersValue = uploaders.value
      uploadersValue[existingIndex] = fileUploader
      uploaders.value = uploadersValue
    } else {
      uploaders.value = [fileUploader, ...uploaders.value]
    }

    const postUpload = async (blobId: string) => {
      return await makePostUpload(blobId, fileUploader)
    }

    try {
      const result = await uploadFile(file, publicBlob, postUpload)
      uploaders.value = uploaders.value.filter((u) => u.id !== fileUploader.id)
      return result
    } catch (e) {
      uploaders.value = [...uploaders.value] // force reactivity. the file uploader changes internally but won't trigger array changes
      throw e
    }
  }

  const retryUpload = async (fileUploader: FileUploader, publicBlob: boolean) => {
    if (!fileUploader.file) return
    await runUpload(fileUploader, fileUploader.file, publicBlob)
  }

  const clearAssetThumbs = () => {
    productAttachments.value.forEach((productAttachment) => {
      const tempUrl = tempThumbUrls[productAttachment.id]
      if (tempUrl) {
        URL.revokeObjectURL(tempUrl)
        delete tempThumbUrls[productAttachment.id]
      }
    })
  }

  const assets = computed(() => {
    return makeAssets(scope)
  })

  const fetchAttachments = async (): Promise<ProductAttachment[]> => {
    if (scope.type === "product") {
      return await UploadsApi.getProductAttachments(scope.id, scope.accept)
    } else if (scope.type === "community") {
      return await UploadsApi.getCommunityAttachments(scope.accept)
    } else {
      return []
    }
  }

  return {
    assets,
    uploadFiles,
    clearAssetThumbs,
    productAttachments,
    uploaders,
    fetchAttachments,
    retryUpload,
    isUploading,
  }
}
