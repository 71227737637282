<template>
  <div v-if="raw">
    <loading-view :loading="!!loading">
      <slot></slot>
    </loading-view>
  </div>

  <div class="card flex w-full flex-col gap-4 overflow-auto p-8" v-else>
    <slot name="title">
      <h4 class="font-medium" v-if="title">{{ title }}</h4>
    </slot>

    <loading-view :loading="!!loading">
      <slot></slot>
    </loading-view>
  </div>
</template>

<script setup lang="ts">
import LoadingView from "/js/components/LoadingView.vue"

type Props = {
  title?: string
  loading?: boolean
  raw?: boolean
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: "CardLoader",
}
</script>
