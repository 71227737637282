<template>
  <div class="flex items-center justify-between gap-4 p-4">
    <router-link :to="{ name: 'profile' }" class="btn-zoom" v-tooltip="'Profile'">
      <round-avatar
        size="sm"
        :id="currentUser?.id"
        :verified="currentUser?.verified"
        :avatar-url="currentUser?.avatar_url"
        :text="initials(currentUser)" />
    </router-link>

    <router-link :to="{ name: 'assistant' }" class="btn-zoom text-primary" v-tooltip="'Zixie Chat'">
      <WizardIcon class="h-6 w-6" />
    </router-link>

    <router-link :to="{ name: 'notifications' }" class="btn-zoom" v-tooltip="'Notifications'">
      <bell-icon class="h-6 w-6" v-if="!hasNotifications"/>
      <unread-dot-bell-icon class="h-6 w-6" v-else />
    </router-link>

    <button
      type="button"
      class="btn-zoom text-primary"
      v-tooltip="'Support'"
      @click="featureFlags.sidebarV2 = !featureFlags.sidebarV2">
      <help-icon class="h-6 w-6" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { initials } from "/js/models/User"
import WizardIcon from "/js/components/icons/WizardIcon.vue"
import HelpIcon from "/js/components/icons/HelpIcon.vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import BellIcon from "/js/components/icons/BellIcon.vue"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { featureFlags } from "/js/composables/useFeatureFlags"
import { computed } from "vue"
import { useNotificationsStore } from "/js/services/NotificationsStore"
import UnreadDotBellIcon from "/js/components/icons/UnreadDotBellIcon.vue";
import {storeToRefs} from "pinia";

const currentCommunityId = window.APP_CONFIG.COMMUNITY_ID
const { data: currentUser } = useCurrentUserService().load()
const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const hasNotifications = computed(() => {
  if (currentCommunityId) {
    return communitiesUnreadCount.value[currentCommunityId] ?? 0 > 0
  } else {
    console.log("currentCommunityId is not set")
    return false
  }
})
</script>

<script lang="ts">
export default {
  name: "SidebarBottomUser",
}
</script>
