import consumer from "/js/channels/consumer"
import { ref, watch } from "vue"
import { handleDates } from "/js/composables/useAxios"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { z } from "zod"
import { ZChatMessage, ZChatMessageUser, ZChatRoomTracking } from "/js/models/Chat"
import { ZStripeCheckoutStatus, ZStripeSubscription } from "/js/services/PlansApi"
import { ZPlatformSubscription } from "/js/services/PlatformPlansApi"
import { ZLinkPreview } from "/js/services/LinkPreviewsApi"
import { ZGptChat, ZGptMessage } from "/js/services/GptApi"
import { ZNotification } from "/js/models/Notifications"
import { ZPersonalCourse } from "/js/models/Course"
import { ZIdentifiable } from "/js/models/Identifiable"

const connected = ref(false)
const connecting = ref(false)

const subscriptionCallbacks: ((message: SocketMessage) => void)[] = []

export const ZOnlineStatus = z.object({
  id: z.string(),
  online: z.boolean(),
  last_online_at: z.date(),
})

export const ZGoalObjectiveWorkflowData = z.object({
  id: z.string(),
  relevant: z.string(),
  specific: z.string(),
  measurable: z.string(),
  achievable: z.string(),
  time_bound: z.number(),
})

export type GoalObjectiveWorkflowData = z.infer<typeof ZGoalObjectiveWorkflowData>

const chatMessageType = z.literal("chat_message")
const messageReadType = z.literal("message_read")
const onlineStatusType = z.literal("online_status")
const trackingType = z.literal("tracking")
const subscriptionType = z.literal("subscription")
const platformSubscriptionType = z.literal("platform_subscription")
const checkoutSessionStatus = z.literal("checkout_session_status")
const linkPreviewType = z.literal("link_preview")
const gptChatMessageType = z.literal("gpt_chat_message")
const notificationType = z.literal("notification")
const gptChatType = z.literal("gpt_chat")
const personalCourseType = z.literal("personal_course")
const goalObjectiveWorkflowType = z.literal("goal_objective_workflow")
const goalTaskWorkflowType = z.literal("goal_task_workflow")
const goalTaskWorkflowFailedType = z.literal("goal_task_workflow_failed")

export const SocketMessageTypes = [
  chatMessageType.value,
  messageReadType.value,
  onlineStatusType.value,
  trackingType.value,
  subscriptionType.value,
  platformSubscriptionType.value,
  checkoutSessionStatus.value,
  linkPreviewType.value,
  gptChatMessageType.value,
  notificationType.value,
  gptChatType.value,
  personalCourseType.value,
  goalObjectiveWorkflowType.value,
  goalTaskWorkflowType.value,
] as const

export const ZSocketMessage = z.union([
  z.object({
    type: chatMessageType,
    object: ZChatMessage,
  }),
  z.object({
    type: messageReadType,
    object: ZChatMessageUser,
  }),
  z.object({
    type: onlineStatusType,
    object: ZOnlineStatus,
  }),
  z.object({
    type: trackingType,
    object: ZChatRoomTracking,
  }),
  z.object({
    type: subscriptionType,
    object: ZStripeSubscription,
  }),
  z.object({
    type: platformSubscriptionType,
    object: ZPlatformSubscription,
  }),
  z.object({
    type: checkoutSessionStatus,
    object: ZStripeCheckoutStatus,
  }),
  z.object({
    type: linkPreviewType,
    object: ZLinkPreview,
  }),
  z.object({
    type: gptChatMessageType,
    object: ZGptMessage,
  }),
  z.object({
    type: notificationType,
    object: ZNotification,
  }),
  z.object({
    type: gptChatType,
    object: ZGptChat,
  }),
  z.object({
    type: personalCourseType,
    object: ZPersonalCourse,
  }),
  z.object({
    type: goalObjectiveWorkflowType,
    object: ZGoalObjectiveWorkflowData,
  }),
  z.object({
    type: goalTaskWorkflowType,
    object: ZIdentifiable,
  }),
  z.object({
    type: goalTaskWorkflowFailedType,
    object: ZIdentifiable,
  })
])

export type SocketMessage = z.infer<typeof ZSocketMessage>
export type OnlineStatus = z.infer<typeof ZOnlineStatus>
export type SocketMessageType = (typeof SocketMessageTypes)[number]

export const subscribeToNewMessage = (callback: (message: SocketMessage) => void) => {
  if (!subscriptionCallbacks.includes(callback)) {
    subscriptionCallbacks.push(callback)
  }
}

export const unsubscribeFromNewMessage = (callback: (message: SocketMessage) => void) => {
  subscriptionCallbacks.splice(subscriptionCallbacks.indexOf(callback), 1)
}

let userSubscription: any

export const useUserChannel = () => {
  const { data: user } = useCurrentUserService().load()

  watch(
    () => user.value?.id,
    (userId) => {
      if (userId && !connected.value) {
        subscribeToUserChannel()
      }
    }
  )

  const subscribeToUserChannel = () => {
    if (connecting.value || connected.value) {
      return
    }

    connecting.value = true

    if (!user.value) {
      connecting.value = false
      return
    }

    consumer.subscriptions.create("PresenceChannel")

    if (!userSubscription) {
      userSubscription = consumer.subscriptions.create(
        { channel: "UserChannel" },
        {
          connected: () => {
            connected.value = true
            connecting.value = false
            console.log("Connected to user channel")
          },

          disconnected: () => {
            console.log("Disconn from user channel")
            connected.value = false
            connecting.value = false
          },

          rejected: () => {
            console.log("Rejected from user channel")
            connected.value = false
            connecting.value = false
          },

          received: (data: any) => {
            handleDates(data)
            console.log("Received from user channel", data)
            try {
              const message = ZSocketMessage.parse(data)
              subscriptionCallbacks.forEach((callback) => callback(message))
            } catch (e) {
              console.log("Error parsing message", e, data)
            }
          },
        }
      )
    }
  }

  subscribeToUserChannel()

  const markRead = (roomId: string) => {
    if (userSubscription) {
      if (connected.value) {
        userSubscription.send({ message: "mark_read", room_id: roomId })
      } else {
        setTimeout(() => {
          if (connected.value) {
            userSubscription.send({ message: "mark_read", room_id: roomId })
          }
        }, 500)
      }
    } else {
      console.log("Not sending mark_read. No user subscription")
    }
  }

  const subscribeToNewMessage = (callback: (message: SocketMessage) => void) => {
    if (!subscriptionCallbacks.includes(callback)) {
      subscriptionCallbacks.push(callback)
    }
  }

  const unsubscribeFromNewMessage = (callback: (message: SocketMessage) => void) => {
    subscriptionCallbacks.splice(subscriptionCallbacks.indexOf(callback), 1)
  }

  return {
    subscribeToNewMessage,
    unsubscribeFromNewMessage,
    markRead,
  }
}
