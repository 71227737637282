<template>
  <Emoji :data="index" :emoji="colonCode" :size />
</template>

<script setup lang="ts">
import { Emoji, EmojiIndex } from "emoji-mart-vue-fast/src"
import data from "emoji-mart-vue-fast/data/all.json"

let index = new EmojiIndex(data)

const props = withDefaults(defineProps<{
  colonCode: string
  size?: number
}>(), {
  size: 18,
})

</script>

<script lang="ts">
export default {
  name: "EmojiColon",
}
</script>
