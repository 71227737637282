<template>
  <div class="flex w-full max-w-2xl flex-col items-center gap-4 text-center">
    <overview-empty-icon class="h-40 w-40 lg:h-64 lg:w-64" />
    <h4 class="text-primary font-semibold">No content found</h4>
    <p class="text-secondary">Please check back later</p>
  </div>
</template>

<script setup lang="ts">

import OverviewEmptyIcon from "/js/components/icons/OverviewEmptyIcon.vue";
</script>

<script lang="ts">
export default {
  name: "LearningHubOverviewEmptyCard"
}
</script>