<template>
  <div class="flex flex-col gap-0.5">
    <div class="flex items-start gap-2">
      <div class="text-md font-semibold">{{ title }}</div>
    </div>

    <div class="text-secondary flex items-center gap-2 text-sm">
      {{ formattedFullStartDate }}
      <arrow-path-rounded-square-icon
        v-if="recurring"
        class="h-5 w-5 shrink-0 text-secondary"
        v-tooltip="'Recurring event'" />
    </div>
    <hosted-in :productName="productName" :productId="productId" />

  </div>
</template>

<script setup lang="ts">
import { ArrowPathRoundedSquareIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"
import { formatDate } from "@vueuse/core"
import HostedIn from "/js/components/utilities/UI/HostedIn.vue";

type Props = {
  title?: string
  recurring?: boolean
  startDate?: Date
  endDate?: Date
  productName?: string
  productId?: string
}

const props = withDefaults(defineProps<Props>(), {
  recurring: false,
})

const formattedFullStartDate = computed(() => {
  if (!props.startDate) return ""
  const startDateFormatted = formatDate(props.startDate, "MMMM D, YYYY")
  const startTimeFormatted = formatDate(props.startDate, "h:mm A")

  if (props.endDate) {
    return `${startDateFormatted} @ ${startTimeFormatted} - ${formatDate(props.endDate, "h:mm A")}`
  }

  return formatDate(props.startDate, "h:mma")
})
</script>

<script lang="ts">
export default {
  name: "EventTitleDate",
}
</script>
