<template>
  <div class="flex items-center gap-4">
    <round-avatar
      :avatar-url="attachmentImageUrl(option.cover_attachment, { preferThumb: true })"
      :id="option.id"
      rounded="rounded-lg"
      no-text
      size="sm" />
    <div class="flex flex-col items-start">
      <div class="text-sm">{{ option.name }}</div>
      <div class="text-secondary text-xs">
        Learning Hub • {{ productAccessLabel(option.access) }}
        <template v-if="option.label">
          • {{ option.label }}
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { attachmentImageUrl, productAccessLabel } from "/js/models/Product"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type {
  NavigationResourceProduct,
} from "/js/components/NavigationResources/NavigationResourcesTypes"

defineProps<{
  option: NavigationResourceProduct
}>()
</script>

<script lang="ts">
export default {
  name: "ProductOptionBox",
}
</script>