<template>
  <button class="rounded-xl hover:bg-gray-100 p-2 focus:bg-gray-200" type="button">
    <slot></slot>
  </button>
</template>

<script lang="ts">
export default {
  name: "IconButton"
}
</script>