<template>
  <div class="flex w-full gap-6 overflow-x-auto scrollbar:h-0 scrollbar:w-0" v-if="data">
    <div
      v-for="question in data.questions?.filter(notNullish)"
      class="flex shrink-0 w-full max-w-xs flex-col gap-4">
      <div class="text-sm font-medium" v-if="question.title">
        {{ question.title }}
      </div>

      <div
        v-for="answer in question.answers?.filter(notNullish)"
        class="flex items-start gap-2">
        <CircleCheckFillIcon class="text-accent h-4 w-4 shrink-0" v-if="answer.correct" />
        <CircleIcon class="text-secondary h-4 w-4 shrink-0" v-else />

        <div class="flex flex-col gap-1">
          <div v-if="answer.title" class="text-secondary text-xs">{{ answer.title }}</div>
          <div
            v-if="answer.description_hint"
            class="text-secondary flex gap-1 text-xxs italic">
            <span class="font-medium">Hint:</span>
            <span>{{ answer.description_hint }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  PartialGenerateQuizQuestions
} from "/js/components/GptChat/GenerateQuizQuestions/GenerateQuizQuestionsModels"
import { notNullish } from "@vueuse/core"
import CircleIcon from "/js/components/icons/CircleIcon.vue"
import CircleCheckFillIcon from "/js/components/icons/CircleCheckFillIcon.vue"

const props = defineProps<{
  data?: PartialGenerateQuizQuestions
}>()
</script>

<script lang="ts">
export default {
  name: "QuizQuestionAssistantMessage",
}
</script>