<template>
  <confirmation-modal
      title="Publish this Learning Hub?"
      v-model="model"
      @confirm="publishProductAction"
      :loading="publishLoading">
    <template #default="{ data }">
      <div>
        The Learning Hub will be published and visible to Members, allowing them to register.
      </div>
    </template>
    <template #confirm>Publish</template>
  </confirmation-modal>
</template>

<script setup lang="ts">


import type {Product} from "/js/models/Product";
import ConfirmationModal from "/js/components/Modals/ConfirmationItemModal.vue";
import {toastError} from "/js/composables/toast";
import {ref} from "vue";
import {useProductService} from "/js/services/useProductService";

const model = defineModel<Product | undefined>()

const emit = defineEmits<{
  published: []
}>()


const publishLoading = ref(false)
const { publishProduct } = useProductService()
const publishProductAction = async (item: Product) => {
  publishLoading.value = true
  try {
    await publishProduct.mutateAsync(item.id)
    emit("published")
  } catch (e) {
    toastError(e)
  } finally {
    model.value = undefined
    publishLoading.value = false
  }
}

</script>

<script lang="ts">
export default {
  name: "LearningHubPublish"
}
</script>