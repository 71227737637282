<template>
  <tag-input-select v-model="emails" placeholder="Enter an email" @blur="handleBlur"/>
</template>

<script setup lang="ts">

import TagInputSelect from "/js/components/utilities/FormFields/TagInputSelect.vue"
import { ref, watch } from "vue"

const props = defineProps<{
  context: any
}>()

const emails = ref<string[]>([])

const handleBlur = (value: string | undefined) => {
  props.context.handlers.blur()

  if (value) {
    emails.value = [...emails.value, value]
  }
}

watch(emails, (value) => {
  if (value.length === 0) {
    props.context.node.input(undefined)
    return
  }
  // if i don't spread the value, formkit doesn't re-run validations. it probably doesn't deep watch the array
  props.context.node.input([...value])
}, { deep: true })

watch(() => props.context.value, (value) => {
  if (value === undefined || value === null || value.length === 0) {
    emails.value = []
  } else {
    emails.value = [...value]
  }
})


</script>

<script lang="ts">
export default {
  name: "FormKitTagInputSelect",
}
</script>