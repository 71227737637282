import { z } from "zod"
import { ZUser } from "/js/models/User"
import {ChatRoomTypes} from "/js/models/Chat";

const postCommentType = z.literal("PostCommentNotification")
const postFollowType = z.literal("PostFollowNotification")
const collectionItemCommentType = z.literal("CollectionItemCommentNotification")
const productContentCommentType = z.literal("ProductContentCommentNotification")
const communityContentCommentType = z.literal("CommunityContentCommentNotification")
const userSubscriptionPaymentSuccessType = z.literal("UserSubscriptionPaymentSuccessNotification")
const userSubscriptionPaymentFailedType = z.literal("UserSubscriptionPaymentFailedNotification")
const userSubscriptionPaymentOverdueType = z.literal("UserSubscriptionPaymentOverdueNotification")
const userSubscriptionUpcomingPaymentType = z.literal("UserSubscriptionUpcomingPaymentNotification")
const messageReactionType = z.literal("MessageReactionNotification")
const courseUnitAvailableType = z.literal("CourseUnitAvailableNotification")
const courseAvailableType = z.literal("CourseAvailableNotification")
const courseAvailableSoonType = z.literal("CourseAvailableSoonNotification")

export const ZPostNotificationData = z.object({
  post_id: z.string(),
  post_feed_id: z.string(),
  product_id: z.string(),
})

export const ZCollectionItemNotificationData = z.object({
  collection_id: z.string(),
  item_id: z.string(),
  product_id: z.string(),
})

export const ZProductContentNotificationData = z.object({
  content_id: z.string(),
  product_id: z.string(),
})

export const ZCommunityContentNotificationData = z.object({
  content_id: z.string(),
})

export const ZMessageReactionNotificationData = z.object({
  chat_room_id: z.string(),
  message_id: z.string(),
  parent_id: z.string().nullable().optional(),
  chat_room_type: z.enum(ChatRoomTypes)
})

export const ZCourseUnitAvailableNotificationData = z.object({
  product_id: z.string()
})

export const ZCourseAvailableNotificationData = z.object({
  product_id: z.string()
})

export const ZNotificationBase = z.object({
  id: z.string(),
  message: z.string(),
  message_data: z.string().nullable(),
  created_at: z.date(),
  read: z.boolean(),
  actor: ZUser.nullable(),
  recipient: ZUser.optional(),
})

export const ZNotification = z.union([
  z.object({
    type: postCommentType,
    data: ZPostNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: postFollowType,
    data: ZPostNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: collectionItemCommentType,
    data: ZCollectionItemNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: productContentCommentType,
    data: ZProductContentNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: communityContentCommentType,
    data: ZCommunityContentNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: userSubscriptionPaymentSuccessType,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: userSubscriptionPaymentFailedType,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: userSubscriptionPaymentOverdueType,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: userSubscriptionUpcomingPaymentType,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: messageReactionType,
    data: ZMessageReactionNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: courseUnitAvailableType,
    data: ZCourseUnitAvailableNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: courseAvailableType,
    data: ZCourseAvailableNotificationData,
    ...ZNotificationBase.shape
  }),
  z.object({
    type: courseAvailableSoonType,
    data: ZCourseAvailableNotificationData,
    ...ZNotificationBase.shape
  }),
])

export type Notification = z.infer<typeof ZNotification>

export const ZCommunityUnreadCount = z.object({
  id: z.string(),
  count: z.number(),
})

export type CommunityUnreadCount = z.infer<typeof ZCommunityUnreadCount>
