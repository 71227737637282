<template>
<svg width="251" height="192" viewBox="0 0 251 192" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M122.851 189.57C174.894 189.57 217.114 147.141 217.114 94.6804C217.114 42.2195 174.894 0 122.851 0C70.8086 0 28.589 42.4285 28.589 94.8894C28.589 147.35 70.8086 189.57 122.851 189.57Z" fill="#EAEEF9"/>
<path d="M169.251 188.316C154.83 188.734 137.691 188.316 125.36 188.107C113.237 188.525 95.8895 188.734 81.4679 188.316C95.6804 180.164 100.697 165.116 101.324 155.084H148.977C150.231 164.907 155.248 180.164 169.251 188.316Z" fill="url(#paint0_linear_640_41600)"/>
<g filter="url(#filter0_d_640_41600)">
  <path d="M215.442 28.634V153.412C215.442 156.547 212.934 158.846 210.217 158.846H41.5475C38.6214 158.846 36.3223 156.338 36.3223 153.412V28.634C36.3223 25.7079 38.8304 23.1998 41.5475 23.1998H210.008C213.143 23.1998 215.442 25.7079 215.442 28.634Z" fill="white"/>
</g>
<path d="M215.442 28.634V136.064H36.3223V28.634C36.3223 25.7079 38.8304 23.1998 41.5475 23.1998H210.008C213.143 23.1998 215.442 25.7079 215.442 28.634Z" fill="#989FB0"/>
<path d="M208.545 30.0971H42.3835V130.003H208.754V30.0971H208.545Z" fill="white"/>
<path d="M125.569 151.402C128.077 151.402 129.958 149.521 129.958 147.013C129.958 144.505 128.077 142.624 125.569 142.624C123.06 142.624 121.179 144.505 121.179 147.013C121.179 149.521 123.06 151.402 125.569 151.402Z" fill="#D0D8E6"/>
<path d="M198.93 48.4898V61.2393C198.93 63.1203 197.467 64.5834 195.586 64.5834H55.1329C53.2519 64.5834 51.7888 63.1203 51.7888 61.2393V48.4898C51.7888 46.6087 53.2519 45.1457 55.1329 45.1457H195.377C197.467 44.9367 198.93 46.6087 198.93 48.4898Z" fill="#E5E9F1"/>
<path d="M71.4356 44.9366V64.7924H54.924C53.0429 64.7924 51.5799 63.3293 51.5799 61.4482V48.6988C51.5799 46.8177 53.0429 45.3547 54.924 45.3547L71.4356 44.9366Z" fill="#E3EAF2"/>
<path opacity="0.8" d="M198.93 75.2426V87.9921C198.93 89.8731 197.467 91.3362 195.586 91.3362H55.1329C53.2519 91.3362 51.7888 89.8731 51.7888 87.9921V75.2426C51.7888 73.3616 53.2519 71.8985 55.1329 71.8985H195.377C197.467 71.6895 198.93 73.3616 198.93 75.2426Z" fill="#E5E9F1"/>
<path d="M71.4356 44.9366V64.7924H54.924C53.0429 64.7924 51.5799 63.3293 51.5799 61.4482V48.6988C51.5799 46.8177 53.0429 45.3547 54.924 45.3547L71.4356 44.9366Z" fill="#989FB0"/>
<path d="M60.9853 59.1491C60.5672 59.1491 60.1492 58.9401 59.9402 58.7311L56.8051 55.5959C56.1781 54.9689 56.1781 54.1329 56.8051 53.5059C57.4321 52.8788 58.2682 52.8788 58.8952 53.5059L60.7762 55.3869L65.3744 50.7888C66.0014 50.1617 66.8375 50.1617 67.4645 50.7888C68.0915 51.4158 68.0915 52.2518 67.4645 52.8788L61.8213 58.522C61.6123 58.9401 61.1943 59.1491 60.9853 59.1491Z" fill="white"/>
<path opacity="0.5" d="M198.93 101.996V114.745C198.93 116.626 197.467 118.089 195.586 118.089H55.1329C53.2519 118.089 51.7888 116.626 51.7888 114.745V101.996C51.7888 100.115 53.2519 98.6517 55.1329 98.6517H195.377C197.467 98.4427 198.93 99.9057 198.93 101.996Z" fill="#E5E9F1"/>
<path opacity="0.3" d="M198.93 128.54V130.003H51.5799V128.54C51.5799 126.659 53.0429 125.196 54.924 125.196H195.377C197.467 125.196 198.93 126.659 198.93 128.54Z" fill="#E5E9F1"/>
<path d="M49.9078 181.419C49.9078 184.972 46.9817 187.898 43.4285 187.898H7.47924C3.92611 187.898 1 184.972 1 181.419V96.9796C1 93.4265 3.92611 90.5004 7.47924 90.5004H43.2195C46.7727 90.5004 49.6988 93.4265 49.6988 96.9796L49.9078 181.419Z" fill="white"/>
<path d="M43.4285 90.2912C46.9817 90.2912 49.9078 93.2173 49.9078 96.7704V181.209C49.9078 184.763 46.9817 187.689 43.4285 187.689H7.47924C3.92611 187.689 1 184.763 1 181.209V96.9794C1 93.4263 3.92611 90.5002 7.47924 90.5002L43.4285 90.2912Z" fill="#989FB0"/>
<path d="M46.7727 96.9794V181.418C46.7727 183.091 45.5186 184.763 43.6375 184.763H43.4285H7.47924H7.27023C5.59817 184.763 4.13512 183.3 4.13512 181.418V96.9794C4.13512 95.0984 5.59817 93.6353 7.27023 93.6353H12.4954C13.3314 93.6353 13.7495 94.2623 13.7495 94.8894C13.7495 96.1434 14.7945 97.3974 16.2576 97.3974H34.4412C35.6953 97.3974 36.9493 96.3524 36.9493 94.8894C36.9493 94.0533 37.5763 93.6353 37.9943 93.6353H43.0105C45.1006 93.4263 46.7727 95.0984 46.7727 96.9794Z" fill="white"/>
<path d="M28.589 94.8894H23.7818C23.3638 94.8894 22.9458 94.4714 22.9458 94.0534C22.9458 93.6353 23.3638 93.2173 23.7818 93.2173H28.589C29.007 93.2173 29.425 93.6353 29.425 94.0534C29.216 94.4714 28.798 94.8894 28.589 94.8894Z" fill="white"/>
<path d="M21.0647 94.8894H20.6467C20.2287 94.8894 19.8107 94.4714 19.8107 94.0534C19.8107 93.6353 20.2287 93.2173 20.6467 93.2173H21.0647C21.4827 93.2173 21.9008 93.6353 21.9008 94.0534C21.9008 94.4714 21.4827 94.8894 21.0647 94.8894Z" fill="white"/>
<path d="M41.7566 124.987H9.15138C8.10634 124.987 7.47932 124.151 7.47932 123.315V106.803C7.47932 105.758 8.31535 105.131 9.15138 105.131H41.9656C43.0106 105.131 43.6376 105.967 43.6376 106.803V123.315C43.4286 124.151 42.8016 124.987 41.7566 124.987Z" fill="#989FB0"/>
<path d="M24.1998 119.343C23.7818 119.343 23.3638 119.134 23.1548 118.925L20.0197 115.79C19.3927 115.163 19.3927 114.327 20.0197 113.7C20.6467 113.073 21.4827 113.073 22.1098 113.7L23.9908 115.581L28.589 110.983C29.216 110.356 30.0521 110.356 30.6791 110.983C31.3061 111.61 31.3061 112.446 30.6791 113.073L25.2449 118.507C24.8269 119.134 24.4088 119.343 24.1998 119.343Z" fill="white"/>
<path opacity="0.8" d="M41.7566 149.649H9.15138C8.10634 149.649 7.47932 148.813 7.47932 147.977V131.466C7.47932 130.421 8.31535 129.794 9.15138 129.794H41.9656C43.0106 129.794 43.6376 130.63 43.6376 131.466V147.977C43.4286 148.813 42.8016 149.649 41.7566 149.649Z" fill="#E5E9F1"/>
<path opacity="0.5" d="M41.7566 174.312H9.15138C8.10634 174.312 7.47932 173.476 7.47932 172.64V156.129C7.47932 155.084 8.31535 154.457 9.15138 154.457H41.9656C43.0106 154.457 43.6376 155.293 43.6376 156.129V172.64C43.4286 173.685 42.8016 174.312 41.7566 174.312Z" fill="#E5E9F1"/>
<path opacity="0.3" d="M43.4285 181.21V184.554H43.2195H7.47923H7.27023V181.21C7.27023 180.165 8.10626 179.538 8.94229 179.538H41.7565C42.8015 179.538 43.4285 180.374 43.4285 181.21Z" fill="#E5E9F1"/>
<path d="M49.2808 37.8305C50.3258 37.8305 51.1618 36.9944 51.1618 35.9494C51.1618 34.9044 50.3258 34.0683 49.2808 34.0683C48.2357 34.0683 47.3997 34.9044 47.3997 35.9494C47.3997 36.9944 48.2357 37.8305 49.2808 37.8305Z" fill="#E3EAF2"/>
<path d="M55.3421 37.8305C56.3871 37.8305 57.2231 36.9944 57.2231 35.9494C57.2231 34.9044 56.3871 34.0683 55.3421 34.0683C54.297 34.0683 53.461 34.9044 53.461 35.9494C53.461 36.9944 54.297 37.8305 55.3421 37.8305Z" fill="#E3EAF2"/>
<path d="M61.4032 37.8305C62.4482 37.8305 63.2843 36.9944 63.2843 35.9494C63.2843 34.9044 62.4482 34.0683 61.4032 34.0683C60.3582 34.0683 59.5221 34.9044 59.5221 35.9494C59.5221 36.9944 60.3582 37.8305 61.4032 37.8305Z" fill="#E3EAF2"/>
<path d="M250.165 154.546C249.329 158.099 248.075 161.861 246.612 164.996C242.641 172.729 236.37 178.791 228.637 182.762C220.695 186.733 211.29 188.405 201.884 186.315C179.729 181.717 165.517 159.98 170.115 137.825C174.713 115.67 196.241 101.249 218.396 106.056C226.338 107.728 233.235 111.699 239.088 117.133C248.911 127.166 252.882 141.169 250.165 154.546Z" fill="url(#paint1_linear_640_41600)"/>
<path d="M222.994 142.632H213.589V133.227C213.589 131.346 212.126 129.674 210.035 129.674C208.154 129.674 206.482 131.137 206.482 133.227V142.632H197.077C195.196 142.632 193.524 144.095 193.524 146.185C193.524 148.276 194.987 149.739 197.077 149.739H206.482V159.144C206.482 161.025 207.945 162.697 210.035 162.697C211.917 162.697 213.589 161.234 213.589 159.144V149.739H222.994C224.875 149.739 226.547 148.276 226.547 146.185C226.756 144.095 225.084 142.632 222.994 142.632Z" fill="white"/>
<path d="M1.65011 188.544L243.074 188.544" stroke="#989FB0" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2966 81.9503C20.7569 81.9503 23.562 79.1452 23.562 75.6849C23.562 72.2246 20.7569 69.4195 17.2966 69.4195C13.8363 69.4195 11.0312 72.2246 11.0312 75.6849C11.0312 79.1452 13.8363 81.9503 17.2966 81.9503Z" fill="#EAEEF9"/>
<path d="M11.0312 61.6261C13.3943 61.6261 15.31 59.7104 15.31 57.3472C15.31 54.9841 13.3943 53.0684 11.0312 53.0684C8.66807 53.0684 6.75237 54.9841 6.75237 57.3472C6.75237 59.7104 8.66807 61.6261 11.0312 61.6261Z" fill="#EAEEF9"/>
<path d="M189.861 11.4836C192.224 11.4836 194.14 9.56791 194.14 7.20478C194.14 4.84166 192.224 2.92598 189.861 2.92598C187.498 2.92598 185.582 4.84166 185.582 7.20478C185.582 9.56791 187.498 11.4836 189.861 11.4836Z" fill="#EAEEF9"/>
<path d="M235.127 97.711C239.516 97.711 243.074 94.1533 243.074 89.7646C243.074 85.376 239.516 81.8183 235.127 81.8183C230.739 81.8183 227.181 85.376 227.181 89.7646C227.181 94.1533 230.739 97.711 235.127 97.711Z" fill="#EAEEF9"/>
<defs>
  <filter id="filter0_d_640_41600" x="14.3223" y="12.1998" width="223.119" height="179.646" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="11"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_640_41600"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_640_41600" result="shape"/>
  </filter>
  <linearGradient id="paint0_linear_640_41600" x1="125.377" y1="187.388" x2="125.377" y2="157.626" gradientUnits="userSpaceOnUse">
    <stop stop-color="#E8ECF5"/>
    <stop offset="0.9913" stop-color="#BDC4D7"/>
  </linearGradient>
  <linearGradient id="paint1_linear_640_41600" x1="169.214" y1="146.184" x2="251.015" y2="146.184" gradientUnits="userSpaceOnUse">
    <stop stop-color="#B0BACC"/>
    <stop offset="1" stop-color="#969EAE"/>
  </linearGradient>
</defs>
</svg>
</template>
