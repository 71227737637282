import { get, post } from "/js/composables/useAxios"
import type { Comment, CommentParams } from "/js/models/Comment"

export const CommentsApi = {
  getPostComments: async (postId: string): Promise<Comment[]> =>
    await get(`/api/posts/${postId}/comments`),

  getCollectionItemComments: async (collectionItemId: string): Promise<Comment[]> =>
    await get(`/api/collection_items/${collectionItemId}/comments`),

  getProductContentComments: async (productContentId: string): Promise<Comment[]> =>
    await get(`/api/product_contents/${productContentId}/comments`),

  getCommunityContentComments: async (communityContentId: string): Promise<Comment[]> =>
    await get(`/api/community_contents/${communityContentId}/comments`),

  getGoalObjectiveComments: async (goalObjectiveId: string): Promise<Comment[]> =>
    await get(`/api/goal_objectives/${goalObjectiveId}/comments`),

  getChildComments: async (commentId: string): Promise<Comment[]> =>
    await get(`/api/comments/${commentId}/comments`),

  createPostComment: async (postId: string, comment: CommentParams): Promise<Comment> =>
    await post(`/api/posts/${postId}/comments`, { comment }),

  createCollectionItemComment: async (
    collectionItemId: string,
    comment: CommentParams
  ): Promise<Comment> =>
    await post(`/api/collection_items/${collectionItemId}/comments`, { comment }),

  createProductContentComment: async (
    productContentId: string,
    comment: CommentParams
  ): Promise<Comment> =>
    await post(`/api/product_contents/${productContentId}/comments`, { comment }),

  createChildComment: async (commentId: string, comment: CommentParams): Promise<Comment> =>
    await post(`/api/comments/${commentId}/comments`, { comment }),

  createCommunityContentComment: async (
    communityContentId: string,
    comment: CommentParams
  ): Promise<Comment> =>
    await post(`/api/community_contents/${communityContentId}/comments`, { comment }),

  createGoalObjectiveComment: async (
    goalObjectiveId: string,
    comment: CommentParams
  ): Promise<Comment> =>
    await post(`/api/goal_objectives/${goalObjectiveId}/comments`, { comment }),
}
