import type { User } from "/js/models/User"
import { z } from "zod"
import type { EditorDataHandlerParams } from "/js/models/EditorContent"
import { ZProductAttachment } from "/js/models/Product"
import type { ProductActivityLog } from "/js/services/ProductActivityLogsApi"

export const ZCourse = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  image_url: z.string().nullable(),
})

export const ZPersonalCourse = ZCourse.extend({
  status: z.enum(["pending", "progress", "completed", "failed"]).optional().nullable(),
})

export const UnitAssignmentCompleteTypes = [
  "member_submission",
  "manager_submission",
  "mark_complete",
] as const

export const ZUnitLesson = z.object({
  id: z.string(),
  title: z.string(),
  editor_content_id: z.string(),
  attachments: ZProductAttachment.array(),
  duration: z.number().nullable(),
})

export const ZQuizQuestionAnswer = z.object({
  id: z.string(),
  title: z.string(),
  correct: z.boolean(),
  description_hint: z.string().nullable(),
  position: z.number(),
})

export const ZQuizQuestion = z.object({
  id: z.string(),
  title: z.string(),
  position: z.number(),
  quiz_question_answers: ZQuizQuestionAnswer.array(),
})

export const ZQuiz = z.object({
  id: z.string(),
  title: z.string(),
  questions: ZQuizQuestion.array(),
  duration: z.number().nullable(),
})

export const ZUnitAssignment = z.object({
  id: z.string(),
  title: z.string(),
  due_date: z.date().nullable(),
  complete_type: z.enum(UnitAssignmentCompleteTypes),
  editor_content_id: z.string(),
  attachments: ZProductAttachment.array(),
  duration: z.number().nullable(),
})

export const learnableUnitLessonType = z.literal("unit_lesson")
export const learnableQuizType = z.literal("quiz")
export const learnableUnitAssignmentType = z.literal("unit_assignment")

export const LearnableTypes = [
  learnableUnitLessonType.value,
  learnableQuizType.value,
  learnableUnitAssignmentType.value,
] as const

export type LearnableType = (typeof LearnableTypes)[number]

export const ZUnitLessonLearnable = z.object({
  type: learnableUnitLessonType,
  unit_lesson: ZUnitLesson,
})

export const ZQuizLearnable = z.object({
  type: learnableQuizType,
  quiz: ZQuiz,
})

export const ZUnitAssignmentLearnable = z.object({
  type: learnableUnitAssignmentType,
  unit_assignment: ZUnitAssignment,
})

export const ZLearnable = z.union([ZUnitLessonLearnable, ZQuizLearnable, ZUnitAssignmentLearnable])

export const ZLearnableType = z.union([
  ZUnitLessonLearnable.shape.type,
  ZQuizLearnable.shape.type,
  ZUnitAssignmentLearnable.shape.type,
])

export const ZLearningObject = z.object({
  id: z.string(),
  learnable_type: ZLearnableType,
  learnable_id: z.string(),
  position: z.number(),
  title: z.string(),
  duration: z.number().nullable(),
})

export const ZCourseUnit = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  release_time: z.date().nullable(),
  learning_objects: ZLearningObject.array(),
})

export const ZCourseUnitWidget = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional().nullable(),
  unit_lessons_count: z.number(),
  quizzes_count: z.number(),
  unit_assignments_count: z.number(),
})

export type CourseUnitWidget = z.infer<typeof ZCourseUnitWidget>

const ZQuizQuestionAnswerParams = ZQuizQuestionAnswer.omit({ id: true }).extend({
  id: z.string().optional(),
  _destroy: z.boolean().optional(),
})

const ZQuizQuestionParams = ZQuizQuestion.omit({ id: true, quiz_question_answers: true }).extend({
  id: z.string().optional(),
  _destroy: z.boolean().optional(),
  quiz_question_answers_attributes: ZQuizQuestionAnswerParams.array().optional(),
})

export const ZQuizParams = z.object({
  title: z.string(),
  duration: z.number().nullable().optional(),
  questions_attributes: ZQuizQuestionParams.array(),
})

export type UnitLessonParams = {
  title: string
  attachments_ids?: string[]
  duration?: number | null
}

export type EditorDataHandlerUnitLessonParams = {
  unit_lesson: UnitLessonParams
} & EditorDataHandlerParams

export type UnitAssignmentParams = {
  title: string
  due_date: Date | null
  complete_type: UnitAssignmentCompleteType
  attachments_ids?: string[]
  duration?: number | null
}

export type EditorDataHandlerCreateUnitAssignmentParams = {
  unit_assignment: {
    title: string
  }
} & EditorDataHandlerParams

export type EditorDataHandlerUnitAssignmentParams = {
  unit_assignment: UnitAssignmentParams
} & EditorDataHandlerParams

export type LearningObject = z.infer<typeof ZLearningObject>
export type UnitAssignment = z.infer<typeof ZUnitAssignment>
export type UnitAssignmentCompleteType = (typeof UnitAssignmentCompleteTypes)[number]
export type UnitLesson = z.infer<typeof ZUnitLesson>

export type Learnable = z.infer<typeof ZLearnable>

export type Quiz = z.infer<typeof ZQuiz>
export type QuizQuestion = z.infer<typeof ZQuizQuestion>
export type QuizQuestionAnswer = z.infer<typeof ZQuizQuestionAnswer>
export type QuizQuestionAnswerParams = z.infer<typeof ZQuizQuestionAnswerParams>
export type QuizQuestionParams = z.infer<typeof ZQuizQuestionParams>
export type QuizParams = z.infer<typeof ZQuizParams>

export type Course = z.infer<typeof ZCourse>
export type PersonalCourse = z.infer<typeof ZPersonalCourse>
export type CourseUnit = z.infer<typeof ZCourseUnit>

export type CourseUnitParams = {
  title: string
  description: string
  release_time?: Date
}

export const learningObjectType = (obj: LearningObject) => {
  return (
    {
      unit_lesson: "Lesson",
      quiz: "Quiz",
      unit_assignment: "Assignment",
    }[obj.learnable_type] || ""
  )
}

export const equalLearnableType = (
  type: Learnable["type"],
  activityLog: ProductActivityLog["loggable_type"]
) => {
  return (
    (type === "unit_lesson" && activityLog === "UnitLesson") ||
    (type === "quiz" && activityLog === "Quiz") ||
    (type === "unit_assignment" && activityLog === "UnitAssignment")
  )
}
