<template>
  <picker
    :data="emojiIndex"
    set="apple"
    @select="handleEmojiPick($event)"
    title="Pick a reaction"
    :show-skin-tones="false" />
</template>

<script setup lang="ts">
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src"
import data from "emoji-mart-vue-fast/data/apple.json"
import type { EmojiMartData } from "/js/models/Chat"

let emojiIndex = new EmojiIndex(data)

const emit = defineEmits<{
  select: [emoji: EmojiMartData]
}>()

const handleEmojiPick = (emoji: EmojiMartData) => {
  emit("select", emoji)
}

</script>

<script lang="ts">
export default {
  name: "EmojiPicker",
}
</script>
