import type {
  ChatMessage,
  ChatMessageBase,
  ChatMessageUser,
  ChatRoom,
  ChatRoomAccessType,
  ChatRoomListItem,
  ChatRoomTracking,
} from "/js/models/Chat"
import { get, post, patch, getZod, postZod, patchZod, del } from "/js/composables/useAxios"
import { makeGetUrl } from "/js/composables/makeQueryString"
import type { User, UserWithName } from "/js/models/User"
import {
  ZChatMessage,
  ZChatMessageUser,
  ZChatRoomListItem,
  ZChatRoomTracking,
} from "/js/models/Chat"
import type { UserGroup } from "/js/services/permissionPolicyService"
import type { RoomId } from "/js/stores/ChatStoreV2"
import { ZUserWithName } from "/js/models/User"

export type CreateMessageParams = {
  text: string
  identifier: string
}

export type ChatMessageAttachmentParams = {
  identifier: string
  attachments_ids: string[]
}

export type ChatRoomChannelParams = {
  name: string
  room_access: ChatRoomAccessType
  description: string
  user_ids?: string[]
  user_groups_ids?: string[]
}

export type ChatRoomDirectParams = {
  user_ids: string[]
}

export type ChatRoomUpdateParams = {
  name?: string
  description?: string
}

export type ChatRoomMessageQueryParams = {
  before?: string
  cursor_id?: string
  after?: string
}

export const ChatApi = {
  getChatRoom: async (roomId: string): Promise<ChatRoomListItem> => {
    return await getZod(`/api/chat/rooms/${roomId}`, ZChatRoomListItem)
  },

  getChatRooms: async (): Promise<ChatRoomListItem[]> => {
    return await getZod(`/api/chat/rooms`, ZChatRoomListItem.array())
  },

  updateChatRoom: async (
    roomId: string,
    chat_room: ChatRoomUpdateParams
  ): Promise<ChatRoomListItem> => {
    return await patchZod(`/api/chat/rooms/${roomId}`, { chat_room }, ZChatRoomListItem)
  },

  deleteChatRoom: async (roomId: string): Promise<void> => {
    return await del(`/api/chat/rooms/${roomId}`)
  },

  getTrackings: async (): Promise<ChatRoomTracking[]> => {
    return await getZod(`/api/chat/trackings`, ZChatRoomTracking.array())
  },

  createChannel: async (chat_room: ChatRoomChannelParams): Promise<ChatRoomListItem> => {
    return await postZod(`/api/chat/community_channels`, { chat_room }, ZChatRoomListItem)
  },

  createProductChannel: async (
    productId: string,
    chat_room: ChatRoomChannelParams
  ): Promise<ChatRoomListItem> => {
    return await postZod(
      `/api/chat/products/${productId}/product_channels`,
      { chat_room },
      ZChatRoomListItem
    )
  },

  createDirectMessageRoom: async (chat_room: ChatRoomDirectParams): Promise<ChatRoomListItem> => {
    return await postZod(`/api/chat/direct_rooms`, { chat_room }, ZChatRoomListItem)
  },

  getChannelAvailableUserGroups: async (): Promise<UserGroup[]> => {
    return await get(`/api/chat/community_channels/user_groups`)
  },

  getProductChannelAvailableUserGroups: async (productId: string): Promise<UserGroup[]> => {
    return await get(`/api/chat/products/${productId}/product_channels/user_groups`)
  },

  getCursorPaginatedMessages: async (
    roomId: RoomId,
    params: ChatRoomMessageQueryParams
  ): Promise<ChatMessageBase[]> => {
    const path = roomId.parentId
      ? `/api/chat/rooms/${roomId.roomId}/messages/${roomId.parentId}/messages`
      : `/api/chat/rooms/${roomId.roomId}/messages`

    return await getZod(makeGetUrl(path, params), ZChatMessage.array())
  },

  sendTextMessage: async (
    roomId: RoomId,
    chat_message: CreateMessageParams
  ): Promise<ChatMessageBase> => {
    const path = roomId.parentId
      ? `/api/chat/rooms/${roomId.roomId}/messages/${roomId.parentId}/messages`
      : `/api/chat/rooms/${roomId.roomId}/messages`

    return await postZod(path, { chat_message }, ZChatMessage)
  },

  sendAttachmentsMessage: async (
    roomId: RoomId,
    chat_message: ChatMessageAttachmentParams
  ): Promise<ChatMessageBase> => {
    const path = roomId.parentId
      ? `/api/chat/rooms/${roomId.roomId}/messages/${roomId.parentId}/messages`
      : `/api/chat/rooms/${roomId.roomId}/messages`

    return await postZod(path, { chat_message }, ZChatMessage)
  },

  markRead: async (roomId: string): Promise<ChatRoomTracking> => {
    return await patchZod(`/api/chat/rooms/${roomId}/mark_read`, {}, ZChatRoomTracking)
  },

  autocompleteMembers: async (query: string): Promise<ChatMessageUser[]> => {
    return await getZod(
      makeGetUrl("/api/chat/autocomplete_members", { query }),
      ZChatMessageUser.array()
    )
  },

  toggleMessageReaction: async (
    roomId: string,
    messageId: string,
    reaction: string,
    native: string
  ): Promise<ChatMessage> => {
    return await post(`/api/chat/rooms/${roomId}/messages/${messageId}/user_reactions`, {
      user_reaction: { reaction, native },
    })
  },

  getReactionUsers: async (
    roomId: string,
    messageId: string,
    reaction: string
  ): Promise<UserWithName[]> => {
    return await getZod(
      `/api/chat/rooms/${roomId}/messages/${messageId}/user_reactions/${reaction}`,
      ZUserWithName.array()
    )
  },

  deleteMessage: async (roomId: string, messageId: string): Promise<void> => {
    return await del(`/api/chat/rooms/${roomId}/messages/${messageId}`)
  },
}
