import {
  get,
  getZod,
  getZodPaginated,
  type PaginationData,
  post,
  postZod,
} from "/js/composables/useAxios"
import type { EditorContent, EditorContentListItem } from "/js/models/EditorContent"
import { z } from "zod"
import type { EditorContentsFilterParams } from "/js/services/AdminEditorContentsApi"
import { makeGetUrl } from "/js/composables/makeQueryString"
import { ZEditorContentListItem } from "/js/models/EditorContent"
import type { Identifiable } from "/js/models/Identifiable"

const productContentType = z.literal("ProductContent")
const communityContentType = z.literal("CommunityContent")
const collectionItemType = z.literal("CollectionItem")
const unitLessonType = z.literal("UnitLesson")
const unitAssignmentType = z.literal("UnitAssignment")
const widgetType = z.literal("Widget")

const ContentUsableTypes = [
  productContentType.value,
  communityContentType.value,
  collectionItemType.value,
  unitLessonType.value,
  unitAssignmentType.value,
  widgetType.value,
] as const

export const ZEditorUsageItemBase = z.object({
  id: z.string(),
  editor_content_id: z.string(),
  title: z.string(),
  content_usable_id: z.string(),
})

export const ZProductContentData = z.object({
  product_id: z.string(),
  product_content_id: z.string(),
})

export const ZCollectionItemData = z.object({
  product_id: z.string(),
  collection_id: z.string(),
  collection_item_id: z.string(),
  collection_folder_id: z.string(),
})

export const ZWidgetItemData = z.object({
  widget_scope: z.enum([
    "product_overview",
    "community_home",
    "community_registration",
    "product_faq",
  ]),
  widget_page_id: z.string(),
  widget_page_type: z.string(),
})

export const ZUnitAssignmentItemData = z.object({
  product_id: z.string(),
})

export const ZUnitLessonItemData = z.object({
  product_id: z.string(),
})

export const ZEditorUsageItem = z.union([
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: productContentType,
    content_usable_data: ZProductContentData,
  }),
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: collectionItemType,
    content_usable_data: ZCollectionItemData,
  }),
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: unitLessonType,
    content_usable_data: ZUnitLessonItemData,
  }),
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: unitAssignmentType,
    content_usable_data: ZUnitAssignmentItemData,
  }),
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: communityContentType,
  }),
  z.object({
    ...ZEditorUsageItemBase.shape,
    content_usable_type: widgetType,
    content_usable_data: ZWidgetItemData,
  }),
])

export type EditorUsageItem = z.infer<typeof ZEditorUsageItem>

export const ZEjsPluginQuestionAnswer = z.object({
  id: z.string(),
  answer: z.string().optional().nullable(),
  created_at: z.date(),
})

export type EjsPluginQuestionAnswer = z.infer<typeof ZEjsPluginQuestionAnswer>

export type EjsPluginQuestionAnswerParams = {
  answer: string
  ejs_plugin_question_id: string
}

export const EditorContentApi = {
  getEditorContentsList: async (
    params?: EditorContentsFilterParams | undefined
  ): Promise<PaginationData<EditorContentListItem[]>> => {
    return await getZodPaginated(
      makeGetUrl("/api/editor_contents", params),
      ZEditorContentListItem.array()
    )
  },

  getEditorUsages: async (editorContentId: string): Promise<EditorUsageItem[]> => {
    return await get(`/api/editor_contents/${editorContentId}/editor_content_usages`)
  },

  getContent: async (id: string): Promise<EditorContent> => {
    return await get(`/api/editor_contents/${id}`)
  },

  createQuestionContainer: async (product_id?: string): Promise<Identifiable> => {
    return await post(`/api/ejs_plugin_questions`, { product_id })
  },

  getQuestionAnswer: async (questionId: string): Promise<EjsPluginQuestionAnswer> => {
    return await getZod(
      `/api/ejs_plugin_questions/${questionId}/ejs_plugin_question_answer`,
      ZEjsPluginQuestionAnswer
    )
  },

  createQuestionAnswer: async (
    params: EjsPluginQuestionAnswerParams
  ): Promise<EjsPluginQuestionAnswer> => {
    return await postZod(`/api/ejs_plugin_question_answers`, { ejs_plugin_question_answer: params }, ZEjsPluginQuestionAnswer)
  },
}
