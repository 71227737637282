<template>
  <dropdown-menu
    v-if="isAdmin"
    icon-color="text-white drop-shadow"
    button-class="w-full">
    <template #button>
      <community-nav-button-inner :community>
        <chevron-down-icon class="h-4 w-4 text-gray-600 dark:text-white" />
      </community-nav-button-inner>
    </template>
    <dropdown-router-menu-item :to="{ name: adminRouteNames.dashboard }">
      Admin
    </dropdown-router-menu-item>
  </dropdown-menu>
  <community-nav-button-inner :community v-else />
</template>

<script setup lang="ts">
import type { Community } from "/js/models/Community"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownRouterMenuItem from "/js/components/DropdownRouterMenuItem.vue"
import { adminRouteNames } from "/js/router/AdminRoutes"
import ChevronDownIcon from "/js/components/icons/ChevronDownIcon.vue"
import CommunityNavButtonInner from "/js/components/Layout/CommunityNavButtonInner.vue"
import { useGate } from "/js/composables/useGate"
import { computed } from "vue"

defineProps<{
  community: Community
}>()

const { hasCommunityAbility } = useGate()

const isAdmin = computed(() => {
  return hasCommunityAbility("community_edit")
})
</script>

<script lang="ts">
export default {
  name: "CommunityNavButtonMembers",
}
</script>
