<template>
  <div
    class="flex flex-1 flex-col items-center justify-between overflow-y-auto scrollbar:h-0 scrollbar:w-0">
    <div class="flex h-full flex-col items-center gap-4">
      <schools-nav class="h-11 w-11" />

      <router-link :to="{ name: 'learner_dashboard' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <DashboardFillIcon class="h-6 w-6" v-if="isActive" />
            <DashboardIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Dashboard</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'home' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <PentagonFillIcon class="h-6 w-6" v-if="isActive" />
            <PentagonIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Community</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'learning_hubs.index' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <BookFillIcon class="h-6 w-6" v-if="isActive" />
            <BookIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Learning</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'community_events.index' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <CalendarFillIcon class="h-6 w-6" v-if="isActive" />
            <CalendarIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Events</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: 'chat' }"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <ChatBubbleMultiFill class="h-6 w-6" v-if="isActive" />
            <ChatBubbleMulti class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Chat</span>
        </span>
      </router-link>

      <router-link
        v-if="hasCommunityAdminAccess"
        to="/admin"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <CogIconFill class="h-6 w-6" v-if="isActive" />
            <CogIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Admin</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: 'personal.index' }"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <BookFillIcon class="h-6 w-6" v-if="isActive" />
            <BookIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Personalized</span>
        </span>
      </router-link>

      <div class="flex-1"></div>

      <global-search-field #default="{ openSearch }">
        <button @click="openSearch">
          <span class="flex flex-col items-center">
            <span class="rounded-lg p-2">
              <MagnifierIcon class="h-6 w-6" />
            </span>
            <span class="text-xxs">Search</span>
          </span>
        </button>
      </global-search-field>

      <router-link :to="{ name: 'notifications' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <BellIcon class="h-6 w-6" v-if="!hasNotifications" />
            <UnreadDotBellIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Notifications</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'assistant' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <WizardIcon class="h-6 w-6" />
          </span>
          <span class="text-xxs">Zixie Chat</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'profile' }" class="btn-zoom">
        <round-avatar
          size="sm"
          :id="currentUser?.id"
          :verified="currentUser?.verified"
          :avatar-url="currentUser?.avatar_url"
          :text="initials(currentUser)" />
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { initials } from "/js/models/User"
import WizardIcon from "/js/components/icons/WizardIcon.vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import MagnifierIcon from "/js/components/icons/MagnifierIcon.vue"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import BellIcon from "/js/components/icons/BellIcon.vue"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import PentagonIcon from "/js/components/icons/PentagonIcon.vue"
import PentagonFillIcon from "/js/components/icons/PentagonFillIcon.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import BookFillIcon from "/js/components/icons/BookFillIcon.vue"
import BookIcon from "/js/components/icons/BookIcon.vue"
import ChatBubbleMultiFill from "/js/components/icons/ChatBubbleMultiFill.vue"
import ChatBubbleMulti from "/js/components/icons/ChatBubbleMulti.vue"
import CogIconFill from "/js/components/icons/CogIconFill.vue"
import CogIcon from "/js/components/icons/CogIcon.vue"
import { useGate } from "/js/composables/useGate"
import UnreadDotBellIcon from "/js/components/icons/UnreadDotBellIcon.vue"
import { computed } from "vue"
import { storeToRefs } from "pinia"
import { useNotificationsStore } from "/js/services/NotificationsStore"
import DashboardIcon from "/js/components/icons/DashboardIcon.vue"
import DashboardFillIcon from "/js/components/icons/DashboardFillIcon.vue"
import GlobalSearchField from "/js/components/GlobalSearch/GlobalSearchField.vue"

const currentCommunityId = window.APP_CONFIG.COMMUNITY_ID

const { data: currentUser } = useCurrentUserService().load()
const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const { hasCommunityAdminAccess } = useGate()

const hasNotifications = computed(() => {
  if (currentCommunityId) {
    return communitiesUnreadCount.value[currentCommunityId] ?? 0 > 0
  } else {
    console.log("currentCommunityId is not set")
    return false
  }
})
</script>

<script lang="ts">
export default {
  name: "SmallSidebarV2",
}
</script>
