import type { Ref } from "vue"
import { ref, watch } from "vue"

export const useFilePreviewUrl = (fileRef: Ref<File | undefined>) => {
  const filePreviewUrl = ref<string | undefined>()

  watch(fileRef, (fileData) => {
    if (fileData) {
      filePreviewUrl.value = URL.createObjectURL(fileData)
    } else {
      if (filePreviewUrl.value) {
        URL.revokeObjectURL(filePreviewUrl.value)
      }
    }
  })

  return {
    filePreviewUrl,
  }
}
