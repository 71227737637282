<template>
  <button type="button" v-auto-animate class="w-full text-start" @click="emit('toggle')">
    <div class="padding flex items-center justify-between gap-8">
      <slot name="title"></slot>
      <ChevronDownIcon
        class="h-4 w-4 transform transition-transform"
        :class="{ 'rotate-180': itemOpen }" />
    </div>
    <div class="padding text-sm" v-if="itemOpen">
      <slot name="default"></slot>
    </div>
  </button>
</template>

<script setup lang="ts">
import ChevronDownIcon from "/js/components/icons/ChevronDownIcon.vue"
import { ref } from "vue"

const props = defineProps<{
  itemOpen: boolean | undefined
}>()

const emit = defineEmits<{
  "toggle": []
}>()

// const itemOpen = defineModel<boolean>({ default: false })
</script>

<script lang="ts">
export default {
  name: "AccordionView",
}
</script>
