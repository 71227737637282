import { del, get, getZod, patch, patchZod, post, postZod } from "/js/composables/useAxios"
import type {
  WidgetParams,
  WidgetPlacement,
  ProductWidgetScopeType,
  CommunityWidgetScopeType,
} from "/js/models/Widget"
import { ZWidget } from "/js/models/Widget"
import type { Product } from "/js/models/Product"
import type { User } from "/js/models/User"
import type { EventItem } from "/js/models/EventModels"
import type { Post } from "/js/models/Post"
import type { EditorContent, EditorContentListItem } from "/js/models/EditorContent"
import type { CourseUnitWidget } from "/js/models/Course"
import { ZCourseUnitWidget } from "/js/models/Course"

export const WidgetsApi = {
  getCommunityWidgets: async (scope: CommunityWidgetScopeType) =>
    await getZod(`/api/community/widgets/${scope}`, ZWidget.array()),

  createCommunityWidget: async (scope: CommunityWidgetScopeType, widget: WidgetParams) =>
    await postZod(`/api/community/widgets/${scope}`, { widget }, ZWidget),

  getProductWidgets: async (product_id: string, page_type: ProductWidgetScopeType) =>
    await getZod(`/api/products/${product_id}/widgets/${page_type}`, ZWidget.array()),

  createProductWidget: async (
    product_id: string,
    page_type: ProductWidgetScopeType,
    widget: WidgetParams
  ) => await postZod(`/api/products/${product_id}/widgets/${page_type}`, { widget }, ZWidget),

  updateWidget: async (id: string, widget: Partial<WidgetParams>) => {
    const { type, ...widgetWithoutType } = widget
    return await patchZod(`/api/widgets/${id}`, { widget: widgetWithoutType }, ZWidget)
  },

  deleteWidget: async (id: string): Promise<void> => {
    return await del(`/api/widgets/${id}`)
  },

  reorderWidget: async (id: string, position: number, placement: WidgetPlacement) => {
    return await patchZod(
      `/api/widgets/${id}/reorder`,
      { widget: { position, placement } },
      ZWidget
    )
  },

  getProducts: async (widgetId: string): Promise<Product[]> => {
    return await get(`/api/widgets/${widgetId}/products`)
  },

  getMembers: async (widgetId: string): Promise<User[]> => {
    return await get(`/api/widgets/${widgetId}/members`)
  },

  getEvents: async (widgetId: string): Promise<EventItem[]> => {
    return await get(`/api/widgets/${widgetId}/events`)
  },

  getPosts: async (widgetId: string): Promise<Post[]> => {
    return await get(`/api/widgets/${widgetId}/feed`)
  },

  getInstructors: async (widgetId: string): Promise<User[]> => {
    return await get(`/api/widgets/${widgetId}/instructors`)
  },

  getEditorContentListItems: async (widgetId: string): Promise<EditorContentListItem[]> => {
    return await get(`/api/widgets/${widgetId}/contents`)
  },

  getEditorContentFullItems: async (widgetId: string): Promise<EditorContent[]> => {
    return await get(`/api/widgets/${widgetId}/contents?full=1`)
  },

  getCourseUnits: async (widgetId: string): Promise<CourseUnitWidget[]> => {
    return await getZod(`/api/widgets/${widgetId}/course_units`, ZCourseUnitWidget.array())
  }
}
