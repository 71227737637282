import type { User } from "/js/models/User"
import { z } from "zod"
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { ZProductAttachment } from "/js/models/Product"
import { ZUser } from "/js/models/User"
import { ZLinkPreview } from "/js/services/LinkPreviewsApi"

export const ChatRoomTypes = ["direct_room", "channel_room"] as const
export const ChatRoomAccessTypes = ["members_access", "private_access"] as const
export const ChatMessageStates = ["sending", "failed"] as const

export const ZChatRoomUser = z.object({
  id: z.string(),
  chat_room_id: z.string(),
  last_read_message_id: z.string().nullable().optional(),
  user_id: z.string(),
  user: ZUser.optional(),
})

export const ZChatMessageUser = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  avatar_url: z.string().url().nullable(),
  verified: z.boolean().nullable().optional(),
})

export const ZReactionCount = z.object({
  reaction: z.string(),
  count: z.number(),
  native: z.string(),
})

export const ZChatMessage = z.object({
  id: z.string(),
  text: z.string().nullable(),
  created_at: z.date(),
  chat_room_id: z.string(),
  identifier: z.string().optional(),
  user: ZChatMessageUser,
  attachments: ZProductAttachment.array().optional(),
  parent_id: z.string().nullable(),
  replies_count: z.number(),
  latest_reply_users: ZChatMessageUser.array().nullable().optional(),
  reactions: ZReactionCount.array().nullable().optional(),
  link_preview: ZLinkPreview.nullable().optional(),
})

export const ZChatRoomListItem = z.object({
  id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  room_type: z.enum(ChatRoomTypes),
  room_access: z.enum(ChatRoomAccessTypes).nullable(),
  last_message: ZChatMessage.nullable().optional(),
  product_id: z.string().nullable().optional(),
  product_cover_attachment_url: z.string().nullable().optional(),
  latest_users: ZChatMessageUser.array().nullable().optional(),
  direct_users_count: z.number().nullable().optional(),
})

export const ZChatRoom = z.object({
  id: z.string(),
  name: z.string(),
  room_type: z.enum(ChatRoomTypes),
  room_access: z.enum(ChatRoomAccessTypes),
  last_message: ZChatMessage.nullable().optional(),
})

export const ZChatRoomTracking = z.object({
  id: z.string(),
  chat_room_id: z.string(),
  last_read_message_id: z.string().nullable().optional(),
  last_read_message_user_id: z.string().nullable().optional(),
})

export type EmojiMartData = {
  colons: string
  native: string
}

export type ChatRoom = z.infer<typeof ZChatRoom> // obsolete
export type ChatRoomListItem = z.infer<typeof ZChatRoomListItem>
export type ChatRoomUser = z.infer<typeof ZChatRoomUser>
export type ChatMessageUser = z.infer<typeof ZChatMessageUser>
export type ChatMessageState = (typeof ChatMessageStates)[number]
export type ChatMessageBase = z.infer<typeof ZChatMessage>
export type ChatMessage = ChatMessageBase & {
  state?: ChatMessageState | undefined
}
export type ChatRoomTracking = z.infer<typeof ZChatRoomTracking>
export type ChatRoomAccessType = (typeof ChatRoomAccessTypes)[number]
export type ReactionCount = z.infer<typeof ZReactionCount>
