<template>
  <div v-if="localData?.course_units" class="flex flex-col gap-4">
    <div
      v-for="unit in localData.course_units.filter(notNullish)"
      class="card padding flex flex-col gap-4">
      <h5 class="font-medium" v-if="unit.title">{{ unit.title }}</h5>
      <div class="flex flex-col gap-2" v-if="unit.items">
        <div
          v-for="item in unit.items?.filter(notNullish)"
          class="card padding group flex flex-col gap-2 border border-gray-100">
          <h6 v-if="item.title || item.type">
            {{ titleTypeLabel(item.title, item.type) }}
          </h6>
          <p class="text-secondary text-xs" v-if="item.description">{{ item.description }}</p>

          <quiz-question-assistant-message v-if="item.type === 'quiz'" :data="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  learnableTypeLabel,
  type PartialCourseOutline,
} from "/js/components/GptChat/CourseOutline/CourseOutlineModels"
import { notNullish } from "@vueuse/core"
import { ref, watch } from "vue"
import QuizQuestionAssistantMessage from "/js/components/GptChat/GenerateQuizQuestions/QuizQuestionAssistantMessage.vue"

const props = defineProps<{
  data?: PartialCourseOutline
}>()

// this was done so that subsequent changes that could be undefined dont remove content
// this happened due to parsing of the data string into json and not getting a proper object
// in the generation streaming process
// TODO: figure out how to not do this here
const localData = ref(props.data)

watch(
  () => props.data,
  (newData) => {
    if (newData) {
      localData.value = newData
    }
  }
)

const titleTypeLabel = (title?: string | null, type?: string | null) => {
  const typeLabel = type ? learnableTypeLabel(type) : undefined
  return [typeLabel, title].filter(notNullish).join(" - ")
}
</script>

<script lang="ts">
export default {
  name: "CourseOutlineAssistantMessage",
}
</script>
