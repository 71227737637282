<template>
  <span class="inline-flex items-center font-medium whitespace-nowrap px-3 py-1 text-xs" :class="classes">
    <slot></slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue"

type Variant = "light-danger" | "info" | "warning" | "secondary" | "custom"

const props = withDefaults(
  defineProps<{
    variant: Variant
    rounded?: string
  }>(),
  {
    variant: "custom",
    rounded: "rounded-full",
  }
)

const variantClass = computed(() => {
  switch (props.variant) {
    case "light-danger":
      return "bg-red-100 text-red-800"
    case "info":
      return "bg-teal-100 text-teal-800"
    case "warning":
      return "bg-yellow-100 text-yellow-800"
    case "secondary":
      return "bg-gray-100 text-gray-800"
    case "custom":
      return ""
  }
})

const classes = computed(() => {
  return [variantClass.value, props.rounded].join(" ")
})
</script>

<script lang="ts">
export default {
  name: "Badge",
}
</script>