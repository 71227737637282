<template>
  <div class="group relative">
    <headless-cropper-file-dropzone-input
      ref="cropperInput"
      :aspect-ratio="context.aspectRatio"
      :disabled="!!context.disabled"
      @save="croppedFile = $event[0]"
      accept="image"
      #default="{ isOverDropZone, dropzoneError, importFileToCrop }">
      <dropzone-image-preview
        v-if="filePreviewUrl"
        :url="filePreviewUrl"
        :uploading
        :aspect-ratio="context.aspectRatio" />
      <dropzone-view
        v-else
        :assistant
        :over="isOverDropZone"
        :error-message="dropzoneError"
        accept="image">
        <div v-if="assistant" class="flex flex-col items-center gap-2">
          <div class="text-sm italic">or</div>
          <image-generator @image="importFileToCrop([$event])" />
        </div>
      </dropzone-view>
    </headless-cropper-file-dropzone-input>
    <div
      v-if="filePreviewUrl"
      class="invisible absolute inset-0 mx-auto my-auto flex items-center justify-center rounded-lg group-hover:visible group-hover:bg-black group-hover:bg-opacity-50 group-hover:opacity-100">
      <existing-image-actions
        :disabled="!!context.disabled"
        v-if="coverBlobId || filePreviewUrl"
        @remove="removeSavedFile"
        @change="cropperInput?.selectFile()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { useFileUploader } from "/js/components/utilities/FormFields/FileUpload/fileUploader"
import DropzoneImagePreview from "/js/components/utilities/FormFields/FileUpload/UI/DropzoneImagePreview.vue"
import DropzoneView from "/js/components/utilities/FormFields/FileUpload/UI/DropzoneView.vue"
import HeadlessCropperFileDropzoneInput from "/js/components/utilities/FormFields/FileUpload/HelperFields/HeadlessCropperFileDropzoneInput.vue"
import { useFilePreviewUrl } from "/js/components/utilities/FormFields/FileUpload/useFilePreviewUrl"
import ExistingImageActions from "/js/components/utilities/FormFields/FileUpload/UI/ExistingImageActions.vue"
import ImageGenerator from "/js/components/utilities/FormFields/FileUpload/UI/ImageGenerator.vue"

const cropperInput = ref<typeof HeadlessCropperFileDropzoneInput | null>(null)

const props = defineProps<{
  context: any
}>()

const assistant = computed(() => !!props.context.assistant)

// TODO: be able to modify the context to disable the form. ex: when uploading
const coverBlobId = ref<string | null | undefined>(undefined)
const croppedFile = ref<File | undefined>()
const { filePreviewUrl } = useFilePreviewUrl(croppedFile)

watch(
  () => props.context.url,
  (value) => {
    filePreviewUrl.value = value
  },
  { immediate: true }
)

const { fileUploader, uploadFile } = useFileUploader()

const uploading = computed(() => fileUploader.status === "uploading")

const removeSavedFile = () => {
  coverBlobId.value = null
  filePreviewUrl.value = undefined
}

watch(coverBlobId, (value) => {
  props.context.node.input(value)
})

watch(croppedFile, (file, oldValue) => {
  if (file && file !== oldValue) {
    handleSaveFile(file)
  }
})

const handleSaveFile = async (file: File) => {
  try {
    await uploadFile(file, props.context.publicBlob)
    coverBlobId.value = fileUploader.blobId
  } catch (e) {
    fileUploader.status = "failed"
  }
}
</script>

<script lang="ts">
export default {
  name: "FormKitImageUploadInput",
}
</script>
