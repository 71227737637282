<template>
  <content-view
    :is-error="isGoalObjectivesError"
    :is-empty="isGoalObjectivesSuccess && goalObjectives?.length === 0"
    :isLoading="isGoalObjectivesLoading">
    <template #empty>
      <empty-view title="User has not created any public goals yet.">
        <events-empty-icon />
      </empty-view>
    </template>
    <template #error>
      <error-view
        title="Error loading Goals"
        :error="goalObjectivesError"
        action="Try Again"
        @on-action="refetch">
        <events-empty-icon />
      </error-view>
    </template>
    <template #default>
      <div class="flex flex-col gap-4" v-if="!detailsGoalId">
        <button v-for="obj in goalObjectives" :key="obj.id" @click="detailsGoalId = obj.id">
          <div class="card padding flex flex-col gap-4">
            <div class="text-start">
              {{ obj.description }}
            </div>

            <goal-objective-stats :obj class="flex flex-col gap-2" />
          </div>
        </button>
      </div>
      <public-user-goal-details v-else :userId v-model="detailsGoalId"/>
    </template>
  </content-view>
</template>

<script setup lang="ts">
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import EventsEmptyIcon from "/js/components/icons/EventsEmptyIcon.vue"
import { useQuery } from "@tanstack/vue-query"
import { GoalsApi } from "/js/services/GoalsApi"
import { computed, ref } from "vue"
import PublicUserGoalDetails from "/js/components/Goals/PublicUserGoals/PublicUserGoalDetails.vue"
import GoalObjectiveStats from "/js/components/Goals/GoalObjectiveStats.vue"

const props = defineProps<{
  userId: string
}>()

const detailsGoalId = ref<string | undefined>()

const {
  data: goalObjectives,
  isLoading: isGoalObjectivesLoading,
  isError: isGoalObjectivesError,
  isSuccess: isGoalObjectivesSuccess,
  error: goalObjectivesError,
  refetch
} = useQuery({
  queryKey: computed(() => ["goalObjectives", props.userId]),
  queryFn: async () => GoalsApi.getUserGoals(props.userId),
})

</script>

<script lang="ts">
export default {
  name: "PublicUserGoals",
}
</script>