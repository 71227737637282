<template>
  <div
    class="text-primary flex gap-4 rounded-lg p-4"
    :class="{
      'bg-info': cardType === 'info',
      'bg-danger': cardType === 'error',
      'items-center': !description,
      'items-start': !!description,
    }">
    <information-circle-icon class="text-primary h-6 w-6 shrink-0" />

    <div class="flex flex-col gap-2">
      <slot>
        <div class="text-sm font-semibold" v-if="title">
          {{ title }}
        </div>
      </slot>
      <slot name="description">
        <div class="max-w-5xl text-sm" v-if="description">
          {{ description }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from "@heroicons/vue/24/outline"

const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    cardType?: "info" | "error"
  }>(),
  {
    cardType: "info",
  }
)
</script>

<script lang="ts">
export default {
  name: "InfoCard",
}
</script>