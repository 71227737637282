<template>
  <button type="button" class="w-full text-start" @click="emit('click')">
    <formkit-input-wrapper>
      <div class="flex items-center">
        <PaperClipIcon class="mr-2 h-4 w-4" />
        <span class="text-sm">Add Attachment</span>
      </div>
    </formkit-input-wrapper>
  </button>
</template>

<script setup lang="ts">

import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue";
import {PaperClipIcon} from "@heroicons/vue/24/outline";

const emit = defineEmits<{
  click: []
}>()
</script>

<script lang="ts">
export default {
  name: "AttachmentAddButton"
}
</script>