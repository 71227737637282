<template>
  <div class="flex flex-1 flex-col gap-4">
    <flash :type="flash.type" :message="flash.message" v-if="flash" />

    <h3 class="text-primary text-center font-medium">{{ title }}</h3>

    <div v-if="sentMessage" class="text-balance">
      {{ sentMessage }}
    </div>

    <FormKit v-else type="form" :actions="false" #default="{ disabled }" @submit="handleSubmit">
      <div class="text-primary flex flex-col gap-8">
        <div class="text-center">
          {{ message }}
        </div>

        <div class="flex flex-col gap-4">
          <FormKit
            type="text"
            v-model="email"
            autocomplete="off"
            name="email"
            placeholder="Your email address"
            validation-label="Email address"
            validation-visibility="dirty"
            validation="required" />
        </div>

        <div class="flex flex-col gap-4">
          <button type="submit" class="btn btn-primary btn-lg" :disabled="!!disabled">
            {{ buttonTitle }}
          </button>

          <form-kit-messages />
        </div>
      </div>
    </FormKit>

    <div class="lg:flex-1"></div>

    <div class="flex justify-center gap-2">
      <button
        type="button"
        class="primary-link flex-1 basis-1 text-right"
        @click="emit('switchRegistration', 'signIn')">
        Sign In
      </button>
      <span>or</span>
      <button
        type="button"
        class="primary-link flex-1 basis-1 text-left"
        @click="emit('switchRegistration', 'signUp')">
        Register Here
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import type {
  RegistrationType,
  ForgotPasswordType,
} from "/js/components/Registration/RegistrationTypes"
import { FormKitMessages } from "@formkit/vue"
import type { FormKitNode } from "@formkit/core"
import Flash from "/js/components/Registration/Flash.vue"
import { useFlash } from "/js/composables/useFlash"
import { ProfileApi } from "/js/services/ProfileApi"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"

const props = defineProps<{
  type: ForgotPasswordType
}>()

const sentMessage = ref<string | null>(null)

const title = computed(() => {
  switch (props.type) {
    case "forgotPassword":
      return "Forgot password"
    case "resendConfirmation":
      return "Resend confirmation instructions"
  }
})

const message = computed(() => {
  switch (props.type) {
    case "forgotPassword":
      return "Please enter your email address to receive instructions on resetting your password."
    case "resendConfirmation":
      return "Please enter your email address to receive instructions on how to confirm your account."
  }
})

const buttonTitle = computed(() => {
  switch (props.type) {
    case "forgotPassword":
      return "Send Email"
    case "resendConfirmation":
      return "Resend confirmation instructions"
  }
})

const email = ref("")

const flash = useFlash()

const emit = defineEmits<{
  switchRegistration: [value: RegistrationType]
}>()

const handleSubmit = async (formData: Record<string, unknown>, node: FormKitNode) => {
  try {
    switch (props.type) {
      case "forgotPassword":
        sentMessage.value = (await ProfileApi.forgotPassword(email.value)).message
        break
      case "resendConfirmation":
        sentMessage.value = (await ProfileApi.resendConfirmation(email.value)).message
        break
    }
  } catch (e) {
    console.log(e)
    node.setErrors(...getFormKitErrorMessage(e, formData))
  }
}
</script>

<script lang="ts">
export default {
  name: "ConfirmationNew",
}
</script>
