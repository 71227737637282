<template>
  <div :class="[bgClass, loaderClass, 'relative overflow-hidden']">
    <div
      class="absolute bottom-0 left-0 right-0 top-0"
      :style="shimmerStyle"
      :class="{ shimmer: !static }"></div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

type LoaderType = "rectangle" | "circle"
type Props = {
  type?: LoaderType
  bgClass?: string
  cssClass?: string
  shimmerColor?: string
  static?: boolean
}

const LOADER_CSS_CLASSES: Record<LoaderType, string> = {
  rectangle: "rounded-md",
  circle: "rounded-full",
}

const SHIMMER_COLOR = "#ffffff"

const props = withDefaults(defineProps<Props>(), {
  type: "rectangle",
  bgClass: "bg-gray-100",
  cssClass: "",
  shimmerColor: SHIMMER_COLOR,
  static: false,
})

const isHexColor = (hexColor: string) => {
  const hex = hexColor.replace("#", "")
  return hexColor.startsWith("#") && hex.length === 6 && !isNaN(Number("0x" + hex))
}

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`

const shimmerStyle = computed(() => {
  const rgb = isHexColor(props.shimmerColor) ? hexToRgb(props.shimmerColor) : SHIMMER_COLOR

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
  }
})

const loaderClass = computed(() =>
  props.cssClass ? props.cssClass : LOADER_CSS_CLASSES[props.type]
)
</script>
