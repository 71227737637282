<template>
  <div class="object-align mb-4 flex w-full">
    <template v-if="!readOnly">
      <div class="padding w-full rounded-lg bg-gray-50">
        <media-gallery-image-input
          assistant
          modal-type="app"
          :scope="mediaGalleryScope"
          v-model="coverImage">
          <template #actions="{ showGalleryAction }">
            <button type="button" class="btn btn-primary" @click="showGalleryAction">Change</button>
          </template>
          <template #imagePreview="{ url }">
            <div class="group relative" ref="containerRef" :style="containerStyle">
              <img class="h-full w-full object-cover" :src="productAttachmentUrl" alt="img" />

              <button
                @mousedown="startResize"
                class="text-red invisible absolute bottom-0 right-0 cursor-se-resize bg-white p-1 shadow-lg focus:visible active:visible group-hover:visible"
                v-if="!readOnly"
                ref="button">
                <arrow-up-left-icon class="h-4 w-4" />
              </button>
            </div>
          </template>
        </media-gallery-image-input>
      </div>
    </template>

    <div v-else :style="containerStyle">
      <img class="h-full w-full object-cover" :src="productAttachmentUrl" alt="img" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { type ProductAttachment } from "/js/models/Product"
import { ArrowUpLeftIcon } from "@heroicons/vue/20/solid"
import { useEventListener } from "@vueuse/core"
import MediaGalleryImageInput from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGalleryImageInput.vue"
import type { ImagePluginProps } from "/js/components/utilities/Editor/createVueComponentPlugin"
import { useQuery } from "@tanstack/vue-query"
import { ProductsApi } from "/js/services/ProductsApi"
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"

const button = ref<HTMLElement>()
const containerRef = ref<HTMLElement>()

const mediaGalleryScope = computed((): MediaGalleryScope => {
  if (props.editorData?.productId) {
    return { type: "product", accept: "image", id: props.editorData.productId }
  } else {
    return { type: "community", accept: "image" }
  }
})

const props = defineProps<ImagePluginProps>()

const coverImage = computed({
  get: () => props.editorData?.productAttachment,
  set: (value: ProductAttachment | undefined) => {
    props.onUpdate({
      productAttachment: value,
    })
  },
})

const { data: attachmentData } = useQuery({
  queryKey: computed(() => ["productAttachment", props.editorData?.productAttachment?.id]),
  queryFn: async () => {
    if (!props.editorData?.productAttachment) return undefined
    return ProductsApi.getAttachmentUrls([props.editorData.productAttachment.id])
  },
  enabled() {
    return !!props.editorData?.productAttachment
  },
})

const containerStyle = computed(() => {
  return {
    width: `${percentWidthNumber(containerPercent.value)}%`,
  }
})

const productAttachmentUrl = computed(() => {
  return attachmentData.value?.[0]?.url || undefined
})

let resizing = false
let startX = 0
let startWidth = 0

const startResize = (event: MouseEvent) => {
  event.preventDefault()
  event.stopPropagation()

  resizing = true
  startX = event.clientX
  startWidth = containerRef.value?.offsetWidth ?? 0
  useEventListener(document, "mousemove", dragResize)
  useEventListener(document, "mouseup", stopResize)
}

const dragResize = (event: MouseEvent) => {
  if (!resizing) return

  const diffX = event.clientX - startX
  const parentWidth = (containerRef.value?.offsetParent as HTMLElement).offsetWidth
  props.onUpdate({
    width: ((startWidth + diffX) / parentWidth) * 100,
  })
}

const percentWidthNumber = (width: number) => {
  return Math.max(25, Math.min(width, 100))
}

const stopResize = () => {
  resizing = false
}

const containerPercent = computed(() => {
  return props.editorData?.width || 100
})
</script>
