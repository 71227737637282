import { computed, type Ref } from "vue"
import { useDebounce } from "@vueuse/core"

export const useDelayedModel = <T>(model: Ref<T | undefined>, ms?: 200) => {
  const debouncedModel = useDebounce(model, ms)
  
  const currentModel = computed(() => model.value || debouncedModel.value)

  const show = computed({
    get: () => !!model.value,
    set: (value: boolean) => {
      if (!value) {
        model.value = undefined
      }
    },
  })

  return {
    currentModel,
    show,
  }
}
