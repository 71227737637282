<template>
  <div class="flex flex-col">
    <div class="relative">
      <gradient-image
        class="aspect-[21/6] max-h-96 w-full"
        :id="product?.id"
        :url="attachmentImageUrl(product?.cover_attachment)" />
      <div class="absolute inset-0">
        <slot name="gradient-overlay"></slot>
      </div>
    </div>

    <div class="main-content-inset relative -mt-8 flex flex-col gap-4 lg:flex-row">
      <div class="flex flex-1 flex-col items-stretch gap-4 overflow-x-auto lg:w-0 pb-8">
        <learning-hub-overview-bar v-if="product" :product :showProductName>
          <template #right-actionbar>
            <div class="flex gap-4">
              <div v-if="canEdit">
                <router-link
                  :to="{ name: namedRoutes.learning_hubs_overview_edit }"
                  class="btn btn-md btn-primary">
                  Edit Overview Page
                </router-link>
              </div>
              <slot name="right-actionbar" />
            </div>
          </template>
        </learning-hub-overview-bar>

        <div v-if="canEdit && product?.status === 'draft'">
          <info-card
            title="This Learning Hub is currently in draft mode and not visible to Members.">
            <template #description>
              <div class="text-secondary max-w-5xl text-sm">
                <button class="primary-link" @click.stop.prevent="publishProductObject = product">
                  Publish
                </button>
                this Learning Hub when ready to start taking registrations
              </div>
            </template>
          </info-card>
        </div>

        <div v-if="canEdit && product?.status === 'archived'">
          <info-card
            title="This Learning Hub is currently archived and not available to new Members. Existing Members with an active subscription can still access this Learning Hub.">
            <template #description>
              <div class="text-secondary max-w-5xl text-sm">
                <button class="primary-link" @click.stop.prevent="publishProductObject = product">
                  Publish
                </button>
                this Learning Hub to make it available to Members
              </div>
            </template>
          </info-card>
        </div>

        <div v-if="!canEdit && product?.status === 'archived'">
          <info-card
            title="This Learning Hub is currently archived and not available to new Members.">
            <template #description>
              <div class="text-secondary max-w-5xl text-sm">
                Please contact the Community owner.
              </div>
            </template>
          </info-card>
        </div>

        <block-widget-card
          v-for="element in mainItems"
          :element="element"
          :key="element.id"
          placement="main" />
      </div>
    </div>

    <learning-hub-edit-overview-empty-card
      v-if="canEdit && !queryWidgets?.length && !isLoading"
      class="mx-auto py-24" />

    <learning-hub-overview-empty-card
      v-else-if="!queryWidgets?.length && !isLoading"
      class="mx-auto py-24" />

    <learning-hub-publish v-model="publishProductObject" @published="onEventPublished" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import BlockWidgetCard from "/js/components/BlockBuilder/Cards/BlockWidgetCard.vue"
import { type BlockWidget, makeBlockWidgets, type Widget } from "/js/models/Widget"
import { useQuery, useQueryClient } from "@tanstack/vue-query"
import { WidgetsApi } from "/js/services/WidgetsApi"

import { ProductsApi } from "/js/services/ProductsApi"
import InfoCard from "/js/components/utilities/InfoCard.vue"
import { useGate } from "/js/composables/useGate"
import LearningHubPublish from "/js/components/LearningHubs/LearningHubPublish.vue"
import { attachmentImageUrl, type Product } from "/js/models/Product"
import LearningHubOverviewBar from "/js/components/LearningHubs/Overview/LearningHubOverviewBar.vue"
import { namedRoutes } from "/js/router/LearningHubRoutes"
import GradientImage from "/js/components/GradientImage.vue"
import LearningHubEditOverviewEmptyCard from "/js/components/LearningHubs/Overview/LearningHubEditOverviewEmptyCard.vue"
import LearningHubOverviewEmptyCard from "/js/components/LearningHubs/Overview/LearningHubOverviewEmptyCard.vue"
import SidebarSectionTitle from "/js/components/Layout/SidebarSectionTitle.vue"

const props = defineProps<{
  productId: string,
  showProductName?: boolean,
}>()

const { productGateStatus } = useGate()
const queryClient = useQueryClient()

const publishProductObject = ref<Product | undefined>()
const mainItems = ref<BlockWidget[]>([])

const canEdit = computed(() => productGateStatus(props.productId, "product_edit") === "authorized")

const { data: queryWidgets, isLoading } = useQuery({
  queryKey: computed(() => ["product_overview_widgets", props.productId]),
  queryFn: async () => {
    const widgets = await WidgetsApi.getProductWidgets(props.productId, "product_overview")
    mainItems.value = makeBlockWidgets(widgets, "main")
    return widgets
  },
})

const { data: product } = useQuery({
  queryKey: computed(() => ["product", props.productId]),
  queryFn: async () => {
    return await ProductsApi.getProduct(props.productId)
  },
})

const onEventPublished = () => {
  publishProductObject.value = undefined
  queryClient.invalidateQueries({ queryKey: ["product", props.productId] })
}
</script>

<script lang="ts">
export default {
  name: "LearningHubOverview",
}
</script>
