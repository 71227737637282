import { getZod, getZodPaginated, type PaginationData } from "/js/composables/useAxios"
import { ZCommunityUnreadCount, ZNotification } from "/js/models/Notifications"
import type { Notification, CommunityUnreadCount } from "/js/models/Notifications"
import { patch } from "/js/composables/useAxios"
import { makeGetUrl } from "/js/composables/makeQueryString"

export const notificationsListQueryKey = "notificationsList"

export const NotificationsApi = {
  getNotifications: async (page: number): Promise<PaginationData<Notification[]>> => {
    return await getZodPaginated(makeGetUrl(`/api/notifications`, { page }), ZNotification.array())
  },

  markAsRead: async (notificationId: string): Promise<void> => {
    return patch(`/api/notifications/${notificationId}/read`, {})
  },

  markAllAsRead: async (): Promise<void> => {
    return patch(`/api/notifications/read_all`, {})
  },

  getCommunitiesUnreadCount: async (): Promise<CommunityUnreadCount[]> => {
    return await getZod(
      `/api/notifications/communities_unread_count`,
      ZCommunityUnreadCount.array()
    )
  },
}
