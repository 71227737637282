import { useQuery } from '@tanstack/vue-query'
import { CommunitiesApi } from '/js/services/CommunitiesApi'
import { computed } from 'vue'

export const communitiesQueryKey = ['communities']
export const communityQueryKey = (id: string) => ['community', id]

export const useCommunityService = () => {
  const communityId = window.APP_CONFIG.COMMUNITY_ID

  const loadCommunities = () =>
    useQuery({
      queryKey: communitiesQueryKey,
      queryFn: CommunitiesApi.getCommunities,
      staleTime: Infinity,
    })

  const loadCurrentCommunity = (enabled: boolean = true) => {
    return useQuery({
      queryKey: computed(() => communityQueryKey(communityId ?? 'missing-community-id')),
      queryFn: async () => {
        if (communityId) {
          return CommunitiesApi.getCommunity(communityId)
        } else {
          throw new Error('Missing community id')
        }
      },
      staleTime: Infinity,
      enabled,
    })
  }

  return {
    loadCommunities,
    loadCurrentCommunity,
  }
}
