<template>
  <div class="card padding" v-if="goal.visibility === 'public_goal'">
    <comment-list
      :config="{
              type: 'goal_objective',
              goalObjectiveId: goal.id,
            }"
    />
  </div>
</template>

<script setup lang="ts">

import CommentList from "/js/components/Posts/CommentList.vue"
import type { GoalObjective } from "/js/services/GoalsApi"

const props = defineProps<{
  goal: GoalObjective
}>()
</script>

<script lang="ts">
export default {
  name: "GoalObjectiveComments",
}
</script>