<template>
  <TabsRoot class="flex w-full flex-col" :default-value="defaultValue">
    <TabsList class="relative flex shrink-0 border-b" aria-label="Manage">
      <TabsIndicator
        class="absolute bottom-0 left-0 h-[2px] w-[--radix-tabs-indicator-size] translate-x-[--radix-tabs-indicator-position] rounded-full px-8 transition-[width,transform] duration-300">
        <div class="h-full w-full bg-teal-600" />
      </TabsIndicator>
      <template v-for="tab in tabs">
        <slot name="triggerContainer" :tab="tab" :triggerClass="triggerClass">
          <TabsTrigger :class="triggerClass" :value="tab" class="cursor-pointer">
            <slot name="trigger" :tab="tab"></slot>
          </TabsTrigger>
        </slot>
      </template>
    </TabsList>
    <TabsContent
      v-for="tab in props.tabs"
      class="outline-none"
      :value="tab">
      <slot name="content" :tab="tab"></slot>
    </TabsContent>
  </TabsRoot>
</template>

<script setup lang="ts">
import { TabsContent, TabsIndicator, TabsList, TabsRoot, TabsTrigger } from "radix-vue"

const props = defineProps<{
  tabs: string[]
  defaultValue?: string
}>()

const triggerClass =
  "flex h-14 flex-1 cursor-default select-none items-center justify-center rounded-tl-md bg-white px-5 leading-none outline-none data-[disabled]:opacity-25"

</script>

<script lang="ts">
export default {
  name: "TabLayout",
}
</script>
