<template>
  <div class="text-secondary text-xs">
    <span class="flex items-center gap-2" v-if="obj.visibility === 'public_goal'">
      <GlobalIcon class="h-4 w-4" />
      Public
    </span>

    <span class="flex items-center gap-2" v-else-if="obj.visibility === 'private_goal'">
      <LockClosedIcon class="h-4 w-4" />
      Private
    </span>

    <span v-if="obj.due_date" class="flex items-center gap-2">
      <CalendarIcon class="h-4 w-4" />
      Due on {{ formatDate(obj.due_date, "MMM DD, YYYY") }}
    </span>

    <span class="flex items-center gap-2">
      <TasksIcon class="h-4 w-4" />
      {{ obj.tasks_count || 0 }} Completed Task{{ obj.tasks_count === 1 ? "" : "s" }}
    </span>

    <template v-if="obj.visibility === 'public_goal'">
      <span class="flex items-center gap-2">
        <MessageIcon class="h-4 w-4" />
        {{ obj.comments_count || 0 }} Comment{{ obj.comments_count === 1 ? "" : "s" }}
      </span>

      <span class="flex items-center gap-2">
        <SmileyFaceIcon class="h-4 w-4" />
        {{ obj.reactions_count || 0 }} Reaction{{ obj.reactions_count === 1 ? "" : "s" }}
      </span>
    </template>

    <span v-if="obj.due_date && obj.completed_at">•</span>
  </div>
</template>

<script setup lang="ts">
import { formatDate } from "@vueuse/core"
import TasksIcon from "/js/components/icons/TasksIcon.vue"
import MessageIcon from "/js/components/icons/MessageIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import type { GoalObjective } from "/js/services/GoalsApi"
import GlobalIcon from "/js/components/icons/GlobalIcon.vue"
import { LockClosedIcon } from "@heroicons/vue/24/outline"
import SmileyFaceIcon from "/js/components/icons/SmileyFaceIcon.vue"

const props = defineProps<{
  obj: GoalObjective
}>()

</script>

<script lang="ts">
export default {
  name: "GoalObjectiveStats",
}
</script>
