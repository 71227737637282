<template>
  <button class="flex gap-2 text-left" @click="dismiss">
    <round-avatar
      size="sm"
      :id="message.user.id"
      :verified="message.user.verified"
      :avatar-url="message.user.avatar_url"
      :text="initials(message.user)" />

    <div class="flex flex-col">
      <div class="text-sm font-semibold">{{ fullName(message.user) }}</div>
      <div class="text-xs">{{ isAttachment ? "Attachment" : message.text }}</div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { fullName, initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type { ChatMessage, ChatRoomListItem } from "/js/models/Chat"
import { computed } from "vue"
import { toast } from "vue-sonner"
import router from "/js/router/routes"
import { generateChatRoute } from "/js/router/ChatRoutes"

const props = defineProps<{
  message: ChatMessage
  room: ChatRoomListItem | undefined
  dismissId: string | number
}>()

const dismiss = () => {
  toast.dismiss(props.dismissId)
  if (props.room) {
    if (props.room.room_type === "channel_room") {
      router.push(generateChatRoute("channel", "show", { room_id: props.room.id }))
    } else {
      router.push(generateChatRoute("direct", "show", { room_id: props.room.id }))
    }
  }
}

const isAttachment = computed(() => {
  return (props.message.attachments?.length ?? 0) > 0
})
</script>

<script lang="ts">
export default {
  name: "ChatToast",
}
</script>
