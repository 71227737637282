<template>
  <div class="flex items-center justify-between">
    <user-modal :user-id="post.user.id" #default="{ showModal }">
      <div class="flex items-center gap-2">
        <button @click="showModal">
          <user-avatar-view :user="post.user" />
        </button>

        <div class="flex flex-col items-start leading-tight">
          <button @click="showModal">
            <div class="text-sm">{{ fullName(post.user) }}</div>
          </button>
          <div class="text-secondary text-xs">
            {{ formatDate(post.created_at, "MMM D, YYYY - h:mmA") }} in
            <router-link
              class="primary-link"
              :to="{
                name: learningHubRoutes.learning_hubs_posts_index,
                params: { product_id: post.product_id, post_feed_id: post.post_feed_id },
              }">
              {{ post.post_feed_name }}
            </router-link>
          </div>
        </div>
      </div>
    </user-modal>

    <dropdown-menu v-if="canManageProduct">
      <dropdown-menu-item @click="emit('update', post)">Edit Post</dropdown-menu-item>
      <dropdown-menu-item variant="danger" @click="emit('delete', post)">
        Delete Post
      </dropdown-menu-item>
    </dropdown-menu>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials } from "/js/models/User"
import { namedRoutes as learningHubRoutes } from "/js/router/LearningHubRoutes"
import { formatDate } from "@vueuse/core"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import UserModal from "/js/components/Modals/UserModal.vue"
import { computed } from "vue"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { useGate } from "/js/composables/useGate"
import type { Post } from "/js/models/Post"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import UserAvatarView from "/js/components/UserAvatarView.vue"

const props = defineProps<{
  post: Post
}>()

const { productGateStatus } = useGate()

const { data: user } = useCurrentUserService().load()

const emit = defineEmits<{
  delete: [value: Post]
  update: [value: Post]
}>()

const canManageProduct = computed(() => {
  if (!user.value) return false
  if (user.value.id === props.post.user.id) return true
  return productGateStatus(props.post.product_id, "content_manage") === "authorized"
})
</script>

<script lang="ts">
export default {
  name: "PostCardHeader",
}
</script>
