import { del, getZod, getZodPaginated, patchZod, post, postZod } from "/js/composables/useAxios"
import { type Poll, type PollParams, ZAdminPoll, ZPoll } from "/js/models/Poll"
import { z } from "zod"
import { makeGetUrl } from "/js/composables/makeQueryString"

export const pollQueryKey = (pollId: string) => ["poll", pollId]

export const adminPollsListQueryKey = (page: number) => {
  return ["polls_list", page]
}

export type PollableType = "Post"

export type CreatePollParams = {
  poll_params: PollParams
  pollable_type: PollableType
  pollable_id: string
}

export const PollsApi = {
  getPoll: async (pollId: string): Promise<Poll> => await getZod(`/api/polls/${pollId}`, ZPoll),

  createPoll: async (params: CreatePollParams): Promise<Poll> => {
    return await postZod(
      `/api/polls/${params.pollable_type}/${params.pollable_id}`,
      { poll: params.poll_params },
      ZPoll
    )
  },

  updatePoll: async (pollId: string, params: PollParams): Promise<Poll> => {
    return await patchZod(`/api/polls/${pollId}`, { poll: params }, ZPoll)
  },

  submitPollVote: async (pollId: string, pollOptionId: string): Promise<void> => {
    await post(`/api/polls/${pollId}/poll_votes`, { poll_vote: { poll_option_id: pollOptionId } })
  },

  deletePollVote: async (pollId: string, pollOptionId: string): Promise<void> => {
    await del(`/api/polls/${pollId}/poll_votes/${pollOptionId}`)
  },

  getAdminPolls: async (page: number) =>
    await getZodPaginated(makeGetUrl("/api/admin/polls", { page }), z.array(ZAdminPoll)),
}
