<template>
  <router-link :to="route" #default="{ href, isActive, navigate }">
    <a
      :href="href"
      @click="navigate"
      class="flex items-center px-6 py-1 text-sm"
      :class="[
        isActive ? 'text-accent border-accent -ml-px border-l dark:border-white' : 'text-primary',
      ]">
      <slot :isActive="isActive">
        {{ title }}
      </slot>
    </a>
  </router-link>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"

const props = defineProps<{
  route: RouteLocationRaw
  title?: string
}>()
</script>

<script lang="ts">
export default {
  name: "MainSidebarSubnav",
}
</script>
