<template>
  <card-loader title="Content" :loading="isLoading">
    <paged-cards full-width :hide-nav="(editorContents?.length ?? 0) <= 1">
      <truncate-view
        v-for="content in editorContents"
        :key="content.id"
        v-model="previewMode"
        class="editor-minimal readonly relative my-4 z-0">
        <editor-wrapper :initial-data="content.data" readonly />
      </truncate-view>
      <error-view :error="error" v-if="error" title="" />
    </paged-cards>
  </card-loader>
</template>

<script setup lang="ts">
import EditorWrapper from "/js/components/utilities/Editor/EditorWrapper.vue"
import type { ContentWidget } from "/js/models/Widget"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import { useQuery } from "@tanstack/vue-query"
import { computed, ref } from "vue"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import TruncateView from "/js/components/utilities/TruncateView.vue"

const props = defineProps<{
  widget: ContentWidget
  widgetId: string
}>()

const previewMode = ref(props.widget.display_type === "preview")

const {
  data: editorContents,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["content_widget_full_items", props.widgetId]),
  queryFn: async () => await WidgetsApi.getEditorContentFullItems(props.widgetId),
})
</script>

<script lang="ts">
export default {
  name: "ContentCard",
}
</script>
