import { del, get, getPaginated, getZod, patch, post } from "/js/composables/useAxios"
import type { PaginationData } from "/js/composables/useAxios"
import type {
  Product,
  ProductParams,
  ProductTemplate,
  ProductTemplateParams,
} from "/js/models/Product"
import type { User } from "/js/models/User"
import { makeGetUrl, makeQueryString } from "/js/composables/makeQueryString"
import type { AdminUsersFilter, UserGroupMembershipParams } from "/js/services/AdminMembersApi"
import { ZProductTemplate } from "/js/models/Product"

export type AttachmentUrl = {
  id: string
  url: string | null
}

export const ProductsApi = {
  getProducts: async (data?: { items?: number; published?: boolean }): Promise<Product[]> => {
    return await get(`/api/products?` + makeQueryString(data ?? {}))
  },

  getProductTemplates: async (): Promise<ProductTemplate[]> => {
    return await getZod(`/api/product_templates`, ZProductTemplate.array())
  },

  createProduct: async (params: ProductTemplateParams): Promise<Product> => {
    return await post(`/api/product_templates`, { product_template: params })
  },

  getProduct: async (id: string): Promise<Product> => {
    return await get(`/api/products/${id}`)
  },

  getAttachmentUrls: async (ids: string[]): Promise<AttachmentUrl[]> => {
    return await post(`/api/product_attachments/data`, { ids })
  },

  updateProduct: async (id: string, product: ProductParams): Promise<Product> => {
    return await patch(`/api/products/${id}`, { product })
  },

  publishProduct: async (id: string): Promise<Product> => {
    return await patch(`/api/products/${id}/publish`, {})
  },

  archiveProduct: async (id: string): Promise<Product> => {
    return await del(`/api/products/${id}`)
  },

  joinProduct: async (id: string): Promise<void> => await post(`/api/products/${id}/join`),

  getMembers: async (
    productId: string,
    filter: AdminUsersFilter
  ): Promise<PaginationData<User[]>> =>
    await getPaginated(makeGetUrl(`/api/products/${productId}/product_members`, filter)),

  updateMemberRole: async (productId: string, params: UserGroupMembershipParams): Promise<User> =>
    await patch(`/api/products/${productId}/user_group_memberships`, {
      user_group_membership: params,
    }),

  removeMember: async (productId: string, userId: string): Promise<void> =>
    await del(`/api/products/${productId}/user_group_memberships?user_id=${userId}`),

  getMember: async (productId: string, userId: string): Promise<User> =>
    await get(`/api/products/${productId}/product_members/${userId}`),

  updateProductComponentsOrder: async (productId: string, components_order: string[]): Promise<void> =>
    await patch(`/api/products/${productId}/product_components`, {
      product: { components_order },
    }),
}
