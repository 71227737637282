<template>
  <div class="flex h-full flex-col justify-stretch gap-4">
    <headless-cropper-file-dropzone-input
      :aspectRatio
      :multiple
      :accept
      :modalType
      @save="handleStartUploading"
      #default="{ isOverDropZone, dropzoneError, importFileToCrop }">
      <dropzone-view :over="isOverDropZone" :error-message="dropzoneError" :accept>
        <div v-if="assistant" class="flex flex-col items-center gap-2">
          <div class="text-sm italic">or</div>
          <image-generator :modalType @image="importFileToCrop([$event])" />
        </div>
      </dropzone-view>
    </headless-cropper-file-dropzone-input>

    <media-gallery-grid
      v-if="assets.length"
      :assets
      @select="handleSelect"
      @retry="handleRetryAsset" />
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from "vue"

import {
  type Asset,
  type MediaGalleryScope,
  useMediaGallery,
} from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { type ProductAttachment } from "/js/models/Product"
import { type SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import MediaGalleryGrid from "/js/components/utilities/FormFields/FileUpload/UI/MediaGalleryGrid.vue"
import HeadlessCropperFileDropzoneInput from "/js/components/utilities/FormFields/FileUpload/HelperFields/HeadlessCropperFileDropzoneInput.vue"
import DropzoneView from "/js/components/utilities/FormFields/FileUpload/UI/DropzoneView.vue"
import { useQuery } from "@tanstack/vue-query"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import ImageGenerator from "/js/components/utilities/FormFields/FileUpload/UI/ImageGenerator.vue"

type Props = {
  scope: MediaGalleryScope
  autoSubmit?: boolean
  multiple?: boolean
  aspectRatio?: number
  accept: SupportedMedia | SupportedMedia[]
  publicBlob?: boolean
  modalType?: ModalProps["modalType"]
  assistant?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  multiple: true,
  publicBlob: true,
})

const emit = defineEmits<{
  save: [value: ProductAttachment]
  close: []
}>()

const handleSelect = (asset: Asset) => {
  if (asset.type === "productAttachment") {
    emit("save", asset.data)
  }
}

const { assets, clearAssetThumbs, uploadFiles, productAttachments, fetchAttachments, retryUpload } =
  useMediaGallery(props.scope)

const handleRetryAsset = (asset: Asset) => {
  if (asset.type === "fileUploader") {
    retryUpload(asset.data, props.publicBlob)
  }
}

const firstAsset = computed(() => assets.value[0])

const startedUploading = ref(false)

const didAutoSubmit = ref(false)

watch(firstAsset, (value) => {
  if (firstAsset.value?.type === "fileUploader") {
    startedUploading.value = true
  }

  if (!props.autoSubmit) return
  if (!startedUploading.value) return
  if (didAutoSubmit.value) return

  if (value && value.type === "productAttachment") {
    emit("save", value.data)
    didAutoSubmit.value = true
  }
})

const { data: attachments } = useQuery({
  queryKey: computed(() => ["productAttachments", props.scope]),
  queryFn: async () => await fetchAttachments(),
})

watch(attachments, (value) => {
  if (!value) return
  productAttachments.value = value
})

const handleStartUploading = (files: File[]) => {
  console.log("handleStartUploading")
  uploadFiles(files, props.publicBlob)
}

onUnmounted(clearAssetThumbs)
</script>

<script lang="ts">
export default {
  name: "MediaGallery",
}
</script>
