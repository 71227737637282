<template>
  <svg width="214" height="173" viewBox="0 0 214 173" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110.428 142.834C148.038 142.834 178.53 112.343 178.53 74.5983C178.53 36.8536 147.904 6.3623 110.428 6.3623C72.8175 6.3623 42.3262 36.8536 42.3262 74.5983C42.3262 112.343 72.8175 142.834 110.428 142.834Z" fill="#EAEEF9"/>
    <path d="M174.097 29.869C177.139 29.869 179.604 27.4033 179.604 24.3617C179.604 21.3202 177.139 18.8545 174.097 18.8545C171.056 18.8545 168.59 21.3202 168.59 24.3617C168.59 27.4033 171.056 29.869 174.097 29.869Z" fill="#E7EBF6"/>
    <path d="M182.157 8.3773C184.234 8.3773 185.918 6.69343 185.918 4.61626C185.918 2.5391 184.234 0.855225 182.157 0.855225C180.079 0.855225 178.396 2.5391 178.396 4.61626C178.396 6.69343 180.079 8.3773 182.157 8.3773Z" fill="#E7EBF6"/>
    <path d="M45.9534 29.7345C48.0306 29.7345 49.7145 28.0506 49.7145 25.9734C49.7145 23.8963 48.0306 22.2124 45.9534 22.2124C43.8763 22.2124 42.1924 23.8963 42.1924 25.9734C42.1924 28.0506 43.8763 29.7345 45.9534 29.7345Z" fill="#E7EBF6"/>
    <path d="M22.9838 102.269C26.8414 102.269 29.9686 99.1415 29.9686 95.2839C29.9686 91.4263 26.8414 88.2991 22.9838 88.2991C19.1262 88.2991 15.999 91.4263 15.999 95.2839C15.999 99.1415 19.1262 102.269 22.9838 102.269Z" fill="#E7EBF6"/>
    <path d="M110.387 105.005L108.008 104.973C107.532 104.966 107.062 104.484 107.098 103.896C107.134 103.309 107.505 102.81 107.981 102.816L110.361 102.849C110.837 102.855 111.306 103.338 111.27 103.925C111.204 104.624 110.863 105.012 110.387 105.005Z" fill="#CED7E2"/>
    <g filter="url(#filter0_d_775_41821)">
      <path d="M99.6156 42.3059L128.471 101.555C129.436 103.608 128.584 105.995 126.531 106.959L68.3214 134.45C66.2687 135.414 63.8818 134.562 62.9174 132.51L26.747 55.8254C25.7825 53.7726 26.6347 51.3858 28.6874 50.4213L70.8153 30.5835L99.6156 42.3059Z" fill="url(#paint0_linear_775_41821)"/>
    </g>
    <path d="M70.8154 30.5833L78.5308 47.0053C79.6505 49.2643 82.5333 50.26 84.7923 49.1402L99.6157 42.3057" fill="#D5DDEA"/>
    <g filter="url(#filter1_d_775_41821)">
      <path d="M150.729 48.2109L151.564 114.108C151.561 116.376 149.773 118.172 147.505 118.169L83.1304 118.213C80.8624 118.21 79.0668 116.421 79.0698 114.153L79.053 29.3664C79.056 27.0984 80.8446 25.3028 83.1126 25.3058L129.678 25.3265L150.729 48.2109Z" fill="url(#paint1_linear_775_41821)"/>
    </g>
    <path d="M129.678 25.3267L129.654 43.4708C129.703 45.9916 131.886 48.1215 134.407 48.072L150.729 48.2111" fill="#D5DDEA"/>
    <g filter="url(#filter2_d_775_41821)">
      <path d="M191.239 77.4378L164.319 137.663C163.423 139.695 160.919 140.585 158.917 139.578L100.595 112.684C98.5629 111.788 97.6732 109.284 98.569 107.252L134.09 30.3152C134.986 28.2834 137.49 27.3936 139.492 28.401L181.842 47.9645L191.239 77.4378Z" fill="url(#paint2_linear_775_41821)"/>
    </g>
    <path d="M181.73 47.935L174.14 64.4357C173.044 66.7728 174.21 69.5888 176.547 70.6848L191.239 77.4378" fill="#D5DDEA"/>
    <defs>
      <filter id="filter0_d_775_41821" x="4.35059" y="19.5835" width="146.517" height="148.263" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_41821"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_41821" result="shape"/>
      </filter>
      <filter id="filter1_d_775_41821" x="57.0527" y="14.3059" width="116.512" height="136.907" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_41821"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_41821" result="shape"/>
      </filter>
      <filter id="filter2_d_775_41821" x="76.2207" y="16.9844" width="137.019" height="156.01" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_775_41821"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_775_41821" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_775_41821" x1="68.3728" y1="28.6375" x2="87.4193" y2="132.195" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_775_41821" x1="128.298" y1="22.5247" x2="101.366" y2="124.317" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_775_41821" x1="160.94" y1="31.5324" x2="133.787" y2="134.161" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
    </defs>
  </svg>
</template>