<template>
  <div class="flex flex-wrap items-center gap-2">
    <button
      v-for="reaction in reactions"
      :key="reaction.reaction"
      type="button"
      @click="handleReaction(reaction)"
      class="btn-zoom rounded-full bg-white px-3 py-1">
      <users-hover-card
        :reaction="reaction.reaction"
        :reactionsCount="reaction.count"
        :reactionableId
        :reactionableType>
        <div class="emoji-reset flex items-center gap-1">
          <div>{{ reaction.count }}</div>
          <emoji-colon :colon-code="reaction.reaction" />
        </div>
      </users-hover-card>
    </button>

    <UserReactionsAdd
      v-if="!hideAdd"
      @toggleReaction="emit('toggleReaction', $event)"
      buttonClass="btn-zoom flex gap-2 rounded-full bg-white px-3 py-1" />
  </div>
</template>

<script setup lang="ts">
import EmojiColon from "/js/components/Chat/EmojiColon.vue"
import type { EmojiMartData, ReactionCount } from "/js/models/Chat"
import UserReactionsAdd from "/js/components/UserReactions/UserReactionsAdd.vue"
import type { UserReactionableType } from "/js/services/UserReactionsApi"
import UsersHoverCard from "/js/components/UserReactions/UsersHoverCard.vue"

defineProps<{
  reactions: ReactionCount[] | undefined
  hideAdd?: boolean
  reactionableType: UserReactionableType
  reactionableId: string
}>()

const emit = defineEmits<{
  toggleReaction: [value: EmojiMartData]
}>()

const handleReaction = (reaction: ReactionCount) => {
  emit("toggleReaction", {
    colons: reaction.reaction,
    native: reaction.native,
  })
}
</script>

<script lang="ts">
export default {
  name: "UserReactionsList",
}
</script>
