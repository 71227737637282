<template>
  <loading-view :loading="isLoading">
    <dropdown-tag-input-select
      v-model:search-term="searchTerm"
      v-model="selectedProductsRef"
      :placeholder="placeholder"
      :display-name="(p) => p.name"
      :multiple="multiple"
      :options="filteredProducts">
      <template #option="{ option: product }">
        <div class="flex items-center gap-2">
          <round-avatar
            :avatar-url="attachmentImageUrl(product.cover_attachment, { preferThumb: true })"
            :id="product.id"
            size="sm"
            no-text
            rounded="rounded-lg" />
          <div class="flex flex-1 flex-col">
            <div class="text-sm">{{ product.name }}</div>
            <div class="text-secondary text-xs">
              {{ formattedProductDate(product) }}
            </div>
          </div>
        </div>
      </template>
    </dropdown-tag-input-select>

    <error-message :error="productsError" />
  </loading-view>
</template>

<script setup lang="ts">
import RoundAvatar from "/js/components/RoundAvatar.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import DropdownTagInputSelect from "/js/components/utilities/FormFields/DropdownTagInputSelect.vue"
import LoadingView from "/js/components/LoadingView.vue"
import { useQuery } from "@tanstack/vue-query"
import { productListQueryKey } from "/js/services/useProductService"
import { ProductsApi } from "/js/services/ProductsApi"
import { computed, onMounted, ref, watch } from "vue"
import { attachmentImageUrl, type Product } from "/js/models/Product"
import { formatDate } from "@vueuse/core"

const props = defineProps<{
  multiple?: boolean
}>()

const productIds = defineModel<string | string[] | undefined>()

const {
  data: products,
  isLoading,
  error: productsError,
} = useQuery({
  queryKey: productListQueryKey({ published: true }),
  queryFn: async () => await ProductsApi.getProducts({ published: true }),
  refetchOnMount: false,
})

const selectedProductsRef = ref<Product | Product[] | undefined>(undefined)

watch(products, () => setupFieldData())

const placeholder = computed(() => {
  if (props.multiple) {
    return "Select Learning Hubs"
  } else {
    return "Select a Learning Hub"
  }
})

const setupFieldData = () => {
  if (!productIds.value || !products.value) {
    return
  }

  if (Array.isArray(productIds.value)) {
    selectedProductsRef.value = products.value.filter((p) => productIds.value?.includes(p.id))
  } else {
    selectedProductsRef.value = products.value.find((p) => p.id === productIds.value)
  }
}

watch(
  selectedProductsRef,
  () => {
    if (!selectedProductsRef.value) return

    if (Array.isArray(selectedProductsRef.value)) {
      // todo: this will set an empty array when multiple = false and clearing the field. should be undefined?
      if (!props.multiple) {
        productIds.value = undefined
        return
      }
      productIds.value = selectedProductsRef.value.map((p) => p.id)
    } else {
      productIds.value = selectedProductsRef.value.id
    }
  },
  { deep: true }
)

onMounted(setupFieldData)

const searchTerm = ref("")

const filteredProducts = computed(() => {
  if (!searchTerm.value || searchTerm.value === "") {
    return products.value ?? []
  }

  return (
    products.value?.filter((p) => {
      return p.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    }) ?? []
  )

  // console.log({ term: searchTerm.value, filtered: filtered.map(f => f.name), products: products.value?.map(f => f.name) })
})

const formattedProductDate = (product: Product | undefined) => {
  if (!product) return ""
  if (product.start_date && product.end_date) {
    console.log({ start: product.start_date, end: product.end_date })
    try {
      return `${formatDate(product.start_date, "MMM DD, YYYY")} - ${formatDate(
          product.end_date,
          "MMM DD, YYYY"
      )}`
    } catch (e) {
      console.error(e)
      console.log(`start date: ${product.start_date instanceof Date}`)
      console.log({ product })
    }
  }
  return "No fixed duration"
}
</script>

<script lang="ts">
export default {
  name: "MultiProductSelect",
}
</script>