<template>
  <hover-card @open="onHoverCardOpen" :closeDelay="0" :openDelay="500">
    <template #trigger>
      <slot></slot>
    </template>

    <content-view :isError :isEmpty="isSuccess && users?.length === 0" :isLoading>
      <template #empty>
        <div class="flex h-20 items-center justify-center">
          <span class="text-gray-500">No users found</span>
        </div>
      </template>
      <template #error>
        <div class="flex h-20 items-center justify-center">
          <span class="text-red-500">{{ error }}</span>
        </div>
      </template>
      <template #loading>
        <div class="flex flex-col gap-2">
          <skeleton class="h-4 w-64"></skeleton>
          <skeleton class="h-4 w-64"></skeleton>
          <skeleton class="h-4 w-64"></skeleton>
        </div>
      </template>
      <template #default>
        <div class="flex flex-col gap-2">
          <div v-for="user in users" class="flex items-center gap-2">
            <round-avatar
              :id="user.id"
              size="xs"
              :verified="user.verified"
              :avatar-url="user.avatar_url"
              :text="initials(user)" />
            {{ user.first_name }} {{ user.last_name }}
          </div>

          <div v-if="otherUsersCountLabel" class="text-gray-500">{{ otherUsersCountLabel }}</div>
        </div>
      </template>
    </content-view>
  </hover-card>
</template>

<script setup lang="ts">
import HoverCard from "/js/components/HoverCard.vue"
import { useQuery } from "@tanstack/vue-query"
import { computed, ref } from "vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import Skeleton from "/js/components/Skeleton.vue"
import { type UserReactionableType, UserReactionsApi } from "/js/services/UserReactionsApi"
import { initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"

const props = defineProps<{
  reaction: string
  reactionsCount: number
  reactionableType: UserReactionableType
  reactionableId: string
}>()

let hoverCardOpen = ref(false)

const {
  data: users,
  isLoading,
  isError,
  error,
  isSuccess,
} = useQuery({
  queryKey: computed(() => [
    "userReactionUsers",
    props.reaction,
    props.reactionableType,
    props.reactionableId,
  ]),
  queryFn: async () => UserReactionsApi.getUsers({
    reactionableId: props.reactionableId,
    reactionableType: props.reactionableType,
    reaction: props.reaction,
  }),
  enabled: computed(() => !!hoverCardOpen.value),
})

const onHoverCardOpen = (open: boolean) => {
  hoverCardOpen.value = open
}

const otherUsersCountLabel = computed(() => {
  if (isSuccess && users.value) {
    const usersCount = users.value.length
    const reactionsCount = props.reactionsCount

    if (usersCount < reactionsCount) {
      return `+${reactionsCount - usersCount} other${usersCount - reactionsCount > 1 ? "s" : ""}`
    }
  }

  return undefined
})

</script>

<script lang="ts">
export default {
  name: "UsersHoverCard",
}
</script>
