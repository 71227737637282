<template>
  <formkit-input-wrapper class="relative">
    <TagsInput.Root
      v-model="modelSelectedValues"
      class="flex flex-wrap items-center gap-2 rounded-full">
      <TagsInput.Item
        v-for="option in modelSelectedValues"
        :key="option"
        :value="option"
        class="flex items-center justify-center gap-2 rounded-full bg-gray-400 px-4 py-1 text-white aria-[current=true]:bg-gray-700">
        <TagsInput.ItemText class="text-sm" />

        <TagsInput.ItemDelete>
          <x-mark-icon class="h-4 w-4" />
        </TagsInput.ItemDelete>
      </TagsInput.Item>

      <TagsInput.Input
        @blur="handleBlur"
        :placeholder
        asChild
        class="placeholder:text-secondary min-w-[150px] flex-1 rounded !bg-transparent px-1 focus:outline-none">
        <input v-model="inputModel" class="w-full" type="text" />
      </TagsInput.Input>
    </TagsInput.Root>
  </formkit-input-wrapper>
</template>

<script setup lang="ts">
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"
import { TagsInput } from "radix-vue/namespaced"
import { computed, ref } from "vue"
import { XMarkIcon } from "@heroicons/vue/24/outline"

const props = defineProps<{
  placeholder?: string
}>()

const modelSelectedValues = defineModel<string[]>()

const inputModel = ref("")

const emit = defineEmits<{
  blur: [value: string | undefined]
}>()

const handleBlur = () => {
  emit("blur", inputModel.value)
  inputModel.value = ""
}

</script>

<script lang="ts">
export default {
  name: "TagInputSelect",
}
</script>
