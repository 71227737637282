<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-30" @close="closeBackdrop" :initialFocus>
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex items-start min-h-full justify-center text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              :class="dialogPanelClass"
              class="relative flex transform flex-col p-4 text-left transition-all">
              <div
                class="flex flex-grow flex-col rounded-lg bg-white shadow-xl"
                :class="[noPadding ? 'p-0' : 'p-9']">
                <div
                  class="flex items-center justify-between"
                  v-if="!hideTopBar"
                  :class="[noPadding ? 'p-4' : 'pb-9']">
                  <div>
                    <slot name="title"></slot>
                    <template v-if="title">
                      <div class="text-lg font-medium">{{ title }}</div>
                    </template>
                  </div>
                  <icon-button @click="handleClose">
                    <x-mark-icon class="h-6 w-6" />
                  </icon-button>
                </div>

                <slot v-bind="{ close: handleClose }" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue"
import { XMarkIcon } from "@heroicons/vue/20/solid"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import IconButton from "/js/components/IconButton.vue"

const props = withDefaults(defineProps<ModalProps>(), {
  modelValue: false,
  hideTopBar: false,
  noBackdropClose: false,
  sizeType: "default",
  confirmModalClose: false,
})

const open = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const dialogPanelClass = computed(() => {
  switch (props.sizeType) {
    case "fullScreen":
      return "h-screen w-screen max-w-10xl"
    case "default":
      return "w-full sm:my-8 sm:max-w-6xl"
    case "auto":
      return "w-fit"
    default:
      return ""
  }
})

const emit = defineEmits<{
  "update:modelValue": [open: boolean]
}>()

const closeBackdrop = () => {
  if (!props.noBackdropClose) {
    handleClose()
  }
}

const handleClose = () => {
  if (props.confirmModalClose) {
    if (confirm("You have unsaved changes. Are you sure you want to leave?")) {
      open.value = false
    }
  } else {
    open.value = false
  }
}
</script>
