<template>
  <template v-if="featureFlags.newLayout">
    <div class="flex h-full flex-col">
      <div
        class="flex items-center justify-between border-b border-gray-100 bg-slate-50 px-4 py-1.5 dark:border-ll-green-700 lg:hidden">
        <schools-nav class="h-8 w-8" />

        <button
          type="button"
          class="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
          @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <mobile-navbar-transition no-transition v-model="sidebarOpen">
        <div class="flex h-full gap-2 rounded-r-lg bg-gray-50 p-2">
          <div class="flex h-full flex-col py-2">
            <small-sidebar-v2 />
          </div>
          <div class="z-10 rounded-lg bg-white shadow-lg" id="mobile-main-sidebar"></div>
        </div>
      </mobile-navbar-transition>

      <div class="flex min-h-0 flex-1 gap-2 p-2 lg:h-full">
        <div class="hidden flex-col py-2 lg:flex">
          <small-sidebar-v2 />
        </div>
        <slot name="main"></slot>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="bg-primary flex h-full">
      <mobile-navbar-transition v-model="sidebarOpen">
        <delay-view>
          <sidebar />
        </delay-view>
      </mobile-navbar-transition>

      <!-- Static sidebar for desktop -->
      <sidebar class="hidden border-r border-gray-100 lg:flex lg:flex-shrink-0" />

      <!--  Main Area  -->
      <div class="flex min-w-0 flex-1 flex-col">
        <!-- Mobile Navbar / Logo, Button -->
        <div
          class="flex items-center justify-between border-b border-gray-100 bg-slate-50 px-4 py-1.5 dark:border-ll-green-700 lg:hidden">
          <logo class="h-8 w-auto" />

          <button
            type="button"
            class="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
            @click="sidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div class="relative flex flex-1">
          <main class="bg-primary relative flex-1 overflow-y-auto focus:outline-none">
            <!-- Start main area -->
            <div class="absolute inset-0 flex flex-col">
              <slot name="main" />
            </div>
            <!-- End main area -->
          </main>

          <slot name="rightside" />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { Bars3Icon } from "@heroicons/vue/20/solid"
import Logo from "/js/components/icons/Logo.vue"
import MobileNavbarTransition from "/js/components/Layout/MobileNavbarTransition.vue"
import Sidebar from "/js/components/Layout/Sidebar.vue"
import DelayView from "/js/components/Chat/DelayView.vue"
import { featureFlags } from "/js/composables/useFeatureFlags"
import SmallSidebarV2 from "/js/components/Layout/Sidebar/SmallSidebarV2.vue"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import { useSidebarLayout } from "/js/components/Layout/useSidebarLayout"

const { sidebarOpen } = useSidebarLayout()
</script>

<script lang="ts">
export default {
  name: "MainLayout",
}
</script>
