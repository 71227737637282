<template>
  <div class="flex justify-center px-6">
    <div class="card my-16 max-w-2xl p-6">
      <h1 class="mb-4 text-2xl font-medium">Customize Your Learning Community</h1>
      <p class="">Let's setup some community details</p>
      <p class="note">Don't worry, you can change this later!</p>

      <FormKit type="form" :actions="false" #default="{ disabled }" @submit="handleSubmit">
        <div class="mt-6 space-y-4">

          <FormKit
            type="text"
            v-model="communityParams.name"
            name="name"
            label="Community Name"
            help="Pick a community name people will remember!"
            validation="required" />

          <FormKit
            type="text"
            label="Domain"
            name="domain"
            v-model="communityParams.domain"
            @change="domainManual = true"
            validation="required">
            <template #suffix>
              <span class="px-4 text-gray-500">.{{ currentDomain }}</span>
            </template>
          </FormKit>

          <FormKit
              :type="FormKitImageUploadInputType"
              v-model="communityParams.logo"
              :publicBlob="true"
              :aspect-ratio="1"
              label="Community Logo" />

          <FormKit
              :type="FormKitImageUploadInputType"
              v-model="communityParams.cover"
              :aspect-ratio="16 / 9"
              label="Community Cover photo" />

          <FormKit name="access" v-model="access" label="Access">
            <template #inner>
              <div class="grid grid-cols-2 gap-4">
                <radio-custom-select
                  v-model="access"
                  :value="option.value"
                  #default="{ selected, select }"
                  v-for="option in communityAccessOptions">
                  <selectable-button
                    :icon="option.icon"
                    :disabled="!!disabled"
                    @select="select"
                    :description="option.description"
                    :text="option.text"
                    :selected="selected" />
                </radio-custom-select>
              </div>
            </template>
          </FormKit>

          <button class="btn btn-primary" type="submit" :disabled="!!disabled">
            Create Community
          </button>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script setup lang="ts">
import {reactive, ref, watch} from "vue"
import RadioCustomSelect from "/js/components/utilities/FormFields/RadioCustomSelect.vue"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import { CommunitiesApi } from "/js/services/CommunitiesApi"
import { type FormKitNode } from "@formkit/core"
import {type CommunityAccessType, type CommunityParams} from "/js/models/Community"
import SelectableButton from "/js/components/utilities/FormFields/SelectableButton.vue"
import { FormKitImageUploadInputType } from "/js/components/utilities/FormFields/FormKitImageUploadInputObjects"
import {communityAccessOptions} from "/js/composables/communityAccessOptions";
import {getMainDomain} from "/js/composables/communityDomain";

const domainManual = ref(false)
const currentDomain = getMainDomain()
const access = ref<CommunityAccessType>("private_community")

const communityParams = reactive<CommunityParams>({
  name: "",
  domain: "",
  cover: undefined,
  logo: undefined,
  access: "private_community",
})

watch(communityParams, (value) => {
  // replace domain value with a lowercase with dashes
  if (!domainManual.value) {
    communityParams.domain = communityParams.name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "")
  }
})

const handleSubmit = async (formData: any, node: FormKitNode) => {
  try {
    node.clearErrors()
    const community = await CommunitiesApi.createCommunity(communityParams)
    window.location.href = community.community_url
  } catch (e) {
    node.setErrors(...getFormKitErrorMessage(e, formData))
  }
}


</script>

<script lang="ts">
export default {
  name: "OnboardingApp",
}
</script>
