<template>
  <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
    <g><path style="opacity:1" fill="currentColor" d="M 123.5,21.5 C 124.376,21.3691 125.043,21.7025 125.5,22.5C 127.327,27.834 129.994,32.6673 133.5,37C 138.088,38.6935 142.421,40.8602 146.5,43.5C 142.246,45.756 137.912,47.9227 133.5,50C 129.855,54.7894 126.855,59.9561 124.5,65.5C 121.731,60.9607 119.231,56.2941 117,51.5C 112.02,48.9302 107.186,46.2635 102.5,43.5C 107.218,40.7222 112.051,38.0555 117,35.5C 119.454,30.9272 121.621,26.2605 123.5,21.5 Z"/></g>
    <g><path style="opacity:1" fill="currentColor" d="M 195.5,49.5 C 197.989,49.2978 200.323,49.7978 202.5,51C 207.333,55.8333 212.167,60.6667 217,65.5C 218.373,67.6954 218.706,70.0288 218,72.5C 165.167,125.333 112.333,178.167 59.5,231C 56.1195,231.938 53.1195,231.271 50.5,229C 46.3333,224.833 42.1667,220.667 38,216.5C 36,213.167 36,209.833 38,206.5C 90.3949,153.938 142.895,101.605 195.5,49.5 Z"/></g>
    <g><path style="opacity:1" fill="currentColor" d="M 61.5,51.5 C 63.0174,51.5106 64.1841,52.1772 65,53.5C 68.1356,61.7695 71.8023,69.7695 76,77.5C 77.5578,79.3589 79.3911,80.8589 81.5,82C 88.8333,85.3333 96.1667,88.6667 103.5,92C 104.337,93.0113 104.67,94.1779 104.5,95.5C 95.4823,98.5082 86.8157,102.341 78.5,107C 75.9426,109.892 73.776,113.059 72,116.5C 69.3922,122.829 66.8922,129.163 64.5,135.5C 63.1779,135.67 62.0113,135.337 61,134.5C 58,127.5 55,120.5 52,113.5C 50.2068,110.705 48.0402,108.205 45.5,106C 36.8224,102.328 28.4891,98.1612 20.5,93.5C 28.4891,88.8388 36.8224,84.6721 45.5,81C 47,79.5 48.5,78 50,76.5C 53.8475,68.1405 57.6808,59.8072 61.5,51.5 Z"/></g>
    <g><path style="opacity:1" fill="#fff" d="M 197.5,54.5 C 202.965,59.4636 208.298,64.6303 213.5,70C 205.652,78.0145 197.652,85.8479 189.5,93.5C 184.167,88.8333 179.167,83.8333 174.5,78.5C 182.188,70.4794 189.855,62.4794 197.5,54.5 Z"/></g>
    <g><path style="opacity:1" fill="currentColor" d="M 174.5,156.5 C 175.376,156.369 176.043,156.702 176.5,157.5C 178.798,162.751 181.464,167.751 184.5,172.5C 189.469,174.984 194.469,177.484 199.5,180C 195.112,182.861 190.446,185.194 185.5,187C 181.762,192.319 178.429,197.819 175.5,203.5C 172.86,199.421 170.694,195.088 169,190.5C 167.833,189.333 166.667,188.167 165.5,187C 160.888,185.361 156.554,183.194 152.5,180.5C 157.469,177.226 162.635,174.226 168,171.5C 170.729,166.712 172.896,161.712 174.5,156.5 Z"/></g>
  </svg>
</template>

