<template>
  <div
    class="flex flex-col items-center justify-between border-r border-gray-100 dark:border-ll-green-700">
    <div class="flex h-full flex-col items-center gap-6">
      <div class="border-b border-gray-100 p-4 dark:border-ll-green-700">
        <schools-nav class="h-11 w-11" />
      </div>

      <router-link :to="{ name: 'assistant' }" class="btn-zoom" v-tooltip="'Zixie Chat'">
        <WizardIcon class="h-6 w-6" />
      </router-link>

      <button type="button" class="btn-zoom text-primary" @click="featureFlags.sidebarV2 = !featureFlags.sidebarV2">
        <help-icon class="h-6 w-6"></help-icon>
      </button>

      <template v-if="false">
        <!-- Section not available -->
        <button type="button" class="btn-zoom text-primary">
          <magnifier-icon class="h-6 w-6"></magnifier-icon>
        </button>

        <button type="button" class="btn-zoom text-primary">
          <bell-icon class="h-6 w-6"></bell-icon>
        </button>

        <button type="button" class="btn-zoom text-primary">
          <help-icon class="h-6 w-6"></help-icon>
        </button>
      </template>

      <div class="grow"></div>

      <router-link :to="{ name: 'profile' }" class="btn-zoom mb-8">
        <round-avatar
          size="sm"
          :id="currentUser?.id"
          :verified="currentUser?.verified"
          :avatar-url="currentUser?.avatar_url"
          :text="initials(currentUser)" />
      </router-link>
    </div>

    <template v-if="false">
      <!-- Section not available -->
      <div class="flex flex-col gap-2 p-4">
        <button
          type="button"
          class="btn btn-primary btn-sm text-xs"
          @click="tourService.resetTour.mutate()">
          RT
        </button>
        <dark-mode-switch />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { initials } from "/js/models/User"
import WizardIcon from "/js/components/icons/WizardIcon.vue"
import HelpIcon from "/js/components/icons/HelpIcon.vue"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import MagnifierIcon from "/js/components/icons/MagnifierIcon.vue"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import BellIcon from "/js/components/icons/BellIcon.vue"
import DarkModeSwitch from "/js/components/Layout/DarkModeSwitch.vue"
import { useTourService } from "/js/services/useTourService"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { featureFlags } from "/js/composables/useFeatureFlags"

const { data: currentUser } = useCurrentUserService().load()

const tourService = useTourService()

</script>

<script lang="ts">
export default {
  name: "SmallSidebar",
}
</script>
