<template>
  <div :class="innerClasses">
    <div :class="inputClasses" class="rounded">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import template from "/js/tailwind-theme"

const inputClasses = template.global.input + ' ' + template["family:text"].input
const innerClasses = template.global.inner + ' ' + template["family:text"].inner

</script>

<script lang="ts">
export default {
  name: "FormkitInputWrapper"
}
</script>