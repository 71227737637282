export const SupportedMediaTypes = ["image", "video", "document", "archive", "other"] as const
export type SupportedMedia = (typeof SupportedMediaTypes)[number]

export const SupportedMediaLabels: Record<SupportedMedia, string> = {
  image: "Images",
  video: "Videos",
  document: "Documents",
  archive: "Archives",
  other: "Other",
}

const extensions: Record<SupportedMedia, string[]> = {
  image: [".jpg", ".jpeg", ".png"],
  video: [".mp4"],
  document: [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"],
  archive: [".zip", ".rar", ".tar", ".gz", ".7z"],
  other: [],
}

const mimeTypes: Record<SupportedMedia, string[]> = {
  image: ["image/jpeg", "image/png", "image/gif", "image/jpg"],
  video: ["video/mp4"],
  document: [
    "application/pdf",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
  ],
  archive: [
    "application/zip",
    "application/x-rar-compressed",
    "application/x-tar",
    "application/gzip",
    "application/x-7z-compressed",
  ],
  other: [],
}

export const strictMediaSupportedMimeTypes = (
  media: SupportedMedia | SupportedMedia[]
): string[] => {
  if (typeof media === "string") {
    return mimeTypes[media]
  } else {
    return media.flatMap((m) => mimeTypes[m])
  }
}

export const mediaSupportedMimeTypes = (
  media: SupportedMedia | SupportedMedia[] | undefined
): string[] | undefined => {
  if (!media) return undefined
  return strictMediaSupportedMimeTypes(media)
}

export const mediaSupportedExtensions = (
  media: SupportedMedia | SupportedMedia[] | undefined
): string[] => {
  if (!media) return []
  if (typeof media === "string") {
    return extensions[media] || []
  } else {
    const types = media.flatMap((m) => extensions[m] || [])
    return types.length ? types : []
  }
}

export const supportedMediaForFile = (file: File): SupportedMedia => {
  if (file.type.startsWith("image")) {
    return "image"
  } else if (file.type.startsWith("video")) {
    return "video"
  } else if (strictMediaSupportedMimeTypes("archive").includes(file.type)) {
    return "archive"
  } else {
    return "document"
  }
}
