<template>
  <paged-cards>
    <template #title>
      <h4 class="font-medium">{{ title }}</h4>
    </template>
    <div
      class="card flex flex-col gap-4 border-2 border-gray-100 p-4"
      v-if="posts.length > 0"
      v-for="post in posts"
      :key="post.id">
      <div class="flex items-center gap-2">
        <user-avatar-view :user="post.user" />
        <div class="flex flex-col">
          <div>{{ fullName(post.user) }}</div>
          <div class="text-secondary text-xs" v-if="post.start_date">
            {{ formatDate(post.start_date, "MMM D, YYYY - h:mmA") }}
          </div>
        </div>
      </div>

      <router-link
        class="flex grow flex-col gap-2"
        :to="{
          name: learningHubRoutes.learning_hubs_posts_show,
          params: {
            post_id: post.id,
            product_id: post.product_id,
            post_feed_id: post.post_feed_id,
          },
        }">
        <div class="grow"></div>
        <h5 class="line-clamp-1">{{ post.title }}</h5>
      </router-link>

      <post-likes
        :likes-count="post.post_likes_count"
        :comments-count="post.comments_count"
        :liked="post.followed_by_current_user"
        @follow="emit('follow', post)" />
    </div>
    <empty-view title="No posts found" v-else>
      <feed-empty-icon class="h-40 w-40" />
    </empty-view>
  </paged-cards>
</template>

<script setup lang="ts">
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import type { Post } from "/js/models/Post"
import PostLikes from "/js/components/Posts/PostLikes.vue"
import { fullName } from "/js/models/User"
import { formatDate } from "@vueuse/core"
import FeedEmptyIcon from "/js/components/icons/FeedEmptyIcon.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import { namedRoutes as learningHubRoutes } from "/js/router/LearningHubRoutes"
import UserAvatarView from "/js/components/UserAvatarView.vue"

withDefaults(
  defineProps<{
    posts: Post[]
    title?: string
  }>(),
  {
    title: "Posts",
  }
)

const emit = defineEmits<{
  follow: [value: Post]
  comment: [value: Post]
}>()
</script>

<script lang="ts">
export default {
  name: "FeedCarouselCard",
}
</script>
