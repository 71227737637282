function hashUUID(uuid: string) {
  let hash = 0;

  for (let i = 0; i < uuid.length; i++) {
    let char = uuid.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}

function gradientClass(uuid: string) {
  let hashedValue = hashUUID(uuid);
  let gradientNumber = Math.abs(hashedValue % 10) + 1;  // Convert to a number between 1 and 10

  return `gradient-${gradientNumber}`;
}

function labelClass(label: string, colors: string[]) {
  let hashedValue = hashUUID(label);
  let colorIndex = Math.abs(hashedValue % colors.length);
  return colors[colorIndex]
}

export { gradientClass, labelClass };