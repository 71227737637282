<template>
  <div
    class="flex flex-col gap-4 rounded-lg border border-gray-200 p-6 shadow-sm"
    :class="{ 'opacity-50': actionable === 'disabled' }">
    <h2 class="text-center text-lg font-medium leading-6 text-gray-900">{{ plan.name }}</h2>

    <div class="flex h-20 flex-col gap-2 text-center text-4xl font-extrabold text-gray-900">
      ${{ priceValue?.toFixed(2) }}
    </div>

    <hr />

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between">
        <h3 class="text-xs font-medium uppercase tracking-wide text-gray-900">
          One Time Purchase
        </h3>

        <plan-products-hover-card :plan-id="plan.id">
          <button
            :disabled="actionable === 'disabled'"
            type="button"
            v-if="plan.product_ids.length > 0"
            class="btn btn-secondary btn-xs self-center rounded-full px-4">
            1 Learning Hub
          </button>
        </plan-products-hover-card>
      </div>
    </div>

    <div class="flex-1"></div>

    <div v-if="confirmPlan" class="flex w-full gap-4 bg-amber-50">
      <button @click="emit('cancel')" type="button" class="btn btn-secondary flex-1">Cancel</button>
      <button @click="emit('confirmPrice', price)" type="button" class="btn btn-primary flex-1">
        Confirm
      </button>
    </div>
    <button
      v-else
      @click="emit('price', price)"
      type="button"
      class="btn btn-primary flex items-center gap-4"
      :disabled="actionable !== 'selectable' || !!loadingStatus">
      <template v-if="loadingStatus === 'loading'">
        Please Wait
        <spinner class="h-4 w-4 text-white" />
      </template>
      <template v-else>
        {{ buttonTitle }}
      </template>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { StripePlan, StripePrice } from "/js/services/PlansApi"
import { computed } from "vue"
import PlanProductsHoverCard from "/js/components/Subscriptions/PlanProductsHoverCard.vue"
import Spinner from "/js/components/icons/Spinner.vue"
import type { PlanSelectorActionable } from "/js/components/Subscriptions/PlanSelectorTypes"

const props = defineProps<{
  plan: StripePlan
  price: StripePrice
  actionable: PlanSelectorActionable
  confirmPlan: boolean
  loadingStatus: "loading" | "disabled" | undefined
}>()

const emit = defineEmits<{
  price: [value: StripePrice]
  confirmPrice: [value: StripePrice]
  cancel: []
}>()

const buttonTitle = computed(() => {
  if (props.actionable === "current") return "Purchased"
  if (props.actionable === "disabled") return "Unavailable"
  return "Purchase"
})

const priceValue = computed(() => {
  const price = props.price
  if (!price) return undefined
  return (price?.unit_amount ?? 0) / 100
})
</script>

<script lang="ts">
export default {
  name: "OneTimeSelectorCard",
}
</script>
