<template>
  <span v-if="disableInternalLinks" class="text-accent">
    {{ productTitle }}
  </span>
  <router-link
    v-else
    @click.stop="handleLinkClick()"
    :to="{
      name: namedRoutes.learning_hubs_show,
      params: { product_id: productId },
    }"
    class="primary-link">
    {{ productTitle }}
  </router-link>
</template>

<script setup lang="ts">
import { namedRoutes } from "/js/router/LearningHubRoutes"

const props = defineProps<{
  productTitle: string
  productId: string
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()

const handleLinkClick = () => {
  if (!props.disableInternalLinks) {
    emit('clickProduct')
  }
}

</script>

<script lang="ts">
export default {
  name: "OptionBoxProductPrimaryLink",
}
</script>
