<template>
  <div class="flex flex-col gap-4">
    <div
      class="flex max-h-[50vh] min-h-[30vh] flex-1 flex-col gap-4 overflow-y-auto rounded-lg border border-gray-200 p-4">
      <gpt-scroller :data="messages" :generating>
        <template v-for="message in messages">
          <div v-if="message.role === 'user'" class="max-w-2/3 self-end text-right">
            <markdown-text :text="message.content" class="prose rounded-lg bg-gray-200 p-2" />
          </div>

          <div v-else class="max-w-[80%] text-left">
            <assistant-message
              :disabled="assistantMessagesDisabled"
              :message
              @generate="handleGenerate" />
          </div>
        </template>
      </gpt-scroller>
    </div>

    <formkit-input-wrapper>
      <autogrow-textarea ref="promptRef" v-model="prompt" @submit="handleSubmit" :placeholder />
    </formkit-input-wrapper>

    <div class="flex justify-end">
      <button type="submit" class="btn btn-primary" :disabled="sendDisabled" @click="handleSubmit">
        <span v-if="generating">
          <spinner class="h-5 w-5" />
        </span>
        <span v-else>Send</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import {
  type CreateChatParams,
  GptApi,
  type GptMessage,
  isGptMessageActionableType,
  isGptMessageGeneratorType,
} from "/js/services/GptApi"
import { getFormKitErrorMessage } from "/js/composables/getErrorMessage"
import { toastError } from "/js/composables/toast"
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"
import Spinner from "/js/components/icons/Spinner.vue"
import MarkdownText from "/js/components/utilities/MarkdownText.vue"
import { useGptChat } from "/js/components/GptChat/useGptChat"
import AssistantMessage from "/js/components/GptChat/AssistantMessage.vue"
import AutogrowTextarea from "/js/components/utilities/AutogrowTextarea.vue"
import GptScroller from "/js/components/GptChat/GptScroller.vue"

const props = defineProps<{
  params?: CreateChatParams
  defaultPrompt?: string
  placeholder?: string
  scrollerClass?: string
}>()

const emit = defineEmits<{
  generated: [message: GptMessage]
}>()

const promptRef = ref<HTMLElement>() // used to set initial focus / pass to modal

const { generating, sendPrompt, messages } = useGptChat()

const processingMessage = ref(false)

const prompt = ref<string>(props.defaultPrompt || "")

const sendDisabled = computed(() => !prompt.value || prompt.value.length === 0 || generating.value)

const assistantMessagesDisabled = computed(() => generating.value || processingMessage.value)

const handleSubmit = async () => {
  try {
    await sendPrompt(prompt.value, props.params)
    prompt.value = ""
  } catch (e) {
    toastError(e)
    console.log(e)
  }
}

const handleGenerate = async (message: GptMessage) => {
  const isGenerator = isGptMessageGeneratorType(message)
  const isActionable = isGptMessageActionableType(message)

  if (isGenerator) {
    try {
      processingMessage.value = true
      await GptApi.generateFromMessage(message.id)
      emit("generated", message)
    } catch (e) {
      toastError(e)
    } finally {
      processingMessage.value = false
    }
  } else if (isActionable) {
    emit("generated", message)
  }
}
</script>

<script lang="ts">
export default {
  name: "GptChatProcessViewInner",
}
</script>
