<template>
  <svg width="150" height="35" viewBox="0 0 150 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.1235 25.5242C52.1235 25.8316 52.3335 26.0365 52.6485 26.0365H59.4735C59.7885 26.0365 59.9985 25.8316 59.9985 25.5242V24.2437C59.9985 23.9363 59.7885 23.7314 59.4735 23.7314H55.011C54.8535 23.7314 54.7485 23.629 54.7485 23.4753V8.62063C54.7485 8.31329 54.5385 8.1084 54.2235 8.1084H52.6485C52.3335 8.1084 52.1235 8.31329 52.1235 8.62063V25.5242Z" fill="black"/>
    <path d="M61.839 8.62063V25.5242C61.839 25.8316 62.049 26.0365 62.364 26.0365H69.189C69.504 26.0365 69.714 25.8316 69.714 25.5242V24.2437C69.714 23.9363 69.504 23.7314 69.189 23.7314H64.7265C64.569 23.7314 64.464 23.629 64.464 23.4753V17.8408C64.464 17.6871 64.569 17.5847 64.7265 17.5847H68.6902C69.0052 17.5847 69.2152 17.3798 69.2152 17.0724V15.7919C69.2152 15.4845 69.0052 15.2796 68.6902 15.2796H64.7265C64.569 15.2796 64.464 15.1772 64.464 15.0235V10.6695C64.464 10.5159 64.569 10.4134 64.7265 10.4134H69.189C69.504 10.4134 69.714 10.2085 69.714 9.9012V8.62063C69.714 8.31329 69.504 8.1084 69.189 8.1084H62.364C62.049 8.1084 61.839 8.31329 61.839 8.62063Z" fill="black"/>
    <path d="M71.8896 26.0365H73.4909C73.7534 26.0365 73.9634 25.8572 73.9896 25.6011L74.3834 22.7838C74.4096 22.6301 74.5409 22.5277 74.6984 22.5277H78.6358C78.7933 22.5277 78.9246 22.6301 78.9246 22.7838L79.3183 25.6011C79.3708 25.8572 79.5808 26.0365 79.8433 26.0365H81.4446C81.812 26.0365 82.022 25.806 81.9695 25.4474L79.6071 8.54379C79.5546 8.28768 79.3446 8.1084 79.0821 8.1084H74.2521C73.9896 8.1084 73.7796 8.28768 73.7271 8.54379L71.3646 25.4474C71.3121 25.806 71.5221 26.0365 71.8896 26.0365ZM74.7509 20.2226L76.1421 10.3878C76.1421 10.2598 76.2471 10.1573 76.4046 10.1573H76.9296C77.0608 10.1573 77.1921 10.2598 77.1921 10.3878L78.5833 20.2226C78.6096 20.3763 78.5046 20.4788 78.3471 20.4788H74.9871C74.8296 20.4788 74.7246 20.3763 74.7509 20.2226Z" fill="black"/>
    <path d="M84.1411 25.5242C84.1411 25.8316 84.3511 26.0365 84.6661 26.0365L86.7661 26.0621H87.5536C92.3048 26.0365 94.221 21.7337 94.221 16.97C94.221 12.488 92.3048 8.1084 87.5536 8.1084H84.6661C84.3511 8.1084 84.1411 8.31329 84.1411 8.62063V25.5242ZM86.7661 23.5009V10.6695C86.7661 10.5159 86.8711 10.4134 87.0286 10.4134H87.5273C90.7035 10.4134 91.596 14.0247 91.596 17.0468C91.596 20.1714 90.7035 23.757 87.5273 23.757H87.0286C86.8711 23.757 86.7661 23.6546 86.7661 23.5009Z" fill="black"/>
    <path d="M96.0612 8.62063V25.5242C96.0612 25.8316 96.2712 26.0365 96.5862 26.0365H103.411C103.726 26.0365 103.936 25.8316 103.936 25.5242V24.2437C103.936 23.9363 103.726 23.7314 103.411 23.7314H98.9487C98.7912 23.7314 98.6861 23.629 98.6861 23.4753V17.8408C98.6861 17.6871 98.7912 17.5847 98.9487 17.5847H102.912C103.227 17.5847 103.437 17.3798 103.437 17.0724V15.7919C103.437 15.4845 103.227 15.2796 102.912 15.2796H98.9487C98.7912 15.2796 98.6861 15.1772 98.6861 15.0235V10.6695C98.6861 10.5159 98.7912 10.4134 98.9487 10.4134H103.411C103.726 10.4134 103.936 10.2085 103.936 9.9012V8.62063C103.936 8.31329 103.726 8.1084 103.411 8.1084H96.5862C96.2712 8.1084 96.0612 8.31329 96.0612 8.62063Z" fill="black"/>
    <path d="M106.558 26.0365H108.133C108.448 26.0365 108.658 25.8316 108.658 25.5242V21.8106C108.658 21.6569 108.763 21.5544 108.92 21.5544H109.97L110.574 21.5032C110.732 21.5032 110.863 21.5801 110.915 21.7337L112.28 25.6779C112.359 25.9084 112.543 26.0365 112.779 26.0365H114.302C114.722 26.0365 114.932 25.7547 114.8 25.3706L113.252 20.9142C113.199 20.7605 113.252 20.6068 113.383 20.5044C114.905 19.4031 115.85 17.3798 115.85 14.4344C115.85 9.92682 113.409 8.1084 109.97 8.1084H106.558C106.243 8.1084 106.033 8.31329 106.033 8.62063V25.5242C106.033 25.8316 106.243 26.0365 106.558 26.0365ZM108.92 10.4134H109.944C111.808 10.4134 113.225 11.4891 113.225 14.4857C113.225 17.6359 111.808 19.2238 109.944 19.2238H108.92C108.763 19.2238 108.658 19.1213 108.658 18.9677V10.6695C108.658 10.5159 108.763 10.4134 108.92 10.4134Z" fill="black"/>
    <path d="M117.953 25.5242C117.953 25.8316 118.163 26.0365 118.478 26.0365H125.303C125.618 26.0365 125.828 25.8316 125.828 25.5242V24.2437C125.828 23.9363 125.618 23.7314 125.303 23.7314H120.841C120.683 23.7314 120.578 23.629 120.578 23.4753V8.62063C120.578 8.31329 120.368 8.1084 120.053 8.1084H118.478C118.163 8.1084 117.953 8.31329 117.953 8.62063V25.5242Z" fill="#0E927A"/>
    <path d="M127.747 26.0365H129.349C129.611 26.0365 129.821 25.8572 129.847 25.6011L130.241 22.7838C130.267 22.6301 130.399 22.5277 130.556 22.5277H134.494C134.651 22.5277 134.782 22.6301 134.782 22.7838L135.176 25.6011C135.229 25.8572 135.439 26.0365 135.701 26.0365H137.302C137.67 26.0365 137.88 25.806 137.827 25.4474L135.465 8.54379C135.412 8.28768 135.202 8.1084 134.94 8.1084H130.11C129.847 8.1084 129.637 8.28768 129.585 8.54379L127.222 25.4474C127.17 25.806 127.38 26.0365 127.747 26.0365ZM130.609 20.2226L132 10.3878C132 10.2598 132.105 10.1573 132.262 10.1573H132.787C132.919 10.1573 133.05 10.2598 133.05 10.3878L134.441 20.2226C134.467 20.3763 134.362 20.4788 134.205 20.4788H130.845C130.687 20.4788 130.582 20.3763 130.609 20.2226Z" fill="#0E927A"/>
    <path d="M140.524 26.0365H143.936C146.456 26.0365 150 25.4986 150 21.0166C150 18.4298 148.609 16.7139 147.086 15.3308C146.981 15.2284 146.981 15.0747 147.086 14.9723C148.005 14.1783 148.688 13.1539 148.688 11.8989C148.688 9.6707 147.559 8.1084 143.91 8.1084H140.524C140.235 8.1084 139.999 8.23646 139.999 8.69746V25.5242C139.999 25.8316 140.209 26.0365 140.524 26.0365ZM142.624 23.4753V17.7127C142.624 17.5334 142.729 17.4054 142.86 17.3285C143.254 17.1749 143.884 16.9188 144.593 16.5858C144.724 16.509 144.908 16.5346 145.013 16.637C146.194 17.7127 147.375 19.1726 147.375 20.9398C147.375 23.9619 144.855 23.7314 143.91 23.7314H142.886C142.729 23.7314 142.624 23.629 142.624 23.4753ZM142.624 14.5369V10.6695C142.624 10.5159 142.729 10.4134 142.886 10.4134H143.884C145.275 10.4134 146.063 10.6952 146.063 11.8989C146.063 13.0514 144.225 14.0503 142.86 14.6649C142.729 14.7418 142.624 14.6906 142.624 14.5369Z" fill="#0E927A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4852 6.00574C14.4852 9.32262 17.2211 12.0115 20.596 12.0115C23.9709 12.0115 26.7068 9.32262 26.7068 6.00574C26.7068 2.68886 23.9709 0 20.596 0C17.2211 0 14.4852 2.68886 14.4852 6.00574Z" fill="#0E927A"/>
    <path d="M0.000144958 15.4778C9.15586 11.2035 16.964 16.3809 19.7889 19.4904V34.4328C19.7744 34.4322 19.7599 34.4317 19.7453 34.4311C18.4943 31.8807 14.1166 27.7301 6.2646 28.7603C2.67537 25.3554 0.33865 20.6837 0.000144958 15.4778Z" fill="#0E927A"/>
    <path d="M20.9207 34.4456C22.1857 31.8481 26.7087 27.5696 34.8593 28.8245C38.4926 25.4091 40.8585 20.7019 41.1934 15.4534C32.0062 11.2233 24.2163 16.3868 21.1148 19.4904L20.8868 34.4461C20.8981 34.4459 20.9094 34.4457 20.9207 34.4456Z" fill="#0E927A"/>
  </svg>

</template>
