<template>
  <div ref="dropZoneRef">
    <slot v-bind="{ isOverDropZone, dropzoneError: error }" />
  </div>
</template>

<script setup lang="ts">
import { useDropZone } from "@vueuse/core"
import { computed, ref } from "vue"
import {
  type SupportedMedia,
  mediaSupportedExtensions,
  mediaSupportedMimeTypes, SupportedMediaTypes,
} from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"

const dropZoneRef = ref<HTMLElement>()

const props = defineProps<{
  multiple?: boolean
  disabled?: boolean
  accept?: SupportedMedia | SupportedMedia[]
}>()

const error = ref<string | undefined>()

const emit = defineEmits<{
  files: [value: File[]]
}>()

const supportedMediaTypes = computed((): SupportedMedia[] => {
  if (!props.accept) return [...SupportedMediaTypes]
  if (Array.isArray(props.accept)) {
    return props.accept
  } else {
    return [props.accept]
  }
})

const supportedExtensions = computed(() =>
  mediaSupportedExtensions(supportedMediaTypes.value).join(", ")
)

const supportedMimeTypes = computed(() => mediaSupportedMimeTypes(supportedMediaTypes.value))

const onDrop = (droppedFiles: File[] | null) => {

  console.log(droppedFiles?.map((file) => file.name.split(".").pop()))

  if (props.disabled) return
  if (!droppedFiles) return
  error.value = undefined

  let files

  if (!props.multiple && droppedFiles.length > 0) {
    files = [droppedFiles[0]!]
  } else {
    files = droppedFiles
  }

  const supportedFiles = files.filter((file) => supportedMimeTypes.value?.includes(file.type))
  if (supportedFiles.length === 0) {
    if (supportedExtensions.value.length) {
      error.value = `Only ${supportedExtensions.value} files are allowed`
    } else {
      const extensions = files.map((file) => file.name.split(".").pop())
      error.value = `${extensions.join(", ")} files are not allowed`
    }
    return
  }

  if (supportedFiles.length) {
    emit("files", supportedFiles)
  }
}

const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)
</script>

<script lang="ts">
export default {
  name: "HeadlessDropzoneInput",
}
</script>