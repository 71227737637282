<template>
  <slot v-bind="{ openSearch }"></slot>
  <modal v-model="showSearch" no-padding hide-top-bar size-type="auto">
    <div class="relative mx-auto h-8 w-full max-w-4xl lg:min-w-[500px]">
      <div class="rounded-lg bg-white">
        <Combobox.Root
          v-model="selectedSearchResult"
          v-model:open="openModel"
          :filter-function="(list, term) => list"
          v-model:search-term="query">
          <Combobox.Anchor
            :class="[openModel ? 'rounded-t-lg' : 'rounded-lg']"
            class="text-primary data-[placeholder]:text-secondary inline-flex w-full cursor-pointer items-center justify-between gap-2 rounded-t-lg bg-gray-200 px-2 py-1 leading-none outline-none transition-colors">
            <TagsInput.Root
              v-model="selectedFilterOptions"
              class="flex w-full flex-wrap items-center gap-2 rounded-full">
              <TagsInput.Item
                v-for="option in selectedFilterOptions"
                :key="option"
                :value="option"
                class="btn btn-outline btn-xs bg-white aria-[current=true]:opacity-70">
                <TagsInput.ItemDelete class="flex items-center gap-2">
                  {{ searchResultTypeLabel(option) }}
                  <close-icon class="h-2 w-2" />
                </TagsInput.ItemDelete>
              </TagsInput.Item>

              <Combobox.Input as-child>
                <TagsInput.Input
                  placeholder="Search..."
                  @focus="openModel = true"
                  class="placeholder:text-secondary min-w-[180px] flex-1 rounded !bg-transparent px-1 focus:outline-none"
                  @keydown.enter.prevent />
              </Combobox.Input>
            </TagsInput.Root>

            <Combobox.Cancel v-if="openModel || query?.length">
              <CloseIcon class="h-3 w-3" />
            </Combobox.Cancel>
            <Combobox.Trigger v-else>
              <MagnifierIcon class="h-4 w-4" />
            </Combobox.Trigger>
          </Combobox.Anchor>
          <Combobox.Content class="max-h-[250px] w-full overflow-y-auto rounded-lg">
            <Combobox.Viewport class="flex flex-col gap-2 !overflow-visible p-2">
              <Combobox.Label>
                <div class="flex flex-wrap gap-2" v-if="availableFilterOptions.length">
                  <button
                    v-for="option in availableFilterOptions"
                    @click="selectedFilterOptions.push(option)"
                    class="btn btn-outline btn-xs">
                    {{ searchResultTypeLabel(option) }}
                  </button>
                </div>
              </Combobox.Label>

              <Combobox.Empty
                v-if="query?.length"
                class="py-2 text-center text-xs font-medium text-gray-400" />

              <Combobox.Group class="flex flex-col gap-1">
                <Combobox.Item
                  v-for="option in opts"
                  :key="`${option.type}-${option.id}`"
                  :value="option"
                  class="text-primary flex select-none items-center rounded-lg px-2 py-2 text-sm leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-200 data-[state=checked]:bg-gray-100 data-[disabled]:text-gray-400 data-[highlighted]:outline-none">

                  <navigation-option-box :option @clickProduct="openModel = false" />

                </Combobox.Item>
              </Combobox.Group>
            </Combobox.Viewport>
          </Combobox.Content>
        </Combobox.Root>
      </div>
    </div>
  </modal>
  <user-modal v-if="userModel" :user-id="userModel" v-model="showUserModal" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import {
  type NavigationResourceType,
  ZNavigationResourcesTypes,
  type NavigationResource,
  navigationResourceRoute, searchResultTypeLabel,
} from "/js/components/NavigationResources/NavigationResourcesTypes"
import CloseIcon from "/js/components/icons/CloseIcon.vue"
import { Combobox, TagsInput } from "radix-vue/namespaced"
import MagnifierIcon from "/js/components/icons/MagnifierIcon.vue"
import { useQuery } from "@tanstack/vue-query"
import { useDebounce } from "@vueuse/core"
import { useRouter } from "vue-router"
import UserModal from "/js/components/Modals/UserModal.vue"
import { useDelayedModel } from "/js/composables/useDelayedModel"
import Modal from "/js/components/Modals/Modal.vue"
import { SearchApi } from "/js/services/SearchApi"
import NavigationOptionBox from "/js/components/GlobalSearch/NavigationOptionBox.vue"

const userModalId = ref<string | undefined>()
const { show: showUserModal, currentModel: userModel } = useDelayedModel(userModalId, 200)

const router = useRouter()

const openModel = defineModel<boolean>("open", { required: false, default: false })
const query = defineModel<string>()

const selectedFilterOptions = ref<NavigationResourceType[]>([])
const allFilterOptions = ZNavigationResourcesTypes

const showSearch = defineModel<boolean>("showSearch", { required: false, default: false })
const openSearch = () => {
  showSearch.value = true
}

const params = computed(() => {
  return {
    query: query.value,
    selectedFilterOptions: selectedFilterOptions.value,
  }
})

const debouncedParams = useDebounce(params, 300)

const { data } = useQuery({
  queryKey: computed(() => ["global-search", debouncedParams.value]),
  queryFn: async () => {
    const p = debouncedParams.value
    if (!p) {
      return []
    }
    if (!p.query || !p.query.length) {
      return []
    }

    return SearchApi.search(p.query, p.selectedFilterOptions)
  },
})

const opts = computed(() => {
  if (!data.value) {
    return []
  }
  return [...data.value]
})

const selectedSearchResult = ref<NavigationResource | undefined>()

watch(query, () => {
  if (!query.value || query.value.length === 0) {
    selectedSearchResult.value = undefined
  }
})

const availableFilterOptions = computed(() => {
  return allFilterOptions.filter((option) => !selectedFilterOptions.value.includes(option))
})

const resetSearch = () => {
  query.value = ""
  selectedFilterOptions.value = []
  selectedSearchResult.value = undefined
  openModel.value = false
  showSearch.value = false
}

watch(selectedSearchResult, (val) => {
  if (val) {
    resetSearch()

    if (val.type === "User") {
      userModalId.value = val.object.id
    } else {
      const route = navigationResourceRoute(val)
      if (route) {
        router.push(route)
      }
    }
  }
})

watch(openModel, (val) => {
  if (!val) {
    resetSearch()
  }
})
</script>

<script lang="ts">
export default {
  name: "GlobalSearchField",
}
</script>
