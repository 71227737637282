import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { useServiceHelpers } from "/js/services/useServiceHelpers"
import type { Product, ProductParams } from "/js/models/Product"
import { ProductsApi } from "/js/services/ProductsApi"

type UpdateProductMutationParams = {
  productId: string
  product: ProductParams
}

// TODO: remove communityId from query keys
export const productListQueryKey = (data?: {}) => {
  return ["product_list", data ?? {}]
}

export const productQueryKey = (id: string) => {
  return ["product", id]
}

export const productMembersQueryKey = (productId: string) => {
  return ["product_members", productId]
}

export const useProductService = () => {
  const queryClient = useQueryClient()
  const { syncItem, optimisticDelete } = useServiceHelpers(queryClient)

  const cancelProductQueries = async (productId: string) => {
    await queryClient.cancelQueries({ queryKey: productQueryKey(productId) })
    await queryClient.cancelQueries({ queryKey: productListQueryKey() })
  }

  const invalidateProductQueries = async (productId: string) => {
    await queryClient.invalidateQueries({ queryKey: productQueryKey(productId) })
    await queryClient.invalidateQueries({ queryKey: productListQueryKey() })
  }

  const updateProduct = useMutation({
    mutationFn: async ({ productId, product }: UpdateProductMutationParams) => {
      return await ProductsApi.updateProduct(productId, product)
    },
    onSuccess: (data, variables) => {
      if (data) {
        syncProduct(data)
      }
    },
  })

  const publishProduct = useMutation({
    mutationFn: async (productId: string) => {
      return await ProductsApi.publishProduct(productId)
    },
    onSuccess: async (product: Product) => {
      return invalidateProductQueries(product.id)
    },
  })

  const archiveProduct = useMutation({
    mutationFn: async (productId: string) => {
      return await ProductsApi.archiveProduct(productId)
    },
    onSuccess: async (product: Product) => {
      return invalidateProductQueries(product.id)
    },
  })

  const syncProduct = (product: Product) => {
    syncItem(
      [
        { key: productListQueryKey(), type: "list" },
        { key: productQueryKey(product.id), type: "single" },
      ],
      product
    )
  }

  return {
    updateProduct,
    publishProduct,
    archiveProduct,
  }
}
