<template>
  <popover-menu :portal="false" button-class="btn-zoom" v-model="emojiPickerOpen">
    <template #button>
      <slot name="button">
        <div :class="buttonClass">
          <SmileyFaceIcon class="h-5 w-5" v-tooltip="'Add Reaction'" />
        </div>
      </slot>
    </template>
    <PopoverContent class="!z-50">
      <emoji-picker @select="handleEmojiMartReaction" />
    </PopoverContent>
  </popover-menu>
</template>

<script setup lang="ts">

import { PopoverContent } from "radix-vue"
import PopoverMenu from "/js/components/PopoverMenu.vue"
import SmileyFaceIcon from "/js/components/icons/SmileyFaceIcon.vue"
import EmojiPicker from "/js/components/utilities/EmojiPicker.vue"
import type { EmojiMartData } from "/js/models/Chat"

const emojiPickerOpen = defineModel<boolean>({ default: false })

const props = defineProps<{
  buttonClass?: string
}>()

const emit = defineEmits<{
  toggleReaction: [reaction: EmojiMartData]
}>()

const handleEmojiMartReaction = (reaction: EmojiMartData) => {
  emojiPickerOpen.value = false
  emit("toggleReaction", reaction)
}
</script>

<script lang="ts">
export default {
  name: "UserReactionsAdd",
}
</script>