<template>
  <div class="group flex h-full w-full items-center gap-3 text-start" v-if="community">
    <schools-nav class="h-10 w-10" v-if="featureFlags.sidebarV2" />
    <community-logo :community class="!w-10" v-else />

    <span class="flex-1 truncate text-sm text-gray-700 dark:text-white">
      {{ community.name }}
    </span>

    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import CommunityLogo from "/js/components/Layout/CommunityLogo.vue"
import type { Community } from "/js/models/Community"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import { featureFlags } from "/js/composables/useFeatureFlags"

defineProps<{
  community: Community
}>()
</script>

<script lang="ts">
export default {
  name: "CommunityNavButtonInner",
}
</script>
