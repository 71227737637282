<template>
  <div v-for="user in users">
    <user-modal :user-id="user?.id" #default="{ showModal }">
      <button @click="showModal" class="flex items-center gap-4 text-left">
        <round-avatar
          :avatar-url="user.avatar_url"
          :id="user.id"
          :verified="user.verified"
          :text="initials(user)"
          size="sm2" />
        <div class="flex flex-col">
          <div class="text-sm font-medium">{{ fullName(user) }}</div>
          <div class="text-secondary line-clamp-2 text-xs whitespace-pre-line">{{ user.description }}</div>
        </div>
      </button>
    </user-modal>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials, type User } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import UserModal from "/js/components/Modals/UserModal.vue"

defineProps<{
  // TODO: this should render a simpler version of the user. See widget_members_controller.rb
  users: User[]
}>()
</script>

<script lang="ts">
export default {
  name: "MembersCard",
}
</script>
