import EditorJS, { type EditorConfig } from "@editorjs/editorjs"
//@ts-ignore
import Undo from "editorjs-undo"
//@ts-ignore
import DragDrop from "editorjs-drag-drop"
import { ref } from "vue"
import { nanoid } from "nanoid"
import type { OutputData } from "@editorjs/editorjs/types/data-formats/output-data"

export type EditorDataOutput = OutputData

export const useEditorBuilder = () => {
  const holder = `vue-editor-js-${nanoid()}`

  const buildingEditor = ref(false)

  const initEditor = async (editorConfig: EditorConfig) => {
    if (buildingEditor.value) {
      return
    }

    buildingEditor.value = true
    destroyEditor()
    editor.value = new EditorJS({
      onReady() {
        new Undo({ editor: editor.value })
        new DragDrop(editor.value)
      },
      holder,
      ...editorConfig,
    })

    await editor.value.isReady
    buildingEditor.value = false
  }

  const destroyEditor = () => {
    if (editor.value && editor.value.destroy) {
      try {
        editor.value.destroy()
      } catch (e) {
        console.log(e)
      }
      editor.value = null
    }
  }

  const editor = ref<EditorJS | null>(null)

  return {
    holder,
    editor,
    initEditor,
    destroyEditor,
  }
}
