<template>
  <loading-view :loading="isLoading">
    <div class="bg-white">
      <modal v-model="showStripeModal" size-type="fullScreen">
        <div id="stripe-modal-id"></div>
      </modal>
      <div class="mx-auto flex max-w-7xl flex-col gap-8 px-4 py-24 sm:px-6 lg:px-8">
        <div class="sm:align-center sm:flex sm:flex-col">
          <h1 class="text-center text-3xl font-extrabold text-gray-900">
            {{ subscription ? "Change" : "Choose" }} your Subscription Plan
          </h1>
          <p class="mx-auto mt-5 max-w-2xl text-center text-xl text-gray-500">
            Want to learn new skills? We have the right plans for you. Take the challenge and start
            learning new skills
          </p>
        </div>

        <div class="mx-auto flex justify-center gap-4" v-if="showRecurringInterval">
          <button
            @click="recurringInterval = 'year'"
            class="btn"
            :class="[recurringInterval === 'year' ? 'btn-primary' : 'btn-secondary']">
            Annual
          </button>
          <button
            @click="recurringInterval = 'month'"
            class="btn"
            :class="[recurringInterval === 'month' ? 'btn-primary' : 'btn-secondary']">
            Monthly
          </button>
        </div>

        <div
          :class="currentGridOption"
          class="space-y-4 sm:mt-16 sm:grid sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none">
          <template v-for="cardPrice in cardPrices" :key="cardPrice.price.id">
            <plan-selector-card
              v-if="cardPrice.price.price_type === 'recurring'"
              v-bind="cardPrice"
              :free-price="freePrice"
              :free-plan="freePlan"
              :confirm-plan="confirmPrice?.id === cardPrice.price.id"
              :loading-status="loadingStatus(cardPrice.price)"
              @confirm-price="handlePriceSelection"
              @cancel="confirmPrice = undefined"
              @price="confirmPrice = $event" />

            <one-time-selector-card
              v-else-if="cardPrice.price.price_type === 'one_time'"
              v-bind="cardPrice"
              :confirm-plan="confirmPrice?.id === cardPrice.price.id"
              :loading-status="loadingStatus(cardPrice.price)"
              @confirm-price="handlePriceSelection"
              @cancel="confirmPrice = undefined"
              @price="confirmPrice = $event" />
          </template>
        </div>
      </div>
    </div>
  </loading-view>
</template>

<script setup lang="ts">
import PlanSelectorCard from "/js/components/Subscriptions/PlanSelectorCard.vue"
import { useQueryClient } from "@tanstack/vue-query"
import {
  PlansApi,
  type RecurringInterval,
  type StripePlan,
  type StripePrice,
  type StripeSubscription,
  subscriptionQueryKey,
  usePlansQuery,
} from "/js/services/PlansApi"
import { computed, nextTick, onMounted, onUnmounted, ref, shallowRef, watch } from "vue"
import { notNullish } from "@vueuse/core"
import Modal from "/js/components/Modals/Modal.vue"
import { useStripe } from "/js/composables/useStripe"
import type { Stripe, StripeEmbeddedCheckout } from "@stripe/stripe-js"
import { toastError } from "/js/composables/toast"
import LoadingView from "/js/components/LoadingView.vue"
import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import type { PlanSelectorActionable } from "/js/components/Subscriptions/PlanSelectorTypes"
import OneTimeSelectorCard from "/js/components/Subscriptions/OneTimeSelectorCard.vue"

const props = defineProps<{
  productId?: string
  subscription: StripeSubscription | null | undefined
}>()

const stripe = shallowRef<Stripe | undefined>()
const checkout = shallowRef<StripeEmbeddedCheckout | undefined>()
const confirmPrice = ref<StripePrice | undefined>()
const changingSubscription = ref<StripePrice | undefined>()

const queryClient = useQueryClient()

const loadingStatus = (price: StripePrice) => {
  if (changingSubscription.value?.id === price.id) return "loading"
  if (confirmPrice.value?.id === price.id) return "disabled"
  return undefined
}

const userChannel = useUserChannel()

const emit = defineEmits<{
  subscriptionChanged: []
}>()

const subscribeToNewMessageCallback = (socketMessage: SocketMessage) => {
  if (socketMessage.type === "subscription") {
    queryClient.invalidateQueries({ queryKey: subscriptionQueryKey })
    emit("subscriptionChanged")
  }
}

onMounted(async () => {
  stripe.value = (await useStripe(window.APP_CONFIG.STRIPE_ACCOUNT_ID)) || undefined
  userChannel.subscribeToNewMessage(subscribeToNewMessageCallback)
})

onUnmounted(() => {
  userChannel.unsubscribeFromNewMessage(subscribeToNewMessageCallback)
})

const recurringInterval = ref<RecurringInterval>("month")
const showStripeModal = ref(false)

type CardPrice = {
  plan: StripePlan
  price: StripePrice
  actionable: PlanSelectorActionable
}

const { data: plans, isInitialLoading: plansInitialLoading } = usePlansQuery(props.productId)

watch(
  () => [plans.value, props.subscription],
  () => {
    changingSubscription.value = undefined

    if (plans.value && props.subscription) {
      const rec = props.subscription.prices[0]?.recurring_interval

      if (rec) {
        recurringInterval.value = rec
      } else {
        if (plans.value) {
          if (availableRecurringIntervals.value.includes("year")) {
            recurringInterval.value = "year"
          } else {
            recurringInterval.value = "month"
          }
        }
      }
    }
  }
)

const isLoading = computed(() => plansInitialLoading.value)

const availableRecurringIntervals = computed(() => {
  if (!plans.value) return []
  return plans.value
    .flatMap((plan) => plan.prices.map((p) => p.recurring_interval))
    .filter((value, index, self) => self.indexOf(value) === index) // unique
})

const showRecurringInterval = computed(() => availableRecurringIntervals.value.length > 1)

const currentSubscriptionPriceIds = computed(() => {
  if (!props.subscription) return []
  return props.subscription.prices.map((p) => p.id)
})

const cardPrices = computed((): CardPrice[] => {
  if (!plans.value) return []

  return plans.value
    .map((plan) => {
      if (plan.plan_type === "free") {
        // don't check for recurring interval on free plans
        const price = plan.prices[0]
        if (!price) return undefined

        const isCurrent =
          currentSubscriptionPriceIds.value.includes(price.id) || // current plan is free
          (props.subscription && // current plan is stripe and canceled
            (props.subscription.cancel_at_period_end || props.subscription.status === "canceled"))
        const actionable: PlanSelectorActionable = isCurrent ? "current" : "selectable"

        const cp: CardPrice = {
          plan,
          price,
          actionable,
        }
        return cp
      }

      if (plan.type === "ProductPlan") {
        const price = plan.prices.find((p) => p.price_type === "one_time")
        if (!price) return undefined

        const isCurrent = currentSubscriptionPriceIds.value.includes(price.id)
        const actionable: PlanSelectorActionable = isCurrent ? "current" : "selectable"

        const cp: CardPrice = {
          plan,
          price,
          actionable,
        }
        return cp
      }

      const price = plan.prices.find(
        (price) => price.recurring_interval === recurringInterval.value
      )

      const cardPrice = price || plan.prices[0]
      if (!cardPrice) return undefined

      if (price) {
        let actionable: PlanSelectorActionable = "selectable"
        if (isSubscribed(price)) {
          if (props.subscription?.cancel_at_period_end) {
            actionable = "canceled"
          } else {
            actionable = "current"
          }
        }
        const cp: CardPrice = {
          plan,
          price,
          actionable,
        }
        return cp
      } else {
        // this is for when a price is not available for the current recurring period
        const cp: CardPrice = {
          plan,
          price: cardPrice,
          actionable: "disabled",
        }
        return cp
      }
    })
    .filter(notNullish)
})

const gridOptions = [
  "sm:grid-cols-1",
  "sm:grid-cols-2",
  "sm:grid-cols-3",
  "sm:grid-cols-2 xl:grid-cols-4",
  "sm:grid-cols-3 xl:grid-cols-5",
]

const currentGridOption = computed(() => {
  if (!plans.value) return ""
  if (plans.value.length >= 1 && plans.value.length <= 4) return gridOptions[plans.value.length - 1]
  return gridOptions[4]
})

const freePrice = computed(() => {
  return plans.value?.find((plan) => plan.plan_type === "free")?.prices[0]
})

const freePlan = computed(() => {
  return plans.value?.find((plan) => plan.plan_type === "free")
})

const isFreePrice = (price: StripePrice | undefined) => {
  return plans.value?.find((plan) => plan.plan_type === "free")?.prices[0]?.id === price?.id
}

const isSubscribed = (price: StripePrice) => {
  return currentSubscriptionPriceIds.value.includes(price.id)
}

const handlePriceSelection = async (price: StripePrice) => {
  confirmPrice.value = undefined
  if (!stripe.value) {
    toastError("Stripe is not available. Please try again later.")
    return
  }

  if (price.stripe_price_id) {
    try {
      changingSubscription.value = price

      const result =
        price.price_type === "one_time"
          ? await PlansApi.createOneTimePurchase(price.id, window.location.pathname)
          : await PlansApi.changeSubscription(price.id, window.location.pathname)

      if (result.type === "checkout") {
        checkout.value = await stripe.value.initEmbeddedCheckout({
          clientSecret: result.object.client_secret,
        })

        showStripeModal.value = true
      }
    } catch (e) {
      toastError(e)
      changingSubscription.value = undefined
    }
  } else if (isFreePrice(price)) {
    await switchToFreePlan()
  } else {
    console.warn("unhandled price", price)
  }
}

const switchToFreePlan = async () => {
  try {
    changingSubscription.value = plans.value?.find((plan) => plan.plan_type === "free")?.prices[0]
    if (props.subscription) {
      await PlansApi.cancelSubscription()
    } else {
      await PlansApi.createFreeSubscription()
      window.location.reload()
    }
    await queryClient.invalidateQueries({ queryKey: subscriptionQueryKey })
  } catch (e) {
    toastError(e)
    console.log(e)
  }
  changingSubscription.value = undefined
}

watch(showStripeModal, (show) => {
  if (!show) {
    changingSubscription.value = undefined
  }

  if (checkout.value) {
    if (show) {
      nextTick(() => {
        if (document.getElementById("stripe-modal-id")) {
          checkout.value?.mount("#stripe-modal-id")
        } else {
          toastError("There was an error while loading the payment form. Please try again.")
        }
      })
    } else {
      checkout.value.unmount()
      checkout.value.destroy()
    }
  }
})
</script>

<script lang="ts">
export default {
  name: "PlanSelector",
}
</script>
