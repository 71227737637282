<template>
  <modal :modalType v-model="showGallery" title="Media Gallery" no-backdrop-close>
    <media-gallery
      :scope="imageScope"
      :autoSubmit="true"
      :multiple="false"
      :modalType
      :assistant
      :aspectRatio
      accept="image"
      @save="handleSave"
      @close="showGallery = false" />
  </modal>

  <div class="w-full">
    <div v-if="productAttachment">
      <div class="group relative mb-4">
        <slot name="imagePreview" v-bind="{ url: attachmentImageUrl(productAttachment) }">
          <dropzone-image-preview :aspectRatio :url="attachmentImageUrl(productAttachment)" />
        </slot>
        <div
          class="invisible absolute inset-0 mx-auto my-auto flex items-center justify-center rounded-lg group-hover:visible group-hover:bg-black group-hover:bg-opacity-50 group-hover:opacity-100">
          <slot name="actions" v-bind="{ showGalleryAction }">
            <existing-image-actions
              :disabled="!!disabled"
              v-if="productAttachment"
              @remove="productAttachment = undefined"
              @change="showGallery = true" />
          </slot>
        </div>
      </div>
    </div>
    <gallery-select-media v-else @show-gallery="showGallery = true" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import MediaGallery from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGallery.vue"
import { attachmentImageUrl, type ProductAttachment } from "/js/models/Product"
import Modal from "/js/components/Modals/Modal.vue"
import ExistingImageActions from "/js/components/utilities/FormFields/FileUpload/UI/ExistingImageActions.vue"
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import GallerySelectMedia from "/js/components/utilities/FormFields/FileUpload/UI/GallerySelectMedia.vue"
import DropzoneImagePreview from "/js/components/utilities/FormFields/FileUpload/UI/DropzoneImagePreview.vue"
import type { ModalProps } from "/js/components/Modals/ModalTypes"

const props = defineProps<{
  scope: MediaGalleryScope
  disabled?: boolean
  modalType?: ModalProps["modalType"]
  aspectRatio?: number
  assistant?: boolean
}>()

const productAttachment = defineModel<ProductAttachment | undefined>()

const imageScope = computed((): MediaGalleryScope => {
  return {
    ...props.scope,
    accept: "image",
  }
})

const showGallery = ref(false)

const showGalleryAction = () => {
  showGallery.value = true
}

const handleSave = (value: ProductAttachment) => {
  showGallery.value = false
  productAttachment.value = value
}
</script>

<script lang="ts">
export default {
  name: "MediaGalleryImageInput",
}
</script>
