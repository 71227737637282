<template>
  <div
    v-if="posts.length > 0"
    v-for="post in posts"
    :key="post.id"
    class="rounded-lg border border-gray-100 p-4">
    <post-card
      :truncate="true"
      :post="post"
      @follow="emit('follow', post)"
      @update="emit('update', post)"
      @delete="emit('delete', post)" />
  </div>
  <empty-view title="No posts found" v-else>
    <feed-empty-icon class="h-40 w-40" />
  </empty-view>
</template>

<script setup lang="ts">
import type { Post } from "/js/models/Post"
import PostCard from "/js/components/Posts/PostCard.vue"
import FeedEmptyIcon from "/js/components/icons/FeedEmptyIcon.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"

defineProps<{
  posts: Post[]
}>()

const emit = defineEmits<{
  follow: [value: Post]
  delete: [value: Post]
  update: [value: Post]
}>()
</script>

<script lang="ts">
export default {
  name: "FeedListCard",
}
</script>
