<template>
  <tour-step ref-id="v-step-admin-sidebar" placement="right" :arrow="true">
    <div class="flex gap-1">
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400/20"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400/20"></div>
    </div>

    <div class="text-xs text-white">
      This is the primary navigation menu for Admins. You will have a chance to explore the various
      sections as part of this tour
    </div>
  </tour-step>

  <tour-step ref-id="v-step-admin-switcher" placement="right" :arrow="true">
    <div class="flex gap-1">
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400/20"></div>
    </div>

    <div class="text-xs text-white">
      You can leave the Admin area at any time and view your community by clicking here.
    </div>
  </tour-step>

  <tour-step
    ref-id="v-step-admin-dashboard-cta-cards"
    :ref-padding="20"
    placement="bottom-start"
    :arrow="false">
    <div class="flex gap-1">
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
      <div class="h-1 w-6 rounded-full bg-ll-green-400"></div>
    </div>

    <div class="text-xs text-white">
      You can find some more information on starting your journey here.
    </div>
  </tour-step>
</template>

<script setup lang="ts">
import TourStep from "/js/components/Tour/TourStep.vue"
import { onMounted } from "vue"
import { mainTourStore } from "/js/stores/TourStore"

onMounted(() => {
  mainTourStore().start()
})
</script>

<script lang="ts">
export default {
  name: "TourMain",
}
</script>
