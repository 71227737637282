<template>
  <div class="flex items-center gap-4">
    <div class="flex h-9 w-9 items-center justify-center text-center text-xl">#</div>

    <div class="flex flex-col gap-0.5">
      <div class="text-sm">{{ option.name }}</div>
      <div class="text-secondary text-xs">
        Channel
        <template v-if="option.product_id && option.product_title">
          •
          <option-box-product-primary-link
            @clickProduct="emit('clickProduct')"
            :disableInternalLinks
            :productId="option.product_id"
            :productTitle="option.product_title" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NavigationResourceChatRoom } from "/js/components/NavigationResources/NavigationResourcesTypes"
import OptionBoxProductPrimaryLink from "/js/components/GlobalSearch/OptionBoxProductPrimaryLink.vue"

defineProps<{
  option: NavigationResourceChatRoom
  disableInternalLinks?: boolean
}>()

const emit = defineEmits<{
  clickProduct: []
}>()
</script>

<script lang="ts">
export default {
  name: "ChatRoomOptionBox",
}
</script>
