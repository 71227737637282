import { ZProductAttachment } from "/js/models/Product"
import { ZUser } from "/js/models/User"
import { z } from "zod"
import {type PollParams, ZPoll} from "/js/models/Poll"

const PostAccessTypes = ["product_members", "public_access"] as const
export type PostAccessType = (typeof PostAccessTypes)[number]

export const ZPostFeedBase = z.object({
  title: z.string(),
  access: z.enum(PostAccessTypes),
  allow_members_create: z.boolean(),
})

export const ZPostFeed = ZPostFeedBase.merge(
  z.object({
    id: z.string(),
  })
)

export type PostFeedParams = z.infer<typeof ZPostFeedBase>

export type PostFeed = z.infer<typeof ZPostFeed>

export type PostParams = {
  title: string
  body: object
  start_date_components?: {
    date: string
    time: string
  }
  cover_image_id?: string | null
  attachments_ids?: string[]
}

export const ZPost = z.object({
  id: z.string(),
  title: z.string(),
  body: z.any(),
  start_date: z.date().optional().nullable(),
  post_likes_count: z.number().optional().nullable(),
  comments_count: z.number().optional().nullable(),
  cover_image: ZProductAttachment.optional().nullable(),
  attachments: z.array(ZProductAttachment).optional().nullable(),
  poll_id: z.string().optional().nullable(),
  followed_by_current_user: z.boolean(),
  post_feed_id: z.string(),
  post_feed_name: z.string(),
  product_id: z.string(),
  user: ZUser,
  created_at: z.date(),
})

export type Post = z.infer<typeof ZPost>
