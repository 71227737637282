import {gradientClass} from "/js/composables/gradientId";

export const bgStyle = (url: string | null | undefined) => {
  if (url) {
    return `background-image: url(${url})`
  } else {
    return ''
  }
}

export const bgClass = (url: string | null | undefined, id: string | null | undefined) => {
  if (url) {
    return ''; // No class should be applied if there's an image URL
  } else if (id) {
    return gradientClass(id);
  }
  return '';
}
