<template>
  <div class="card padding flex flex-col justify-between gap-4 xl:flex-row">
    <div class="flex flex-1 flex-col items-start gap-4 xl:flex-row xl:items-center">
      <h3 v-if="showProductName && product" class="line-clamp-1 max-w-1/2 overflow-ellipsis">
        {{ product.name }}
      </h3>

      <div class="flex items-center gap-4">
        <template v-if="product.status !== 'published'">
          <badge
            variant="custom"
            :class="productStatusTagClass(product.status)"
            v-tooltip="productStatusTooltipLabel(product.status)">
            {{ productStatusTagLabel(product.status) }}
          </badge>
          <vertical-divider />
        </template>

        <badge v-if="product.label" variant="custom" :class="productLabelTagClass(product.label)">
          {{ product.label }}
        </badge>
      </div>

      <div class="flex items-center gap-4" v-if="product.end_date">
        <div class="flex items-center gap-2">
          <calendar-icon class="text-secondary h-6 w-6 shrink-0" />
          <div class="text-secondary text-sm">{{ productDurationLabel(product) }}</div>
        </div>

        <vertical-divider />
        
        <div class="flex items-center gap-2">
          <clock-icon class="text-secondary h-6 w-6" />
          <div class="text-secondary text-sm">{{ productStartsInLabel(product) }}</div>
        </div>
      </div>

      <div class="flex items-center gap-2">
        <global-icon class="text-secondary h-6 w-6" v-if="product.access === 'public_product'" />
        <members-icon class="text-secondary h-6 w-6" v-if="product.access === 'members'" />
        <invite-icon class="text-secondary h-6 w-6" v-if="product.access === 'invite'" />
        <div class="text-secondary text-sm">
          {{ productAccessLabels[product.access] ?? "-" }}
        </div>
      </div>
    </div>
    <slot name="right-actionbar" />
  </div>
</template>

<script setup lang="ts">
import {
  productLabelTagClass,
  productStatusTagClass,
  productStatusTagLabel,
  productStatusTooltipLabel,
} from "/js/composables/productLabelsHelper"
import {
  type Product,
  productAccessLabels,
  productDurationLabel,
  productStartsInLabel,
} from "/js/models/Product"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import MembersIcon from "/js/components/icons/MembersIcon.vue"
import ClockIcon from "/js/components/icons/ClockIcon.vue"
import GlobalIcon from "/js/components/icons/GlobalIcon.vue"
import VerticalDivider from "/js/components/utilities/UI/VerticalDivider.vue"
import InviteIcon from "/js/components/icons/InviteIcon.vue"
import { formatDate } from "@vueuse/core"
import Badge from "/js/components/utilities/UI/Badge.vue"

defineProps<{
  product: Product
  showProductName?: boolean
}>()
</script>

<script lang="ts">
export default {
  name: "LearningHubOverviewBar",
}
</script>
