<template>
  <div class="inline-block h-6 w-px bg-neutral-200 opacity-100 dark:opacity-50"></div>
</template>

<script setup lang="ts"></script>

<script lang="ts">
export default {
  name: "VerticalDivider",
}
</script>
