<template>
  <teleport to="body" v-if="isActiveStep && reference && isLargeScreen">
    <div class="fixed inset-0 select-none" @click.prevent.stop></div>
    <div
      class="soft-highlight-shadow fixed rounded-lg"
      @click.prevent.stop
      :style="referenceCoords"></div>
    <div class="relative max-w-[260px]" ref="floating" :style="floatingStyles">
      <div class="flex flex-col gap-6 rounded-xl bg-ll-green-600 p-6">
        <arrow-triangle
          v-if="arrow"
          :direction="arrowDirection"
          :class="[arrowClassName, arrowDirectionClass, 'absolute border-ll-green-700']" />

        <slot></slot>

        <div class="flex justify-between gap-2">
          <button
            type="button"
            v-if="nextStep"
            class="btn btn-secondary btn-sm"
            @click="store.skipTour()">
            Skip
          </button>
          <div v-else></div>

          <div class="flex gap-2">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              v-if="previousStep"
              @click="store.goToPreviousStep()">
              Back
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              v-if="nextStep"
              @click="store.goToNextStep()">
              Continue
            </button>
            <button type="button" class="btn btn-primary btn-sm" v-else @click="store.doneTour()">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import ArrowTriangle, { type Direction } from "/js/components/Layout/ArrowTriangle.vue"
import { offset, useFloating, autoUpdate, type Placement, shift, flip } from "@floating-ui/vue"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useElementBounding, useMediaQuery } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { mainTourStore } from "/js/stores/TourStore"

type Props = {
  refId: string
  refPadding?: number
  placement: Placement
  arrow?: boolean
  arrowClassName?: string
}

const props = withDefaults(defineProps<Props>(), {
  refPadding: 0,
  arrow: true,
  arrowClassName: "",
})

const store = mainTourStore()
const { activeStep, nextStep, previousStep } = storeToRefs(store)

const isActiveStep = computed(() => {
  return activeStep.value?.id === props.refId
})

const arrowDirection = computed((): Direction => {
  const arrowPlacements: Record<Placement, Direction> = {
    "top-start": "down",
    top: "down",
    "top-end": "down",
    "right-start": "left",
    right: "left",
    "right-end": "left",
    "bottom-start": "up",
    bottom: "up",
    "bottom-end": "up",
    "left-start": "right",
    left: "right",
    "left-end": "right",
  }
  return arrowPlacements[props.placement]
})

const arrowDirectionClass = computed(() => {
  if (!props.arrow) return {}
  return {
    "-left-2": arrowDirection.value === "left",
    "-right-2": arrowDirection.value === "right",
    "-top-2": arrowDirection.value === "up",
    "-bottom-2": arrowDirection.value === "down",
  }
})

const isLargeScreen = useMediaQuery("(min-width: 1024px)")

const floating = ref(null)

const reference = ref<HTMLElement | null>(null)

const { x, y, width, height } = useElementBounding(reference)

const referenceCoords = computed(() => {
  if (!reference.value) return {}
  return {
    top: `${y.value - props.refPadding / 2}px`,
    left: `${x.value - props.refPadding / 2}px`,
    width: `${width.value + props.refPadding}px`,
    height: `${height.value + props.refPadding}px`,
  }
})

const { floatingStyles } = useFloating(reference, floating, {
  placement: props.placement,
  middleware: [
    shift(),
    flip(),
    offset({ crossAxis: 10 + props.refPadding, mainAxis: 10 + props.refPadding }),
  ],
  whileElementsMounted: autoUpdate,
})

watch(() => isActiveStep.value, (value) => {
  if (value) {
    nextTick(() => {
      reference.value = document.getElementById(props.refId)
    })
  }
})


</script>

<script lang="ts">
export default {
  name: "TourStep",
}
</script>
