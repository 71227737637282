import { type RouteRecordRaw, RouterView } from "vue-router"

export const namedRoutes = {
  community_content_index: "community_content.index",
  community_content_show: "community_content.show",
  community_content_edit: "community_content.edit",
} as const

export const communityContentRoutes: RouteRecordRaw[] = [
  {
    path: "library",
    component: RouterView,
    redirect: { name: namedRoutes.community_content_index },
    children: [
      {
        name: namedRoutes.community_content_index,
        path: "",
        component: () => import("/js/components/CommunityContent/CommunityContentList.vue"),
      },
      {
        name: namedRoutes.community_content_show,
        path: ":id",
        component: () => import("/js/components/CommunityContent/CommunityContentShow.vue"),
        props: (route) => ({ communityContentId: route.params.id }),
      },
      {
        name: namedRoutes.community_content_edit,
        path: ":id/edit",
        component: () => import("/js/components/CommunityContent/CommunityContentShow.vue"),
        props: (route) => ({ communityContentId: route.params.id, edit: true }),
      },
    ],
  },
]