<template>
  <div
    class="rounded-lg border p-4"
    :class="[
      type === 'error' && 'border-red-500 bg-red-200 text-red-600',
      type === 'notice' && 'border-green-500 bg-green-200 text-green-600',
    ]">
    {{ message }}
  </div>
</template>

<script setup lang="ts">

defineProps<{
  message: string
  type: "error" | "notice"
}>()

</script>

<script lang="ts">
export default {
  name: "Flash",
}
</script>