import type { Post, PostFeed, PostFeedParams } from "/js/models/Post"
import { PostsApi } from "/js/services/PostsApi"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/vue-query"
import { type QueryKeyType, useServiceHelpers } from "/js/services/useServiceHelpers"

export type LikePostMutationParams = {
  postId: string
  postFeedId: string
  follow: boolean
}

export const postListQueryKey = (postFeedId: string) => ["post_list", postFeedId]

export const widgetFeedPostsQueryKey = (widgetId: string) => ["widget_feed_posts", widgetId]

export const postQueryKey = (id: string) => ["post", id]

export const commentListQueryKey = (postId: string) => ["comment_list", "post", postId]

export const replyCommentListQueryKey = (commentId: string) => ["reply_comment_list", commentId]

export const postFeedListQueryKey = (productId: string) => ["postFeeds", productId]

export type UpdatePostFeedParams = {
  feedId: string
  params: PostFeedParams
}

export const usePostFeedService = (productId: string) => {
  const queryClient = useQueryClient()
  const { appendItem, optimisticDelete } = useServiceHelpers(queryClient)

  const postFeedListKey = postFeedListQueryKey(productId)

  const createPostFeed = useMutation({
    mutationFn: async (params: PostFeedParams) => {
      return await PostsApi.createPostFeed(productId, params)
    },
    onSuccess: async (data, variables, context) => {
      appendItem(postFeedListKey, data)
    },
  })

  const updatePostFeed = useMutation({
    mutationFn: async ({ feedId, params }: UpdatePostFeedParams) => {
      return await PostsApi.updatePostFeed(feedId, params)
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: postFeedListKey })
    },
  })

  const deletePostFeed = useMutation({
    mutationFn: async (id: string) => {
      return await PostsApi.deletePostFeed(id)
    },
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: postFeedListKey })
      return optimisticDelete<PostFeed>(postFeedListKey, "id", id)
    },
    onError: (err, variables, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData<PostFeed[]>(postFeedListKey, context.previousItems)
      }
    },
  })

  return {
    createPostFeed,
    updatePostFeed,
    deletePostFeed,
  }
}

export const usePostService = (client?: QueryClient, otherQueryKeys?: QueryKeyType[]) => {
  const queryClient = client || useQueryClient()
  const { fetchLocalItem, syncItem } = useServiceHelpers(queryClient)

  const allQueryKeys = (post: Post): QueryKeyType[] => {
    return [
      { key: postListQueryKey(post.post_feed_id), type: "list" },
      { key: postQueryKey(post.id), type: "single" },
      ...(otherQueryKeys || []),
    ]
  }

  const fetchLocalPost = (id: string, postFeedId: string) => {
    const keys = [
      postListQueryKey(postFeedId),
      postQueryKey(id),
      ...(otherQueryKeys?.map((k) => k.key) || []),
    ]
    return fetchLocalItem<Post>(keys, id)
  }

  const followPostObject = async (post: Post) => {
    return await followPost.mutateAsync({
      postId: post.id,
      postFeedId: post.post_feed_id,
      follow: !(post.followed_by_current_user || false),
    })
  }

  const followPost = useMutation({
    mutationFn: async ({ postId, follow }: LikePostMutationParams) => {
      if (follow) {
        await PostsApi.followPost(postId)
      } else {
        await PostsApi.unfollowPost(postId)
      }
    },
    onMutate: async ({ postId, postFeedId, follow }) => {
      await cancelPostQueries(postId, postFeedId)

      const currentPost = fetchLocalPost(postId, postFeedId)

      if (currentPost) {
        syncPost({
          ...currentPost,
          followed_by_current_user: follow,
          post_likes_count: follow
            ? (currentPost.post_likes_count ?? 0) + 1
            : Math.max(0, (currentPost.post_likes_count ?? 0) - 1),
        })
      }
      return { currentPost }
    },
    onError: (err, variables, context) => {
      if (context?.currentPost) {
        syncPost(context.currentPost)
      }
    },
  })

  const cancelPostQueries = async (postId: string, postFeedId: string) => {
    await queryClient.cancelQueries({ queryKey: postQueryKey(postId) })
    await queryClient.cancelQueries({ queryKey: postListQueryKey(postFeedId) })
  }

  const syncPost = (post: Post) => {
    syncItem(allQueryKeys(post), post)
  }

  return {
    followPost,
    followPostObject,
    listQueryKey: postListQueryKey,
    postQueryKey,
    commentListQueryKey,
    replyCommentListQueryKey,
    fetchLocalPost,
    syncPost,
  }
}
