<template>
  <div ref="componentRoot" class="flex w-full">
    <template v-if="embedUrl">
      <div class="flex grow flex-col gap-2">
        <div class="embed-container grow">
          <iframe :src="embedUrl" class="border-0" />
        </div>
        <div class="flex items-center gap-2">
          <button
            type="button"
            class="btn btn-primary btn-sm self-start"
            v-if="!readOnly"
            @click="showForm = true">
            Edit
          </button>

          <a
            :href="embedUrl"
            class="btn btn-primary btn-sm self-start !no-underline"
            v-if="showUrl"
            target="_blank">
            Open in New Tab
          </a>
        </div>
      </div>
    </template>

    <div class="flex-1" v-else>
      <button type="button" v-if="!readOnly" class="drop-zone" @click="showForm = true">
        <span class="flex h-24 flex-col items-center justify-center gap-3">
          Add {{ source.name }}
        </span>
      </button>
    </div>

    <template v-if="!readOnly">
      <modal modal-type="app" v-model="showForm">
        <div class="flex flex-col">
          <FormKit
            type="text"
            v-model="resourceUrl"
            validation-label="URL"
            :help="`Paste the URL of the ${source.name} you want to embed.`"
            :label="`Embed ${source.name}`"
            :placeholder="source.placeholderUrl"
            validation="required" />

          <span v-if="note" class="text-secondary text-xs italic">{{ note }}</span>

          <button
            class="btn btn-primary mt-4 self-start"
            :disabled="!validResourceUrl"
            @click="handleSave">
            Save
          </button>
        </div>
      </modal>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import Modal from "/js/components/Modals/Modal.vue"
import embedPluginSources, {
  type EmbedSourceType,
  getEmbedUrl,
  isValidResourceUrl,
} from "/js/components/utilities/Editor/embedPluginSources"
import type { IframeEmbedPluginProps } from "/js/components/utilities/Editor/createVueComponentPlugin"

const props = defineProps<IframeEmbedPluginProps>()

const source = computed(() => {
  return embedPluginSources[props.configKey]
})

const showUrl = computed(() => {
  const urlShowSources: EmbedSourceType[] = ["googleDocsEmbed", "googleSheetsEmbed"]
  return urlShowSources.includes(props.configKey)
})

const showForm = ref(false)
const resourceUrl = ref<string | undefined>(
  props.editorData?.url ? props.editorData.url : undefined
)

const componentRoot = ref<HTMLElement | null>(null)

const validResourceUrl = computed(() => {
  return isValidResourceUrl(resourceUrl.value, source.value)
})

const embedUrl = computed(() => {
  if (!validResourceUrl.value) return undefined
  if (!resourceUrl.value) return undefined
  return getEmbedUrl(resourceUrl.value, source.value)
})

const handleSave = () => {
  if (validResourceUrl.value) {
    showForm.value = false
    props.onUpdate({
      url: resourceUrl.value || "",
    })
  }
}
</script>
