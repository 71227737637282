<template>
  <button @click="onAction">
    <div class="flex cursor-pointer flex-col items-center justify-center gap-4 py-24">
      <slot>
        <!-- Error Icon -->
      </slot>
      <div class="text-l pt-6 font-medium whitespace-pre-line">{{ props.title }}</div>
      <div v-if="props.subtitle" class="text-secondary text-center whitespace-pre-line">{{ props.subtitle }}</div>
      <button v-if="props.action" type="button" class="btn btn-primary" @click="onAction">
        {{ props.action }}
      </button>
    </div>
  </button>
</template>

<script setup lang="ts">
type Props = {
  title: string
  subtitle?: string | unknown
  action?: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  onAction: []
}>()

const onAction = () => {
  emit("onAction")
}
</script>

<script lang="ts">
export default {
  name: "ErrorView",
}
</script>
