import {ref} from "vue";

export const modalCourseOpen = ref(false)
export const modalCourseId = ref<string | null>(null)

export const openCourseId = (courseId: string) => {
  modalCourseId.value = courseId
  modalCourseOpen.value = true
}

export const closeCourse = () => {
  modalCourseOpen.value = false
  modalCourseId.value = null
}