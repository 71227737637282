<template>
  <cropper-file-input
    :modalType
    :aspectRatio
    :accept
    :multiple
    @files="emit('save', $event)"
    #default="{ selectFile, importFileToCrop }">
    <button ref="button" type="button" class="w-full" @click="selectFile" :disabled>
      <headless-dropzone-input
        :disabled
        :accept
        #default="{ isOverDropZone, dropzoneError }"
        @files="importFileToCrop($event)">
        <slot v-bind="{ selectFile, isOverDropZone, dropzoneError, importFileToCrop }" />
      </headless-dropzone-input>
    </button>
  </cropper-file-input>
</template>

<script setup lang="ts">
import CropperFileInput from "/js/components/utilities/FormFields/FileUpload/HelperFields/CropperFileInput.vue"
import HeadlessDropzoneInput from "/js/components/utilities/FormFields/FileUpload/HelperFields/HeadlessDropzoneInput.vue"
import { ref } from "vue"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import type { ModalProps } from "/js/components/Modals/ModalTypes"

defineProps<{
  aspectRatio?: number | undefined
  disabled?: boolean
  accept?: SupportedMedia | SupportedMedia[]
  multiple?: boolean
  modalType?: ModalProps["modalType"]
}>()

const button = ref<HTMLElement | null>(null)

const selectFile = () => {
  button.value?.click()
}

const emit = defineEmits<{
  save: [value: File[]]
}>()

defineExpose({
  selectFile,
})
</script>

<script lang="ts">
export default {
  name: "CropperFileDropzoneInput",
}
</script>
