import {QueryClient} from '@tanstack/vue-query'
import { notNullish } from "@vueuse/core"

type Identifiable = {
  id: string
}

export type QueryKeyType = {
  key: any[]
  type: 'list' | 'single'
}

export const useServiceHelpers = (queryClient: QueryClient) => {
  const optimisticDelete = <T extends Identifiable>(
    listQueryKey: string[],
    filterProperty: keyof T,
    filterValue: T[keyof T]
  ) => {
    const previousItems = queryClient.getQueryData<T[]>(listQueryKey)
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (old) {
        return old.filter((item) => item[filterProperty] !== filterValue)
      } else {
        return old
      }
    })

    return {previousItems}
  }

  const appendItem = <T extends Identifiable>(listQueryKey: string[], item: T) => {
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (old && Array.isArray(old)) {
        return [...old, item]
      } else {
        return [item]
      }
    })
  }

  const prependItem = <T extends Identifiable>(listQueryKey: string[], item: T) => {
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (old && Array.isArray(old)) {
        return [item, ...old]
      } else {
        return [item]
      }
    })
  }

  const replaceItem = <T extends Identifiable>(listQueryKey: string[], item: T) => {
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (!old) return old;

      const index = old.findIndex((i) => i.id === item.id)
      if (index > -1) {
        old[index] = item
      }
      return old
    })
  }

  const modifyItem = <T extends Identifiable>(listQueryKey: string[], id: string, modifier: (item: T) => T) => {
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (!old) return old;

      const item = old.find((i) => i.id === id)
      if (!item) return old;

      const result = modifier(item)

      return old.map((i) => {
        if (i.id === id) {
          return result
        } else {
          return i
        }
      })
    })
  }

  const removeItemId = <T extends Identifiable>(listQueryKey: string[], id: string) => {
    queryClient.setQueryData<T[]>(listQueryKey, (old) => {
      if (!old) return old;
      return old.filter((i) => i.id !== id)
    })
  }

  const fetchLocalItem = <T extends Identifiable>(keys: (string | null | undefined)[][] | null | undefined, id: string) => {
    if (!keys) return null;
    keys = keys.filter(notNullish)

    for (const key of keys) {
      const item = queryClient.getQueryData(key)
      if (!item) continue;

      if (Array.isArray(item)) {
        const obj = item.find((item) => item.id === id)
        if (obj) {
          return obj as T
        }
      } else {
        return item as T
      }
    }

    return null
  }

  const syncItem = <T extends Identifiable>(
    queryKeys: QueryKeyType[],
    item: T,
  ) => {
    queryKeys.forEach((queryKey) => {
      if (queryKey.type === 'list') {
        const items = queryClient.getQueryData<T[]>(queryKey.key)
        if (items) {
          console.log('syncing list', queryKey.key, item)
          const index = items.findIndex((i) => i.id === item.id)
          if (index >= 0) {
            const newList = [...items]
            newList[index] = item
            queryClient.setQueryData(queryKey.key, newList)
          }
        }
      } else {
        queryClient.setQueryData(queryKey.key, item)
      }
    })
  }

  return {
    optimisticDelete,
    appendItem,
    prependItem,
    replaceItem,
    removeItemId,
    modifyItem,
    fetchLocalItem,
    syncItem,
  }
}
