<template>
  <div class="flex items-center gap-4">
    <round-avatar
      :avatar-url="option.avatar_url"
      :id="option.id"
      :verified="option.verified"
      :text="initials(option)"
      size="sm" />
    <div class="flex flex-col items-start">
      <div class="text-sm font-medium">{{ fullName(option) }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fullName, initials } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type { NavigationResourceUser } from "/js/components/NavigationResources/NavigationResourcesTypes"

defineProps<{
  option: NavigationResourceUser
}>()
</script>

<script lang="ts">
export default {
  name: "UserOptionBox",
}
</script>
