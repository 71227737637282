import { createApp, h, render, reactive } from "vue"
import type { Component } from "vue"
import { type API, type BlockAPI, type BlockTool, type OutputBlockData } from "@editorjs/editorjs"
import type { BlockToolConstructorOptions } from "@editorjs/editorjs/types/tools/block-tool"
import InputWrapperPlugin from "/js/components/utilities/Editor/InputWrapperPlugin.vue"
import { plugin as formKitPlugin, defaultConfig as formKitDefaultConfig } from "@formkit/vue"
import { generateClasses } from "@formkit/themes"
import formKitTheme from "/js/tailwind-theme"
import type { EmbedSourceType } from "/js/components/utilities/Editor/embedPluginSources"
import type { ProductAttachment } from "/js/models/Product"
import { VueQueryPlugin, type VueQueryPluginOptions } from "@tanstack/vue-query"
import FloatingVue from "floating-vue"
import type { AccordionWidgetItem } from "/js/models/Widget"
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import {
  convertEJSContent,
  type EditorJsSchema,
  type PartialEditorJsSchema,
} from "/js/components/GptChat/EditorJs/EditorJsModels"

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  },
}

export type ReplaceContentType = {
  type: "paragraphs"
  data: string[]
} | {
  type: "schema"
  data: EditorJsSchema
}

export type VueEmbedPluginConfig<T> = {
  onUpdate: (value: T) => void
  replaceContent: (content: ReplaceContentType | null) => void
  editorData: T | null
  readOnly: boolean
}

type VueEmbedPluginData<T> = VueEmbedPluginConfig<T> & Record<string, any>

export type IframeEmbedPluginConfig = {
  configKey: EmbedSourceType
  note?: string
}

export type IframeEmbedPluginData = {
  url: string | null
  productId?: string | null
}

export type ImagePluginData = {
  productAttachment?: ProductAttachment
  width?: number
  productId?: string | null
}

export type ContentGeneratorPluginData = {
  productId?: string | null
  editorContentId?: string | null
}

export type AttachmentsPluginData = {
  attachments: ProductAttachment[]
  productId?: string | null
}

export const QuestionPluginQuestionTypes = ["text", "radio"] as const
export type QuestionPluginQuestionType = typeof QuestionPluginQuestionTypes[number]
export type QuestionPluginQuestionOption = {
  identifier: string
  label: string
}

export type QuestionPluginData = {
  identifier: string
  questionType: QuestionPluginQuestionType
  question?: string
  options?: QuestionPluginQuestionOption[]
  productId?: string
}

export type AccordionPluginData = {
  items?: AccordionWidgetItem[]
}

export type IframeEmbedPluginProps = VueEmbedPluginConfig<IframeEmbedPluginData> &
  IframeEmbedPluginConfig

export type ImagePluginProps = VueEmbedPluginConfig<ImagePluginData>

export type AttachmentsPluginProps = VueEmbedPluginConfig<AttachmentsPluginData>

export type ContentGeneratorPluginProps = VueEmbedPluginConfig<ContentGeneratorPluginData>

export type QuestionPluginProps = VueEmbedPluginConfig<QuestionPluginData>

export type AccordionPluginProps = VueEmbedPluginConfig<AccordionPluginData>

function renderVueComponentToHTML(component: Component): string {
  const container = document.createElement("div")
  render(h(component), container)
  return container.innerHTML
}

interface MenuOption<Data> {
  icon: Component // Vue component for the icon.
  title: string // Tooltip for the menu option.
  action: (data: Data, instance: any) => void // Action function to run when the menu option is clicked.
}

type MenuOptions<Data> = Array<MenuOption<Data>>

function createWrappedApp(VueComponent: any, rootProps: any) {
  // Here we render the WrapperComponent and pass VueComponent as its default slot content
  const AppWithWrapper = {
    render() {
      return h(
        InputWrapperPlugin,
        {},
        {
          default: () => h(VueComponent, rootProps), // Pass rootProps to the original VueComponent
        }
      )
    },
  }

  return createApp(AppWithWrapper)
}

export function createVueComponentPlugin<
  T,
  Data extends VueEmbedPluginData<T>,
  CustomProps extends Record<string, any> = {},
>(
  VueComponent: Component,
  title: string,
  IconComponent?: Component,
  menuOptions?: MenuOptions<Data> | null,
  customProps?: CustomProps
) {
  const iconHTML = IconComponent ? renderVueComponentToHTML(IconComponent) : ""

  class VueComponentPlugin implements BlockTool {
    static toolbox = {
      icon: iconHTML,
      title: title,
    }

    data: Data
    vueApp: ReturnType<typeof createApp> | null = null
    readOnly: boolean
    api: API
    block: BlockAPI | undefined

    constructor({ api, data, readOnly, config, block }: BlockToolConstructorOptions) {
      this.readOnly = readOnly || false
      this.api = api
      this.block = block
      this.data = reactive({
        ...data,
        ...config,
      })
    }

    static get isReadOnlySupported() {
      return true
    }

    render() {
      const wrapper = document.createElement("div")

      const rootProps = {
        ...customProps,
        editorData: this.data,
        readOnly: this.readOnly,
        onUpdate: this.updateData.bind(this),
        replaceContent: this.replaceContent.bind(this),
      }

      this.vueApp = createWrappedApp(VueComponent, rootProps)
      this.vueApp.use(
        formKitPlugin,
        formKitDefaultConfig({
          config: {
            classes: generateClasses(formKitTheme),
          },
        })
      )
      this.vueApp.use(VueQueryPlugin, vueQueryPluginOptions)
      this.vueApp.use(FloatingVue)
      this.vueApp.use(autoAnimatePlugin)
      this.vueApp.mount(wrapper)

      return wrapper
    }

    save(blockContent: HTMLElement) {
      return this.data
    }

    destroy() {
      if (this.vueApp) {
        this.vueApp.unmount()
        this.vueApp = null
      }
    }

    updateData(newData: Data) {
      Object.entries(newData).forEach(([key, value]) => {
        if (value !== undefined) {
          this.data[key as keyof Data] = value
        }
      })
    }

    replaceBlockWithParagraphs(paragraphs: string[]) {
      if (!this.block) return

      const currentIndex = this.api.blocks.getBlockIndex(this.block.id)
      if (currentIndex === -1) return

      this.api.blocks.delete(currentIndex)

      const outputBlockData: OutputBlockData[] = paragraphs.map(p => {
        return {
          type: "paragraph",
          data: {
            text: p
          }
        }
      })

      this.api.blocks.insertMany(outputBlockData, currentIndex)
    }

    replaceBlockWithBlocks(blocks: object[]) {
      if (!this.block) return

      const currentIndex = this.api.blocks.getBlockIndex(this.block.id)
      if (currentIndex === -1) return

      this.api.blocks.delete(currentIndex)

      this.api.blocks.insertMany(blocks as any, currentIndex)
    }

    replaceContent(content: ReplaceContentType | null) {
      if (content === null) {
        this.replaceBlockWithParagraphs([""])
        return
      } else {
        if (content.type === "schema") {
          const result = convertEJSContent(content.data)
          this.replaceBlockWithBlocks(result)
        } else if (content.type === "paragraphs") {
          this.replaceBlockWithParagraphs(content.data)
        }
      }
    }

    // Implement the renderSettings method to integrate the menu options
    renderSettings() {
      const wrapper = document.createElement("div")

      if (menuOptions) {
        menuOptions.forEach((option) => {
          const optionElem = this.renderMenuOption(option)
          wrapper.appendChild(optionElem)
        })
      }

      return wrapper
    }

    renderMenuOption(option: MenuOption<any>) {
      // Create the main wrapper for the option
      const optionWrapper = document.createElement("div")
      optionWrapper.classList.add("ce-popover-item")

      // Create the icon wrapper and append the SVG icon
      const iconWrapper = document.createElement("div")
      iconWrapper.classList.add("ce-popover-item__icon")
      iconWrapper.innerHTML = renderVueComponentToHTML(option.icon)
      optionWrapper.appendChild(iconWrapper)

      // Create the title div and set its content
      const titleDiv = document.createElement("div")
      titleDiv.classList.add("ce-popover-item__title")
      titleDiv.textContent = option.title
      optionWrapper.appendChild(titleDiv)

      // Attach the action using an event listener
      optionWrapper.addEventListener("click", () => {
        option.action(this.data, this)
      })

      return optionWrapper
    }
  }

  return VueComponentPlugin
}
