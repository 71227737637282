<template>
  <round-avatar
    :id="user.id"
    :verified="user.verified"
    :avatar-url="user.avatar_url"
    :text="initials(user)" />
</template>

<script setup lang="ts">
import { type AdminMember, initials, type User, type UserProfile, ZUser } from "/js/models/User"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type { ChatMessageUser } from "/js/models/Chat"
import { z } from "zod"

defineProps<{
  user: User | AdminMember | ChatMessageUser | UserProfile | z.infer<typeof ZUser>
}>()

</script>

<script lang="ts">
export default {
  name: "UserAvatarView"
}
</script>