<template>
  <modal v-model="cropping" hide-top-bar :modalType>
    <div class="p-6">
      <cropper-wrapper
        :aspect-ratio="aspectRatio"
        :file="file"
        @save="handleCroppedFileSave"
        @cancel="handleCroppedFileCancel" />
    </div>
  </modal>
</template>

<script setup lang="ts">
import { computed } from "vue"
import Modal from "/js/components/Modals/Modal.vue"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import CropperWrapper from "/js/components/utilities/FormFields/FileUpload/HelperFields/CropperWrapper.vue"

type CropperModalProps = {
  aspectRatio?: number
  modalType?: ModalProps["modalType"]
}

withDefaults(defineProps<CropperModalProps>(), {
  modalType: "headless",
})

const emit = defineEmits<{
  croppedFile: [value: File]
}>()

const file = defineModel<File | undefined>()

const cropping = computed({
  get: () => file.value !== undefined,
  set: (value) => {
    if (!value) file.value = undefined
  },
})

const handleCroppedFileSave = (fileData: File) => {
  cropping.value = false
  emit("croppedFile", fileData)
}

const handleCroppedFileCancel = () => {
  cropping.value = false
}

</script>

<script lang="ts">
export default {
  name: "CropperModal",
}
</script>
