<template>
  <DropdownMenuRoot v-model:open="toggleState" :modal>
    <DropdownMenuTrigger :class="buttonClass" @click.prevent.stop>
      <slot name="button">
        <ellipsis-icon
          class="h-4 w-4 text-gray-600 hover:text-gray-500"
          aria-hidden="true"
          :class="iconColor" />
      </slot>
    </DropdownMenuTrigger>

    <DropdownMenuPortal v-if="portal">
      <DropdownMenuContent :class="menuItemsClass" :side-offset="10" :align>
        <div class="flex flex-col py-1">
          <slot />
        </div>
      </DropdownMenuContent>
    </DropdownMenuPortal>
    <DropdownMenuContent :class="menuItemsClass" :side-offset="10" :align v-else>
      <div class="flex flex-col py-1">
        <slot />
      </div>
    </DropdownMenuContent>
  </DropdownMenuRoot>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import {
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  // DropdownMenuItem,
  // DropdownMenuArrow,
  // DropdownMenuCheckboxItem,
  // DropdownMenuItemIndicator,
  // DropdownMenuLabel,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  // DropdownMenuSeparator,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
} from "radix-vue"
import EllipsisIcon from "/js/components/icons/EllipsisIcon.vue"

type Props = {
  iconColor?: string
  buttonClass?: string
  menuItemsWrapperClass?: string
  align?: "start" | "center" | "end"
  modal?: boolean
  noMenuItemsClass?: boolean
  portal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placement: "bottom-end",
  align: "end",
  modal: false,
  noMenuItemsClass: false,
  portal: false,
})

const toggleState = defineModel({ default: false })

const menuItemsClass = computed(() => {
  if (props.noMenuItemsClass) return ""
  let cls = props.menuItemsWrapperClass || ""

  return (
    cls +
    " z-10 min-w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" +
    " will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
  )
})
</script>

<script lang="ts">
export default {
  name: "DropdownMenu",
}
</script>
