<template>
  <loading-view :loading="isLoading">
    <dropdown-tag-input-select
      v-model:search-term="searchTerm"
      v-model:open="comboOpen"
      v-model="selectedGroupsRef"
      placeholder="Select Groups"
      :display-name="(p) => p.name"
      multiple
      :options="filteredGroups">
      <template #footer>
        <slot name="footer"/>
      </template>
    </dropdown-tag-input-select>

    <error-message :error="groupsError" />
  </loading-view>
</template>

<script setup lang="ts">
import ErrorMessage from "/js/components/ErrorMessage.vue"
import DropdownTagInputSelect from "/js/components/utilities/FormFields/DropdownTagInputSelect.vue"
import LoadingView from "/js/components/LoadingView.vue"
import { computed, onMounted, ref, watch } from "vue"
import type { UserGroup } from "/js/services/permissionPolicyService"
import type { UseQueryReturnType } from "@tanstack/vue-query"

const props = defineProps<{
  groupQuery: () => UseQueryReturnType<UserGroup[], any>
  open?: boolean
}>()

const searchTerm = ref("")
const selectedGroupsRef = ref<UserGroup[]>([])

const groupIds = defineModel<string[] | undefined>()

const emit = defineEmits<{
  "update:open": [value: boolean | undefined]
}>()

const _comboOpen = ref(false)

const comboOpen = computed({
  get: () => {
    return props.open || _comboOpen.value
  },
  set: (value) => {
    _comboOpen.value = value
    emit("update:open", value)
  }
})

const { data: groups, isLoading, error: groupsError } = props.groupQuery()

const setupFieldData = () => {
  if (!groupIds.value || !groups.value) {
    return
  }

  selectedGroupsRef.value = groups.value.filter((p) => groupIds.value?.includes(p.id))
}

watch(groups, setupFieldData)

watch(
  selectedGroupsRef,
  () => {
    if (!selectedGroupsRef.value) return
    groupIds.value = selectedGroupsRef.value.map((p) => p.id)
  },
  { deep: true }
)

const filteredGroups = computed(() => {
  if (!searchTerm.value || searchTerm.value === "") {
    // limit to the first 50. ComboBox has an issue with more than 100-115 items
    return groups.value?.slice(0, 50) ?? []
  }

  return (
    groups.value?.filter((p) => {
      return p.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    }) ?? []
  )
})

onMounted(setupFieldData)
</script>

<script lang="ts">
export default {
  name: "MultiUserGroupSelect",
}
</script>
