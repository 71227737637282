<template>
  <content-view :isError :isEmpty="isSuccess && data?.length === 0" :isLoading>
    <template #empty>
      <empty-view title="No Learning Hubs found">
        <contents-empty-icon />
      </empty-view>
    </template>
    <template #error>
      <error-view title="Something went wrong" subtitle="Try refreshing the page.">
        <contents-empty-icon />
      </error-view>
    </template>

    <template #default>
      <responsive-table class="text-sm" :class="{ 'opacity-60': isLoading }">
        <thead class="bg-primary text-secondary text-left">
          <tr>
            <th class="p-3">Name</th>
            <th class="p-3">Role</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in data" :key="product.id" class="text-primary group hover:bg-gray-50">
            <td class="max-w-40 truncate p-3" :title="product.name">
              <router-link
                :to="{ name: namedRoutes.learning_hubs_show, params: { product_id: product.id } }">
                {{ product.name }}
              </router-link>
            </td>
            <td class="p-3">
              <span
                class="rounded-lg px-2 py-1 text-sm"
                v-if="product.role"
                :class="tagClass(product.role)">
                {{ roleOptionLabels[product.role] }}
              </span>
            </td>
          </tr>
        </tbody>
      </responsive-table>
    </template>
  </content-view>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import { computed } from "vue"
import { UsersApi } from "/js/services/UsersApi"
import ErrorView from "/js/components/utilities/UI/ErrorView.vue"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import ResponsiveTable from "/js/components/Admin/ResponsiveTable.vue"
import { roleOptionLabels, tagClass } from "/js/services/permissionPolicyService"
import ContentsEmptyIcon from "/js/components/icons/ContentsEmptyIcon.vue"
import { namedRoutes } from "/js/router/LearningHubRoutes"

const props = defineProps<{
  userId: string
}>()

const { data, isError, isSuccess, isLoading } = useQuery({
  queryKey: computed(() => ["userProducts", props.userId]),
  queryFn: async () => await UsersApi.getUserProducts(props.userId),
})
</script>

<script lang="ts">
export default {
  name: "UserProductList",
}
</script>
