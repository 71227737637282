import { createInput } from "@formkit/vue"
import FormKitImageUploadInput from "/js/components/utilities/FormFields/FormKitImageUploadInput.vue"
import FormKitTagInputSelect from "/js/components/utilities/FormFields/FormKitTagInputSelect.vue"
import type { FormKitInputs } from "@formkit/inputs"
import type { PropType } from "vue"
import FormKitGalleryAttachmentInput from "/js/components/utilities/FormFields/FormKitGalleryAttachmentInput.vue"
import type { ProductAttachment } from "/js/models/Product"
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"

declare module "@formkit/inputs" {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    FormKitImageUploadInputType: {
      type: "FormKitImageUploadInputType"
      url: string | undefined
      aspectRatio: number | undefined
      publicBlob: boolean | undefined
    },
    FormKitGalleryAttachmentInputType: {
      type: "FormKitGalleryAttachmentInputType"
      url: string | undefined
      scope: MediaGalleryScope
      accept: string | undefined
      multiple: boolean | undefined
      publicBlob: boolean | undefined
      autoSubmit: boolean | undefined
      attachment?: ProductAttachment | undefined
    },
  }
}

export const FormKitImageUploadInputType = createInput(FormKitImageUploadInput, {
  props: ["url", "aspectRatio", "publicBlob", "assistant"],
})

export const FormKitGalleryAttachmentInputType = createInput(FormKitGalleryAttachmentInput, {
  props: ["url", "scope", "accept", "multiple", "publicBlob", "autoSubmit", "attachment"],
})

// not working:
export const FormKitTagInputSelectType = createInput(FormKitTagInputSelect)
