<template>
  <slot></slot>
</template>

<script setup lang="ts">

</script>

<script lang="ts">
export default {
  name: "FullScreenLayout"
}
</script>