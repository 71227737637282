import { del, get, patch, post } from "/js/composables/useAxios"

export const TourApi = {
  show: async (): Promise<string[]> => {
    return await get("/api/tour")
  },

  update: async (tour: string | string[]): Promise<string[]> => {
    return await patch("/api/tour", { tour })
  },

  reset: async (): Promise<string[]> => {
    return await del("/api/tour")
  },
}
