<template>
  <aside class="relative xl:flex xl:flex-col flex-shrink-0 w-96 border-l border-gray-200 overflow-y-auto empty:hidden">
    <div class="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
      <slot/>
    </div>
  </aside>
</template>

<script lang="ts">
export default {
  name: "RightSidebar"
}
</script>