<template>
  <div class="flex flex-col gap-3">
    <div class="flex items-center justify-between gap-2" v-if="title || !hideNav">
      <slot name="title">
        <h5>{{ title }}</h5>
      </slot>

      <div class="flex gap-2" v-if="!hideNav">
        <button
          class="bg-secondary text-secondary hover:bg-tertiary disabled:text-secondary disabled:hover:bg-secondary rounded-md px-3 py-3"
          @click="scrollLeft"
          :disabled="arrivedState.left">
          <chevron-left-icon />
        </button>
        <button
          class="bg-secondary text-secondary hover:bg-tertiary disabled:text-secondary disabled:hover:bg-secondary rounded-md px-3 py-3"
          @click="scrollRight"
          :disabled="isRight">
          <chevron-right-icon />
        </button>
      </div>
    </div>

    <div
      :class="{
        'full-width-paged-cards-parent': fullWidth,
        'paged-cards-parent': !fullWidth,
      }"
      class="flex w-full items-stretch gap-4 overflow-x-scroll scrollbar:h-0 scrollbar:w-0"
      ref="scroller">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChevronLeftIcon from "/js/components/icons/ChevronLeftIcon.vue"
import ChevronRightIcon from "/js/components/icons/ChevronRightIcon.vue"
import { ref, computed, onMounted, nextTick } from "vue"
import { useScroll } from "@vueuse/core"

defineProps<{
  title?: string
  hideNav?: boolean
  fullWidth?: boolean
}>()

const scroller = ref<HTMLElement | null>(null)
const { arrivedState, x } = useScroll(scroller)
const cardWidth = computed(() => {
  return scroller.value?.children[0]?.clientWidth ?? 0
})

const scrollRight = () => {
  scroller.value?.scrollBy({ left: cardWidth.value, behavior: "smooth" })
}

const scrollLeft = () => {
  scroller.value?.scrollBy({ left: -cardWidth.value, behavior: "smooth" })
}

const isRight = computed(() => {
  // hack: by default for some reason arrivedState.right is true the first time until you scroll a bit
  // so we check if x.value is also >= 0
  return arrivedState.right && x.value > 0
})

</script>

<script lang="ts">
export default {
  name: "PagedCards",
}
</script>
<style>
.full-width-paged-cards-parent > * {
  @apply w-full max-w-full;
}

.paged-cards-parent > * {
  @apply shrink-0 w-full max-w-sm;
}
</style>
