<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 8.5H5.33337C5.06004 8.5 4.83337 8.27333 4.83337 8C4.83337 7.72667 5.06004 7.5 5.33337 7.5H10.6667C10.94 7.5 11.1667 7.72667 11.1667 8C11.1667 8.27333 10.94 8.5 10.6667 8.5Z"
      fill="currentColor" />
    <path
      d="M8 11.1667C7.72667 11.1667 7.5 10.94 7.5 10.6667V5.33334C7.5 5.06001 7.72667 4.83334 8 4.83334C8.27333 4.83334 8.5 5.06001 8.5 5.33334V10.6667C8.5 10.94 8.27333 11.1667 8 11.1667Z"
      fill="currentColor" />
    <path
      d="M10 15.1667H6.00004C2.38004 15.1667 0.833374 13.62 0.833374 10V6.00001C0.833374 2.38001 2.38004 0.833344 6.00004 0.833344H10C13.62 0.833344 15.1667 2.38001 15.1667 6.00001V10C15.1667 13.62 13.62 15.1667 10 15.1667ZM6.00004 1.83334C2.92671 1.83334 1.83337 2.92668 1.83337 6.00001V10C1.83337 13.0733 2.92671 14.1667 6.00004 14.1667H10C13.0734 14.1667 14.1667 13.0733 14.1667 10V6.00001C14.1667 2.92668 13.0734 1.83334 10 1.83334H6.00004Z"
      fill="currentColor" />
  </svg>
</template>
