<template>
  <HoverCardRoot v-model:open="openState" :closeDelay :openDelay @update:open="onUpdateOpen">
    <HoverCardTrigger
      class="inline-block cursor-pointer outline-none focus:shadow-[0_0_0_2px_white]"
      rel="noreferrer noopener">
      <slot name="trigger"></slot>
    </HoverCardTrigger>
    <HoverCardPortal>
      <HoverCardContent
        class="!z-50 w-[400px] rounded-xl bg-white p-5 shadow-lg data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all"
        :side-offset="5">
        <slot></slot>
        <HoverCardArrow class="fill-white" size="8" />
      </HoverCardContent>
    </HoverCardPortal>
  </HoverCardRoot>
</template>

<script setup lang="ts">
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardPortal,
  HoverCardRoot,
  HoverCardTrigger,
} from "radix-vue"

const openState = defineModel<boolean>({ default: false })

withDefaults(
  defineProps<{
    openDelay?: number
    closeDelay?: number
  }>(),
  {
    openDelay: 0,
    closeDelay: 0,
  }
)

const emit = defineEmits<{
  open: [open: boolean]
}>()

const onUpdateOpen = (open: boolean) => {
  emit("open", open)
}
</script>

<script lang="ts">
export default {
  name: "HoverCard",
}
</script>