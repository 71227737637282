<template>
  <div>
    <gpt-chat-process-view-modal
      skip-button
      modal-type="app"
      :params="{
        process_type: 'generate_editor_content',
      }"
      @generated="handleGenerated"
      v-model="showForm"
      placeholder="Describe the content you want to generate" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { type ContentGeneratorPluginProps } from "./createVueComponentPlugin"
import GptChatProcessViewModal from "/js/components/GptChat/GptChatProcessViewModal.vue"
import type { GptMessage } from "/js/services/GptApi"
import {
  type EditorJsSchema,
  ZEditorJsSchema,
} from "/js/components/GptChat/EditorJs/EditorJsModels"
import { toastError } from "/js/composables/toast"
import { z } from "zod"

const props = defineProps<ContentGeneratorPluginProps>()

const showForm = ref(true)
const contentToReplace = ref<EditorJsSchema | null>(null)

const handleGenerated = (message: GptMessage) => {
  if (message.message_type === "generate_editor_content") {
    try {
      contentToReplace.value = ZEditorJsSchema.parse(JSON.parse(message.content))
      showForm.value = false
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error(error.issues)
        console.error(error, JSON.parse(message.content))
      } else {
        console.log(error)
      }

      toastError("Failed to parse generated content")
      return
    }
  }
}

watch(
  showForm,
  (value) => {
    if (!value) {
      if (contentToReplace.value) {
        props.replaceContent({
          type: "schema",
          data: contentToReplace.value
        })
      } else {
        props.replaceContent(null)
      }
    } else {
      contentToReplace.value = null
    }
  },
  { immediate: true }
)
</script>
