import { SupportedMediaTypes } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { type Component } from "vue"
import { z } from "zod"
import { ZUser } from "/js/models/User"

export const ProductAccessTypes = ["public_product", "members", "invite"] as const
export type ProductAccessType = (typeof ProductAccessTypes)[number]

export const ProductStatuses = ["draft", "published", "archived"] as const
export type ProductStatus = (typeof ProductStatuses)[number]

export type ProductAttachmentParams = {
  data: string
}

export type ProductAttachmentUpdateParams = {
  name: string
}

export const ZProductAttachment = z.object({
  id: z.string(),
  attachment_type: z.enum(SupportedMediaTypes),
  usage_count: z.number().nullable(),
  data_url: z.string().nullable(),
  data_thumb_url: z.string().nullable(),
  file_name: z.string().nullable(),
  size: z.number().nullable(),
  created_at: z.date(),
})

export const ZStorageAttachment = z.object({
  id: z.string(),
  attachment_type: z.enum(SupportedMediaTypes),
  data_url: z.string().nullable(),
  file_name: z.string().nullable(),
  created_at: z.date(),
})

export type ProductAttachment = z.infer<typeof ZProductAttachment>
export type StorageAttachment = z.infer<typeof ZStorageAttachment>

export const ZProduct = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  label: z.string().nullable(),
  access: z.enum(ProductAccessTypes),
  start_date: z.date().optional(),
  end_date: z.date().optional(),
  status: z.enum(ProductStatuses),
  capacity: z.number().optional(),
  auto_add_members: z.boolean().optional(),
  created_at: z.date(),
  cover_attachment: ZProductAttachment.optional(),
  instructors: ZUser.array(),
  has_events: z.boolean(),
  has_course: z.boolean(),
  has_faq: z.boolean(),
  rating: z.number().nullable(),
  ratings_count: z.number(),
  components_order: z.string().array().nullable(),
  role: ZProductRole.optional().nullable(),
})

export type Product = z.infer<typeof ZProduct>

export type ProductParams = {
  name?: string
  description?: string
  label?: string
  access?: ProductAccessType
  start_date?: string
  end_date?: string
  status?: ProductStatus
  capacity?: number
  cover_attachment_id?: string | null
  has_events?: boolean
  has_course?: boolean
  has_faq?: boolean
}

export const ProductTemplateTypes = [
  "blank",
  "cohort_based_course",
  "self_paced_course",
  "event_series",
  "member_space",
] as const
export type ProductTemplateType = (typeof ProductTemplateTypes)[number]

export const ProductTemplateItems = [
  "course",
  "feed",
  "collections",
  "events",
  "channels",
  "content",
  "faq",
] as const
export type ProductTemplateItem = (typeof ProductTemplateItems)[number]

export const ZProductTemplate = z.object({
  type: z.enum(ProductTemplateTypes),
  name: z.string(),
  hint: z.string(),
  items: z.enum(ProductTemplateItems).array(),
})

export type ProductTemplate = z.infer<typeof ZProductTemplate>

import {
  DocumentTextIcon,
  DocumentIcon,
  QueueListIcon,
  Square3Stack3DIcon,
  CalendarDaysIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/vue/24/outline"
import ProductTypeBlankIcon from "/js/components/icons/ProductTypeBlankIcon.vue"
import ProductTypeCohortIcon from "/js/components/icons/ProductTypeCohortIcon.vue"
import ProductTypeSPCIcon from "/js/components/icons/ProductTypeSPCIcon.vue"
import ProductTypeEventsIcon from "/js/components/icons/ProductTypeEventsIcon.vue"
import ProductTypeMemberIcon from "/js/components/icons/ProductTypeMemberIcon.vue"
import ClipboardIcon from "/js/components/icons/ClipboardIcon.vue"
import { formatDate } from "@vueuse/core"
import { ZProductRole } from "/js/services/permissionPolicyService"

export const productTemplateIcons: Record<ProductTemplateItem, Component> = {
  course: DocumentTextIcon,
  feed: QueueListIcon,
  collections: Square3Stack3DIcon,
  events: CalendarDaysIcon,
  channels: ChatBubbleBottomCenterTextIcon,
  content: DocumentIcon,
  faq: ClipboardIcon,
}

export const productTypeIcons: Record<ProductTemplateType, Component> = {
  blank: ProductTypeBlankIcon,
  cohort_based_course: ProductTypeCohortIcon,
  self_paced_course: ProductTypeSPCIcon,
  event_series: ProductTypeEventsIcon,
  member_space: ProductTypeMemberIcon,
}

export const productTypeBgClass: Record<ProductTemplateType, string> = {
  blank: "bg-sky-800",
  cohort_based_course: "bg-pink-300",
  self_paced_course: "bg-indigo-400",
  event_series: "bg-teal-600",
  member_space: "bg-cyan-600",
}

export const productTemplateLabels: Record<ProductTemplateType, string> = {
  blank: "Learning Hub",
  cohort_based_course: "Cohort Course",
  self_paced_course: "Self Paced Course",
  event_series: "Event Series",
  member_space: "Member Space",
}

export const productAccessLabels: Record<ProductAccessType, string> = {
  public_product: "Public",
  members: "Members",
  invite: "Invite Only",
}

export type ProductTemplateParams = {
  template: string
  product: ProductParams
}

export const isImageRepresentable = (attachment: ProductAttachment): boolean => {
  const type = attachment.attachment_type
  return (
    type === "image" || ((type === "video" || type === "document") && !!attachment.data_thumb_url)
  )
}

export const attachmentImageUrl = (
  attachment: ProductAttachment | null | undefined,
  options?: {
    preferThumb?: boolean
  }
): string | undefined => {
  if (!attachment) return undefined
  if (options?.preferThumb && attachment.data_thumb_url) {
    return attachment.data_thumb_url
  }

  if (isImageRepresentable(attachment)) {
    if (attachment.attachment_type === "image") {
      return attachment.data_url || attachment.data_thumb_url || undefined
    } else {
      return attachment.data_thumb_url || undefined
    }
  } else {
    return attachment.data_thumb_url || undefined
  }
}

export const productDurationLabel = (product: Product | undefined) => {
  if (!product) return ""
  if (product.start_date && product.end_date) {
    const startDate = new Date(product.start_date)
    const endDate = new Date(product.end_date)
    return `${formatDate(startDate, "MMM DD")} - ${formatDate(endDate, "MMM DD, YYYY")}`
  }
  return "No fixed duration"
}

export const productStartsInLabel = (product: Product | undefined) => {
  if (!product) return ""
  if (product.end_date && new Date(product.end_date).getTime() < Date.now()) return "Ended"
  if (product.start_date) {
    const startDate = new Date(product.start_date)
    const nrDays = Math.floor((startDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24))
    if (nrDays > 0) return `Starts in ${nrDays} ${nrDays === 1 ? "day" : "days"}`
    else {
      if (startDate.getTime() > Date.now()) return `Starts today`
      else return `Live now`
    }
  }
  return "No start date"
}

export const productAccessLabel = (access: ProductAccessType) => {
  if (access === "public_product") {
    return "Public"
  } else if (access === "members") {
    return "Members Only"
  } else if (access === "invite") {
    return "Invite Only"
  } else {
    return "Unknown"
  }
}