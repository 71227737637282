import { z } from "zod"
import { ProductAccessTypes, ZProductAttachment } from "/js/models/Product"
import {
  ChatRoomAccessTypes,
  ChatRoomTypes,
  ZChatMessageUser,
  ZChatRoomListItem,
} from "/js/models/Chat"
import type { RouteLocationNamedRaw } from "vue-router"
import { namedRoutes as learningHubRoutes } from "/js/router/LearningHubRoutes"
import { chatRouteNames } from "/js/router/ChatRoutes"
import { fullName } from "/js/models/User"
import { eventDateRouteFormatted } from "/js/router/EventsRoutes"

export const navigationResourcePostFeedType = z.literal("PostFeed")
export const navigationResourceUserType = z.literal("User")
export const navigationResourcePostType = z.literal("Post")
export const navigationResourceProductType = z.literal("Product")
export const navigationResourceChatRoomType = z.literal("ChatRoom")
export const navigationResourceEventType = z.literal("Event")

export const ZNavigationResourcesTypes = [
  navigationResourcePostFeedType.value,
  navigationResourceUserType.value,
  navigationResourcePostType.value,
  navigationResourceProductType.value,
  navigationResourceChatRoomType.value,
  navigationResourceEventType.value,
]

export type NavigationResourceType = (typeof ZNavigationResourcesTypes)[number]

export const ZNavigationResourceChatRoom = z.object({
  id: z.string(),
  name: z.string(),
  room_type: z.enum(ChatRoomTypes),
  room_access: z.enum(ChatRoomAccessTypes),
  product_id: z.string().nullable(),
  product_title: z.string().nullable(),
})

export const ZNavigationResourcePost = z.object({
  id: z.string(),
  title: z.string(),
  post_feed_id: z.string(),
  post_feed_title: z.string(),
  product_id: z.string(),
  product_title: z.string(),
  user: ZChatMessageUser,
})

export const ZNavigationResourcePostFeed = z.object({
  id: z.string(),
  title: z.string(),
  product_id: z.string(),
  product_title: z.string(),
})

export const ZNavigationResourceProduct = z.object({
  id: z.string(),
  name: z.string(),
  label: z.string().nullable(),
  access: z.enum(ProductAccessTypes),
  cover_attachment: ZProductAttachment.optional().nullable(),
})

export const ZNavigationResourceEvent = z.object({
  id: z.string(),
  start_date: z.date(),
  name: z.string(),
  product_id: z.string().nullable(),
  product_title: z.string().nullable(),
})

export const ZNavigationResource = z.union([
  z.object({
    type: navigationResourcePostFeedType,
    id: z.string(),
    object: ZNavigationResourcePostFeed,
  }),
  z.object({
    type: navigationResourceUserType,
    id: z.string(),
    object: ZChatMessageUser,
  }),
  z.object({
    type: navigationResourcePostType,
    id: z.string(),
    object: ZNavigationResourcePost,
  }),
  z.object({
    type: navigationResourceProductType,
    id: z.string(),
    object: ZNavigationResourceProduct,
  }),
  z.object({
    type: navigationResourceChatRoomType,
    id: z.string(),
    object: ZNavigationResourceChatRoom,
  }),
  z.object({
    type: navigationResourceEventType,
    id: z.string(),
    object: ZNavigationResourceEvent,
  }),
  // TODO: add content
])

export type NavigationResourcePostFeed = z.infer<typeof ZNavigationResourcePostFeed>
export type NavigationResourceUser = z.infer<typeof ZChatMessageUser>
export type NavigationResourcePost = z.infer<typeof ZNavigationResourcePost>
export type NavigationResourceProduct = z.infer<typeof ZNavigationResourceProduct>
export type NavigationResourceChatRoom = z.infer<typeof ZNavigationResourceChatRoom>
export type NavigationResourceEvent = z.infer<typeof ZNavigationResourceEvent>

export type NavigationResource = z.infer<typeof ZNavigationResource>

export const navigationResourceRoute = (
  resource: NavigationResource
): RouteLocationNamedRaw | undefined => {
  switch (resource.type) {
    case "Post": {
      return {
        name: learningHubRoutes.learning_hubs_show,
        params: {
          product_id: resource.object.product_id,
          post_feed_id: resource.object.post_feed_id,
          post_id: resource.object.id,
        },
      }
    }
    case "Product": {
      return {
        name: learningHubRoutes.learning_hubs_show,
        params: { product_id: resource.object.id },
      }
    }
    case "ChatRoom": {
      return {
        name: chatRouteNames.channel.show,
        params: { room_id: resource.object.id },
      }
    }
    case "PostFeed": {
      return {
        name: learningHubRoutes.learning_hubs_posts_index,
        params: { product_id: resource.object.product_id, post_feed_id: resource.object.id },
      }
    }
    case "Event": {
      return {
        name: learningHubRoutes.learning_hubs_events_edit,
        params: {
          date: eventDateRouteFormatted(resource.object.start_date),
          product_id: resource.object.product_id,
          event_id: resource.object.id,
        },
      }
    }
    case "User": {
      return undefined
    }
  }
}

export const navigationResourceTitle = (resource: NavigationResource): string => {
  switch (resource.type) {
    case "Post":
      return resource.object.title
    case "Product":
      return resource.object.name
    case "ChatRoom":
      return resource.object.name
    case "PostFeed":
      return resource.object.title
    case "Event":
      return resource.object.name
    case "User":
      return fullName(resource.object)
  }
}

export const searchResultTypeLabel = (type: NavigationResourceType) => {
  switch(type) {
    case "Post":
      return "Posts"
    case "Product":
      return "Learning Hubs"
    case "User":
      return "Users"
    case "Event":
      return "Events"
    case "PostFeed":
      return "Feeds"
    case "ChatRoom":
      return "Channels"
  }
}