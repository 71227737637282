<template>
  <modal v-model="showModal" title="Create Direct Message">
    <div class="gap-padding flex flex-col">
      <create-direct-message-form @save="handleCreateRoom" @cancel="showModal = false" />
    </div>
  </modal>
</template>

<script setup lang="ts">
import Modal from "/js/components/Modals/Modal.vue"
import type { ChatRoomListItem } from "/js/models/Chat"
import CreateDirectMessageForm from "/js/components/Chat/CreateDirectMessageForm.vue"
import {nextTick} from "vue";
import { generateChatRoute } from "/js/router/ChatRoutes"
import {useChatStoreV2} from "/js/stores/ChatStoreV2";
import {useRouter} from "vue-router";

const props = defineProps<{
  channelId?: string | undefined
}>()

const { insertChatRoom } = useChatStoreV2()
const router = useRouter()

const showModal = defineModel<boolean>({ required: true })

const emit = defineEmits<{
  save: [room: ChatRoomListItem]
}>()

const handleCreateRoom = (room: ChatRoomListItem) => {
  insertChatRoom(room)
  nextTick(() => {
    router.push(generateChatRoute("direct", "show", { room_id: room.id }))
  })
  showModal.value = false
  emit("save", room)
}

</script>

<script lang="ts">
export default {
  name: "CreateDirectMessageModal",
}
</script>
