<template>
  <div class="w-full flex flex-col gap-4">
    <accordion :items />
    <template v-if="!readOnly">
      <button type="button" class="btn btn-primary btn-sm self-start" @click="showModal = true">
        Edit Accordion
      </button>
      <modal modal-type="app" v-model="showModal" title="Edit Accordion">
        <draggable v-model="items" item-key="id" :animation="200" class="flex flex-col gap-4">
          <template #item="{ element, index }">
            <div class="padding rounded-lg bg-white shadow">
              <div>
                <div class="flex items-center justify-end gap-4">
                  <button
                    type="button"
                    @click="items.splice(items.indexOf(element), 1)"
                    class="text-danger">
                    <TrashIcon class="h-4 w-4" />
                  </button>
                </div>
              </div>

              <FormKit type="text" v-model="element.title" label="Title" />
              <FormKit type="textarea" v-model="element.content" label="Content">
                <template #help>
                  <markdown-help :portal="false" class="z-60-popover-content" />
                </template>
              </FormKit>
            </div>
          </template>
        </draggable>

        <div class="flex items-center justify-center">
          <button type="button" @click="appendBlankItem" class="padding btn-zoom">
            <PlusSquareIcon class="h-6 w-6" />
          </button>
        </div>
      </modal>
    </template>
  </div>
</template>

<script setup lang="ts">
import draggable from "vuedraggable"
import MarkdownHelp from "/js/components/utilities/MarkdownHelp.vue"
import { TrashIcon } from "@heroicons/vue/24/outline"
import type { AccordionPluginProps } from "/js/components/utilities/Editor/createVueComponentPlugin"
import { computed, onMounted, ref, watch } from "vue"
import PlusSquareIcon from "/js/components/icons/PlusSquareIcon.vue"
import { nanoid } from "nanoid"
import Accordion from "/js/components/utilities/Accordion.vue"
import type { AccordionWidgetItem } from "/js/models/Widget"
import Modal from "/js/components/Modals/Modal.vue"

const props = defineProps<AccordionPluginProps>()

const items = ref<AccordionWidgetItem[]>(
  props.editorData?.items ? [...props.editorData.items.map((item) => ({ ...item }))] : []
)

const showModal = ref(false)

onMounted(() => {
  if (!items.value.length) {
    appendBlankItem()
  }
})

watch(
  items,
  (value) => {
    if (value) {
      props.onUpdate({ items: value })
    }
  },
  { deep: true }
)

const appendBlankItem = () => {
  items.value = [...items.value, { id: nanoid(), title: "", content: "" }]
}
</script>

<script lang="ts">
export default {
  name: "AccordionPlugin",
}
</script>
