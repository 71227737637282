<template>
  <div class="drop-zone">
    <button type="button" class="btn btn-primary" @click="emit('showGallery')">Select Media</button>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  showGallery: []
}>()
</script>

<script lang="ts">
export default {
  name: "GallerySelectMedia",
}
</script>