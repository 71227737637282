<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 122.3 80.3"
    style="enable-background: new 0 0 122.3 80.3"
    xml:space="preserve">
    <g>
      <path
        class="st0"
        d="M94.3,0H65.4c-26,0-28,11.2-28,26.2l0,27.9c0,15.6,2,26.2,28.1,26.2h28.8c26,0,28-11.2,28-26.1V26.2
		C122.3,11.2,120.3,0,94.3,0z M0,20.1C0,6.9,5.2,0,14,0c8.8,0,14,6.9,14,20.1v40.1c0,13.2-5.2,20.1-14,20.1c-8.8,0-14-6.9-14-20.1
		V20.1z"></path>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
