<template>
  <modal :modalType v-model="showGallery" title="Media Gallery" no-backdrop-close>
    <media-gallery
      :scope
      autoSubmit
      :multiple="false"
      :modalType
      :assistant
      :accept
      @save="handleSave"
      @close="showGallery = false" />
  </modal>

  <div class="flex flex-col gap-3 w-full">
    <attachment-media
      v-for="attachment in attachments"
      :key="attachment.id"
      :attachment
      @remove="removeAttachment(attachment)"
      @change="changeAsset = attachment"
      :disableChange
      :disableRemove />
    <attachment-add-button @click="showGallery = true" />
  </div>
</template>

<script setup lang="ts">
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import Modal from "/js/components/Modals/Modal.vue"
import MediaGallery from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGallery.vue"
import { ref, watch } from "vue"
import { type ProductAttachment } from "/js/models/Product"
import AttachmentAddButton from "/js/components/utilities/FormFields/FileUpload/UI/AttachmentAddButton.vue"
import AttachmentMedia from "/js/components/utilities/FormFields/FileUpload/UI/AttachmentMedia.vue"
import type { ModalProps } from "/js/components/Modals/ModalTypes"
import {
  type SupportedMedia,
  SupportedMediaTypes,
} from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"

type Props = {
  modalType?: ModalProps["modalType"]
  accept?: SupportedMedia[]
  scope: MediaGalleryScope
  disabled?: boolean
  disableChange?: boolean
  disableRemove?: boolean
  assistant?: boolean
}

withDefaults(defineProps<Props>(), {
  accept: () => [...SupportedMediaTypes]
})

const attachments = defineModel<ProductAttachment[]>()

const showGallery = ref(false)

const changeAsset = ref<ProductAttachment | undefined>()

watch(changeAsset, (asset) => {
  if (asset) {
    showGallery.value = true
  }
})

const handleSave = (value: ProductAttachment) => {
  showGallery.value = false
  if (containsAttachment(value)) return

  if (changeAsset.value) {
    attachments.value = attachments.value?.map((a) => {
      if (a.id === changeAsset.value?.id) {
        return value
      }
      return a
    })
  } else {
    attachments.value = [...(attachments.value || []), value]
  }

  changeAsset.value = undefined
}

const containsAttachment = (attachment: ProductAttachment) => {
  return attachments.value?.some((a) => a.id === attachment.id)
}

const removeAttachment = (attachment: ProductAttachment) => {
  attachments.value = attachments.value?.filter((a) => a.id !== attachment.id)
}
</script>

<script lang="ts">
export default {
  name: "MediaGalleryAttachmentsInput",
}
</script>
