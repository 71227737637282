<template>
  <div v-if="productName" class="text-secondary flex text-xs">
    <p>
      Hosted in
      <router-link
          v-if="productId"
          :to="{
            name: learningHubRoutes.learning_hubs_show,
            params: { product_id: productId },
          }"
          class="font-bold text-blue-400">
        {{ productName }}
      </router-link>
      <span v-else>{{ productName }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import {namedRoutes as learningHubRoutes} from "/js/router/LearningHubRoutes";

defineProps<{
  productName?: string
  productId?: string
}>()
</script>

<script lang="ts">
export default {
  name: "HostedIn"
}
</script>