<template>
  <div>
    <main-sidebar-button :route="{ name: 'home' }">
      <template #link="{ isActive }">
        <PentagonFillIcon class="h-6 w-6" v-if="isActive" />
        <PentagonIcon class="h-6 w-6" v-else />
        Home
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: 'learning_hubs.index' }">
      <template #link="{ isActive }">
        <BookClosedFillIcon class="h-6 w-6" v-if="isActive" />
        <BookClosedIcon class="h-6 w-6" v-else />
        Learning Hubs
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: 'community_events.index' }">
      <template #link="{ isActive }">
        <CalendarFillIcon class="h-6 w-6" v-if="isActive" />
        <CalendarIcon class="h-6 w-6" v-else />
        Events
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: 'community_members.index' }">
      <template #link="{ isActive }">
        <MultiUserFillIconSm class="h-6 w-6" v-if="isActive" />
        <MultiUserIconSm class="h-6 w-6" v-else />
        Members
      </template>
    </main-sidebar-button>

    <main-sidebar-button :route="{ name: communityContentRouteNames.community_content_index }">
      <template #link="{ isActive }">
        <BookClosedFillIcon class="h-6 w-6" v-if="isActive" />
        <BookClosedIcon class="h-6 w-6" v-else />
        Library
      </template>
    </main-sidebar-button>

    <main-sidebar-button
      :route="generateChatRoute('direct', 'index', {})"
      start-collapsed
      :collapse-toggle="!!(directRooms && directRooms.length)">
      <template #link>
        <ChatBubbleSingle class="h-6 w-6" />
        <span class="flex-1">Direct Messages</span>
        <button type="button" @click.prevent.stop="showCreateDirectMessage = true">
          <PlusSquareIcon class="btn-zoom h-6 w-6" />
        </button>
      </template>
      <template #subnav>
        <main-sidebar-subnav
          :route="generateChatRoute('direct', 'show', { room_id: room.id })"
          v-for="room in directRooms"
          :title="room.name?.length ? room.name : 'no name'"
          :key="room.id">
          <direct-message-room-list-item :room :is-read="!!isRead(room)" />
        </main-sidebar-subnav>
      </template>
    </main-sidebar-button>

    <main-sidebar-button
      :route="generateChatRoute('channel', 'index', {})"
      collapse-toggle
      start-collapsed>
      <template #link>
        <ChatBubbleSingle class="h-6 w-6" />
        <span class="flex-1">Discussions</span>
        <create-channel-dropdown v-if="canManageCommunity" v-model="showCreateCommunityChannel" />
      </template>
      <template #subnav>
        <main-sidebar-subnav
          :route="generateChatRoute('channel', 'show', { room_id: room.id })"
          v-for="room in channelRooms"
          :key="room.id">
          <template #default>
            <div class="flex items-center gap-2" :class="{ 'font-bold': !isRead(room) }">
              <template v-if="room.product_id">
                <round-avatar
                  :avatar-url="room.product_cover_attachment_url"
                  :id="room.product_id"
                  size="xs"
                  no-text
                  rounded="rounded-lg" />
              </template>
              <template v-else>
                <span class="w-6 text-center">#</span>
              </template>
              {{ room.name }}
            </div>
          </template>
        </main-sidebar-subnav>
      </template>
    </main-sidebar-button>

    <create-channel-modal v-model="showCreateCommunityChannel" />
    <create-direct-message-modal v-model="showCreateDirectMessage" />
  </div>
</template>

<script setup lang="ts">
import MainSidebarButton from "/js/components/Layout/Sidebar/MainSidebarButton.vue"
import BookClosedFillIcon from "/js/components/icons/BookClosedFillIcon.vue"
import BookClosedIcon from "/js/components/icons/BookClosedIcon.vue"
import PentagonIcon from "/js/components/icons/PentagonIcon.vue"
import PentagonFillIcon from "/js/components/icons/PentagonFillIcon.vue"
import { generateChatRoute } from "/js/router/ChatRoutes"
import ChatBubbleSingle from "/js/components/icons/ChatBubbleSingle.vue"
import { storeToRefs } from "pinia"
import { useChatStoreV2 } from "/js/stores/ChatStoreV2"
import { computed, ref } from "vue"
import MainSidebarSubnav from "/js/components/Layout/Sidebar/MainSidebarSubnav.vue"
import PlusSquareIcon from "/js/components/icons/PlusSquareIcon.vue"
import CreateChannelModal, { type ChannelType } from "/js/components/Chat/CreateChannelModal.vue"
import type { ChatRoomListItem } from "/js/models/Chat"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import CreateDirectMessageModal from "/js/components/Chat/CreateDirectMessageModal.vue"
import DirectMessageRoomListItem from "/js/components/Layout/Sidebar/DirectMessageRoomListItem.vue"
import CreateChannelDropdown from "/js/components/Chat/CreateChannelDropdown.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import MultiUserIconSm from "/js/components/icons/MultiUserIconSm.vue"
import MultiUserFillIconSm from "/js/components/icons/MultiUserFillIconSm.vue"
import { useGate } from "/js/composables/useGate"
import { namedRoutes as communityContentRouteNames } from "/js/router/CommunityContentRoutes"
import BellIcon from "/js/components/icons/BellIcon.vue"
import { useNotificationsStore } from "/js/services/NotificationsStore"
import UnreadDotBellIcon from "/js/components/icons/UnreadDotBellIcon.vue"

const currentCommunityId = window.APP_CONFIG.COMMUNITY_ID
const { directRooms, channelRooms, roomTrackings } = storeToRefs(useChatStoreV2())

const isRead = (room: ChatRoomListItem) => {
  return roomTrackings.value[room.id]
}

const showCreateCommunityChannel = ref<ChannelType | undefined>(undefined)
const showCreateDirectMessage = ref(false)

const { communityGateStatus } = useGate()
const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const canManageCommunity = computed(() => communityGateStatus("community_edit") === "authorized")

const hasNotifications = computed(() => {
  if (currentCommunityId) {
    return communitiesUnreadCount.value[currentCommunityId] ?? 0 > 0
  } else {
    console.log("currentCommunityId is not set")
    return false
  }
})
</script>

<script lang="ts">
export default {
  name: "MainSidebar",
}
</script>
