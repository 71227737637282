<template>
  <div class="flex gap-5">
    <div class="flex items-center gap-2 truncate">
      <button @click="emit('follow')">
        <component
          :is="liked ? HeartIcon : HeartIconOutline"
          class="h-6 w-6"
          :class="{ 'text-red-600': liked }" />
      </button>

      <div class="text-secondary text-sm truncate">
        {{ likesCount || 0 }}
        <template v-if="!short">
          like{{ likesCount !== 1 ? "s" : "" }}
        </template>
      </div>
    </div>

    <button class="flex items-center gap-2 truncate" @click="emit('comment')">
      <ChatBubbleBottomCenterTextIconOutline class="h-6 w-6" />
      <span class="text-secondary text-sm truncate">
        {{ commentsCount || 0 }}
        <template v-if="!short">
          comment{{ commentsCount !== 1 ? "s" : "" }}
        </template>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { HeartIcon } from "@heroicons/vue/24/solid"
import {
  HeartIcon as HeartIconOutline,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconOutline,
} from "@heroicons/vue/24/outline"

const props = defineProps<{
  likesCount: number | null | undefined
  commentsCount: number | null | undefined
  liked: boolean
  short?: boolean
}>()

const emit = defineEmits<{
  follow: []
  comment: []
}>()

</script>

<script lang="ts">
export default {
  name: "PostLikes",
}
</script>
