import { get, patch, post, del, getZod, patchZod, delZod } from "/js/composables/useAxios"
import { type User, type UserCommunityRole, type UserProfile, ZUserProfile } from "/js/models/User"
import type { UserGroup } from "/js/services/permissionPolicyService"
import type { EventItem } from "/js/models/EventModels"
import type { Product } from "/js/models/Product"

interface UserParams {
  first_name?: string
  last_name?: string
  avatar?: string
  cover?: string
  description?: string
}

export type PermissionPolicy = {
  id: string
  type: string
  show: boolean
  edit: boolean
  delete: boolean
}

export type UpdatePasswordParams = {
  current_password: string
  password: string
  password_confirmation: string
}

export type UpdateEmailParams = {
  email: string
  current_password: string
}

export const UsersApi = {
  logActivity: async (): Promise<void> => {
    return await post(`/api/user_activity`)
  },

  logPageView: async (paths: string[]): Promise<void> => {
    return await post(`/api/page_views`, { paths })
  },

  getUser: async (id: string): Promise<User> => {
    return await get(`/api/users/${id}`)
  },

  getCurrentUser: async (): Promise<UserProfile> => {
    return await getZod(`/api/users/profile`, ZUserProfile)
  },

  updateUser: async (params: UserParams): Promise<UserProfile> => {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined && v !== null)
    )
    return await patchZod(`/api/users/update_profile`, { user: filteredParams }, ZUserProfile)
  },

  updatePassword: async (user: UpdatePasswordParams): Promise<UserProfile> => {
    return await patchZod(`/api/users/update_password`, { user }, ZUserProfile)
  },

  updateEmail: async (user: UpdateEmailParams): Promise<UserProfile> => {
    return await patchZod(`/api/users/update_email`, { user }, ZUserProfile)
  },

  cancelUpdateEmail: async (): Promise<UserProfile> => {
    return await delZod(`/api/users/cancel_update_email`, ZUserProfile)
  },

  logout: async (): Promise<void> => {
    return await del(`/api/session`)
  },

  getUserGroups: (): Promise<UserGroup[]> => get(`/api/user_groups`),

  getCommunityRole: (userId: string): Promise<UserCommunityRole> =>
    get(`/api/users/${userId}/community_role`),

  getUserEvents: (userId: string): Promise<EventItem[]> =>
    get(`/api/users/${userId}/events`),

  getUserProducts: (userId: string): Promise<Product[]> =>
    get(`/api/users/${userId}/products`),
}
