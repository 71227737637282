<template>
  <content-view
    :is-error="pollIsError"
    :is-empty="pollSuccess && !poll"
    :isLoading="pollLoading">
    <template #error>
      <error-message :error="pollError" />
    </template>
    <template #empty>
      <empty-view title="No poll found">
        <contents-empty-icon class="h-40 w-40" />
      </empty-view>
    </template>
    <template #default>
      <poll-card :poll="poll" v-if="poll" />
    </template>
  </content-view>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import { pollQueryKey, PollsApi } from "/js/services/PollsApi"
import ContentView from "/js/components/utilities/UI/ContentView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import ContentsEmptyIcon from "/js/components/icons/ContentsEmptyIcon.vue"
import PollCard from "/js/components/Polls/PollCard.vue"

const props = defineProps<{
  pollId: string
}>()

const {
  data: poll,
  isLoading: pollLoading,
  isSuccess: pollSuccess,
  isError: pollIsError,
  error: pollError,
} = useQuery({
  queryKey: pollQueryKey(props.pollId),
  queryFn: async () => {
    return await PollsApi.getPoll(props.pollId)
  },
})
</script>

<script lang="ts">
export default {
  name: "PollCardLoader",
}
</script>
