<template>
  <div class="flex flex-col gap-7">
    <h5 class="flex items-center gap-2">
      Comments
      <spinner v-if="isLoading" class="h-4 w-4"></spinner>
    </h5>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col" v-for="comment in comments" :key="comment.id">
        <comment-view
          v-model:reply-to-comment="replyToComment"
          :comment="comment"
          :level="0"
          :config="config" />
      </div>
    </div>
    <FormKit
      type="textarea"
      placeholder="type something here..."
      v-model="body"
      name="message"
      label="" />
    <button @click="addComment" class="btn btn-primary self-end" :disabled="!bodyHasContent">
      Add Comment
    </button>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import { computed, ref } from "vue"
import type { Comment } from "/js/models/Comment"
import CommentView from "/js/components/Posts/CommentView.vue"

import Spinner from "/js/components/icons/Spinner.vue"
import {
  useCommentsService,
  commentListQueryKey,
  getApiComments,
} from "/js/services/useCommentsService"
import type { CommentableConfig } from "/js/services/useCommentsService"

const props = defineProps<{
  config: CommentableConfig
}>()

const { addCommentToCommentable } = useCommentsService(props.config)

const body = ref<string | undefined>()
const replyToComment = ref<Comment | undefined>()

const bodyHasContent = computed(() => {
  return body.value && body.value.length > 0
})

const addComment = async () => {
  if (!body.value) return

  try {
    await addCommentToCommentable.mutateAsync({
      config: props.config,
      body: body.value,
    })
    body.value = undefined
  } catch (e) {
    console.log(e)
  }
}

const { data: comments, isLoading } = useQuery({
  queryKey: computed(() => commentListQueryKey(props.config)),
  queryFn: async () => await getApiComments(props.config),
})
</script>

<script lang="ts">
export default {
  name: "CommentList",
}
</script>
