import type { RouteMeta, RouteRecordRaw } from "vue-router"
import { RouterView } from "vue-router"

export const adminRouteNames = {
  dashboard: "admin.dashboard",
  members: "admin.members",
  settings: "admin.settings",
  content: "admin.content",
  events: "admin.events",
  learning_hubs: "admin.learning_hubs",
  surveys: "admin.surveys",
  polls: "admin.polls"
}

export const membersRouteNames = {
  members: "admin.members.index",
  invites: "admin.members.invites",
  applications: "admin.members.applications",
  groups: "admin.members.groups",
  profile_fields: "admin.members.profile_fields",
  onboarding: "admin.members.onboarding",
  roles: "admin.members.roles",
}

export const settingsRouteNames = {
  general: "admin.settings.general",
  appearance: "admin.settings.appearance",
  registrationEdit: "admin.settings.registration.edit",
  appearanceBuild: "admin.settings.appearance.build",
}

export const contentRouteNames = {
  content: "admin.content.index",
  mediaAssets: "admin.content.media_assets",
}

export const eventsRouteNames = {
  calendarRange: "admin.events.calendar.range",
}

export const learningHubsRouteNames = {
  learningHubs: "admin.learning_hubs.index",
  staticPages: "admin.learning_hubs.static_pages",
  plans: "admin.learning_hubs.plans",
}

export const billingRouteNames = {
  billing: "admin.billing.index",
}

export const surveysRouteNames = {
  surveys: "admin.surveys.index",
  showSurvey: "admin.surveys.show",
  editSurvey: "admin.surveys.edit",
}

export const pollsRouteNames = {
  polls: "admin.polls.index"
}

const metaCommunityEditRequired: RouteMeta = {
  authorize: {
    classType: "Community",
    authorizeFor: "community_edit",
    requiresPlatformPlan: true,
  },
}

const metaCommunityBillingRequired: RouteMeta = {
  authorize: {
    classType: "Community",
    authorizeFor: "community_billing",
    requiresPlatformPlan: false,
  },
}

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "dashboard",
    name: adminRouteNames.dashboard,
    component: () => import("/js/components/Admin/Dashboard/Dashboard.vue"),
    meta: metaCommunityEditRequired,
  },
  {
    path: "settings",
    name: adminRouteNames.settings,
    meta: metaCommunityEditRequired,
    component: RouterView,
    redirect: { name: settingsRouteNames.general },
    children: [
      {
        path: "",
        name: settingsRouteNames.general,
        component: () => import("/js/components/Admin/Settings/General.vue"),
      },
      {
        path: "registration/edit",
        name: settingsRouteNames.registrationEdit,
        component: () => import("/js/components/Admin/Settings/RegistrationEdit.vue"),
      },
    ],
  },
  {
    path: "learning_hubs",
    name: adminRouteNames.learning_hubs,
    component: RouterView,
    redirect: { name: learningHubsRouteNames.learningHubs },
    meta: metaCommunityEditRequired,
    children: [
      {
        path: "",
        name: learningHubsRouteNames.learningHubs,
        component: () => import("/js/components/Admin/LearningHubs/LearningHubs.vue"),
        meta: metaCommunityEditRequired,
      },
      {
        path: "static_pages",
        name: learningHubsRouteNames.staticPages,
        component: () => import("/js/components/Admin/LearningHubs/StaticPages/StaticPages.vue"),
        meta: metaCommunityEditRequired,
      },
      {
        path: "plans",
        name: learningHubsRouteNames.plans,
        component: () => import("/js/components/Admin/LearningHubs/Plans/Plans.vue"),
        meta: metaCommunityEditRequired,
      },
    ],
  },
  {
    path: "members",
    name: adminRouteNames.members,
    component: RouterView,
    redirect: { name: membersRouteNames.members },
    children: [
      {
        path: "",
        name: membersRouteNames.members,
        component: () => import("/js/components/Admin/Members/Members.vue"),
        meta: metaCommunityEditRequired,
      },
      {
        path: "invites",
        name: membersRouteNames.invites,
        component: () => import("/js/components/Admin/Members/Invites/Invites.vue"),
        meta: metaCommunityEditRequired,
      },
      {
        path: "groups",
        name: membersRouteNames.groups,
        component: () => import("/js/components/Admin/Members/Groups/Groups.vue"),
        meta: metaCommunityEditRequired,
      },
    ],
  },
  {
    path: "events",
    name: adminRouteNames.events,
    component: () => import("/js/components/Admin/Events/Events.vue"),
    meta: metaCommunityEditRequired,
    children: [
      {
        path: ":calendarView/:calendarDate",
        name: eventsRouteNames.calendarRange,
        component: () => import("/js/components/Admin/Events/Events.vue"),
        meta: metaCommunityEditRequired,
      },
    ],
  },
  {
    path: "content",
    name: adminRouteNames.content,
    component: RouterView,
    meta: metaCommunityEditRequired,
    redirect: { name: contentRouteNames.content },
    children: [
      {
        path: "",
        name: contentRouteNames.content,
        component: () => import("/js/components/Admin/Content/Content.vue"),
        meta: metaCommunityEditRequired,
      },
      {
        path: "assets",
        name: contentRouteNames.mediaAssets,
        component: () => import("/js/components/Admin/Content/MediaAssets/MediaAssets.vue"),
        meta: metaCommunityEditRequired,
      },
    ],
  },
  {
    path: "surveys",
    name: surveysRouteNames.surveys,
    component: () => import("/js/components/Admin/Surveys/Surveys.vue"),
    meta: metaCommunityEditRequired,
  },
  {
    path: "polls",
    name: pollsRouteNames.polls,
    component: () => import("/js/components/Admin/Polls/Polls.vue"),
    meta: metaCommunityEditRequired,
  },
  {
    path: "billing",
    name: billingRouteNames.billing,
    component: () => import("/js/components/Admin/Billing/Billing.vue"),
    meta: metaCommunityBillingRequired,
  },
]
