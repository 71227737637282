<template>
  <slot></slot>
  <div ref="bottomMessageRef"></div>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from "vue"
import { useElementVisibility } from "@vueuse/core"

const props = defineProps<{
  data: any
  generating: boolean | undefined
}>()

const bottomMessageRef = ref<HTMLElement>()

const bottomMessageVisible = useElementVisibility(bottomMessageRef)

const scrollToBottom = () => {
  bottomMessageRef.value?.scrollIntoView()
}

watch(
  () => props.data,
  () => {
    if (bottomMessageVisible.value) {
      nextTick(scrollToBottom)
    }
  },
  { immediate: true }
)

watch(
  () => props.generating,
  (value) => {
    if (value && bottomMessageVisible.value) {
      scrollToBottom()
    }
  },
  { immediate: true }
)
</script>

<script lang="ts">
export default {
  name: "GptScroller",
}
</script>
