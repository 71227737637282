export const resizeBlob = async (
  blob: File | Blob,
  maxWidth: number,
  quality = 1
): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create an image and set its source to the blob URL
    const img = new Image()
    img.src = url

    img.onload = () => {
      // Check if the image is already smaller than the maximum width
      if (img.width <= maxWidth) {
        // If smaller, resolve the original blob without resizing
        resolve(blob)
      } else {
        // Determine the scaling factor
        const scaleFactor = maxWidth / img.width

        // Determine the new dimensions
        const newWidth = img.width * scaleFactor
        const newHeight = img.height * scaleFactor

        // Create a canvas and set its dimensions
        const canvas = document.createElement("canvas")
        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the image onto the canvas at the new dimensions
        const ctx = canvas.getContext("2d")
        if (!ctx) {
          return reject("Could not create canvas context")
        }

        ctx.drawImage(img, 0, 0, newWidth, newHeight)

        // Export the canvas to a Blob with the specified quality
        canvas.toBlob(
          (resizedBlob) => {
            if (resizedBlob) {
              resolve(resizedBlob)
            } else {
              reject("Could not create canvas blob")
            }
          },
          "image/jpeg",
          quality
        )

        // Revoke the URL to free up resources
        URL.revokeObjectURL(url)
      }
    }
  })
}
