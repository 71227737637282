<template>
  <div class="flex items-center justify-between gap-4 rounded-lg px-2 hover:bg-gray-50">
    <div class="flex items-center gap-4">
      <img
        :src="thumbUrl"
        alt="preview image"
        v-if="hasImageRepresentation && thumbUrl"
        :class="{ 'opacity-60': loading }"
        class="h-8 w-8 rounded object-cover" />

      <div v-else class="h-8 w-8 rounded bg-gray-100 flex items-center justify-center">
        <PaperClipIcon class="h-4 w-4" :class="{ 'opacity-60': loading }" />
      </div>

      <div class="flex flex-col items-start">
        <div class="text-primary text-xs md:text-sm">{{ fileName || "file" }}</div>
        <div class="text-danger text-xs" v-if="error">
          There was an error while uploading this file
        </div>
      </div>
    </div>

    <spinner class="text-primary h-8 w-8" v-if="loading" />

    <div class="flex items-center gap-4" v-else>
      <button
        v-if="!disableChange"
        type="button"
        class="text-accent text-sm"
        @click="emit('change')">
        Change
      </button>

      <button v-if="error" type="button" class="text-danger text-sm" @click="emit('retry')">
        Retry
      </button>

      <template v-else>
        <a class="text-accent text-sm !no-underline" :href="downloadUrl" target="_blank">Download</a>
        <button
          v-if="!remove && !disableRemove"
          type="button"
          class="text-danger text-sm"
          @click="remove = true">
          Remove
        </button>
        <div class="flex gap-2" v-if="remove">
          <button type="button" class="btn btn-xs btn-secondary" @click="remove = false">
            Cancel
          </button>
          <button type="button" class="btn btn-xs btn-danger" @click="emit('remove')">
            Remove
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PaperClipIcon } from "@heroicons/vue/24/outline"
import Spinner from "/js/components/icons/Spinner.vue"
import { computed, ref } from "vue"
import {
  attachmentImageUrl,
  isImageRepresentable,
  type ProductAttachment,
} from "/js/models/Product"
import {
  type Asset, assetDataUrl,
  assetFileName, assetImageRepresentable,
  assetThumbUrl,
} from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"

const props = defineProps<{
  attachment?: ProductAttachment
  asset?: Asset
  loading?: boolean
  error?: boolean
  disableChange?: boolean
  disableRemove?: boolean
}>()

const remove = ref(false)

const hasImageRepresentation = computed(() => {
  if (props.attachment) {
    return isImageRepresentable(props.attachment)
  } else if (props.asset) {
    return assetImageRepresentable(props.asset)
  }
  return false
})

const thumbUrl = computed(() => {
  if (props.attachment) {
    return attachmentImageUrl(props.attachment)
  } else if (props.asset) {
    return assetThumbUrl(props.asset)
  }
  return undefined
})

const downloadUrl = computed(() => {
  if (props.attachment) {
    return props.attachment.data_url
  } else if (props.asset) {
    return assetDataUrl(props.asset)
  }
  return undefined
})

const fileName = computed(() => {
  if (props.attachment) {
    return props.attachment.file_name
  } else if (props.asset) {
    return assetFileName(props.asset)
  }
  return undefined
})

const emit = defineEmits<{
  remove: []
  change: []
  retry: []
}>()
</script>

<script lang="ts">
export default {
  name: "AttachmentMedia",
}
</script>
