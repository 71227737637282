<template>
  <slot v-if="show"></slot>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue"

const props = defineProps<{
  nextTick?: boolean
  delay?: number
}>()

const show = ref(false)

onMounted(() => {
  if (props.nextTick) {
    nextTick(() => {
      show.value = true
    })
  } else {
    setTimeout(() => {
      show.value = true
    }, props.delay || 100)
  }
})
</script>

<script lang="ts">
export default {
  name: "DelayView",
}
</script>
