import { QueryClient, useMutation, useQueryClient } from "@tanstack/vue-query"
import { CommentsApi } from "/js/services/CommentsApi"
import { useServiceHelpers } from "/js/services/useServiceHelpers"
import type { Comment } from "/js/models/Comment"
import { usePostService } from "/js/services/usePostService"

export type CreateCommentMutationParams = {
  config: CommentableConfig
  body: string
}

export type CreateReplyMutationParams = {
  commentId: string
  config: CommentableConfig
  body: string
}

export type CommentableConfig =
  | {
  type: "post"
  postFeedId: string
  postId: string
} | {
  type: "collection_item"
  collectionId: string
  collectionItemId: string
} | {
  type: "product_content"
  productContentId: string
} | {
  type: "community_content"
  communityContentId: string
} | {
  type: "goal_objective"
  goalObjectiveId: string
}

export const getCommentableId = (config: CommentableConfig) => {
  switch (config.type) {
    case "post":
      return config.postId
    case "collection_item":
      return config.collectionItemId
    case "product_content":
      return config.productContentId
    case "community_content":
      return config.communityContentId
    case "goal_objective":
      return config.goalObjectiveId
  }
}

export const commentListQueryKey = (config: CommentableConfig) => [
  "comment_list",
  config.type,
  getCommentableId(config),
]

export const createApiComment = (config: CommentableConfig, body: string): Promise<Comment> => {
  switch (config.type) {
    case "post":
      return CommentsApi.createPostComment(getCommentableId(config), { body })
    case "collection_item":
      return CommentsApi.createCollectionItemComment(getCommentableId(config), { body })
    case "product_content":
      return CommentsApi.createProductContentComment(getCommentableId(config), { body })
    case "community_content":
      return CommentsApi.createCommunityContentComment(getCommentableId(config), { body })
    case "goal_objective":
      return CommentsApi.createGoalObjectiveComment(getCommentableId(config), { body })
  }
}

export const getApiComments = (config: CommentableConfig): Promise<Comment[]> => {
  switch (config.type) {
    case "post":
      return CommentsApi.getPostComments(getCommentableId(config))
    case "collection_item":
      return CommentsApi.getCollectionItemComments(getCommentableId(config))
    case "product_content":
      return CommentsApi.getProductContentComments(getCommentableId(config))
    case "community_content":
      return CommentsApi.getCommunityContentComments(getCommentableId(config))
    case "goal_objective":
      return CommentsApi.getGoalObjectiveComments(getCommentableId(config))
  }
}

export const replyCommentListQueryKey = (commentId: string) => ["reply_comment_list", commentId]

const makeHandleCacheUpdate = (
  config: CommentableConfig,
  queryClient: QueryClient,
): (() => void) => {
  switch (config.type) {
    case "post":
      const { fetchLocalPost, syncPost } = usePostService(queryClient)

      return () => {
        const post = fetchLocalPost(config.postId, config.postFeedId)

        if (post) {
          syncPost({ ...post, comments_count: (post.comments_count ?? 0) + 1 })
        }
      }
    case "collection_item":
      console.warn("todo: implement handleCacheUpdate for collection_item")
      return () => {
      }
    case "product_content":
      console.warn("todo: implement handleCacheUpdate for product_content")
      return () => {
      }
    case "community_content":
      console.warn("todo: implement handleCacheUpdate for community_content")
      return () => {
      }
    case "goal_objective":
      console.warn("todo: implement handleCacheUpdate for goal_objective")
      return () => {
      }
  }
}

export const useCommentsService = (config: CommentableConfig) => {
  const queryClient = useQueryClient()
  const { appendItem } = useServiceHelpers(queryClient)

  // we create a local function here since usePostService calls useMutation internally which needs to happen in vue setup
  // creating the usePostService when calling onSuccess below for example won't work
  // todo: find a better solution
  const handleCacheUpdate = makeHandleCacheUpdate(config, queryClient)

  const addCommentToCommentable = useMutation({
    mutationFn: async ({ config, body }: CreateCommentMutationParams) =>
      await createApiComment(config, body),
    onSuccess: (comment, variables, context) => {
      handleCacheUpdate()
      appendItem(commentListQueryKey(config), comment)
    },
  })

  const addReplyToComment = useMutation({
    mutationFn: async ({ commentId, config, body }: CreateReplyMutationParams) =>
      await CommentsApi.createChildComment(commentId, { body }),
    onSuccess: (comment, variables, context) => {
      handleCacheUpdate()
      appendItem(replyCommentListQueryKey(variables.commentId), comment)
    },
  })

  return {
    addCommentToCommentable,
    addReplyToComment,
  }
}
