<template>
  <div class="relative">
    <router-link :to="route" #default="{ href, navigate, isActive, isExactActive }">
      <template v-if="updateIsActiveState(isActive, isExactActive)">
        <button
          v-if="collapseToggle"
          @click.prevent.stop="showCollapsed = !showCollapsed"
          class="absolute top-3 transition-transform dark:text-white"
          :class="[isActiveState ? 'text-white' : 'text-primary', showCollapsed ? 'rotate-90' : '']">
          <ArrowRightIcon class="h-4 w-4" />
        </button>

        <a
          :href="href"
          @click="handleClick(navigate)"
          class="group flex items-center gap-3 rounded-xl px-4 py-2 text-sm"
          :class="[isActiveState ? 'bg-teal-600 text-white' : 'text-primary']">
          <slot name="link" :isActive="isActiveState"></slot>
        </a>
      </template>
    </router-link>
  </div>

  <div v-auto-animate v-if="hasSubnav">
    <div
      v-if="showCollapsed"
      class="ml-7 flex flex-col gap-2 border-l py-2 dark:border-ll-green-450 empty:hidden">
      <slot name="subnav" :isActive="isActiveState" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ArrowRightIcon from "/js/components/icons/ArrowRightIcon.vue"
import type { RouteLocationRaw } from "vue-router"
import { ref, watch, useSlots } from "vue"

const slots = useSlots()

const hasSubnav = slots.subnav !== undefined

const props = defineProps<{
  route: RouteLocationRaw
  collapseToggle?: boolean
  startCollapsed?: boolean
  exact?: boolean
}>()

const showCollapsed = ref(props.startCollapsed ?? false)

const isActiveState = ref(false)

watch(isActiveState, (isActive) => {
  if (isActive) {
    showCollapsed.value = true
  }
})

function updateIsActiveState(isActive: boolean, isExactActive: boolean) {
  isActiveState.value = props.exact ? isExactActive : isActive
  // Always return true to render the default slot content
  return true
}

const handleClick = (navigate: () => void) => {
  if (props.collapseToggle) {
    showCollapsed.value = true
  }
  navigate()
}
</script>

<script lang="ts">
export default {
  name: "MainSidebarButton",
}
</script>
