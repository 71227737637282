import { z } from "zod"
import {
  getZod,
  postZod,
  post,
  type PaginationData,
  getZodPaginated,
} from "/js/composables/useAxios"
import { jsonAutocomplete } from "/js/composables/jsonAutocomplete"
import { parse, Allow } from "partial-json"
import { ZCourseOutline } from "/js/components/GptChat/CourseOutline/CourseOutlineModels"
import type { DeepPartialNullable } from "/js/types/PartialTypes"
import { ZGptMessageTextGenerate } from "/js/components/GptChat/TextGpt/TextGptModels"
import { ZEditorJsSchema } from "/js/components/GptChat/EditorJs/EditorJsModels"
import { makeGetUrl } from "/js/composables/makeQueryString"
import { ZGenerateQuizQuestions } from "/js/components/GptChat/GenerateQuizQuestions/GenerateQuizQuestionsModels"
import { ZStorageAttachment } from "/js/models/Product"

export const GptProcessTypeCreateCourseOutline = z.literal("create_course_outline")
export const GptProcessTypeGenerateEditorContent = z.literal("generate_editor_content")
export const GptProcessTypeGenerateGeneralChat = z.literal("general_chat")
export const GptProcessTypeGenerateText = z.literal("generate_text")
export const GptProcessTypeAddCourseUnits = z.literal("add_course_units")
export const GptProcessTypeGenerateQuizQuestions = z.literal("generate_quiz_questions")
export const GptProcessTypeGenerateImage = z.literal("generate_image")

export const GptProcessTypes = [
  GptProcessTypeCreateCourseOutline.value,
  GptProcessTypeGenerateEditorContent.value,
  GptProcessTypeGenerateGeneralChat.value,
  GptProcessTypeGenerateText.value,
  GptProcessTypeAddCourseUnits.value,
  GptProcessTypeGenerateQuizQuestions.value,
  GptProcessTypeGenerateImage.value,
] as const

export const ZGptChat = z.object({
  id: z.string(),
  process_type: z.enum(GptProcessTypes),
  title: z.string().nullable(),
  created_at: z.date(),
})

export const ZGptMessageRoles = ["user", "assistant", "system"] as const
export const ZGptMessageStatuses = ["pending", "completed", "failed"] as const

export const GptMessageTypeMarkdown = z.literal("markdown")
export const GptMessageTypeCourseOutline = z.literal("course_outline")
export const GptMessageTypeGenerateText = z.literal("generate_text")
export const GptMessageTypeGenerateEditorContent = z.literal("generate_editor_content")
export const GptMessageTypeAddCourseUnits = z.literal("add_course_units")
export const GptMessageTypeGenerateQuizQuestions = z.literal("generate_quiz_questions")
export const GptMessageTypeGenerateImage = z.literal("generate_image")

// Message Type describes the type of content/structure that the message contains
export const GptMessageTypes = [
  GptMessageTypeMarkdown.value,
  GptMessageTypeCourseOutline.value,
  GptMessageTypeGenerateText.value,
  GptMessageTypeGenerateEditorContent.value,
  GptMessageTypeAddCourseUnits.value,
  GptMessageTypeGenerateQuizQuestions.value,
  GptMessageTypeGenerateImage.value,
] as const

// Message types that should be sent to the API for generation based on their json content
export const GptGeneratorMessageTypes = [
  GptMessageTypeCourseOutline.value,
  GptMessageTypeAddCourseUnits.value,
] as const

export const GptActionableMessageTypes = [
  GptMessageTypeCourseOutline.value,
  GptMessageTypeGenerateText.value,
  GptMessageTypeGenerateEditorContent.value,
  GptMessageTypeAddCourseUnits.value,
  GptMessageTypeGenerateQuizQuestions.value,
  GptMessageTypeGenerateImage.value,
] as const

export const isGptMessageActionableType = (gptMessage: GptMessage) => {
  return (
    gptMessage.role === "assistant" &&
    gptMessage.status === "completed" &&
    GptActionableMessageTypes.includes(gptMessage.message_type as any)
  )
}

export const isGptMessageGeneratorType = (gptMessage: GptMessage) => {
  return (
    gptMessage.role === "assistant" &&
    gptMessage.status === "completed" &&
    GptGeneratorMessageTypes.includes(gptMessage.message_type as any)
  )
}

export type GptMessageType = (typeof GptMessageTypes)[number]

export const GptMessageTypeSchemas = {
  [GptMessageTypeMarkdown.value]: z.string(),
  [GptMessageTypeCourseOutline.value]: ZCourseOutline,
  [GptMessageTypeGenerateText.value]: ZGptMessageTextGenerate,
  [GptMessageTypeGenerateEditorContent.value]: ZEditorJsSchema,
  [GptMessageTypeAddCourseUnits.value]: ZCourseOutline,
  [GptMessageTypeGenerateQuizQuestions.value]: ZGenerateQuizQuestions,
  [GptProcessTypeGenerateImage.value]: z.undefined()
}

// TODO: specialize the processable type based on the process_type
export type CreateChatParams = {
  processable_type?: "Product" | "CourseUnit"
  processable_id?: string
  process_type?: (typeof GptProcessTypes)[number]
}

// Converts a GptMessage's content string to what we consider a valid object based on its message_type
export const dataStringToObject = <T extends GptMessageType>(
  data: string,
  gptMessageType: T
): z.infer<(typeof GptMessageTypeSchemas)[T]> => {
  const schema = GptMessageTypeSchemas[gptMessageType]
  return schema.parse(JSON.parse(data))
}

const parseJsonWithAutocomplete = (data: string | undefined) => {
  const jsonString = jsonAutocomplete(data)

  if (!jsonString) {
    console.log("no json")
    return undefined
  }

  try {
    return JSON.parse(jsonString)
  } catch (error) {
    console.log("failed to parse json", { jsonString, data })
    return undefined
  }
}

const parseJsonWithAllow = (data: string | undefined) => {
  try {
    if (!data) {
      return undefined
    }
    return parse(data, Allow.ALL)
  } catch (error) {
    console.log("failed to parse json", { data })
    return undefined
  }
}

export const dataStringToPartialObject = <T extends GptMessageType>(
  data: string,
  gptMessageType: T
): DeepPartialNullable<z.infer<(typeof GptMessageTypeSchemas)[T]>> | undefined => {
  // TODO find a way to properly infer this deep/partial type
  return parseJsonWithAllow(data)
}

export const ZGptMessage = z.object({
  id: z.string(),
  content: z.string(),
  message_type: z.enum(GptMessageTypes),
  gpt_chat_id: z.string(),
  created_at: z.date(),
  role: z.enum(ZGptMessageRoles),
  status: z.enum(ZGptMessageStatuses).nullable(),
  gpt_image: ZStorageAttachment.nullable(),
})

export type GptMessage = z.infer<typeof ZGptMessage>

export type GptChat = z.infer<typeof ZGptChat>

export const GptApi = {
  createChat: async (gpt_chat?: CreateChatParams) => {
    return await postZod(`/api/gpt/chats`, { gpt_chat }, ZGptChat)
  },

  getChat: async (chatId: string) => {
    return await getZod(`/api/gpt/chats/${chatId}`, ZGptChat)
  },

  sendMessage: async (chatId: string, content: string) => {
    return await postZod(
      `/api/gpt/chats/${chatId}/messages`,
      { gpt_message: { content } },
      ZGptMessage
    )
  },

  getMessages: async (chatId: string) => {
    return await getZod(`/api/gpt/chats/${chatId}/messages`, z.array(ZGptMessage))
  },

  generateFromMessage: async (messageId: string): Promise<void> => {
    return await post(`/api/gpt/messages/${messageId}/generate`, {})
  },

  getGeneralChats: async (page: string | number): Promise<PaginationData<GptChat[]>> => {
    return await getZodPaginated(makeGetUrl(`/api/gpt/chats`, { page }), ZGptChat.array())
  },
}
