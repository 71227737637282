<template>
  <div class="bg-red-50 border-l-4 border-red-400 p-4 mb-4" v-if="error">
    <p class="text-sm leading-5 font-medium text-red-800">
      {{ parsedError }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import getErrorMessage from '/js/composables/getErrorMessage'

const props = defineProps<{
  error?: unknown | null
}>()

const parsedError = computed(() => {
  if (!props.error) return null
  return getErrorMessage(props.error)
})

</script>

<script lang="ts">
export default {
  name: 'ErrorMessage',
}
</script>
