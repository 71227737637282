<template>
  <div class="h-full" :class="{ dark: adminRoute }">
    <div class="flex h-full bg-white dark:bg-ll-green-950">
      <!-- Vertical / First small sidebar -->
      <small-sidebar v-if="!featureFlags.sidebarV2" />

      <!-- Vertical / Second wider sidebar -->
      <div
        class="flex w-60 flex-1 flex-col overflow-y-auto scrollbar:h-0 scrollbar:w-0"
        id="v-step-admin-sidebar">
        <div
          id="v-step-admin-switcher"
          class="flex border-b border-gray-100 px-3 py-4 dark:border-ll-green-700">
          <current-community #default="{ community }">
            <router-link :to="{ name: 'home' }" class="flex h-11 w-full items-center">
              <community-nav-button
                :community
                v-if="adminRoute"
                :has-unread-notifications="false">
                <template #logo>
                  <schools-nav class="h-10 w-10" />
                </template>
              </community-nav-button>
              <community-nav-button-members :community v-else />
            </router-link>
          </current-community>
        </div>

        <div class="flex flex-1 flex-col overflow-y-auto px-2 py-4">
          <admin-sidebar v-if="adminRoute" />
          <main-sidebar v-else />
        </div>

        <sidebar-bottom-user class="border-t border-gray-100 dark:border-ll-green-700" v-if="featureFlags.sidebarV2" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommunityNavButton from "/js/components/Layout/CommunityNavButton.vue"
import CurrentCommunity from "/js/components/Layout/CurrentCommunity.vue"
import { useAdminRoute } from "/js/composables/useAdminRoute"
import CommunityNavButtonMembers from "/js/components/Layout/CommunityNavButtonMembers.vue"
import MainSidebar from "/js/components/Layout/Sidebar/MainSidebar.vue"
import AdminSidebar from "/js/components/Layout/Sidebar/AdminSidebar.vue"
import SmallSidebar from "/js/components/Layout/Sidebar/SmallSidebar.vue"
import SidebarBottomUser from "/js/components/Layout/Sidebar/SidebarBottomUser.vue"
import { featureFlags } from "/js/composables/useFeatureFlags"
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import type { Community } from "/js/models/Community"
import { useNotificationsStore } from "/js/services/NotificationsStore"
import {storeToRefs} from "pinia";

const adminRoute = useAdminRoute().isAdminRoute
const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const hasUnreadNotifications = (community: Community) => {
  return (communitiesUnreadCount.value[community.id] ?? 0) > 0
}
</script>

<script lang="ts">
export default {
  name: "Sidebar",
}
</script>
