<template>
  <card-loader :title="widget.title" :raw>
    <accordion :items="widget.items"/>
  </card-loader>
</template>

<script setup lang="ts">
import type { AccordionWidget } from "/js/models/Widget"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import Accordion from "/js/components/utilities/Accordion.vue"

defineProps<{
  widget: AccordionWidget
  raw?: boolean
}>()

</script>

<script lang="ts">
export default {
  name: "AccordionCard",
}
</script>
