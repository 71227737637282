import { defineStore } from "pinia"
import { ChatApi } from "/js/services/ChatApi"
import { useQuery, useQueryClient } from "@tanstack/vue-query"
import { computed, defineComponent, markRaw, ref, watch, h, nextTick } from "vue"
import type {
  ChatMessage,
  ChatMessageBase,
  ChatMessageState,
  ChatRoomListItem,
  ChatRoomTracking,
  EmojiMartData,
} from "/js/models/Chat"
import { nanoid } from "nanoid"
import { useCurrentUserService } from "/js/services/useCurrentUserService"
import { z } from "zod"
import { ZChatMessageUser } from "/js/models/Chat"
import type { Asset } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import uniqBy from "lodash/uniqBy"

import {
  makeAssets,
  useMediaGallery,
} from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import { notNullish } from "@vueuse/core"
import type { ProductAttachment } from "/js/models/Product"
import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import { toast } from "vue-sonner"
import ChatToast from "/js/components/Chat/ChatToast.vue"

export const chatRoomsQueryKey = ["chat_rooms"]
export const chatRoomQueryKey = (roomId: string) => ["chat_rooms", roomId]

export type ChatMessageDecoratorOptions = {
  timestamp: boolean
  user: boolean
}

export type RoomId = {
  roomId: string
  parentId: string | null
}

export const sameRoomId = (message: ChatMessage, roomId: RoomId) => {
  return message.chat_room_id === roomId.roomId && message.parent_id === roomId.parentId
}

type MessageDecorators = Record<string, ChatMessageDecoratorOptions>

export const makeRoomIdString = (roomId: RoomId) => {
  const rid = `room/${roomId.roomId}`
  const pid = roomId.parentId ? `parent/${roomId.parentId}` : undefined
  return [rid, pid].filter(notNullish).join("/")
}

export const makeRoomId = (roomId: string): RoomId => {
  const parts = roomId.split("/")
  const rid = parts.find((p) => p.startsWith("room/"))?.split("/")[1]
  if (!rid) throw new Error("Invalid room id")
  const pid = parts.find((p) => p.startsWith("parent/"))?.split("/")[1]

  return {
    roomId: rid,
    parentId: pid ?? null,
  }
}

export type CurrentRoomId = {
  roomId: RoomId
  cursorId: string | undefined
}

export const useChatStoreV2 = defineStore("chatv2", () => {
  const queryClient = useQueryClient()

  const userChannel = useUserChannel()

  const currentRooms = ref<Record<string, CurrentRoomId>>({})
  const currentReplyTo = ref<ChatMessage | undefined>(undefined)

  const currentRoom = (roomId: RoomId) => {
    return currentRooms.value[makeRoomIdString(roomId)]
  }

  const isCurrentRoom = (roomId: RoomId) => {
    return !!currentRoom(roomId)
  }

  const enterRoom = (currentRoomId: CurrentRoomId) => {
    currentRooms.value[makeRoomIdString(currentRoomId.roomId)] = currentRoomId
  }

  const leaveRoom = (roomId: RoomId) => {
    delete currentRooms.value[makeRoomIdString(roomId)]
  }

  const { data: currentUser } = useCurrentUserService().load()

  const myUser = computed((): z.infer<typeof ZChatMessageUser> => {
    return {
      id: currentUser.value?.id ?? "",
      first_name: currentUser.value?.first_name ?? "",
      last_name: currentUser.value?.last_name ?? "",
      avatar_url: currentUser.value?.avatar_url ?? "",
    }
  })

  // a hash of decorators for each chat room: roomId -> { message_id -> decorator }
  const chatRoomMessageDecorators = ref<Record<string, MessageDecorators>>({})

  const allRoomMessages = ref<Record<string, ChatMessage[]>>({})

  const chatRooms = ref<ChatRoomListItem[]>([])

  const chatRoomTrackingsStore = ref<Record<string, ChatRoomTracking>>({})

  const drafts = ref<Record<string, string>>({})

  const makeDecoratorForLowerMessage = (
    lowerMessage: ChatMessage | undefined,
    upperMessage: ChatMessage | undefined
  ): ChatMessageDecoratorOptions | undefined => {
    if (!lowerMessage) return undefined

    if (!upperMessage) {
      return { user: true, timestamp: true }
    }

    const isDifferentUser = lowerMessage.user.id !== upperMessage.user.id
    const isSameDay = lowerMessage.created_at.getDate() === upperMessage.created_at.getDate()

    return {
      user: isDifferentUser,
      timestamp: !isSameDay,
    }
  }

  const updateDecorators = (roomId: RoomId, messages: ChatMessage[]) => {
    if (!messages || messages.length === 0) return
    const decorators: MessageDecorators = {}

    for (let i = 1; i <= messages.length; i++) {
      const lowerMessage = messages[i - 1]
      const upperMessage = messages[i]
      const decorator = makeDecoratorForLowerMessage(lowerMessage, upperMessage)

      if (decorator && lowerMessage) {
        decorators[lowerMessage.id] = decorator
      }
    }

    chatRoomMessageDecorators.value[makeRoomIdString(roomId)] = decorators
  }

  const {
    isInitialLoading: roomsIsInitialLoading,
    isError: roomsIsError,
    isSuccess: roomsIsSuccess,
    data: roomsData,
    error: roomsError,
    refetch: roomsRefetch,
  } = useQuery({
    queryKey: chatRoomsQueryKey,
    queryFn: async () => {
      const rooms = await ChatApi.getChatRooms()
      chatRooms.value = rooms
      return rooms
    },
  })

  const roomTrackings = computed(() => {
    const readRooms: Record<string, boolean> = {}
    chatRooms.value.forEach((room) => {
      readRooms[room.id] = isRoomRead(room.id)
    })
    return readRooms
  })

  const isRoomRead = (roomId: string) => {
    if (!trackingLoaded.value) return true
    const room = chatRooms.value.find((r) => r.id === roomId)
    if (!room) return true
    if (!room.last_message) return true
    if (room.last_message?.user.id === myUser.value.id) return true

    const tracking = chatRoomTrackingsStore.value[roomId]
    if (!tracking) return false
    if (!tracking.last_read_message_id) return false

    return room.last_message.id === tracking.last_read_message_id
  }

  const { isLoading: trackingLoading, isFetched: trackingLoaded } = useQuery({
    queryKey: ["chat_room_trackings"],
    queryFn: async () => {
      const trackings = await ChatApi.getTrackings()
      chatRoomTrackingsStore.value = trackings.reduce(
        (acc, t) => {
          acc[t.chat_room_id] = t
          return acc
        },
        {} as Record<string, ChatRoomTracking>
      )
      return trackings
    },
  })

  const directRooms = computed(() => {
    return chatRooms.value?.filter((room) => room.room_type === "direct_room") ?? []
  })

  const channelRooms = computed(() => {
    return chatRooms.value?.filter((room) => room.room_type === "channel_room") ?? []
  })

  const channelCommunityRooms = computed(() => {
    return channelRooms.value.filter((room) => !room.product_id)
  })

  const refreshChatRooms = async () => {
    await queryClient.invalidateQueries({ queryKey: chatRoomsQueryKey })
  }

  const updateMessages = (roomId: RoomId, messages: ChatMessage[]) => {
    const sortedMessages = uniqBy(messages, "id").sort((a, b) => {
      return b.created_at.getTime() - a.created_at.getTime()
    })

    allRoomMessages.value[makeRoomIdString(roomId)] = sortedMessages
    updateDecorators(roomId, sortedMessages)
  }

  const loadMessages = (roomId: RoomId, result: ChatMessageBase[]) => {
    // merge the new messages with the old ones
    const messages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []

    // remove messages from messages that are already in result
    // this is to make sure we have the fresh version of the message
    // issue is with AMZ expired assets. this will reload the links
    const filteredMessages = messages.filter((m) => !result.some((r) => r.id === m.id))

    const sortedMessages = [...filteredMessages, ...result]
    updateMessages(roomId, sortedMessages)
  }

  const markMessage = (roomId: RoomId, identifier: string, state: ChatMessageState) => {
    const messages = (allRoomMessages.value[makeRoomIdString(roomId)] ?? []).map((m) => {
      if (m.identifier === identifier) {
        m.state = state
      }
      return m
    })
    updateMessages(roomId, messages)
  }

  const insertLocalMessage = (roomId: RoomId, text?: string) => {
    const id = nanoid(16)
    const localMessage: ChatMessage = {
      id: id,
      text: text ?? "",
      created_at: new Date(),
      chat_room_id: roomId.roomId,
      user: myUser.value,
      identifier: id,
      state: "sending",
      parent_id: null,
      replies_count: 0,
    }

    const messages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
    updateMessages(roomId, [localMessage, ...messages])
    return localMessage
  }

  const sendTextMessage = async (roomId: RoomId, localMessage: ChatMessage) => {
    const id = localMessage.id

    try {
      // optimistic update
      const result = await ChatApi.sendTextMessage(roomId, {
        text: localMessage.text ?? "",
        identifier: id,
      })
      // fully replace the message with the result
      const roomMessages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
      const idx = roomMessages.findIndex((m) => m.identifier === localMessage.identifier)
      if (idx !== -1) {
        roomMessages[idx] = result
      }
      updateMessages(roomId, roomMessages)
    } catch (e) {
      markMessage(roomId, id, "failed")
    }
  }

  const uploadingMessageIds = ref<
    {
      id: string
      roomId: RoomId
    }[]
  >([])

  const uploadingMediaAssets = computed(() => {
    const assets: Record<string, Asset[]> = {}
    uploadingMessageIds.value.forEach(({ id }) => {
      assets[id] = makeAssets({ type: "user", id: id })
    })
    return assets
  })

  const prependAttachmentMessage = async (roomId: RoomId, files: File[]) => {
    const id = nanoid(16)
    const localMessage: ChatMessage = {
      id,
      text: "",
      created_at: new Date(),
      chat_room_id: roomId.roomId,
      user: myUser.value,
      identifier: id,
      state: "sending",
      parent_id: roomId.parentId,
      replies_count: 0,
    }

    const { uploadFiles } = useMediaGallery({
      type: "user",
      id: localMessage.id,
    })

    const messages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
    updateMessages(roomId, [localMessage, ...messages])

    if (!uploadingMessageIds.value.some(({ id }) => id === localMessage.id)) {
      uploadingMessageIds.value = [{ id: localMessage.id, roomId }, ...uploadingMessageIds.value]
    }

    await uploadFiles(files, false)
  }

  const createAttachmentMessage = async (params: {
    roomId: RoomId
    messageId: string
    attachments: ProductAttachment[]
  }) => {
    const { roomId, messageId, attachments } = params
    const result = await ChatApi.sendAttachmentsMessage(roomId, {
      identifier: messageId,
      attachments_ids: attachments.map((a) => a.id),
    })
    // fully replace the message with the result
    const roomMessages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
    const idx = roomMessages.findIndex((m) => m.identifier === messageId)
    if (idx !== -1) {
      roomMessages[idx] = result
    }
    updateMessages(roomId, roomMessages)
    uploadingMessageIds.value = uploadingMessageIds.value.filter(({ id }) => id !== messageId)
  }

  watch(uploadingMediaAssets, (assetHash) => {
    Object.keys(assetHash).forEach(async (messageId) => {
      const assets = assetHash[messageId]
      if (assets === undefined) return

      if (assets.length === 0) {
        const roomId = uploadingMessageIds.value.find(({ id }) => id === messageId)?.roomId
        if (!roomId) return
        await removeMessage(roomId, messageId)
        return
      }

      const allUploaded = assets.every((a) => a.type === "productAttachment")
      if (allUploaded) {
        const roomId = uploadingMessageIds.value.find(({ id }) => id === messageId)?.roomId
        if (!roomId) return

        const attachments = assets
          .map((a) => (a.type === "productAttachment" ? a.data : undefined))
          .filter(notNullish)

        try {
          await createAttachmentMessage({ roomId, messageId, attachments })
        } catch (e) {
          markMessage(roomId, messageId, "failed")
        }
      }

      const hasFailed = assets.some((a) => a.type === "fileUploader" && a.data.status === "failed")

      if (hasFailed) {
        const roomId = uploadingMessageIds.value.find(({ id }) => id === messageId)?.roomId
        if (!roomId) return
        markMessage(roomId, messageId, "failed")
      }
    })
  })

  const removeMessage = async (roomId: RoomId, messageId: string) => {
    const messages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
    const message = messages.find((m) => m.id === messageId)
    const isPersisted = message?.id !== message?.identifier
    if (isPersisted) {
      await ChatApi.deleteMessage(roomId.roomId, messageId)
    }
    uploadingMessageIds.value = uploadingMessageIds.value.filter(({ id }) => id !== messageId)

    updateMessages(
      roomId,
      messages.filter((m) => m.id !== messageId)
    )

    const { clearAssetThumbs, productAttachments } = useMediaGallery({
      type: "user",
      id: messageId,
    })

    clearAssetThumbs()
    productAttachments.value = []
  }

  const toggleReaction = async (roomId: RoomId, messageId: string, reaction: EmojiMartData) => {
    const message = await ChatApi.toggleMessageReaction(
      roomId.roomId,
      messageId,
      reaction.colons,
      reaction.native
    )

    const roomMessages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
    //TODO: adding a reaction in a cursor room will not update the regular room (or other existing cursor rooms)
    const idx = roomMessages.findIndex((m) => m.id === messageId)
    if (idx !== -1) {
      roomMessages[idx] = message
      updateMessages(roomId, roomMessages)
      if (currentReplyTo.value?.id === messageId) {
        currentReplyTo.value = message
      }
    }
  }

  const retryAsset = async (roomId: RoomId, messageId: string, assetId: string) => {
    const { retryUpload } = useMediaGallery({
      type: "user",
      id: messageId,
    })

    const asset = uploadingMediaAssets.value[messageId]?.find((a) => a.id === assetId)
    if (!asset) return

    if (asset.type === "productAttachment") {
      return
    }

    const message = allRoomMessages.value[makeRoomIdString(roomId)]?.find((m) => m.id === messageId)
    if (!message) return

    markMessage(roomId, messageId, "sending")

    await retryUpload(asset.data, false)
  }

  const removeAsset = async (messageId: string, assetId: string) => {
    const { uploaders } = useMediaGallery({
      type: "user",
      id: messageId,
    })

    uploaders.value = uploaders.value.filter((u) => u.id !== assetId)
  }

  const retryMessage = async (roomId: RoomId, messageId: string) => {
    const message = allRoomMessages.value[makeRoomIdString(roomId)]?.find((m) => m.id === messageId)
    if (!message) return

    if (uploadingMessageIds.value.some(({ id }) => id === messageId)) {
      const { uploaders, retryUpload } = useMediaGallery({
        type: "user",
        id: messageId,
      })

      const uploadersWithErrors = uploaders.value.filter((u) => u.status === "failed")

      if (uploadersWithErrors.length === 0) return

      markMessage(roomId, messageId, "sending")

      uploadersWithErrors.forEach((u) => {
        retryUpload(u, false)
      })
    } else {
      markMessage(roomId, messageId, "sending")
      if (!message.text) return

      const result = await ChatApi.sendTextMessage(roomId, {
        text: message.text,
        identifier: messageId,
      })
      // fully replace the message with the result
      const roomMessages = allRoomMessages.value[makeRoomIdString(roomId)] ?? []
      const idx = roomMessages.findIndex((m) => m.identifier === messageId)
      if (idx !== -1) {
        roomMessages[idx] = result
      }
      updateMessages(roomId, roomMessages)
    }
  }

  const getChatRoom = async (roomId: string) => {
    const chatRoom = chatRooms.value?.find((room) => room.id === roomId)
    if (chatRoom) return chatRoom

    // console.log("not found, fetching from server")
    try {
      const room = await ChatApi.getChatRoom(roomId)
      const exists = chatRooms.value?.find((room) => room.id === roomId)
      if (!exists) chatRooms.value = [room, ...chatRooms.value]
      return room
    } catch (e) {
      return undefined
    }
  }

  const insertChatRoom = (chatRoom: ChatRoomListItem) => {
    const rooms = chatRooms.value
    const idx = rooms.findIndex((r) => r.id === chatRoom.id)
    if (idx !== -1) {
      rooms[idx] = chatRoom
    } else {
      rooms.unshift(chatRoom)
    }
    // console.log("===== insert chat room")
    chatRooms.value = rooms
  }

  const deleteChatRoom = async (chatRoomId: string) => {
    await ChatApi.deleteChatRoom(chatRoomId)
    chatRooms.value = chatRooms.value.filter((r) => r.id !== chatRoomId)
  }

  userChannel.subscribeToNewMessage(async (socketMessage: SocketMessage) => {
    switch (socketMessage.type) {
      case "chat_message":
        const message = socketMessage.object

        const roomId: RoomId = {
          roomId: message.chat_room_id,
          parentId: message.parent_id,
        }

        const roomIdentifier = makeRoomIdString(roomId)

        const currentMessages = allRoomMessages.value[roomIdentifier]

        if (currentMessages && currentMessages.length > 0) {
          // if we didn't already load the chat, don't add the message

          const roomMessages = allRoomMessages.value[roomIdentifier] ?? []
          const idx = roomMessages.findIndex((m) => m.identifier === message.identifier)
          if (idx !== -1) {
            roomMessages[idx] = message
            updateMessages(roomId, roomMessages)
          } else {
            // prevent socket message insertion on rooms that are open but not in chat mode (ie. in cursor mode)
            const _currentRoom = currentRoom(roomId)
            const shouldInsert = !_currentRoom || !_currentRoom.cursorId
            if (shouldInsert) {
              roomMessages.unshift(message)
              updateMessages(roomId, roomMessages)
            }
          }
        }

        if (roomId.parentId) {
          // find a message in the parent room and update the replies count
          const parentRoomId: RoomId = {
            roomId: roomId.roomId,
            parentId: null,
          }
          const parentRoomMessages = allRoomMessages.value[makeRoomIdString(parentRoomId)] ?? []
          const parentIdx = parentRoomMessages.findIndex((m) => m.id === roomId.parentId)
          if (parentIdx !== -1) {
            let parentMessage = parentRoomMessages[parentIdx]
            if (parentMessage) {
              parentMessage.replies_count += 1
              parentMessage.latest_reply_users = uniqBy(
                [message.user, ...(parentMessage.latest_reply_users ?? [])],
                "id"
              ).slice(0, 5)
              parentRoomMessages[parentIdx] = parentMessage
              updateMessages(parentRoomId, parentRoomMessages)
            }
          }
        }

        const chatRoom = await getChatRoom(message.chat_room_id)

        if (chatRoom) {
          chatRoom.last_message = message
          insertChatRoom(chatRoom)
        }

        if (!isCurrentRoom(roomId) && message.user.id !== myUser.value.id) {
          // TODO: when dismissing the toast, the toast gets a recursive error in chrome console
          const id = toast.custom(
            markRaw(
              defineComponent({
                render() {
                  return h(ChatToast, { message, dismissId: id, room: chatRoom })
                },
              })
            )
          )
        }

        break
      case "message_read":
        // console.log("message read")
        break
      case "tracking":
        const tracking = socketMessage.object
        chatRoomTrackingsStore.value = {
          ...chatRoomTrackingsStore.value,
          [tracking.chat_room_id]: tracking,
        }
        break
    }
  })

  // returns true if a server request is required
  const markReadLocal = async (roomId: string) => {
    if (!myUser.value.id.length) return true

    const existingTracking = chatRoomTrackingsStore.value[roomId]

    // if using room.last_message there are some async issues
    // ChatRoomLoader -> subscribeToNewMessageCallback -> markRead
    // ChatStoreV2 -> subscribeToNewMessage has an await after a chatRoom, after which it does the update
    // meanwhile, ChatRoomLoader has already called markRead
    // TODO: should the mark read be combined with the chat room message update? (if it's the same roomId only)
    await nextTick()

    const room = chatRooms.value.find((r) => r.id === roomId)
    const lastMessage = room?.last_message
    if (lastMessage?.id && lastMessage.id === existingTracking?.last_read_message_id) return false

    let store = chatRoomTrackingsStore.value

    let existing = store[roomId]

    if (existing && lastMessage) {
      existing.last_read_message_id = lastMessage.id
      existing.last_read_message_user_id = lastMessage.user.id
      store[roomId] = existing
    } else if (lastMessage) {
      store[roomId] = {
        id: nanoid(16),
        chat_room_id: roomId,
        last_read_message_id: lastMessage.id,
        last_read_message_user_id: lastMessage.user.id,
      }
    }

    chatRoomTrackingsStore.value = store
    return true
  }

  const markRead = async (roomId: string) => {
    const notifyServer = await markReadLocal(roomId)
    if (!notifyServer) return
    userChannel.markRead(roomId)
  }

  return {
    chatRooms,
    roomsIsInitialLoading,
    roomsIsError,
    roomsIsSuccess,
    roomsData,
    roomsError,
    roomsRefetch,
    directRooms,
    channelRooms,
    channelCommunityRooms,
    refreshChatRooms,
    updateDecorators,
    allRoomMessages,
    loadMessages,
    chatRoomMessageDecorators,
    sendTextMessage,
    prependAttachmentMessage,
    uploadingMediaAssets,
    removeMessage,
    retryAsset,
    removeAsset,
    retryMessage,
    insertLocalMessage,
    roomTrackings,
    markRead,
    currentRooms,
    isCurrentRoom,
    enterRoom,
    leaveRoom,
    insertChatRoom,
    deleteChatRoom,
    chatRoomTrackingsStore,
    drafts,
    toggleReaction,
    currentReplyTo,
  }
})
