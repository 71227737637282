import { makeGetUrl, makeQueryString } from "/js/composables/makeQueryString"
import { del, get, getPaginated, type PaginationData, patch, post } from "/js/composables/useAxios"
// @ts-ignore
import { FileChecksum } from "@rails/activestorage/src/file_checksum"
import type {
  ProductAttachment,
  ProductAttachmentParams,
  ProductAttachmentUpdateParams,
} from "/js/models/Product"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"

type BlobRecord = {
  filename: string
  content_type: string
  byte_size: number
  checksum: string
}

type BlobResponse = {
  blob_signed_id: string
  direct_upload: {
    url: string
    headers: {
      "Content-Type": string
      "Content-MD5": string
      "Content-Disposition": string
    }
  }
}

export type AdminMediaFilter = {
  query?: string
  page?: number
  sort?: string
  order?: string
  attachment_type?: SupportedMedia
}

export const UploadsApi = {
  getFileSignature: async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      FileChecksum.create(file, (error: Error, checksum: string) => {
        if (error) {
          reject(error)
        } else {
          resolve(checksum)
        }
      })
    })
  },

  makeBlobRecord: async (file: File): Promise<BlobRecord> => {
    const checksum = await UploadsApi.getFileSignature(file)
    return {
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
      checksum: checksum,
    }
  },

  createPresignedUrl: async (file: File, publicBlob: boolean): Promise<BlobResponse> => {
    const blobRecord = await UploadsApi.makeBlobRecord(file)
    return await post("/api/presigned_uploads", {
      blob: blobRecord,
      public: publicBlob ? "1" : null,
    })
  },

  uploadToS3: async (
    file: File,
    blobResponse: BlobResponse,
    percentLoaded?: (n: number) => void
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.open("PUT", blobResponse.direct_upload.url, true)

      Object.keys(blobResponse.direct_upload.headers).forEach((key) => {
        xhr.setRequestHeader(
          key,
          blobResponse.direct_upload.headers[key as keyof typeof blobResponse.direct_upload.headers]
        )
      })

      xhr.upload.addEventListener("progress", (event: ProgressEvent) => {
        if (percentLoaded) {
          const progress = Math.round((event.loaded / event.total) * 100)
          percentLoaded(progress)
        }
      })
      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          resolve()
        } else {
          reject(xhr.response)
        }
      })
      xhr.send(file)
    })
  },

  uploadFile: async (
    file: File,
    publicBlob: boolean,
    percentLoaded?: (n: number) => void
  ): Promise<string> => {
    const blobResponse = await UploadsApi.createPresignedUrl(file, publicBlob)
    await UploadsApi.uploadToS3(file, blobResponse, percentLoaded)
    return blobResponse.blob_signed_id
  },

  createProductAttachment: async (
    productId: string,
    params: ProductAttachmentParams
  ): Promise<ProductAttachment> => {
    return await post(`/api/products/${productId}/product_attachments`, {
      product_attachment: { ...params },
    })
  },

  getProductAttachments: async (
    productId: string,
    mediaType?: SupportedMedia | SupportedMedia[]
  ): Promise<ProductAttachment[]> => {
    let media = mediaType
    if (mediaType && !Array.isArray(mediaType)) {
      media = [mediaType]
    }

    return await get(
      makeGetUrl(`/api/products/${productId}/product_attachments`, { attachment_type: media })
    )
  },

  createCommunityAttachment: async (
    params: ProductAttachmentParams
  ): Promise<ProductAttachment> => {
    return await post(`/api/community_attachments`, {
      product_attachment: params,
    })
  },

  createUserAttachment: async (params: ProductAttachmentParams): Promise<ProductAttachment> => {
    return await post(`/api/user_attachments`, {
      user_attachment: params,
    })
  },

  getCommunityAttachments: async (
    mediaType?: SupportedMedia | SupportedMedia[]
  ): Promise<ProductAttachment[]> => {
    let media = mediaType
    if (mediaType && !Array.isArray(mediaType)) {
      media = [mediaType]
    }

    return await get(
      makeGetUrl(`/api/community_attachments`, { attachment_type: media })
    )
  },

  getAdminAttachmentsPaginated: async (
    filter: AdminMediaFilter
  ): Promise<PaginationData<ProductAttachment[]>> => {
    const base = "/api/admin/file_attachments"
    const url = base + "?" + makeQueryString(filter)
    return getPaginated(url)
  },

  updateAdminAttachment: async (
    id: string,
    params: ProductAttachmentUpdateParams
  ): Promise<ProductAttachment> => {
    return await patch(`/api/admin/file_attachments/${id}`, {
      file_attachment: params,
    })
  },

  deleteAdminAttachment: async (id: string): Promise<void> => {
    return await del(`/api/admin/file_attachments/${id}`)
  },
}
