<template>
  <div class="relative">
    <div class="absolute inset-0 opacity-50 blur-sm bg-no-repeat bg-cover" :style="backgroundImageStyle"></div>
    <img
      :src="url"
      class="relative h-full max-h-[300px] w-full rounded-lg object-contain"
      alt="image preview" />
    <span
      v-if="uploading"
      class="absolute inset-0 flex h-full w-full flex-col items-center justify-center">
      <span class="absolute h-full w-full bg-gray-500/20"></span>
      <spinner class="h-12 w-12 text-white" />
    </span>
  </div>
</template>

<script setup lang="ts">
import Spinner from "/js/components/icons/Spinner.vue"
import { computed } from "vue"

const props = defineProps<{
  url: string | undefined
  aspectRatio?: number | undefined
  uploading?: boolean
}>()

const backgroundImageStyle = computed(() => {
  return {
    backgroundImage: `url(${props.url})`,
  }
})
</script>

<script lang="ts">
export default {
  name: "DropzoneImagePreview",
}
</script>
