<template xmlns="http://www.w3.org/1999/html">
  <div v-if="poll" class="card mt-4 flex flex-col gap-4">
    <div class="text-primary font-medium" v-if="showQuestion">{{ poll.question }}</div>
    <div class="flex flex-col gap-2" v-if="!poll.user_vote?.poll_option_id && !onlyResults">
      <div v-for="option in poll.poll_options" :key="option.id">
        <div class="flex gap-2">
          <button
            class="btn btn-outline btn-sm w-1/2"
            @click="submitVote(option)"
            :disabled="onlyResults || pollEnded">
            {{ option.text }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col gap-2">
        <button
          v-for="option in poll.poll_options"
          :key="option.id"
          :disabled="onlyResults || pollEnded"
          @click="submitVote(option)">
          <div class="flex items-center justify-between gap-4">
            <div class="relative flex-1">
              <div
                class="absolute inset-0 rounded-lg"
                :class="{
                  'bg-ll-green-400': option.id === poll.user_vote?.poll_option_id,
                  'bg-ll-slate-200': option.id !== poll.user_vote?.poll_option_id,
                }"
                :style="{
                  width: `${Math.max(Math.floor(optionVotePercentage(option, poll.total_votes)), 1)}%`,
                }"></div>
              <div class="relative flex items-center justify-between p-2">
                <span
                  class="ps-4 text-sm"
                  :class="{
                    'font-light text-white': option.id === poll.user_vote?.poll_option_id,
                    'text-black': option.id !== poll.user_vote?.poll_option_id,
                  }"
                  >{{ option.text }}</span
                >
              </div>
            </div>
            <span class="text-secondary ml-auto text-right text-sm">
              {{ optionVotePercentage(option, poll.total_votes) }}%
            </span>
          </div>
        </button>
      </div>
    </div>
    <div class="text-secondary flex gap-2 text-sm">
      <div>
        {{ poll.total_votes }} vote{{ poll.total_votes == 0 || poll.total_votes > 1 ? "s" : "" }}
      </div>
      •
      <div v-if="poll.end_time">
        <span v-if="!pollEnded">{{ timeLeft(poll.end_time) }} left</span>
        <span v-else>Ended</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type AdminPoll, optionVotePercentage, type Poll, type PollOption } from "/js/models/Poll"
import { pollQueryKey, PollsApi } from "/js/services/PollsApi"
import { useQueryClient } from "@tanstack/vue-query"
import { timeLeft } from "/js/composables/timeLeft"
import {computed} from "vue";
import {toastError} from "/js/composables/toast";

const props = withDefaults(
  defineProps<{
    poll: Poll | AdminPoll
    onlyResults?: boolean
    showQuestion?: boolean
  }>(),
  {
    onlyResults: false,
    showQuestion: false,
  }
)

const queryClient = useQueryClient()

const submitVote = async (option: PollOption) => {
  if (!props.poll.id) return
  try {
    if (props.poll.user_vote?.poll_option_id === option.id && props.poll.user_vote.id) {
      await PollsApi.deletePollVote(props.poll.id, props.poll.user_vote.id)
    } else {
      await PollsApi.submitPollVote(props.poll.id, option.id)
    }
  } catch (error) {
    toastError(error)
  }
  await queryClient.invalidateQueries({ queryKey: pollQueryKey(props.poll.id) })
}

const pollEnded = computed(() => {
  return props.poll.end_time ? props.poll.end_time.getTime() < Date.now() : false
})

</script>

<script lang="ts">
export default {
  name: "PollCard",
}
</script>
