import {formatTimeAgo, formatDate} from "@vueuse/core";

export const customTimeAgo = (date: Date, includeAgo = false) => {
  return formatTimeAgo(date, {
    fullDateFormatter: (time) => {
      const date = new Date()
      const diff = Math.abs(date.getTime() - time.getTime())

      const hours = Math.floor(diff / (1000 * 60 * 60))
      const minutes = Math.floor(diff / (1000 * 60))
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))

      if (hours < 1) {
        return `${minutes}m${includeAgo ? ' ago' : ''}`
      } else if (hours < 24) {
        return `${hours}h${includeAgo ? ' ago' : ''}`
      } else if (days < 1) {
        return `${days}d${includeAgo ? ' ago' : ''}`
      } else {
        return formatDate(time, 'MMM DD')
      }
    },
    max: 0
  })
}