<template>
  <teleport :to v-if="isMounted && toExists">
    <slot />
  </teleport>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue"

const props = defineProps<{
  to: string
}>()

const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})

onUnmounted(() => {
  isMounted.value = false
})

const toExists = computed(() => {
  return !!document.querySelector(props.to)
})
</script>

<script lang="ts">
export default {
  name: "MountedTeleport",
}
</script>
