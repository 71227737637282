import { get, post, patch, type PaginationData, getPaginated } from "/js/composables/useAxios"
import type { Community, CommunityParams } from "/js/models/Community"
import type { User } from "/js/models/User"
import { makeGetUrl } from "/js/composables/makeQueryString"
import type { AdminUsersFilter } from "/js/services/AdminMembersApi"

export const CommunitiesApi = {
  createCommunity: async (community: CommunityParams): Promise<Community> => {
    return await post(`/api/communities`, { community })
  },

  updateCommunity: async (id: string, community: CommunityParams): Promise<Community> => {
    return await patch(`/api/communities/${id}`, { community })
  },

  getCommunities: async (): Promise<Community[]> => {
    return await get(`/api/communities`)
  },

  getCommunity: async (id: string): Promise<Community> => {
    return await get(`/api/communities/${id}`)
  },

  joinCurrentCommunity: async (): Promise<void> => {
    return await post(`/api/community_memberships`)
  },

  getMembers: async (filters: AdminUsersFilter): Promise<PaginationData<User[]>> => {
    return await getPaginated(makeGetUrl(`/api/community/community_members`, filters))
  },
}
