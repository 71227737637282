<template>
  <div class="flex flex-col gap-2">
    <FormKit
      label="Poll end time"
      help="Set the end time for the poll"
      name="end_date"
      v-model="params.end_time"
      validation-label="Poll end time"
      validation-visibility="submit"
      validation="required"
      :disabled>
      <template #inner>
        <formkit-input-wrapper>
          <vue-date-picker
            :enable-time-picker="true"
            :min-date="new Date()"
            :max-date="new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)"
            position="left"
            :month-change-on-scroll="false"
            auto-apply
            v-model="params.end_time" />
        </formkit-input-wrapper>
      </template>
    </FormKit>
    <FormKit
      type="list"
      dynamic
      v-model="params.poll_options_attributes"
      #default="{ items }"
      :disabled
      name="poll_options">
      <FormKit type="group" v-for="(option, index) in items" :key="option" :index="index">
        <div
          class="group flex items-end gap-1"
          :class="{ 'line-through': params.poll_options_attributes[index]?._destroy }"
          :key="index">
          <div class="w-full">
            <FormKit
              type="text"
              name="text"
              :label="'Choice ' + (index + 1) + (index > 1 ? ' (Optional)' : '')"
              validation-label="Choice">
            </FormKit>
          </div>
          <div class="flex gap-1">
            <icon-button
              v-if="!disabled && index > 1 && params.poll_options_attributes.length > 2"
              @click="deleteOption(index)"
              class="self-end mb-2">
              <TrashIcon class="h-4 w-4" />
            </icon-button>
            <icon-button
              v-if="!disabled &&
              params.poll_options_attributes[index]?._destroy"
              @click="recoverDeletedOption(index)"
              class="self-end mb-2">
              <arrow-uturn-left-icon class="h-4 w-4" />
            </icon-button>
          </div>
        </div>
      </FormKit>
    </FormKit>
    <button
      class="btn btn-primary btn-sm mt-4 w-32 self-center"
      v-if="canAddOption"
      @click.prevent.stop="addNewOption"
      :disabled>
      Add Choice
    </button>
  </div>
</template>

<script setup lang="ts">
import type { PollParams } from "/js/models/Poll"
import IconButton from "/js/components/IconButton.vue"
import { ArrowUturnLeftIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"
import VueDatePicker from "@vuepic/vue-datepicker"

withDefaults(defineProps<{
  disabled: boolean
}>(), {
  disabled: false,
})


const params = defineModel<PollParams>({
  required: true,
})

const canAddOption = computed(() => params.value.poll_options_attributes.length < 5)

const deleteOption = (index: number) => {
  if (params.value.poll_options_attributes[index]?.id) {
    params.value.poll_options_attributes[index]._destroy = true
  } else {
    params.value.poll_options_attributes.splice(index, 1)
  }
}

const recoverDeletedOption = (index: number) => {
  if (params.value.poll_options_attributes[index])
    params.value.poll_options_attributes[index]._destroy = false
}

const addNewOption = () => {
  if (canAddOption.value) {
    params.value.poll_options_attributes.push({
      text: "",
    })
  }
}
</script>

<script lang="ts">
export default {
  name: "PollFormFields",
}
</script>
