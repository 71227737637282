export const timeLeftFromTime = (diff: number, format?: "short" | "medium"): string => {
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)

  const daysFormatted = format === "short" ? `${days}d` : `${days} day${days > 1 ? "s" : ""}`
  const hoursFormatted = format === "short" ? `${hours}h` : `${hours} hour${hours > 1 ? "s" : ""}`
  const minutesFormatted =
    format === "short" ? `${minutes}m` : `${minutes} min`
  const secondsFormatted = format === "short" ? `${seconds}s` : `${seconds} second${seconds > 1 ? "s" : ""}`

  if (days > 0) {
    return `${daysFormatted}`
  } else if (hours > 0) {
    return `${hoursFormatted} ${minutesFormatted}`
  } else if (minutes > 0) {
    return `${minutesFormatted}`
  } else {
    return `${secondsFormatted}`
  }
}

export const timeLeft = (date: Date, format?: "short" | "medium"): string => {
  const now = new Date()
  const diff = date.getTime() - now.getTime()
  return timeLeftFromTime(diff, format)
}