import { del, get, getZod, patch, post } from "/js/composables/useAxios"
import {
  type Post,
  type PostFeed,
  type PostFeedParams,
  type PostParams,
  ZPost,
} from "/js/models/Post"

export const PostsApi = {
  createPostFeed: async (productId: string, post_feed: PostFeedParams): Promise<PostFeed> =>
    await post(`/api/products/${productId}/post_feeds`, { post_feed }),

  updatePostFeed: async (postFeedId: string, post_feed: PostFeedParams): Promise<PostFeed> =>
    await patch(`/api/post_feeds/${postFeedId}`, { post_feed }),

  getPostFeeds: async (productId: string): Promise<PostFeed[]> =>
    await get(`/api/products/${productId}/post_feeds`),

  getPostFeed: async (postFeedId: string): Promise<PostFeed> =>
    await get(`/api/post_feeds/${postFeedId}`),

  deletePostFeed: async (postFeedId: string): Promise<void> =>
    await del(`/api/post_feeds/${postFeedId}`),

  getPosts: async (feedId: string): Promise<Post[]> => await get(`/api/post_feeds/${feedId}/posts`),

  getPost: async (postId: string): Promise<Post> => await getZod(`/api/posts/${postId}`, ZPost),

  createPost: async (feedId: string, params: PostParams): Promise<Post> =>
    await post(`/api/post_feeds/${feedId}/posts`, { post: params }),

  updatePost: async (postId: string, post: PostParams): Promise<Post> =>
    await patch(`/api/posts/${postId}`, { post }),

  deletePost: async (postId: string): Promise<void> => await del(`/api/posts/${postId}`),

  followPost: async (postId: string): Promise<void> =>
    await post(`/api/posts/${postId}/post_follow`),

  unfollowPost: async (postId: string): Promise<void> =>
    await del(`/api/posts/${postId}/post_follow`),
}
