import { useQuery, useQueryClient } from "@tanstack/vue-query"
import { NotificationsApi } from "/js/services/NotificationsApi"
import { computed, ref } from "vue"
import { type SocketMessage, useUserChannel } from "/js/composables/useUserChannel"
import { defineStore } from "pinia"

const communitiesUnreadCountQueryKey = ["communitiesUnreadCount"]

export const useNotificationsStore = defineStore("ll_notifications", () => {
  const currentCommunityId = window.APP_CONFIG.COMMUNITY_ID

  const queryClient = useQueryClient()
  const userChannel = useUserChannel()

  const communitiesUnreadCount = ref<Record<string, number>>({})

  useQuery({
    queryKey: communitiesUnreadCountQueryKey,
    queryFn: async () => {
      const commUnreadCount = await NotificationsApi.getCommunitiesUnreadCount()
      if (commUnreadCount && commUnreadCount.length === 0) {
        communitiesUnreadCount.value = {}
      } else {
        commUnreadCount.forEach((commUnreadCount) => {
          communitiesUnreadCount.value[commUnreadCount.id] = commUnreadCount.count
        })
      }
      return commUnreadCount
    },
  })

  userChannel.subscribeToNewMessage(async (socketMessage: SocketMessage) => {
    if (socketMessage.type === "notification") {
      await refreshCommunitiesUnreadCount()
    }
  })

  const refreshCommunitiesUnreadCount = async () => {
    await queryClient.invalidateQueries({ queryKey: communitiesUnreadCountQueryKey })
  }

  const markAsRead = async (notificationId: string) => {
    await NotificationsApi.markAsRead(notificationId)
    await refreshCommunitiesUnreadCount()
  }

  const markAllAsRead = async () => {
    await NotificationsApi.markAllAsRead()
    await refreshCommunitiesUnreadCount()
  }

  return {
    refreshCommunitiesUnreadCount,
    communitiesUnreadCount,
    markAsRead,
    markAllAsRead,
  }
})
