<template>
  <item-modal v-model="currentProductAttachment" #default="{ data }">
    <div class="flex flex-col gap-4">
      <img
        v-if="isImageRepresentable(data)"
        :src="attachmentImageUrl(data)"
        alt="Attachment"
        class="max-h-3/4 w-full object-contain" />
      <div v-else class="flex flex-col items-center p-12">
        <div class="flex flex-col items-center justify-center rounded-lg bg-gray-50 p-4">
          <PaperClipIcon class="h-8 w-8 text-gray-400" />
          <div class="text-secondary line-clamp-1 text-sm">
            {{ data.file_name }}
          </div>
        </div>
      </div>

      <div class="flex justify-end">
        <button @click="downloadAttachment" class="btn btn-primary btn-sm">Download</button>
      </div>
    </div>
  </item-modal>
</template>

<script setup lang="ts">
import ItemModal from "/js/components/Modals/ItemModal.vue"
import { attachmentImageUrl, isImageRepresentable, type ProductAttachment } from "/js/models/Product"
import { PaperClipIcon } from "@heroicons/vue/24/outline"

const currentProductAttachment = defineModel<ProductAttachment>()

const downloadAttachment = () => {
  if (currentProductAttachment.value?.data_url) {
    window.open(currentProductAttachment.value.data_url, "_blank")
  }
}
</script>

<script lang="ts">
export default {
  name: "ModalProductAttachment",
}
</script>
