import type { RouteRecordRaw } from "vue-router"
import { RouterView } from "vue-router"

export const namedRoutes = {
  members_index: "community_members.index",
  members_show: "community_members.show",
} as const

export const communityMembersRoutes: RouteRecordRaw[] = [
  {
    path: "/members",
    components: {
      default: RouterView,
    },
    redirect: { name: namedRoutes.members_index },
    children: [
      {
        name: namedRoutes.members_index,
        path: "/community/members",
        component: () => import("/js/components/Members/CommunityMembersList.vue"),
      },
    ],
  },
]
