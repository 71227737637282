<template>
  <Toaster
    position="top-right"
    richColors
    :toastOptions="{
      style: {},
      class: 'bg-secondary rounded-lg shadow-lg p-4 w-[350px]',
    }" />
  <router-view />
</template>

<script setup lang="ts">
import { Toaster } from "vue-sonner"
</script>

<script lang="ts">
export default {
  name: "MainApp",
}
</script>
