<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.375 12V13.875C15.375 15.3225 14.1975 16.5 12.75 16.5H5.25C3.8025 16.5 2.625 15.3225 2.625 13.875V13.3875C2.625 12.21 3.585 11.25 4.7625 11.25H14.625C15.0375 11.25 15.375 11.5875 15.375 12Z"
      fill="currentColor" />
    <path
      d="M11.625 1.5H6.375C3.375 1.5 2.625 2.25 2.625 5.25V10.935C3.195 10.4325 3.945 10.125 4.7625 10.125H14.625C15.0375 10.125 15.375 9.7875 15.375 9.375V5.25C15.375 2.25 14.625 1.5 11.625 1.5ZM9.75 8.0625H6C5.6925 8.0625 5.4375 7.8075 5.4375 7.5C5.4375 7.1925 5.6925 6.9375 6 6.9375H9.75C10.0575 6.9375 10.3125 7.1925 10.3125 7.5C10.3125 7.8075 10.0575 8.0625 9.75 8.0625ZM12 5.4375H6C5.6925 5.4375 5.4375 5.1825 5.4375 4.875C5.4375 4.5675 5.6925 4.3125 6 4.3125H12C12.3075 4.3125 12.5625 4.5675 12.5625 4.875C12.5625 5.1825 12.3075 5.4375 12 5.4375Z"
      fill="currentColor" />
  </svg>
</template>
