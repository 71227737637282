<template>
  <loading-view :loading="isLoading">
    <dropdown-tag-input-select
      v-model:search-term="searchTerm"
      v-model:open="comboOpen"
      v-model="selectedUsersRef"
      placeholder="Select Users"
      :display-name="(p) => getName(p)"
      multiple
      :options="users">
      <template #option="{ option: user }">
        <div class="flex items-center gap-2">
          <round-avatar
            :avatar-url="user.avatar_url"
            :id="user.id"
            :verified="user.verified"
            :text="getInitials(user)"
            size="sm"
            rounded="rounded-full" />
          <div class="text-primary text-sm font-semibold">{{ getName(user) }}</div>
        </div>
      </template>
      <template #tag="{ option: user }">
        <div class="text-primary flex items-center gap-2">
          <round-avatar
            :avatar-url="user.avatar_url"
            :id="user.id"
            :verified="user.verified"
            :text="getInitials(user)"
            size="xs"
            rounded="rounded-full" />
          <div class="text-primary text-sm font-semibold">{{ getName(user) }}</div>
        </div>
      </template>
    </dropdown-tag-input-select>

    <error-message :error="usersError" />
  </loading-view>
</template>

<script setup lang="ts">
import ErrorMessage from "/js/components/ErrorMessage.vue"
import DropdownTagInputSelect from "/js/components/utilities/FormFields/DropdownTagInputSelect.vue"
import LoadingView from "/js/components/LoadingView.vue"
import { computed, ref } from "vue"
import type { UseQueryReturnType } from "@tanstack/vue-query"
import RoundAvatar from "/js/components/RoundAvatar.vue"
import type { AdminMember, User, UserProfile } from "/js/models/User"
import type { ChatMessageUser } from "/js/models/Chat"
import { fullName, initials } from "/js/models/User"

type UserModel = User | AdminMember | ChatMessageUser | UserProfile

const props = defineProps<{
  groupQuery: () => UseQueryReturnType<UserModel[], any>
  open?: boolean
}>()

const getName = (user: UserModel) => {
  const name = fullName(user)
  if (name.length > 0) return name
  if ("email" in user) {
    return user.email
  }
  return ""
}

const getInitials = (user: UserModel) => {
  const text = initials(user)
  if (text.length > 0) return text
  if ("email" in user) {
    return user.email[0] ?? ""
  }
  return ""
}

const searchTerm = defineModel<string>("searchTerm", { required: true })
const selectedUsersRef = defineModel<UserModel[]>()

const emit = defineEmits<{
  "update:open": [value: boolean | undefined]
}>()

const _comboOpen = ref(false)

const comboOpen = computed({
  get: () => {
    return props.open || _comboOpen.value
  },
  set: (value) => {
    _comboOpen.value = value
    emit("update:open", value)
  },
})

const { data: groups, isLoading, error: usersError } = props.groupQuery()

const users = computed(() => {
  // TODO
  // issue:
  // passing an array of users as options, but having the selected users being a different array seems to cause some issues with the radix component
  // the ids match but since it's a different ref / object, they don't show up as selected
  // this fix replaces the option/fetch users that are also present in the selection, with the selected users
  if (groups.value) {
    return groups.value.map((user) => {
      const selectedUser = selectedUsersRef.value?.find((u) => u.id === user.id)
      if (selectedUser) {
        return selectedUser
      } else {
        return user
      }
    })
  }

  return []
})
</script>

<script lang="ts">
export default {
  name: "MultiUserSelect",
}
</script>
