<template>
  <cropper
    v-if="croppingUrl"
    :src="croppingUrl"
    :stencil-props="stencilProps"
    ref="cropperObject"
    class="w-full" />

  <slot :cropAndSave="cropAndSave" :close="close">
    <div class="mt-3">
      <div class="flex justify-end">
        <button class="btn btn-primary" @click="cropAndSave">Save Image</button>
        <button class="btn btn-secondary ml-3" @click="close">Cancel</button>
      </div>
    </div>
  </slot>
</template>

<script setup lang="ts">
import { Cropper } from "vue-advanced-cropper"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"

const props = defineProps<{
  file?: File | undefined
  aspectRatio?: number | undefined
}>()

const emit = defineEmits<{
  save: [value: File]
  cancel: []
}>()

const cropperObject = ref<typeof Cropper | undefined>()
const croppingUrl = ref<string | undefined>() // this is the cropping URL of the original file. managed internally

const stencilProps = computed(() => ({
  aspectRatio: props.aspectRatio,
  movable: true,
  scalable: true,
  resizable: true,
}))

const cropAndSave = async () => {
  const { canvas } = cropperObject.value?.getResult()
  const blob = (await new Promise((resolve) => canvas.toBlob(resolve))) as Blob
  const filename = props.file?.name ?? "image.png"
  const result = new File([blob], filename, { type: "image/png" })
  clearUrl()
  emit("save", result)
}

const clearUrl = () => {
  setTimeout(() => {
    if (croppingUrl.value) URL.revokeObjectURL(croppingUrl.value)
    croppingUrl.value = undefined
  }, 200)
}

const close = () => {
  clearUrl()
  emit("cancel")
}

watch(
  () => props.file,
  (value) => {
    if (value) {
      croppingUrl.value = URL.createObjectURL(value)
    } else {
      clearUrl()
    }
  }
)

onMounted(() => {
  if (props.file) {
    croppingUrl.value = URL.createObjectURL(props.file)
  }
})

onUnmounted(clearUrl)
</script>

<script lang="ts">
export default {
  name: "CropperWrapper",
}
</script>
