<template>
  <paged-cards>
    <template #title>
      <h4 class="font-medium">{{ title }}</h4>
    </template>
    <div class="card border-2 border-gray-100" v-for="product in products">
      <router-link
        class="flex flex-col"
        :to="{ name: learningHubRoutes.learning_hubs_show, params: { product_id: product.id } }">
        <gradient-image
          class="aspect-[21/9] max-h-48 w-full rounded-t-lg"
          :id="product.id"
          :url="attachmentImageUrl(product.cover_attachment)" />

        <div class="padding flex flex-col gap-4">
          <h4 class="text-primary font-medium">{{ product.name }}</h4>
          <truncate-view v-model="truncate">
            <div class="text-secondary whitespace-pre-line">
              {{ product.description }}
            </div>
          </truncate-view>
        </div>
      </router-link>
    </div>
  </paged-cards>
</template>

<script setup lang="ts">
import { attachmentImageUrl, type Product } from "/js/models/Product"
import GradientImage from "/js/components/GradientImage.vue"
import PagedCards from "/js/components/Admin/Dashboard/PagedCards.vue"
import { ref } from "vue"
import { namedRoutes as learningHubRoutes } from "/js/router/LearningHubRoutes"
import TruncateView from "/js/components/utilities/TruncateView.vue"

withDefaults(
  defineProps<{
    products: Product[]
    title?: string
  }>(),
  {
    title: "Featured Learning Hubs",
  }
)

const truncate = ref(true)
</script>

<script lang="ts">
export default {
  name: "FeaturedProductsCard",
}
</script>
