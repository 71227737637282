import { reactive } from "vue"
import { UploadsApi } from "/js/components/utilities/FormFields/FileUpload/UploadsApi"
import { nanoid } from "nanoid"
import { resizeBlob } from "/js/composables/resizeBlob"

export type FileUploader = {
  id: string
  createdAt: Date
  percentUploaded: number
  blobId: string | undefined
  status: "pending" | "uploading" | "uploaded" | "failed"
  failedStatus?: "upload" | "post"
  error: unknown | undefined
  file: File | undefined
  thumbUrl: string | undefined
}

export const makeFileUploader = (file?: File): FileUploader => {
  return {
    id: nanoid(),
    createdAt: new Date(),
    percentUploaded: 0,
    blobId: undefined,
    status: "pending",
    failedStatus: undefined,
    error: undefined,
    file: file,
    thumbUrl: undefined,
  }
}

type UploadFileResult<T> = {
  fileUploader: FileUploader
  postUploadResult: T | undefined
}

export const useFileUploader = (existingUploader?: FileUploader) => {

  const fileUploader = reactive<FileUploader>(existingUploader || makeFileUploader())

  const resetFileUploader = () => {
    fileUploader.createdAt = new Date()
    fileUploader.percentUploaded = 0
    fileUploader.blobId = undefined
    fileUploader.status = "pending"
    fileUploader.error = undefined
    fileUploader.failedStatus = undefined
    fileUploader.file = undefined
    if (fileUploader.thumbUrl) URL.revokeObjectURL(fileUploader.thumbUrl)
    fileUploader.thumbUrl = undefined
  }

  const resetUploaderStatus = () => {
    fileUploader.percentUploaded = 0
    fileUploader.status = "pending"
    fileUploader.error = undefined
    fileUploader.failedStatus = undefined
  }

  const uploadFile = async <T>(
    file: File,
    publicBlob: boolean,
    postUpload?: (blobId: string) => Promise<T>
  ): Promise<UploadFileResult<T> | undefined> => {
    if (fileUploader.status === "failed") {
      resetUploaderStatus()
    } else if (fileUploader.status === "uploaded") {
      if (fileUploader.file === file && fileUploader.blobId) {
        return undefined
      } else {
        resetFileUploader()
      }
    }

    fileUploader.status = "uploading"
    fileUploader.file = file
    fileUploader.percentUploaded = 0

    // if we didn't reset it and it's there, don't re-create it
    if (!fileUploader.thumbUrl && file.type.startsWith("image/")) {
      const resizedBlob = await resizeBlob(file, 800, 0.8)
      fileUploader.thumbUrl = URL.createObjectURL(resizedBlob)
    }

    if (!fileUploader.blobId) {
      try {
        fileUploader.blobId = await UploadsApi.uploadFile(file, publicBlob, (percentLoaded) => {
          fileUploader.percentUploaded = percentLoaded
        })
      } catch (error) {
        fileUploader.failedStatus = "upload"
        fileUploader.status = "failed"
        fileUploader.error = error
        throw error
      }
    }

    try {
      if (postUpload) {
        const result = await postUpload(fileUploader.blobId)
        fileUploader.status = "uploaded"
        return {
          fileUploader,
          postUploadResult: result,
        }
      } else {
        fileUploader.status = "uploaded"
        return {
          fileUploader,
          postUploadResult: undefined,
        }
      }
    } catch (error) {
      fileUploader.failedStatus = "post"
      fileUploader.status = "failed"
      fileUploader.error = error
      throw error
    }
  }

  return {
    fileUploader,
    uploadFile,
  }
}
