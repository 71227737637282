<template>
  <template v-if="!readOnly">
    <media-gallery-attachments-input modal-type="app" :scope="mediaGalleryScope" v-model="attachments" />
  </template>
  <template v-else>
    <div class="flex items-stretch gap-4">
      <attachment-card :attachment v-for="attachment in attachments" :key="attachment.id" />
    </div>
  </template>
</template>

<script setup lang="ts">
import type { AttachmentsPluginProps } from "/js/components/utilities/Editor/createVueComponentPlugin"
import { computed } from "vue"
import type { MediaGalleryScope } from "/js/components/utilities/FormFields/FileUpload/MediaGalleryStore"
import MediaGalleryAttachmentsInput from "/js/components/utilities/FormFields/FileUpload/MediaGallery/MediaGalleryAttachmentsInput.vue"
import type { ProductAttachment } from "/js/models/Product"
import AttachmentCard from "/js/components/utilities/FormFields/FileUpload/UI/AttachmentCard.vue"
// import { useQuery } from "@tanstack/vue-query"
// import { notNullish } from "@vueuse/core"
// import { ProductsApi } from "/js/services/ProductsApi"

const props = defineProps<AttachmentsPluginProps>()

const mediaGalleryScope = computed((): MediaGalleryScope => {
  if (props.editorData?.productId) {
    return { type: "product", id: props.editorData.productId }
  } else {
    return { type: "community" }
  }
})

// const attachmentIds = computed(() => props.editorData?.attachments.map((a) => a.id).filter(notNullish) ?? [])
//
// const { data: attachmentData } = useQuery({
//   queryKey: computed(() => ["attachments", attachmentIds]),
//   queryFn: async () => {
//     return ProductsApi.getAttachmentUrls(attachmentIds.value)
//   },
//   enabled: computed(() => attachmentIds.value.length > 0),
// })
//
// watch(attachmentData, (data) => {
//   if (!data) return
//   let attachmentsCopy = [...attachments.value]
//   data.forEach((attachment) => {
//     const index = attachmentsCopy.findIndex((a) => a.id === attachment.id)
//     if (index !== -1) {
//       attachmentsCopy[index].data_url = attachment.data_url
//     }
//   })
// })

const attachments = computed({
  get: () => props.editorData?.attachments ?? [],
  set: (value: ProductAttachment[]) => {
    props.onUpdate({
      attachments: value,
    })
  },
})
</script>

<script lang="ts">
export default {
  name: "AttachmentsPlugin",
}
</script>
