<template>
  <formkit-input-wrapper class="relative">
    <Combobox.Root
      v-model="modelSelectedValues"
      v-model:open="openModel"
      v-model:search-term="modelSearchTerm"
      :filterFunction
      :multiple>
      <Combobox.Anchor
        class="text-primary data-[placeholder]:text-secondary inline-flex w-full items-center justify-between gap-2 leading-none outline-none">
        <TagsInput.Root
          v-model="idSelectedValues"
          class="flex flex-wrap items-center gap-2 rounded-full">
          <TagsInput.Item
            v-for="option in normalizedSelectedValues"
            :key="option.id"
            :value="option.id"
            class="flex items-center justify-center gap-2 rounded-full bg-slate-100 px-4 py-2 aria-[current=true]:bg-slate-300">
            <TagsInput.ItemText class="text-sm">
              <slot name="tag" :option="option">
                {{ displayName(option) }}
              </slot>
            </TagsInput.ItemText>
            <TagsInput.ItemDelete>
              <icon-button class="p-0.5">
                <close-icon class="text-primary h-2.5 w-2.5 font-semibold" />
              </icon-button>
            </TagsInput.ItemDelete>
          </TagsInput.Item>

          <Combobox.Input as-child>
            <TagsInput.Input
              :placeholder
              @focus="openModel = true"
              class="placeholder:text-secondary min-w-[180px] flex-1 rounded !bg-transparent px-1 focus:outline-none"
              @keydown.enter.prevent />
          </Combobox.Input>
        </TagsInput.Root>

        <Combobox.Trigger>
          <ChevronDownIcon class="text-primary h-4 w-4" />
        </Combobox.Trigger>
      </Combobox.Anchor>
      <Combobox.Content
        class="absolute left-0 z-30 mt-4 max-h-[250px] w-full overflow-y-auto rounded-lg bg-white shadow-lg">
        <Combobox.Viewport class="!overflow-visible p-2">
          <Combobox.Empty class="py-2 text-center text-xs font-medium text-gray-400" />

          <Combobox.Group class="flex flex-col gap-1">
            <Combobox.Item
              v-for="option in options"
              :key="option.id"
              class="text-primary flex select-none items-center rounded-lg px-2 py-2 leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-200 data-[state=checked]:bg-gray-100 data-[disabled]:text-gray-400 data-[highlighted]:outline-none"
              :value="option">
              <slot name="option" :option="option">
                {{ displayName(option) }}
              </slot>
            </Combobox.Item>

            <slot name="footer" />
          </Combobox.Group>
        </Combobox.Viewport>
      </Combobox.Content>
    </Combobox.Root>
  </formkit-input-wrapper>
</template>

<script setup lang="ts" generic="T extends Identifiable">
import ChevronDownIcon from "/js/components/icons/ChevronDownIcon.vue"
import FormkitInputWrapper from "/js/components/utilities/FormFields/FormkitInputWrapper.vue"
import { Combobox, TagsInput } from "radix-vue/namespaced"
import { computed, type Ref, ref, watch } from "vue"
import type { Identifiable } from "/js/models/Identifiable"
import { notNullish } from "@vueuse/core"
import IconButton from "/js/components/IconButton.vue"
import CloseIcon from "/js/components/icons/CloseIcon.vue"

type ArrayOrWrapped<T> = T extends any[] ? T : Array<T>;

type Props = {
  placeholder?: string
  options: T[]
  multiple?: boolean
  displayName: (value: T) => string
  filterFunction?: (options: ArrayOrWrapped<T>, searchTerm: string) => ArrayOrWrapped<T>
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "Select an option",
  multiple: false,
})

const modelSelectedValues = defineModel<T | T[] | undefined>()
const openModel = defineModel<boolean>("open", { required: false, default: false })
const modelSearchTerm = defineModel<string>("searchTerm", { required: true })

// options can change over time, we sync the tag input ids based on accumulated options over time
const accumulatedOptions = ref<T[]>([]) as Ref<T[]>

watch(
  () => props.options,
  (value) => {
    const optionIds = value.map((v) => v.id)
    accumulatedOptions.value = [
      ...value,
      ...accumulatedOptions.value.filter((v) => !optionIds.includes(v.id)),
    ]
  },
  { immediate: true }
)

const normalizedSelectedValues = computed(() => {
  if (Array.isArray(modelSelectedValues.value)) {
    return modelSelectedValues.value
  } else if (modelSelectedValues.value) {
    return [modelSelectedValues.value]
  } else {
    return []
  }
})

const idSelectedValues = computed({
  get: () => normalizedSelectedValues.value.map((v) => v.id),
  set: (value) => {
    if (!Array.isArray(value)) {
      value = [value]
    }
    modelSelectedValues.value = value
      .map((id) => accumulatedOptions.value.find((v) => v.id === id))
      .filter(notNullish)
  },
})

</script>

<script lang="ts">
export default {
  name: "DropdownTagInputSelect",
}
</script>

<style>
[data-highlighted][data-state="checked"] {
  @apply bg-gray-200;
}
</style>
