<template>
  <slot :openRoom="handleCreateRoom">
    <button @click="handleCreateRoom" :class="buttonClass">Send Message</button>
  </slot>
</template>

<script setup lang="ts">
import { nextTick } from "vue"
import { generateChatRoute } from "/js/router/ChatRoutes"
import { useRouter } from "vue-router"
import { useChatStoreV2 } from "/js/stores/ChatStoreV2"
import { ChatApi } from "/js/services/ChatApi"

const props = withDefaults(
  defineProps<{
    userId: string
    buttonClass?: string
  }>(),
  {
    buttonClass: "btn btn-primary",
  },
)

const router = useRouter()
const { insertChatRoom } = useChatStoreV2()

const handleCreateRoom = async () => {
  try {
    const room = await ChatApi.createDirectMessageRoom({ user_ids: [props.userId] })
    insertChatRoom(room)
    await nextTick()
    await router.push(generateChatRoute("direct", "show", { room_id: room.id }))
  } catch (e) {
    console.log(e)
  }
}

</script>

<script lang="ts">
export default {
  name: "MessageUserAction",
}
</script>
