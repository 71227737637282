import { getZod } from "/js/composables/useAxios"
import type { ChatMessageUser } from "/js/models/Chat"
import { ZChatMessageUser } from "/js/models/Chat"

export type UserReactionableType = "ChatMessage" | "GoalObjective"

export type UserReactionListParams = {
  reaction: string
  reactionableId: string
  reactionableType: UserReactionableType
}

export const UserReactionsApi = {
  getUsers: async (params: UserReactionListParams): Promise<ChatMessageUser[]> => {
    return await getZod(`/api/user_reactions/${params.reactionableType}/${params.reactionableId}/${params.reaction}/users`, ZChatMessageUser.array())
  }
}