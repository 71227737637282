<template>
  <div class="flex items-end w-full">
    <slot></slot>
    <input type="text" class="input-reset !w-0"/>
  </div>
</template>

<script setup lang="ts">

</script>

<style>

</style>