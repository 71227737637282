import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteMeta,
  RouteRecordRaw,
} from "vue-router"
import { RouterView } from "vue-router"

export type ChatRouteName = {
  index: string
  show: string
  cursor: string
  threadCursor: string
}

const metaCommunityReadRequired: RouteMeta = {
  authorize: {
    classType: "Community",
    authorizeFor: "community_read",
    requiresPlatformPlan: true,
  },
}

const ChatRouteGroupDirect = "direct" as const
const ChatRouteGroupChannel = "channel" as const
const ChatRouteGroupProduct = "product" as const
const ChatRouteGroupCommunity = "community" as const

const ChatRouteGroups = [
  ChatRouteGroupDirect,
  ChatRouteGroupChannel,
  ChatRouteGroupProduct,
  ChatRouteGroupCommunity,
] as const

export type ChatRouteGroup = (typeof ChatRouteGroups)[number]

export const chatRouteNames: Record<ChatRouteGroup, ChatRouteName> = {
  direct: {
    index: "chat.direct_messages.index",
    show: "chat.direct_messages.show",
    cursor: "chat.direct_messages.show.cursor",
    threadCursor: "chat.direct_messages.show.thread.cursor",
  },
  channel: {
    index: "chat.channels.index",
    show: "chat.channels.show",
    cursor: "chat.channels.show.cursor",
    threadCursor: "chat.channels.show.thread.cursor",
  },
  product: {
    index: "chat.product.channels.index",
    show: "chat.product.channels.show",
    cursor: "chat.product.channels.show.cursor",
    threadCursor: "chat.product.channels.show.thread.cursor",
  },
  community: {
    index: "chat.community.channels.index",
    show: "chat.community.channels.show",
    cursor: "chat.community.channels.show.cursor",
    threadCursor: "chat.community.channels.show.thread.cursor",
  },
} as const

type IndexParams = {}
type ShowParams = { room_id: string }
type CursorParams = { room_id: string; cursor_id: string }
type ThreadCursorParams = {
  room_id: string
  thread_id: string
  cursor_id: string
}

type RouteParams = {
  index: IndexParams
  show: ShowParams
  cursor: CursorParams
  threadCursor: ThreadCursorParams
}

type ProductParams = {
  product_id: string;
}

type RouteParamMap = {
  [K in keyof ChatRouteName]: RouteParams[K] & (ChatRouteGroup extends "product" ? ProductParams : {});
}

type GenerateChatRouteParams<K extends keyof RouteParamMap, G extends ChatRouteGroup> = G extends 'product'
  ? RouteParamMap[K] & { product_id: string }
  : RouteParamMap[K];

type ProductRouteParams<K extends keyof RouteParams> = RouteParams[K] & { product_id: string };
type RegularRouteParams<K extends keyof RouteParams> = RouteParams[K];

// TODO: the problem below is that GenerateChatRouteParams<K, G> does not constrain enough (allows some params to miss or be passed)
// export const generateChatRoute = <K extends keyof RouteParamMap, G extends ChatRouteGroup>(
//   chatRouteGroup: G,
//   routeType: K,
//   params: GenerateChatRouteParams<K, G>
// ): RouteLocationRaw => {
//   const routeParams = {
//     ...params,
//     chat_route_group: chatRouteGroup,
//   };
//
//   return {
//     name: chatRouteNames[chatRouteGroup][routeType],
//     params: routeParams,
//   };
// }

export const isChatRoute = (route: RouteLocationNormalized): boolean => {
  return Object.values(chatRouteNames).some((group) => Object.values(group).includes(route.name as string))
}

export const getChatRouteGroupFromRoute = (route: RouteLocationNormalized): ChatRouteGroup | undefined => {
  if (!route.name) return undefined;

  const routeName: string = route.name.toString();

  return ChatRouteGroups.find((group) => {
    const groupNames = Object.values(chatRouteNames[group])
    return groupNames.includes(routeName)
  })
}

function generateChatRoute<K extends keyof RouteParams>(
  chatRouteGroup: 'product',
  routeType: K,
  params: ProductRouteParams<K>
): RouteLocationRaw;

function generateChatRoute<K extends keyof RouteParams>(
  chatRouteGroup: Exclude<ChatRouteGroup, 'product'>,
  routeType: K,
  params: RegularRouteParams<K>
): RouteLocationRaw;

function generateChatRoute<K extends keyof RouteParams>(
  chatRouteGroup: ChatRouteGroup,
  routeType: K,
  params: any
): RouteLocationRaw {
  return {
    name: chatRouteNames[chatRouteGroup][routeType],
    params,
  };
}

export { generateChatRoute }

const chatLoaderProps = (
  chatRouteGroup: ChatRouteGroup,
  chatRouteName: keyof ChatRouteName,
  route: RouteLocationNormalized
) => {
  switch (chatRouteName) {
    case "index":
      return {
        chatRouteGroup,
      }
    case "show":
      return { chatRouteGroup, roomId: route.params.room_id }
    case "cursor":
      return {
        chatRouteGroup,
        roomId: route.params.room_id,
        mainCursorId: route.params.cursor_id,
      }
    case "threadCursor":
      return {
        chatRouteGroup,
        roomId: route.params.room_id,
        mainCursorId: route.params.thread_id,
        threadCursorId: route.params.cursor_id,
      }
  }
}

const buildChatRoute = (
  path: string,
  chatRouteGroup: ChatRouteGroup,
  chatRouteName: ChatRouteName,
  name: keyof ChatRouteName
): RouteRecordRaw => {
  return {
    path,
    name: chatRouteName[name],
    component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
    props: (route) => ({ ...chatLoaderProps(chatRouteGroup, name, route) }),
    meta: metaCommunityReadRequired,
  }
}

const buildChatRelativeRoutes = (
  chatRouteGroup: ChatRouteGroup,
  basePath: string
): RouteRecordRaw[] => {
  const chatRouteName = chatRouteNames[chatRouteGroup]

  return [
    {
      path: basePath,
      component: RouterView,
      redirect: { name: chatRouteName.index },
      children: [
        {
          path: "",
          name: chatRouteName.index,
          component: () =>
            chatRouteGroup === "direct"
              ? import("/js/components/Chat/DirectMessageList.vue")
              : import("/js/components/Chat/DiscussionList.vue"),
          meta: metaCommunityReadRequired,
        },
        {
          path: ":room_id",
          component: RouterView,
          redirect: { name: chatRouteName.show },
          children: [
            buildChatRoute("", chatRouteGroup, chatRouteName, "show"),
            buildChatRoute("at/:cursor_id", chatRouteGroup, chatRouteName, "cursor"),
            buildChatRoute(
              "thread/:thread_id/at/:cursor_id",
              chatRouteGroup,
              chatRouteName,
              "threadCursor"
            ),
          ],
        },
      ],
    },
  ]
}

export const directMessageRelativeRoutes: RouteRecordRaw[] = buildChatRelativeRoutes(
  "direct",
  "direct_messages"
)
export const channelRelativeRoutes: RouteRecordRaw[] = buildChatRelativeRoutes(
  "channel",
  "discussions"
)
export const communityChannelRoutes: RouteRecordRaw[] = buildChatRelativeRoutes(
  "community",
  "/community/chat"
)
export const productChannelRoutes: RouteRecordRaw[] = buildChatRelativeRoutes("product", "chat")

// export const chatRoutes: RouteRecordRaw[] = [
//   {
//     path: "/chat/direct_messages",
//     component: RouterView,
//     redirect: { name: chatRouteNames.directMessages },
//     meta: metaCommunityReadRequired,
//     children: [
//       {
//         path: "",
//         name: chatRouteNames.directMessages,
//         component: () => import("/js/components/Chat/DirectMessageList.vue"),
//         meta: metaCommunityReadRequired,
//       },
//       {
//         path: ":room_id",
//         component: RouterView,
//         redirect: { name: chatRouteNames.directMessage },
//         children: [
//           {
//             path: "",
//             name: chatRouteNames.directMessage,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({ roomId: route.params.room_id, type: "direct" }),
//             meta: metaCommunityReadRequired,
//           },
//           {
//             path: "at/:cursor_id",
//             name: chatRouteNames.directMessageCursor,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({
//               roomId: route.params.room_id,
//               type: "direct",
//               mainCursorId: route.params.cursor_id,
//             }),
//             meta: metaCommunityReadRequired,
//           },
//           {
//             path: "thread/:thread_id/at/:cursor_id",
//             name: chatRouteNames.directMessageThreadCursor,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({
//               roomId: route.params.room_id,
//               type: "direct",
//               mainCursorId: route.params.thread_id,
//               threadCursorId: route.params.cursor_id,
//             }),
//             meta: metaCommunityReadRequired,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: "/chat/discussions",
//     component: RouterView,
//     redirect: { name: chatRouteNames.channels },
//     children: [
//       {
//         path: "",
//         name: chatRouteNames.channels,
//         component: () => import("/js/components/Chat/DiscussionList.vue"),
//         meta: metaCommunityReadRequired,
//       },
//       {
//         path: ":room_id",
//         component: RouterView,
//         redirect: { name: chatRouteNames.channel },
//         children: [
//           {
//             path: "",
//             name: chatRouteNames.channel,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({ roomId: route.params.room_id, type: "channel" }),
//             meta: metaCommunityReadRequired,
//           },
//           {
//             path: "at/:cursor_id",
//             name: chatRouteNames.channelCursor,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({
//               roomId: route.params.room_id,
//               type: "channel",
//               mainCursorId: route.params.cursor_id,
//             }),
//             meta: metaCommunityReadRequired,
//           },
//           {
//             path: "thread/:thread_id/at/:cursor_id",
//             name: chatRouteNames.channelThreadCursor,
//             component: () => import("/js/components/Chat/ChatRoomLoader.vue"),
//             props: (route) => ({
//               roomId: route.params.room_id,
//               type: "channel",
//               mainCursorId: route.params.thread_id,
//               threadCursorId: route.params.cursor_id,
//             }),
//             meta: metaCommunityReadRequired,
//           },
//         ],
//       },
//     ],
//   },
// ]
