import { z } from "zod"

export const ZPollOption = z.object({
  id: z.string(),
  text: z.string(),
  votes_count: z.number(),
  position: z.number(),
})

export const UserPollVote = z.object({
  id: z.string(),
  poll_option_id: z.string(),
})

const pollablePostType = z.literal("Post")
const pollableDummyType = z.literal("Dummy")

const ZPollPostData = z.object({
  post_id: z.string(),
  post_feed_id: z.string(),
  product_id: z.string(),
  product_name: z.string(),
})

export const ZPoll = z.object({
  id: z.string(),
  question: z.string(),
  end_time: z.date().optional().nullable(),
  poll_options: z.array(ZPollOption),
  total_votes: z.number(),
  user_vote: UserPollVote.optional().nullable(),
})

export const ZAdminPoll = z.union([
  z.object({
    pollable_type: pollablePostType,
    pollable_data: ZPollPostData,
    ...ZPoll.shape,
  }),
  z.object({
    pollable_type: pollableDummyType,
    ...ZPoll.shape,
  })
])

export type PollParams = {
  id?: string
  question?: string
  end_time?: Date | null
  _destroy?: boolean
  poll_options_attributes: PollOptionParams[]
}
export type PollOptionParams = {
  id?: string
  text: string
  _destroy?: boolean
}

export type Poll = z.infer<typeof ZPoll>
export type PollOption = z.infer<typeof ZPollOption>
export type AdminPoll = z.infer<typeof ZAdminPoll>

export const optionVotePercentage = (option: PollOption, totalVotes: number) => {
  return totalVotes > 0 ? (option.votes_count / totalVotes) * 100 : 0
}
