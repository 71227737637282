import { z } from "zod"
import { postZod } from "/js/composables/useAxios"

export const ZLinkPreview = z.object({
  success: z.literal(1).or(z.literal(0)),
  link: z.string(),
  meta: z.object({
    title: z.string().nullable(),
    description: z.string().nullable(),
    favicon: z.string().nullable(),
    host: z.string().nullable(),
    author: z.string().nullable(),
    image: z.object({
      url: z.string().nullable()
    })
  })
})

export type LinkPreview = z.infer<typeof ZLinkPreview>

export const LinkPreviewsApi = {
  createPreview: async (url: string): Promise<LinkPreview> => {
    return await postZod(`/api/link_previews`, { link_preview: { url } }, ZLinkPreview)
  }
}