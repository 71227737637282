import {del, get, getZod, patch, patchZod, post, postZod} from "/js/composables/useAxios"
import {z} from "zod"
import {type Product, ZProduct, ZProductAttachment} from "/js/models/Product"
import {useQuery, type UseQueryReturnType} from "@tanstack/vue-query"
import {makeGetUrl} from "/js/composables/makeQueryString"
import { computed } from "vue"

export const PlanTypes = ["free", "paid"] as const
export const PlanStatuses = ["draft", "published", "disabled"] as const
export const PriceTypes = ["recurring", "one_time"] as const
export const RecurringIntervals = ["month", "year"] as const
export const SubscriptionStatuses = [
  "incomplete",
  "incomplete_expired",
  "trialing",
  "active",
  "past_due",
  "canceled",
  "unpaid",
] as const
export const StripePlanTypes = ["CommunityPlan", "ProductPlan"] as const

export const ZBillingLink = z.object({
  url: z.string(),
})

export const ZStripePrice = z.object({
  id: z.string(),
  name: z.string(),
  price_type: z.enum(PriceTypes),
  recurring_interval: z.enum(RecurringIntervals).nullable(),
  unit_amount: z.number(),
  active: z.boolean(),
  currency: z.string(),
  published: z.boolean(),
  stripe_price_id: z.string().nullable(),
  stripe_plan_id: z.string(),
})

export const ZStripePlan = z.object({
  id: z.string(),
  name: z.string(),
  type: z.enum(StripePlanTypes),
  description: z.string().nullable(),
  plan_type: z.enum(PlanTypes),
  status: z.enum(PlanStatuses),
  cover_image: ZProductAttachment.nullable().optional(),
  prices: ZStripePrice.array(),
  product_ids: z.string().array(),
})

export const ZStripeCheckoutSession = z.object({
  client_secret: z.string(),
})

export const ZStripeSubscription = z.object({
  id: z.string(),
  status: z.enum(SubscriptionStatuses),
  current_period_end: z.date(),
  current_period_start: z.date(),
  cancel_at_period_end: z.boolean().nullable(),
  prices: ZStripePrice.array(),
  stripe_plans: ZStripePlan.omit({prices: true, cover_image: true}).array(),
})

export type StripeSubscription = z.infer<typeof ZStripeSubscription>

export const ZStripeSessionCheckout = z.object({
  type: z.literal("checkout"),
  object: ZStripeCheckoutSession,
})

export const ZStripeSessionSubscription = z.object({
  type: z.literal("subscription"),
  object: ZStripeSubscription,
})

export const ZStripeSession = z.union([ZStripeSessionCheckout, ZStripeSessionSubscription])

export const ZStripeCheckoutStatus = z.object({
  id: z.string(),
  status: z.enum(["paid", "unpaid", "no_payment_required"]).nullable(), // Mirror Stripe statuses
})

export type StripePlan = z.infer<typeof ZStripePlan>
export type StripePrice = z.infer<typeof ZStripePrice>
export type RecurringInterval = (typeof RecurringIntervals)[number]
export type BillingLink = z.infer<typeof ZBillingLink>
export type StripeCheckoutStatus = z.infer<typeof ZStripeCheckoutStatus>

export const subscriptionQueryKey = ["my_subscriptions"]
export const plansQueryKey = (productId?: string) => {
  if (productId) {
    return ["plans", productId]
  } else {
    return ["plans"]
  }
}

export const usePlansQuery = (productId?: string) => {
  console.log("mege")
  return useQuery({
    queryKey: computed(() => plansQueryKey(productId)),
    queryFn: async () => await PlansApi.getPlans(productId),
  })
}

export const PlansApi = {
  getPlans: async (productId?: string) => {
    return await getZod(
      makeGetUrl("/api/payments/community_plans", {product_id: productId}),
      ZStripePlan.array()
    )
  },

  getPlan: async (id: string) => {
    return await getZod(`/api/payments/community_plans/${id}`, ZStripePlan)
  },

  getProducts: async (planId: string): Promise<Product[]> => {
    return await get(`/api/payments/community_plans/${planId}/plan_products`)
  },

  createOneTimePurchase: async (price_id: string, redirect: string) => {
    return await postZod(`/api/payments/subscription/create_one_time`, {price_id, redirect}, ZStripeSession)
  },

  changeSubscription: async (price_id: string, redirect: string) => {
    return await postZod(`/api/payments/subscription`, {price_id, redirect}, ZStripeSession)
  },

  createFreeSubscription: async () => {
    return await postZod(`/api/payments/subscription/create_free`, {}, ZStripeSessionSubscription)
  },

  cancelSubscription: async (): Promise<void> => {
    return await del(`/api/payments/subscription`)
  },

  reactivateSubscription: async (): Promise<void> => {
    return await patch(`/api/payments/subscription/reactivate`, {})
  },

  getSubscription: async (): Promise<StripeSubscription | null> => {
    try {
      return await getZod("/api/payments/subscription", ZStripeSubscription)
    } catch (e: any) {
      if (e.response.status === 404) {
        return null
      } else {
        throw e
      }
    }
  },

  getSubscriptionTokens: async (): Promise<string[]> => {
    return getZod("/api/payments/subscription_tokens", z.string().array())
  },

  getBillingLink: async () => {
    return getZod("/api/payments/billing", ZBillingLink)
  },

  getCheckoutSessionStatus: async (id: string) => {
    return getZod(`/api/payments/checkout_sessions/${id}`, ZStripeCheckoutStatus)
  }
}
