<template>
  <svg width="300" height="246" viewBox="0 0 300 246" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M145.316 243.23C206.169 243.23 255.5 193.613 255.5 132.407C255.5 71.2016 206.169 21.5847 145.316 21.5847C84.4633 21.5847 35.1324 71.2016 35.1324 132.407C35.1324 193.613 84.4633 243.23 145.316 243.23Z" fill="#EAEEF9"/>
    <g filter="url(#filter0_d_177_3715)">
      <path d="M42.6694 75.3238V89.5947V203.316C42.6694 208.445 46.8817 212.681 51.9807 212.681H235.325C240.424 212.681 244.636 208.445 244.636 203.316V89.5947V75.7697C244.636 70.1952 240.202 65.9585 234.881 65.9585H51.9807L42.6694 75.3238Z" fill="url(#paint0_linear_177_3715)"/>
    </g>
    <path d="M184.556 85.135H42.6694V75.3238C42.6694 70.1952 46.8817 65.9585 51.9807 65.9585H235.325C240.424 65.9585 244.636 70.1952 244.636 75.3238V85.135H213.377H184.556Z" fill="#D5DDEA"/>
    <path d="M54.6413 79.1149C56.3554 79.1149 57.745 77.7172 57.745 75.9931C57.745 74.269 56.3554 72.8713 54.6413 72.8713C52.9271 72.8713 51.5375 74.269 51.5375 75.9931C51.5375 77.7172 52.9271 79.1149 54.6413 79.1149Z" fill="#989FB0"/>
    <path d="M63.5092 79.1149C65.2233 79.1149 66.6129 77.7172 66.6129 75.9931C66.6129 74.269 65.2233 72.8713 63.5092 72.8713C61.795 72.8713 60.4054 74.269 60.4054 75.9931C60.4054 77.7172 61.795 79.1149 63.5092 79.1149Z" fill="#989FB0"/>
    <path d="M72.5991 79.1149C74.3133 79.1149 75.7029 77.7172 75.7029 75.9931C75.7029 74.269 74.3133 72.8713 72.5991 72.8713C70.8849 72.8713 69.4953 74.269 69.4953 75.9931C69.4953 77.7172 70.8849 79.1149 72.5991 79.1149Z" fill="#989FB0"/>
    <path d="M135.117 140.212L98.9805 139.989C97.4286 139.989 96.3201 138.651 96.3201 137.313C96.3201 135.752 97.6503 134.637 98.9805 134.637L135.117 134.86C136.669 134.86 137.778 136.198 137.778 137.536C137.778 138.874 136.669 140.212 135.117 140.212Z" fill="#D6DCE8"/>
    <path d="M220.914 140.212L179.235 139.989C177.683 139.989 176.575 138.651 176.575 137.313C176.575 135.752 177.905 134.637 179.235 134.637L220.693 134.86C222.244 134.86 223.353 136.198 223.353 137.536C223.575 138.874 222.466 140.212 220.914 140.212Z" fill="#EAECF3"/>
    <path d="M193.202 192.613L151.523 192.39C149.971 192.39 148.863 191.052 148.863 189.714C148.863 188.153 150.193 187.039 151.523 187.039L193.202 187.262C194.754 187.262 195.863 188.599 195.863 189.937C195.863 191.498 194.532 192.613 193.202 192.613Z" fill="#D6DCE8"/>
    <path d="M167.485 139.989L147.311 139.766C145.759 139.766 144.65 138.428 144.65 137.09C144.65 135.529 145.98 134.415 147.311 134.415L167.485 134.638C169.037 134.638 170.145 135.975 170.145 137.313C170.367 138.874 169.037 139.989 167.485 139.989Z" fill="#AAB2C5"/>
    <path d="M84.5704 139.766H69.7166C68.1647 139.766 67.0562 138.428 67.0562 137.09C67.0562 135.529 68.1647 134.415 69.7166 134.415H84.5704C86.1222 134.415 87.2307 135.752 87.2307 137.09C87.2307 138.651 86.1222 139.766 84.5704 139.766Z" fill="#AAB2C5"/>
    <path d="M142.212 157.605L120.485 157.382C118.933 157.382 117.825 156.044 117.825 154.706C117.825 153.145 119.155 152.03 120.485 152.03L142.212 152.253C143.764 152.253 144.872 153.591 144.872 154.929C145.094 156.267 143.764 157.605 142.212 157.605Z" fill="#D6DCE8"/>
    <path d="M209.608 158.051L154.405 157.382C152.853 157.382 151.745 156.044 151.745 154.706C151.745 153.145 153.075 152.03 154.405 152.03L209.608 152.699C211.16 152.699 212.268 154.037 212.268 155.375C212.268 156.713 210.938 158.051 209.608 158.051Z" fill="#D6DCE8"/>
    <path d="M106.075 157.382H69.7166C68.1647 157.382 67.0562 156.044 67.0562 154.706C67.0562 153.145 68.3864 152.03 69.7166 152.03H106.075C107.627 152.03 108.735 153.368 108.735 154.706C108.735 156.044 107.627 157.382 106.075 157.382Z" fill="#EAECF3"/>
    <path d="M170.811 174.997L120.485 174.774C118.933 174.774 117.825 173.437 117.825 172.099C117.825 170.538 119.155 169.423 120.485 169.423L171.032 169.646C172.584 169.646 173.693 170.984 173.693 172.322C173.693 173.883 172.363 174.997 170.811 174.997Z" fill="#AAB2C5"/>
    <path d="M106.075 174.774H69.7166C68.1647 174.774 67.0562 173.437 67.0562 172.099C67.0562 170.538 68.3864 169.423 69.7166 169.423H106.075C107.627 169.423 108.735 170.761 108.735 172.099C108.735 173.437 107.627 174.774 106.075 174.774Z" fill="#D6DCE8"/>
    <path d="M137.556 192.39L109.844 192.167C108.292 192.167 107.184 190.829 107.184 189.491C107.184 187.93 108.514 186.815 109.844 186.815L137.556 187.038C139.108 187.038 140.216 188.376 140.216 189.714C140.216 191.275 139.108 192.39 137.556 192.39Z" fill="#EAECF3"/>
    <path d="M92.1081 192.167H69.7166C68.1647 192.167 67.0562 190.829 67.0562 189.491C67.0562 187.93 68.3864 186.815 69.7166 186.815H92.1081C93.66 186.815 94.7684 188.153 94.7684 189.491C94.9901 191.052 93.66 192.167 92.1081 192.167Z" fill="#AAB2C5"/>
    <path d="M171.698 113.231L69.717 112.785C68.1651 112.785 67.0567 111.447 67.0567 110.109C67.0567 108.549 68.3868 107.434 69.717 107.434L171.698 107.88C173.25 107.88 174.358 109.217 174.358 110.555C174.358 112.116 173.25 113.231 171.698 113.231Z" fill="#AAB2C5"/>
    <path d="M5.98583 101.457C6.42922 101.903 6.65093 102.349 6.65093 103.018C4.43395 109.485 3.76886 109.707 6.65093 110.822C6.87262 110.822 7.09432 111.268 6.87262 111.491C6.20753 113.275 5.98583 114.613 5.32074 114.39C-5.09905 110.599 5.54244 103.464 0.221694 101.457C-3.38614e-06 101.457 0 101.234 0 101.011L1.10849 97.6664C1.10849 97.4434 1.33018 97.4434 1.55188 97.4434C4.21225 98.3354 4.43395 96.3285 5.32074 93.8757C6.42922 90.308 9.31129 89.1931 12.6368 90.308C13.3018 90.531 13.9669 90.7539 13.7452 91.1999C12.6368 94.5447 12.8585 94.3217 11.5283 93.8757C8.2028 92.7608 9.08959 101.68 5.98583 101.457Z" fill="#AAB2C5"/>
    <path d="M292.197 102.126C291.975 102.126 291.975 102.349 292.197 102.126C292.64 102.572 292.862 103.018 292.862 103.687C290.645 110.153 289.98 110.376 292.862 111.491C293.084 111.491 293.306 111.937 293.084 112.16C292.419 113.944 292.197 115.282 291.532 115.059C281.112 111.268 291.754 104.133 286.433 102.126C286.211 102.126 286.211 101.903 286.211 101.68L287.32 98.3354C287.32 98.1124 287.541 98.1124 287.763 98.1124C290.424 99.0043 290.645 96.9975 291.532 94.5447C292.64 90.9769 295.523 89.862 298.848 90.9769C299.513 91.1999 300.178 91.4229 299.957 91.8689C298.848 95.2136 299.07 94.9906 297.74 94.5447C294.192 93.6527 295.301 102.349 292.197 102.126Z" fill="#AAB2C5"/>
    <path d="M162.726 5.79755C162.504 5.57457 162.504 5.57457 162.726 5.79755C162.283 6.24352 161.839 6.68949 161.396 6.68949C154.745 5.57457 154.301 4.90562 153.636 7.8044C153.636 8.02738 153.415 8.25037 152.971 8.25037C150.976 7.8044 149.867 8.02738 149.867 7.13545C151.863 -4.01369 160.731 5.57457 161.617 0.222982C161.617 -1.27924e-06 161.839 0 162.061 0L165.386 0.668947C165.608 0.668947 165.608 0.891931 165.608 1.11491C165.165 3.79071 166.938 3.79071 169.82 4.23667C173.367 4.90562 175.141 7.35843 174.476 10.9262C174.254 11.5951 174.254 12.2641 173.811 12.2641C170.485 11.5951 170.485 12.0411 170.929 10.4802C171.372 6.4665 162.726 8.91931 162.726 5.79755Z" fill="#AAB2C5"/>
    <path d="M94.9906 21.1387C93.4387 22.6996 93.217 25.3754 94.7689 26.9363C95.6557 28.0512 97.2076 28.4971 98.7595 28.0512C99.4246 28.9431 99.4246 30.058 98.9812 30.9499L96.5425 34.5177C96.3208 34.7407 96.0991 35.1866 96.0991 35.4096L93.6604 32.7338C94.3255 30.504 93.217 28.2742 91.0001 27.6052C88.7831 26.9363 86.5661 28.0512 85.901 30.281C85.2359 32.5108 86.3444 34.7407 88.5614 35.4096C89.2265 35.6326 90.1133 35.6326 90.7784 35.4096L97.2076 42.3221C96.5425 44.5519 97.651 46.7817 99.868 47.4507C102.085 48.1196 104.302 47.0047 104.967 44.7749C104.967 44.5519 104.967 44.5519 105.189 44.3289C105.189 43.8829 105.189 43.437 105.189 43.214C104.967 40.9842 102.972 39.2003 100.533 39.6463C100.311 39.6463 100.311 39.6463 100.09 39.6463C99.4246 38.7543 99.2029 37.6394 99.868 36.7475L102.307 33.1798C103.859 30.727 103.637 27.6052 101.863 25.3754C101.863 25.1524 101.863 24.9294 102.085 24.9294C102.085 24.4834 102.085 24.0375 102.085 23.8145C101.863 21.5847 99.868 20.0238 97.651 20.2468C96.3208 20.2468 95.6557 20.4698 94.9906 21.1387ZM88.7831 30.504C89.4482 30.058 90.1133 30.058 90.7784 30.504C91.2218 31.1729 91.2218 31.8419 90.7784 32.5108C90.1133 32.9568 89.4482 32.9568 88.7831 32.5108C88.118 32.0649 88.118 31.1729 88.7831 30.504ZM102.085 42.991C102.307 43.214 102.307 43.437 102.307 43.66C102.307 44.3289 101.642 44.9979 100.755 44.9979C100.09 44.9979 99.4246 44.3289 99.4246 43.437C99.4246 43.214 99.6463 42.768 99.6463 42.5451C99.868 42.0991 100.311 42.0991 100.755 42.0991C101.42 42.3221 101.863 42.5451 102.085 42.991ZM96.5425 23.1455C96.9859 22.4766 97.8727 22.4766 98.5378 22.9226C98.7595 22.9226 98.7595 23.1455 98.7595 23.3685C98.7595 23.5915 98.7595 23.5915 98.9812 23.8145C98.9812 24.0375 98.9812 24.0375 98.9812 24.2605C98.9812 24.4834 98.9812 24.4834 98.9812 24.4834C98.7595 24.7064 98.5378 24.9294 98.3161 25.1524C97.651 25.5984 96.7642 25.1524 96.5425 24.4834C96.3208 24.2605 96.3208 23.5915 96.5425 23.1455Z" fill="#D6DCE8"/>
    <path d="M184.014 40.7247L180.688 42.5086C177.363 39.1638 172.264 39.1638 168.939 42.5086C167.165 44.2924 166.278 46.9682 166.722 49.644L163.174 51.2049C162.509 51.4279 162.288 52.3198 162.731 52.7658L164.061 55.4416C164.283 56.1105 165.17 56.3335 165.613 55.8875L168.939 54.1037C172.264 57.4484 177.363 57.4484 180.688 54.1037C182.462 52.3198 183.349 49.644 182.905 46.9682L186.231 45.1843C186.896 44.9614 187.118 44.0694 186.674 43.6235L185.344 40.9477C185.344 40.7247 184.457 40.5017 184.014 40.7247ZM176.698 52.0968C174.703 53.2117 172.264 52.3198 171.155 50.313C170.047 48.3061 170.934 45.8533 172.929 44.7384C174.924 43.6235 177.363 44.5154 178.472 46.5222C179.358 48.5291 178.693 50.9819 176.698 52.0968Z" fill="#D6DCE8"/>
    <path d="M29.9291 180.589L26.382 174.123C25.0518 171.67 22.1697 170.778 19.731 172.116L19.0659 172.339L18.4008 171.224C18.1791 170.778 17.7357 170.778 17.2923 171.001C17.0706 171.001 17.0706 171.224 16.849 171.447L15.0754 175.907C14.8537 176.353 15.0754 176.799 15.5188 177.021C15.5188 177.021 15.5188 177.021 15.7405 177.021L20.3961 177.913C20.8395 177.913 21.2829 177.69 21.2829 177.244C21.2829 177.021 21.2829 176.799 21.2829 176.799L20.6178 175.684L21.2829 175.238C21.948 174.792 23.0565 175.015 23.2782 175.907L26.8253 182.373C26.1603 183.488 26.1603 184.826 26.8253 185.941C27.7121 187.725 29.7074 188.171 31.481 187.279C32.1461 186.833 32.5895 186.387 33.0329 185.718C33.2546 185.495 33.2546 185.049 33.2546 184.826C33.4763 182.819 32.1461 181.035 30.1508 180.812C30.1508 180.589 29.9291 180.589 29.9291 180.589ZM29.4857 185.049C29.264 185.049 29.264 185.049 29.4857 185.049C28.8206 184.603 28.5989 183.934 29.0423 183.265C29.264 182.819 29.7074 182.596 30.1508 182.596C30.3725 182.596 30.8159 182.819 31.0376 183.042C31.481 183.488 31.481 184.157 31.0376 184.603C30.3725 185.272 29.9291 185.272 29.4857 185.049Z" fill="#D6DCE8"/>
    <path d="M10.4199 174.793C8.86805 175.685 8.20296 177.915 9.08975 179.476C9.75484 180.591 10.8633 181.26 12.1935 181.26L18.401 192.409C18.8444 193.301 19.7312 193.524 20.618 193.078C21.5048 192.632 21.7265 191.74 21.2831 190.848L15.0756 179.699C15.7407 178.584 15.7407 177.246 15.0756 176.131C14.1888 174.57 11.9718 173.901 10.4199 174.793ZM12.6369 178.807C11.9718 179.03 11.3067 179.03 11.085 178.361C10.8633 177.692 10.8633 177.023 11.5284 176.8C12.1935 176.577 12.8586 176.577 13.0803 177.246C13.302 177.915 13.0803 178.584 12.6369 178.807Z" fill="#D6DCE8"/>
    <path d="M274.787 144.894L275.009 144.448C275.674 143.11 275.674 141.772 275.009 140.434L273.014 136.198C272.792 135.975 272.792 135.529 273.014 135.306L273.679 133.745L274.787 134.414C275.231 134.637 275.674 134.414 275.896 133.968C275.896 133.745 275.896 133.522 275.896 133.522L275.009 128.616C275.009 128.17 274.566 127.947 274.122 127.947H273.901L269.467 129.954C269.023 130.177 268.802 130.623 269.023 131.069C269.023 131.292 269.245 131.292 269.467 131.515L270.575 132.184L269.91 133.745C269.688 133.968 269.467 134.191 269.245 134.191L264.589 134.86C263.259 135.083 261.929 135.975 261.486 137.09L261.264 137.536C259.269 137.536 257.717 139.096 257.717 141.103C257.717 143.11 259.269 144.671 261.264 144.671C263.259 144.671 264.811 143.11 264.811 141.103C264.811 140.434 264.589 139.765 264.146 139.319L264.368 138.873C264.589 138.65 264.811 138.427 265.033 138.427L269.023 137.759C269.467 137.759 269.91 137.981 270.132 138.427L271.905 142.218C272.127 142.441 272.127 142.887 271.905 143.11L271.684 143.556C269.688 143.556 268.137 145.34 268.358 147.124C268.358 149.131 270.132 150.692 271.905 150.469C273.901 150.469 275.453 148.685 275.231 146.901C275.453 146.232 275.231 145.563 274.787 144.894ZM260.821 142.441C260.155 142.218 259.934 141.326 260.377 140.88C260.599 140.211 261.486 139.988 261.929 140.434C262.594 140.657 262.816 141.549 262.372 141.995C262.151 142.441 261.264 142.664 260.821 142.441ZM271.24 148.016C270.575 147.793 270.354 146.901 270.797 146.455C271.019 145.786 271.905 145.563 272.349 146.009C272.792 146.455 273.236 147.124 272.792 147.57C272.571 148.016 271.905 148.239 271.24 148.016Z" fill="#D6DCE8"/>
    <path d="M129.354 230.074C128.024 230.297 126.915 231.189 126.472 232.527C122.925 233.196 120.043 230.52 119.377 229.182C120.708 227.621 120.486 225.391 118.934 224.053C117.382 222.715 115.165 222.938 113.835 224.499C112.505 226.06 112.727 228.29 114.278 229.628C114.722 229.851 115.165 230.297 115.609 230.297L117.16 238.547C115.609 239.885 115.387 242.115 116.717 243.676C118.047 245.237 120.264 245.46 121.816 244.122C122.038 244.122 122.038 243.899 122.259 243.899C122.481 243.676 122.703 243.453 122.925 243.007C123.811 241.223 123.146 238.993 121.151 238.101C120.929 238.101 120.929 237.878 120.708 237.878L120.043 234.756C122.038 236.094 124.476 236.54 126.915 236.317H127.137C128.467 237.878 130.684 238.101 132.236 236.763C132.458 236.763 132.458 236.54 132.458 236.54C132.679 236.317 132.901 236.094 133.123 235.648C134.009 233.865 133.344 231.635 131.571 230.743C130.906 230.074 130.019 229.851 129.354 230.074ZM116.495 225.391C117.16 225.168 117.826 225.614 118.047 226.506C118.269 227.175 117.826 227.844 116.939 228.067C116.274 228.29 115.609 227.844 115.387 226.952C115.387 226.283 115.83 225.614 116.495 225.391ZM120.043 242.338C119.599 242.784 118.712 242.561 118.269 242.115C117.826 241.669 118.047 240.777 118.491 240.331C118.491 240.331 118.712 240.108 118.934 240.108C119.156 239.885 119.599 240.108 119.821 240.108C120.486 240.554 120.708 241.223 120.264 241.892C120.486 242.115 120.264 242.115 120.043 242.338ZM130.019 234.979C129.354 234.979 128.91 234.311 128.91 233.642C128.91 233.196 129.354 232.75 129.797 232.527C130.019 232.527 130.462 232.527 130.684 232.527C131.349 232.75 131.571 233.642 131.349 234.311C130.906 234.756 130.462 234.979 130.019 234.979Z" fill="#D6DCE8"/>
    <defs>
      <filter id="filter0_d_177_3715" x="20.6694" y="54.9585" width="245.967" height="190.723" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="11"/>
        <feGaussianBlur stdDeviation="11"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_177_3715"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_177_3715" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_177_3715" x1="143.587" y1="62.5647" x2="143.587" y2="214.263" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFEFF"/>
        <stop offset="0.9964" stop-color="#ECF0F5"/>
      </linearGradient>
    </defs>
  </svg>
</template>