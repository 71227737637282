<template>
  <Menu as="div" class="relative" v-if="currentCommunity">
    <HighOrderFloat>
      <MenuButton class="btn-zoom relative w-full" @click.prevent.stop>
        <div
          class="absolute inset-x-1 -bottom-1 top-0 rounded-lg bg-zinc-100 dark:bg-ll-green-450"></div>
        <div
          class="absolute inset-x-0.5 -bottom-0.5 top-0 rounded-lg bg-neutral-300 dark:bg-ll-green-100"></div>
        <community-logo :community="currentCommunity" class="relative" />
        <div
            class="absolute top-0 right-0 -mt-1 -mr-1">
          <unread-dot v-if="anyUnreadNotifications" />
        </div>
      </MenuButton>
      <scale-opacity-transition>
        <MenuItems
          class="absolute mt-1 flex max-h-3/4 max-w-sm origin-top-left flex-col gap-1 overflow-y-auto rounded-xl bg-white py-2 pr-2 shadow-lg focus:outline-none scrollbar:h-0 scrollbar:w-0 dark:bg-ll-green-800">
          <MenuItem v-slot="{ active }" v-for="community in communities">
            <div class="flex w-full items-center gap-1">
              <span
                :class="[
                  'h-8 w-1 rounded-br-full rounded-tr-full bg-ll-green-400',
                  currentCommunity.id !== community.id && 'invisible',
                ]"></span>
              <button
                @click="handleCommunitySelect(community)"
                :class="[
                  'w-full flex-1 rounded-lg p-2',
                  (active || currentCommunity.id === community.id) &&
                    'bg-gray-100 dark:bg-ll-green-600',
                ]">
                <community-nav-button :community="community" :has-unread-notifications="hasUnreadNotifications(community)" />
              </button>
            </div>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <div class="flex w-full items-center gap-1">
              <span class="w-1"></span>
              <button
                @click="handleNewCommunity"
                :class="[
                  'group flex w-full items-center gap-3 rounded-lg p-2 text-sm text-gray-700 dark:text-white',
                  active && 'bg-gray-100 dark:bg-ll-green-600',
                ]">
                <span
                  class="flex aspect-square w-10 items-center justify-center rounded-lg bg-teal-600 text-white dark:bg-ll-green-600 dark:text-ll-green-200 dark:group-hover:bg-ll-green-700 dark:group-hover:text-white">
                  <plus-icon class="h-6 w-6" />
                </span>

                Add New Community
              </button>
            </div>
          </MenuItem>
        </MenuItems>
      </scale-opacity-transition>
    </HighOrderFloat>
  </Menu>
</template>

<script setup lang="ts">
import PlusIcon from "@heroicons/vue/24/solid/PlusIcon"
import { type Community } from "/js/models/Community"
import { useCommunityService } from "/js/services/useCommunityService"
import CommunityLogo from "/js/components/Layout/CommunityLogo.vue"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import ScaleOpacityTransition from "/js/components/Transitions/ScaleOpacityTransition.vue"
import CommunityNavButton from "/js/components/Layout/CommunityNavButton.vue"
import HighOrderFloat from "/js/components/HighOrderFloat.vue"
import UnreadDot from "/js/components/icons/UnreadDot.vue";
import {computed} from "vue";
import {useNotificationsStore} from "/js/services/NotificationsStore";
import {storeToRefs} from "pinia";

const communityService = useCommunityService()
const { communitiesUnreadCount } = storeToRefs(useNotificationsStore())

const { data: communities } = communityService.loadCommunities()
const { data: currentCommunity } = communityService.loadCurrentCommunity()

const activeCommunity = (community: Community) => {
  return community.id === currentCommunity.value?.id
}

const handleCommunitySelect = (community: Community) => {
  if (activeCommunity(community)) return
  window.location.href = community.community_url
}

const handleNewCommunity = () => {
  window.location.href = "/community/new"
}

const anyUnreadNotifications = computed(() => {
  return Object.values(communitiesUnreadCount.value).some((count) => count > 0)
})

const hasUnreadNotifications = (community: Community) => {
  return (communitiesUnreadCount.value[community.id] ?? 0) > 0
}

</script>

<script lang="ts">
export default {
  name: "SchoolsNav",
}
</script>
