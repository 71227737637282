import type {CommunityAccessType} from "/js/models/Community";
import {GlobeAltIcon, LockClosedIcon} from "@heroicons/vue/24/outline";

export type AccessOption = {
  text: string
  value: CommunityAccessType
  description: string
  icon: any
}

export const communityAccessOptions: AccessOption[] = [
  {
    text: "Private",
    value: "private_community",
    description: "Only people you invite can join your community",
    icon: LockClosedIcon,
  },
  {
    text: "Public",
    value: "public_community",
    description: "Anyone can join your community",
    icon: GlobeAltIcon,
  },
]