import pickBy from 'lodash/pickBy'

export const makeQueryString = (params: Record<string, any>) => {
  const snakeCaseParams = convertObjectKeysToSnakeCase(params)
  return new URLSearchParams(pickBy(snakeCaseParams, (val) => ![null, undefined, ""].includes(val)))
}

export const makeGetUrl = (base: string, params: Record<string, any> | undefined) => {
  if (!params) return base
  const queryString = makeQueryString(params)
  if (!queryString) return base
  return `${base}?${queryString}`
}

const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const convertObjectKeysToSnakeCase = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    acc[camelToSnakeCase(key)] = obj[key]
    return acc
  }, {})
}