<template>
  <cropper-modal
    :modalType
    :aspectRatio
    v-model="fileToCrop"
    @cropped-file="onCroppedFile" />

  <file-input
    :accept
    :multiple
    @files="onFileInputChange"
    #default="{ selectFile }">
    <slot v-bind="{ selectFile, importFileToCrop }" />
  </file-input>
</template>

<script setup lang="ts">
import { ref } from "vue"
import FileInput from "/js/components/utilities/FormFields/FileUpload/HelperFields/FileInput.vue"
import CropperModal from "/js/components/utilities/FormFields/FileUpload/HelperFields/CropperModal.vue"
import type { SupportedMedia } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import { mediaSupportedMimeTypes } from "/js/components/utilities/FormFields/FileUpload/useFileSupportedMedia"
import type { ModalProps } from "/js/components/Modals/ModalTypes"

defineProps<{
  multiple?: boolean
  aspectRatio?: number | undefined
  accept?: SupportedMedia | SupportedMedia[]
  modalType?: ModalProps["modalType"]
}>()

const fileToCrop = ref<File | undefined>()

const emit = defineEmits<{
  files: [value: File[]]
}>()

const onFileInputChange = (files: File[] | undefined) => {
  if (!files || !files.length) return
  if (files.length === 1 && isCroppable(files[0])) {
    fileToCrop.value = files[0]
  } else {
    emit("files", files)
  }
}

const onCroppedFile = (file: File) => {
  emit("files", [file])
}

const isCroppable = (file: File | undefined) => {
  if (!file) return false
  return mediaSupportedMimeTypes("image")?.includes(file.type) ?? false
}

const importFileToCrop = (files: File[]) => {
  onFileInputChange(files)
}

</script>

<script lang="ts">
export default {
  name: "CropperFileInput",
}
</script>
