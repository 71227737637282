<template>
  <div
    class="flex shrink-0 flex-col items-center justify-center border border-gray-200"
    :class="sizeWidth">
    <div class="text-secondary" :class="startMonthTextSize">
      {{ startDateMonth }}
    </div>

    <div :class="startDateDayTextSize">
      {{ startDateDay }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { formatDate } from "@vueuse/core"

const props = withDefaults(
  defineProps<{
    date: Date | undefined
    size?: "base" | "sm" | "lg"
  }>(),
  {
    size: "base",
  }
)

const sizeWidth = computed(() => {
  if (props.size === "sm") return "w-9 h-9 rounded-lg leading-snug"
  if (props.size === "lg") return "w-20 h-20 rounded-xl"
  return "w-14 h-14 rounded-lg"
})

const startDateDayTextSize = computed(() => {
  if (props.size === "sm") return "text-base font-medium leading-none"
  if (props.size === "lg") return "text-4xl font-bold leading-tight"
  return "text-2xl font-bold leading-tight"
})

const startMonthTextSize = computed(() => {
  if (props.size === "sm") return "text-xxs"
  if (props.size === "lg") return "text-sm"
  return "text-xs"
})

const startDateMonth = computed(() => {
  if (!props.date) return ""
  return formatDate(props.date, "MMM").toUpperCase()
})

const startDateDay = computed(() => {
  if (!props.date) return ""
  return formatDate(props.date, "DD")
})
</script>

<script lang="ts">
export default {
  name: "CalendarDayBox",
}
</script>