import { z } from "zod"
import { type ProductAttachment, ZProductAttachment } from "/js/models/Product"

export type EditorContentParams = {
  title: string
  data: object | null
}

export const ZEditorContentListItem = z.object({
  id: z.string(),
  title: z.string(),
  created_at: z.date(),
  updated_at: z.date(),
  usages_count: z.number(),
})

export const ZEditorContent = ZEditorContentListItem.extend({
  data: z.any().nullable() // todo: map the correct type from EditorJS (OutputData)
})

export type EditorContentListItem = z.infer<typeof ZEditorContentListItem>
export type EditorContent = z.infer<typeof ZEditorContent>

export type EditorDataHandlerParams = {
  editor_content_id?: string | null
  editor_content_data?: object | null
  duplicate?: boolean
}

export const ZProductContent = z.object({
  id: z.string(),
  title: z.string(),
  product_id: z.string(),
  editor_content_id: z.string(),
})

export const ZCommunityContent = z.object({
  id: z.string(),
  title: z.string(),
  editor_content_id: z.string(),
  cover_image: ZProductAttachment.nullable(),
  comments_count: z.number().nullable(),
})

export type ProductContent = z.infer<typeof ZProductContent>
export type CommunityContent = z.infer<typeof ZCommunityContent>

export type ProductContentParams = {
  title: string
}

export type CommunityContentParams = {
  title: string
  cover_image_id?: string | null
}

export type CommunityContentEditorDataHandlerParams = {
  community_content: CommunityContentParams
} & EditorDataHandlerParams

export type ProductContentEditorDataHandlerParams = {
  product_content: ProductContentParams
} & EditorDataHandlerParams
